export function fundingBuilderForMyCRM({
  id,
  propertyId,
  value,
  name,
  isRequired,
  type,
  description,
}) {
  const result = {
    funding_id: id,
    value,
    name,
    type,
    description,
  };
  if (propertyId) {
    result.property_id = propertyId;
    if (isRequired !== undefined) {
      result.is_only_value_editable = isRequired;
    }
  }
  return result;
}
