import { DIRECT_ASSETS_API_PATH } from 'shared/constants/apiPaths';
import {
  axiosWithAuth as directAxiosWithAuth,
  returnJSON as directReturnJSON,
  dataTransform as directDataTransform,
} from './mycrmBaseApi.js';
import { assetBuilderForMilli } from 'shared/lib/builders/milli/asset.js';
import { assetBuilderForMyCRM } from '../../shared/lib/builders/mycrm/asset';
import { incomeBuilderForMyCRM } from '../../shared/lib/builders/mycrm/income';
import { incomeBuilderForMilli } from '../../shared/lib/builders/milli/income';
import { liabilityBuilderForMyCRM } from '../../shared/lib/builders/mycrm/liability';
import { liabilityBuilderForMilli } from '../../shared/lib/builders/milli/liability';
import { addressDetailsGet, buildAddressForMyCRM } from './placesApi';
import { realEstateAssetBuilderForMyCRM } from '../../shared/lib/builders/mycrm/realEstateAsset';
import { realEstateAssetBuilderForMilli } from '../../shared/lib/builders/milli/realEstateAsset';

// Begin port of serverless assetsApi
//
export const postRealEstateIncomeForAssetDirect = (assetId, data) => {
  return directAxiosWithAuth
    .post(`${DIRECT_ASSETS_API_PATH}/${assetId}/real-estate-incomes`, data)
    .then(directReturnJSON);
};

export const postRealEstateLiabilityForAssetDirect = (assetId, data) => {
  return directAxiosWithAuth
    .post(`${DIRECT_ASSETS_API_PATH}/${assetId}/real-estate-liabilities`, data)
    .then(directReturnJSON);
};
//
// End port of serverless assetsApi

export const putAsset = (asset) => {
  return directAxiosWithAuth
    .put(`${DIRECT_ASSETS_API_PATH}/${asset.id}`, assetBuilderForMyCRM(asset))
    .then(directDataTransform(assetBuilderForMilli))
    .then(directReturnJSON);
};

export const putRealEstateAsset = (asset) => {
  return buildAddressForMyCRM(asset.address, false).then((addressId) => {
    return directAxiosWithAuth
      .put(
        `${DIRECT_ASSETS_API_PATH}/real-estates/${asset.id}`,
        realEstateAssetBuilderForMyCRM({ ...asset, addressId }),
      )
      .then(function (newAsset) {
        return addressDetailsGet(addressId).then(function (address) {
          return {
            data: {
              ...newAsset.data,
              address,
            },
          };
        });
      })
      .then(directDataTransform(realEstateAssetBuilderForMilli))
      .then(directReturnJSON);
  });
};

export const delAsset = (assetId) => {
  return directAxiosWithAuth
    .delete(`${DIRECT_ASSETS_API_PATH}/${assetId}`)
    .then(function (rowcount) {
      return { data: assetId };
    })
    .then(directReturnJSON);
};

export const postRealEstateLiabilityForAsset = (liability) => {
  return directAxiosWithAuth
    .post(
      `${DIRECT_ASSETS_API_PATH}/${liability.assetId}/real-estate-liabilities`,
      liabilityBuilderForMyCRM(liability),
    )
    .then(directDataTransform(liabilityBuilderForMilli))
    .then(directReturnJSON);
};

export const postRealEstateIncomeForAsset = (income) => {
  return directAxiosWithAuth
    .post(
      `${DIRECT_ASSETS_API_PATH}/${income.assetId}/real-estate-incomes`,
      incomeBuilderForMyCRM(income),
    )
    .then(function (incomeResponse) {
      return {
        data: {
          ...incomeBuilderForMilli(incomeResponse.data),
          assetId: parseInt(income.assetId, 10),
          category: income.category,
        },
      };
    })
    .then(directReturnJSON);
};
