/* eslint-disable sonarjs/cognitive-complexity */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import { push, goBack } from '@loan_market/react-router-redux-multi';

import { getSection } from 'lib/goalFutureHelper';
import GoalSetterForm from 'containers/goal/GoalSetterForm/GoalSetterForm';
import { featureFlags } from 'lib/rollout';

import {
  LONG_TERM_GOALS,
  LONG_TERM_GOALS_CONSOLIDATED,
} from 'shared/constants/goalLoanAppInfo';

import UIActions from 'actions/UIActions';
import goalLoanAppInfoActions from 'actions/goal/goalLoanAppInfoActions';
import applicationActions from 'actions/loanApplicationActions';
import locale from 'config/locale';

import * as applicationSelectors from 'selectors/applicationSelectors';
import * as goalLoanApplicationSelectors from 'selectors/goalLoanApplicationSelectors';
import * as goalLoanAppInfoSelectors from 'selectors/goalLoanAppInfoSelectors';
import * as clientSelectors from 'selectors/clientSelectors';

const NEXT_BUTTON_TEXT = 'Save and Next';
const CLOSE_BUTTON_TEXT = 'Save and Close';

const getSubmitButtonText = (featureFlag, nextClient) => {
  const buttonText =
    Object.keys(nextClient).length === 0 ? CLOSE_BUTTON_TEXT : NEXT_BUTTON_TEXT;
  return featureFlag ? buttonText : NEXT_BUTTON_TEXT;
};

export function GoalFutureLongTerm(props) {
  const {
    questions,
    answers,
    family,
    goTo,
    getLoanAppInfoQuestions,
    clients,
    applicationId,
    getNextClient,
    goToPathWithAnimation,
    saveLoanAppInfoAnswers,
    back,
    trackEvent,
    updateLoanApplication,
    metadata,
  } = props;
  const familyId = family.contactId;
  const [savedAnswers, updateSavedAnswers] = useState({});
  const [savedFormAnswers, updateSavedFormAnswers] = useState({});
  const featureFlag =
    featureFlags['complianceQuestions.reduceDuplication'].isEnabled() ||
    locale.isNZ;

  const nextClient = getNextClient(familyId, clients[0].id);
  const section = featureFlag ? LONG_TERM_GOALS_CONSOLIDATED : LONG_TERM_GOALS;

  const setAnswer = (questionId, value, goalTypeId) => {
    updateSavedAnswers({
      ...savedAnswers,
      [questionId]: { value, goalTypeId },
    });
    updateSavedFormAnswers({ ...savedFormAnswers, [questionId]: value });
  };

  const postAnswer = () => {
    const answersPayload = {
      section,
      loanAppId: applicationId,
      familyId,
      answers: savedAnswers,
    };
    saveLoanAppInfoAnswers(
      featureFlag && clients[0]
        ? { ...answersPayload, clientId: clients[0].id }
        : answersPayload,
    );
  };

  const saveMetadata = () => {
    if (!metadata?.saved || !metadata?.saved['long-term-goals']) {
      updateLoanApplication({
        id: applicationId,
        metadata: {
          ...metadata,
          saved: { ...metadata.saved, 'long-term-goals': true },
        },
      });
    }
  };

  const onSave = (isBack) => {
    const path = getSection({
      section,
      client: clients[0],
      family,
      nextClient,
      active: applicationId,
    }).nextPath;
    if (Object.keys(savedAnswers).length) {
      postAnswer();
      if (!isBack) {
        goToPathWithAnimation({ path, animation: [''] });
      }
      return;
    }
    saveMetadata();
    if (!isBack) {
      goTo(path);
    }
  };

  useEffect(() => {
    if (familyId) {
      getLoanAppInfoQuestions({ familyId });
    }
  }, []);

  const formProps = {
    formName: section,
    action: setAnswer,
    questions,
    answers: { ...(answers || {}), ...savedFormAnswers },
    displayName: family.familyFullName,
    submitButtonProps: {
      text: getSubmitButtonText(featureFlag, nextClient),
      action: onSave,
      theme: 'buttonNext',
    },
    back,
    trackEvent,
    subtitleSpace: !!featureFlag,
  };

  return <GoalSetterForm {...formProps} />;
}

GoalFutureLongTerm.propTypes = {
  goTo: PropTypes.func,
  goToPathWithAnimation: PropTypes.func,
  match: PropTypes.object,
  family: PropTypes.object,
  clients: PropTypes.arrayOf(PropTypes.object),
  questions: PropTypes.object,
  answers: PropTypes.object,
  saveLoanAppInfoAnswers: PropTypes.func,
  applicationId: PropTypes.number,
  getNextClient: PropTypes.func,
  getLoanAppInfoQuestions: PropTypes.func,
  back: PropTypes.func,
  trackEvent: PropTypes.func,
  updateLoanApplication: PropTypes.func,
  metadata: PropTypes.object,
};

const mapStateToProps = (state, props) => {
  const { clientId, familyId } = props.match.params;
  const featureFlag =
    featureFlags['complianceQuestions.reduceDuplication'].isEnabled() ||
    locale.isNZ;

  const section = featureFlag ? LONG_TERM_GOALS_CONSOLIDATED : LONG_TERM_GOALS;

  const clients =
    featureFlag && clientId
      ? [clientSelectors.client(state)(Number(clientId)) || {}]
      : clientSelectors.clientsOnContact(state)(Number(familyId));

  const answersForSection = goalLoanAppInfoSelectors.answersForSection(state)(
    Number(familyId),
    section,
  );

  const answers =
    featureFlag && clientId
      ? answersForSection[Number(clientId)]
      : answersForSection;

  return {
    applicationId: goalLoanApplicationSelectors.loanApplicationId(state),
    family: clientSelectors.family(state)(Number(familyId)),
    clients,
    questions: goalLoanAppInfoSelectors.questionsForSection(state)(section),
    answers,
    getNextClient: clientSelectors.nextClient(state),
    metadata: applicationSelectors.workingApplicationMetadata(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goTo: push,
      back: goBack,
      trackEvent: UIActions.trackEvent,
      goToPathWithAnimation: UIActions.goToPathWithAnimation,
      saveLoanAppInfoAnswers: goalLoanAppInfoActions.saveLoanAppInfoAnswers,
      getLoanAppInfoQuestions: goalLoanAppInfoActions.getLoanAppInfoQuestions,
      updateLoanApplication: applicationActions.updateLoanApplication,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(GoalFutureLongTerm);
