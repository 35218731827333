import { put, call, cancel, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import LocalStorageProxy from 'lib/localStorageProxy';
import scenarioActions from 'actions/scenarioActions';
import UIActions from 'actions/UIActions';
import { hasActiveSpinners } from 'selectors/UISelectors';
import { isNetworkOffline } from 'lib/errorHelper';
import { isNetworkError } from 'axios-retry';
import util from 'util';
import { getErrorStatus } from './errorHelper';
import { GHOST_ERROR_EVENT } from 'constants/GTMEvents';
import { ASYNC_REQUEST_TYPE } from 'constants/options';
export const CANCELED_MESSAGE = 'Operation canceled by the user';

function defaultIdResolver({ payload }) {
  if (payload && typeof payload !== 'object') {
    return payload;
  } else if (payload && payload.id) {
    return payload.id;
  }
  return 'new';
}

function* trackSessionExpiredEvent() {
  const clientId = LocalStorageProxy.currentClientId;
  const brokerFamilyId = LocalStorageProxy.brokerFamilyId;
  yield put(
    scenarioActions.trackOktaSessionExpired({ clientId, brokerFamilyId }),
  );
}

export function* checkIfNetworkError(error, cancelSaga) {
  if (isNetworkOffline(error) || isNetworkError(error)) {
    yield put(UIActions.setPageError({ status: 504, error }));
    if (cancelSaga) {
      yield cancel(cancelSaga);
    }
  }
}

export function monitorAsyncRequest(
  sagaTakeFn,
  type,
  fn,
  asyncType,
  idResolver = defaultIdResolver,
) {
  function* asyncRequestWrapper(action) {
    const isPopuForm = asyncType === ASYNC_REQUEST_TYPE.FORM_POP_UP_REQUEST;
    const id = idResolver(action);
    yield put(UIActions.startAsyncRequest({ id, type }));
    if (isPopuForm) {
      yield put(UIActions.processingFormPopupStatus());
    }
    try {
      yield fn(action);
      yield put(UIActions.endAsyncRequest({ id, type }));
      if (isPopuForm) {
        yield put(UIActions.processedFormPopupStatus());
        yield delay(1100);
        yield put(UIActions.resetFormPopupStatus());
      }
    } catch (error) {
      if (error?.errorCode === 'login_required') {
        yield call(trackSessionExpiredEvent);
      }
      if (error?.message === CANCELED_MESSAGE) {
        yield put(UIActions.endAsyncRequest({ id, type }));
      } else {
        console.error(util.inspect(error));
        yield put(UIActions.setAsyncRequestError({ id, type, error }));

        triggerGTMGhostErrorEvent(error);

        const isSpinnerLoading = yield select(hasActiveSpinners);
        if (isSpinnerLoading) {
          yield put(UIActions.removeSpinners());
          yield put(
            UIActions.setPageError({ status: getErrorStatus(error), error }),
          );
        }
      }
    }
  }
  return sagaTakeFn(type, asyncRequestWrapper);
}

export function monitorSpinnerRequest(sagaTakeFn, type, fn, err) {
  function* spinnerRequestWrapper(action) {
    const spinnerType = fn.name;
    yield put(UIActions.pushSpinner(spinnerType));

    try {
      yield call(fn, action);
    } catch (error) {
      if (error?.errorCode === 'login_required') {
        yield call(trackSessionExpiredEvent);
      }
      if (err) {
        yield call(err, error);
      }
    }
    yield put(UIActions.popSpinner(spinnerType));
  }
  return sagaTakeFn(type, spinnerRequestWrapper);
}

// TODO: refactor monitorAsyncRequest
export function monitorSingleAsyncProgress(sagaFn, type, callFn) {
  function* func(action) {
    yield put({ type: `STARTED_${type}` });
    yield callFn(action);
    yield put({ type: `ENDED_${type}` });
  }
  return sagaFn(type, func);
}

export const triggerGTMGhostErrorEvent = (error) => {
  const { config, status, statusText, data } = error?.response || {};
  const { accessToken } = JSON.parse(
    localStorage.getItem('mycrm-tokens') || '{}',
  );
  const { clientId, familyId, sub: email } = accessToken?.claims || {};
  window.dataLayer.push({
    event: GHOST_ERROR_EVENT,
    user_client_id: clientId,
    user_family_id: familyId,
    user_email: email,
    error_name: ('Ghost Error II ' + statusText).trim(),
    error_page_url: window.location.href,
    error_request_url: config?.url,
    error_data: data,
    error_status: status,
    error_message: error?.message,
  });
};
