import _invert from 'lodash/invert';
import * as myCRM from 'shared/constants/myCRMTypes/general';
import * as options from 'shared/constants/options';

export const MILLI_TO_MYCRM_FREQ_ID = {
  [options.YEARLY]: myCRM.YEARLY,
  [options.HALF_YEARLY]: myCRM.HALF_YEARLY,
  [options.QUARTERLY]: myCRM.QUARTERLY,
  [options.MONTHLY]: myCRM.MONTHLY,
  [options.FORTNIGHTLY]: myCRM.FORTNIGHTLY,
  [options.WEEKLY]: myCRM.WEEKLY,
};

export const MYCRM_TO_MILLI_FREQ_ID = _invert(MILLI_TO_MYCRM_FREQ_ID);
