/* eslint-disable prefer-promise-reject-errors */
import axios from 'axios';
import LocalStorageProxy from 'lib/localStorageProxy';
import { handleSessionExpiry } from 'lib/utils/browserUtils';
import { axiosRetry, retryConfig } from 'lib/axiosRetryHelper';
import { ERROR_EVENT } from 'constants/GTMEvents';
import { FILE_INVITE_API_BASE } from 'shared/constants/apiPaths';

export const axiosWithAuth = axios.create();
axiosWithAuth.defaults.baseURL = window.LM_CONFIG.FILE_INVITE_API_BASE;
axiosWithAuth.defaults.headers.common = {
  Accept: 'application/json, application/xml, text/play, text/html, *.*',
  'Content-Type': 'application/json',
};

axiosRetry(axiosWithAuth, retryConfig);

export const X_MILLICLIENTID = 'X-MilliClientId';

axiosWithAuth.interceptors.request.use(
  async (config) => {
    const token = LocalStorageProxy.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      delete config.headers.Authorization;
    }

    // // Enable once allow headers is fixed in service-document-collection to allow X-MilliClientId
    // if (LocalStorageProxy.selectedClientId) {
    //   config.headers[X_MILLICLIENTID] = LocalStorageProxy.selectedClientId;
    // } else if (LocalStorageProxy.currentClientId) {
    //   config.headers[X_MILLICLIENTID] = LocalStorageProxy.currentClientId;
    // }

    return config;
  },
  (error) => Promise.reject(error),
);

axiosWithAuth.interceptors.response.use(
  (response) => response,
  (error) => {
    triggerGTMErrorEvent(error);
    if (error.response && error.response.status === 401) {
      handleSessionExpiry();
    }
    return Promise.reject(error);
  },
);

export const dataTransform = (transform) => (responseWithData) => {
  if (!responseWithData) {
    return { data: undefined };
  }

  const responseData = responseWithData.data || responseWithData;
  const dataFroMilli = transform(responseData);
  return {
    data:
      typeof dataFroMilli === 'object'
        ? JSON.parse(JSON.stringify(dataFroMilli))
        : dataFroMilli,
  };
};

export const returnJSON = (res) =>
  res && res.data !== undefined ? res.data : res;
export const throwJSON = (err) => {
  if (err.response && err.response.data) {
    return Promise.reject({
      status: err.response.status,
      message: err.response.data,
    });
  }
  return Promise.reject();
};

export const axiosRequest = (config) => {
  return axiosWithAuth({
    ...config,
    baseURL: FILE_INVITE_API_BASE,
  });
};

export const triggerGTMErrorEvent = (error) => {
  const { config, status, statusText, data } = error?.response || {};
  const { accessToken } = JSON.parse(
    localStorage.getItem('mycrm-tokens') || '{}',
  );
  const { clientId, familyId, sub: email } = accessToken?.claims || {};
  window.dataLayer.push({
    event: ERROR_EVENT,
    user_client_id: clientId,
    user_family_id: familyId,
    user_email: email,
    error_name: ('API Error ' + statusText).trim(),
    error_page_url: window.location.href,
    error_request_url: config?.url,
    error_data: data,
    error_status: status,
  });
};
