import {
  DIRECT_LENDERS_API_PATH,
  DIRECT_LENDER_API_PATH,
} from 'shared/constants/apiPaths';
import axios, { returnJSON, throwJSON } from './baseApi';
import {
  axiosWithAuth as directAxiosWithAuth,
  returnJSON as directReturnJSON,
} from './mycrmBaseApi';
import locale from 'config/locale';
import {
  COUNTRY_NEW_ZEALAND,
  COUNTRY_AUSTRALIA,
} from 'shared/constants/myCRMTypes/countries';
import { accreditedLenderBuilderForMilli } from 'shared/lib/builders/milli/lenderBuilder';

export const getAccreditedLenders = (adviserId) => {
  return directAxiosWithAuth
    .get(`${DIRECT_LENDER_API_PATH}/${adviserId}/accredited`)
    .then(directReturnJSON)
    .then((data) => data.map(accreditedLenderBuilderForMilli));
};

export default function find(keyword) {
  return getLendersListApi();
}

export function getLendersListApi() {
  return axios
    .get(`${DIRECT_LENDERS_API_PATH}`, {
      params: {
        countryId: locale.isNZ ? COUNTRY_NEW_ZEALAND.id : COUNTRY_AUSTRALIA.id,
      },
    })
    .then(returnJSON, throwJSON);
}
