export const GOALS = 'goals';
export const PREFERENCES = 'preferences';
export const PROFILE = 'profile';
export const SERVICEABILITY = 'serviceability';
export const LOAN_AMOUNT = 'loanAmount';
export const LOAN_STRUCTURES = 'loans';
export const FUTURE = 'future';
export const TEAM = 'team';
export const SERVICES = 'services';
export const PROPOSAL = 'proposal';

export const SECTIONS_TO_VIEW_SERVICEABILITY = [
  'goal',
  'family',
  'to-purchase',
  'incomes',
  'expenses',
];

export const GOAL_PREFERENCES_KEYS = {
  [LOAN_AMOUNT]: 1,
  [SERVICEABILITY]: 2,
  [TEAM]: 3,
};
