import { MILLI_TO_MYCRM_FREQ_ID } from 'shared/lib/frequencyHelper';
import { historyBuilderForMyCRM } from './history';

export function incomeBuilderForMyCRM(income) {
  return {
    id: income.id,
    scenario_id: income.applicationId,
    asset_id: income.assetId,
    property_id: income.propertyId,
    value: income.value,
    ownership_percentage: income.clientIds
      ? 100 / income.clientIds.length
      : undefined, // rental income for prospective property deosnt take clientIds
    frequency_id: MILLI_TO_MYCRM_FREQ_ID[income.frequency],
    is_gross: income.isGross,
    category_id: income.category,
    type_id: income.type,
    client_ids: income.clientIds,
    employment_id: income.employmentId,
    description: income.description,
    ...historyBuilderForMyCRM(income),
  };
}
