import { DIRECT_LENDERS_API_PATH } from 'shared/constants/apiPaths';

let lenders = [];

export const getLendersList = async (countryId) => {
  const url = `${DIRECT_LENDERS_API_PATH}?countryId=${countryId}`;
  const response = await fetch(url, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  const results = await response.json();
  lenders = results;
  return results;
};

export default lenders;
