import { historyBuilderForMyCRM } from './history';

export function assetBuilderForMyCRM(asset) {
  return {
    ownership_percentage: 100 / asset.clientIds.length,
    value: asset.value,
    type_id: asset.type,
    client_ids: asset.clientIds,
    description: asset.description,
    make: asset.make,
    year: asset.year,
    ...historyBuilderForMyCRM(asset),
  };
}
