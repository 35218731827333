import { DIRECT_EMPLOYMENTS_API_PATH } from 'shared/constants/apiPaths';

import {
  axiosWithAuth as directAxiosWithAuth,
  dataTransform as directDataTransform,
  returnJSON as directReturnJSON,
} from './mycrmBaseApi';

import { employmentBuilderForMyCRM } from 'shared/lib/builders/mycrm/employment';
import { employmentBuilderForMilli } from 'shared/lib/builders/milli/employment';

// Begin port of serverless employmentApi
//
const putEmploymentDirect = (employmentId, data) => {
  return directAxiosWithAuth
    .put(`${DIRECT_EMPLOYMENTS_API_PATH}/${employmentId}`, data)
    .then(directReturnJSON);
};

const deleteEmploymentDirect = (employmentId) => {
  return directAxiosWithAuth
    .delete(`${DIRECT_EMPLOYMENTS_API_PATH}/${employmentId}`)
    .then(directReturnJSON);
};
//
// End port of serverless employmentApi

// port of serverless handler: employments/employments.put
export function putEmployment(employment) {
  return putEmploymentDirect(
    employment.id,
    employmentBuilderForMyCRM(employment),
  )
    .then(directDataTransform((data) => data))
    .then(directDataTransform(employmentBuilderForMilli))
    .then(directReturnJSON);
}

// port of serverless handler: employments/employments.del
export function delEmployment(employmentId) {
  return deleteEmploymentDirect(employmentId)
    .then((_) => ({ data: employmentId }))
    .then(directReturnJSON);
}

// port of serverless handler: employments/employments.validateNZBN
export const validateNZBN = (nzbn) => {
  return directAxiosWithAuth
    .get(`${DIRECT_EMPLOYMENTS_API_PATH}/validateNzbn/${nzbn}`)
    .then(directReturnJSON)
    .then((value) => {
      if (value) {
        return nzbn;
      } else {
        return Promise.reject(new Error('NZBN not found'));
      }
    });
};
