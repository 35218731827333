import { ASSET_REAL_ESTATE } from 'shared/constants/myCRMTypes/assets';
import {
  PRIMARY_PURPOSE_OWNER_OCCUPIED,
  PRIMARY_PURPOSE_INVESTMENT,
} from 'shared/constants/myCRMTypes/property';

import { assetBuilderForMyCRM } from './asset';

export function realEstateAssetBuilderForMyCRM(property) {
  return {
    ...assetBuilderForMyCRM(property),
    type_id: ASSET_REAL_ESTATE.id,
    scenario_id: property.applicationId,
    address_id: property.addressId,
    estate_address: property.address.formattedAddress,
    rental_income: !!property.generateRentalIncome,
    existing_mortgages: !!property.existingMortgages,
    primary_purpose_id: property.residence
      ? PRIMARY_PURPOSE_OWNER_OCCUPIED.id
      : PRIMARY_PURPOSE_INVESTMENT.id,
    zoning_id: property.zoningId,
    property_type_id: property.propertyTypeId,
    security_bond: property.useAsSecurity,
    value_basis: 'Applicant Estimate', // value basis is defined as a const enum in mycrm.
    value_basis_id: 1,
  };
}
