import { DIRECT_AGGREGATOR_API_PATH } from 'shared/constants/apiPaths';

import {
  axiosWithAuth as directAxiosWithAuth,
  dataTransform as directDataTransform,
  returnJSON as directReturnJSON,
} from './mycrmBaseApi';

export const aggregatorMapper = (data) => {
  return (
    data && {
      id: data.ID,
      companyNumber: `ABN ${data.CorporateNumber}`,
      licenceNumber: `Australian Credit Licence ${data.LicenceNumber}`,
      companyName: data.Name,
      privacyPolicyLink: data.PrivacyPolicyLink,
      brandFeatures: data.BrandFeatures,
    }
  );
};

const getBrandFeatures = (data) =>
  data.reduce((map, key) => ({ ...map, [key]: true }), {});

const getAggregatorBrandFeaturesDirect = (aggregatorId = 0) => {
  return directAxiosWithAuth
    .get(`${DIRECT_AGGREGATOR_API_PATH}/${aggregatorId}/brand-features`)
    .then(directReturnJSON);
};

const getAggregatorDirect = (aggregatorId = 0) => {
  return directAxiosWithAuth
    .get(`${DIRECT_AGGREGATOR_API_PATH}/${aggregatorId}`)
    .then(directReturnJSON);
};

const getAggregatorByAdvisorFamilyIdDirect = (familyId = 0) => {
  return directAxiosWithAuth
    .get(`${DIRECT_AGGREGATOR_API_PATH}/GetByAdviserFamilyId/${familyId}`)
    .then(directReturnJSON);
};

const getAndIncludeBrandFeatures = (aggregatorData) => {
  return getAggregatorBrandFeaturesDirect(aggregatorData.ID).then(
    (bfData = []) => ({
      ...aggregatorData,
      BrandFeatures: getBrandFeatures(bfData),
    }),
  );
};

export const getAggregator = (aggregatorId = 0) => {
  return getAggregatorDirect(aggregatorId)
    .then(getAndIncludeBrandFeatures)
    .then(directDataTransform(aggregatorMapper))
    .then(directReturnJSON);
};

export const getAggregatorByFamilyId = (familyId = 0) => {
  return getAggregatorByAdvisorFamilyIdDirect(familyId)
    .then(getAndIncludeBrandFeatures)
    .then(directDataTransform(aggregatorMapper))
    .then(directReturnJSON);
};
