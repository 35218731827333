const lenders = {
  _all: [],
  _aggregatorId: null,

  get all() {
    return this._all;
  },

  get bigLenders() {
    return this._all.filter((l) => l.isBigLender);
  },

  get userPanel() {
    return this._aggregatorId
      ? this._all.filter((l) => l.aggregatorIds.includes(this._aggregatorId))
      : this._all;
  },

  get data() {
    return {
      all: this._all,
      aggregatorId: this._aggregatorId,
    };
  },

  set data({ _all = this._all, _aggregatorId = this._aggregatorId }) {
    this._all = [..._all];
    this._aggregatorId = _aggregatorId;
  },
};

export default lenders;

export const getLenderName = (lenderId) => {
  const lender = lenders.all.find((b) => b.lenderId === lenderId);

  return lender && lender.lenderName;
};

export const isLenderBigFour = (lenderId) =>
  lenders.bigLenders.findIndex((b) => b.lenderId === lenderId) !== -1;

export const getLenderIdFromMogoName = (mogoName) => {
  const lender = lenders.all.find((b) => b.mogoName && b.mogoName === mogoName);

  return lender && lender.lenderId;
};

export const getLenderFilterBycountryCode = (countryCode) => {
  const code = countryCode === 'AU' ? 2 : 1;
  return lenders._all.filter((lender) => lender.countryCode === code);
};
