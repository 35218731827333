import { DIRECT_GOAL_SETTER_PATH } from 'shared/constants/apiPaths';
import {
  axiosWithAuth as directAxiosWithAuth,
  dataTransform as directDataTransform,
  returnJSON as directReturnJSON,
} from '../mycrmBaseApi';
import { goalPreferencesBuilderForMilli } from '../../../shared/lib/builders/milli/goalPreferences';
import { goalPreferencesBuilderForMyCRM } from '../../../shared/lib/builders/mycrm/goalPreferences';

// Begin of port of serverless goalSetterApi
//
const getPreferencesDirect = (adviserId) => {
  return directAxiosWithAuth
    .get(`${DIRECT_GOAL_SETTER_PATH}/advisers/${adviserId}/settings/sections`)
    .then(directReturnJSON);
};

export const postPreferencesDirect = (adviserId, data) => {
  return directAxiosWithAuth
    .post(
      `${DIRECT_GOAL_SETTER_PATH}/advisers/${adviserId}/settings/sections`,
      data,
    )
    .then(directReturnJSON);
};
//
// End of port of serverless goalPreferencesApi

// port of serverless handler: goalPreferences/goalPreferences.getGoalPrefences
export function getPreferences(adviserId) {
  return getPreferencesDirect(adviserId)
    .then(directDataTransform(goalPreferencesBuilderForMilli))
    .then(directReturnJSON);
}

// port of serverless handler: goalPreferences/goalPreferences.postGoalPreferences
export function postPreferences(adviserId, data) {
  return postPreferencesDirect(adviserId, goalPreferencesBuilderForMyCRM(data))
    .then(directDataTransform(goalPreferencesBuilderForMilli))
    .then(directReturnJSON);
}
