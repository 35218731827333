import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SharedSectionTile from '@bit/loanmarket.share.components.section-tile';
import { logEvent, EVENTS } from 'lib/amplitude';

import styles from './SectionTile.css';

const ApplySummary = ({
  disabled,
  id,
  iconName,
  image,
  imageAlt,
  isOpen,
  isCompleted,
  isLocked,
  noStatus,
  onClick,
  title,
  titleDescription,
  theme,
  warningMessage,
}) => {
  const hasWarning = warningMessage && warningMessage !== '';
  let actionIcon;
  let actionText;

  if (isLocked) {
    actionIcon = 'sl-custom-lock-2';
  } else if (hasWarning) {
    actionIcon = 'mi-icon-warning-section';
    actionText = 'Needs review';
  } else if (isCompleted) {
    actionIcon = 'sl-custom-chat-1-1';
    actionText = 'Completed';
  } else if (noStatus) {
    actionIcon = 'mi-arrow-with-circle-right';
  } else {
    actionIcon = 'sl-custom-arrow-circle-29';
    actionText = 'Please complete';
  }

  const className = classNames(theme, {
    [styles.open]: isOpen,
  });

  const actionClass = classNames({
    [styles.completed]: isCompleted,
    [styles.locked]: isLocked,
    [styles.warning]: hasWarning,
  });

  const onSectionClick = () => {
    !id.includes('accordionSection') &&
      logEvent(EVENTS.OPEN_SECTION, { section: id });
    onClick();
  };

  return (
    <SharedSectionTile
      actionClass={actionClass}
      actionIcon={actionIcon}
      actionText={actionText}
      disabled={disabled}
      id={id}
      iconName={iconName}
      image={image}
      imageAlt={imageAlt}
      onClick={onSectionClick}
      title={title}
      titleDescription={titleDescription}
      theme={className}
    />
  );
};

ApplySummary.propTypes = {
  disabled: PropTypes.bool,
  iconName: PropTypes.string,
  id: PropTypes.string,
  image: PropTypes.string,
  imageAlt: PropTypes.string,
  isOpen: PropTypes.bool,
  isCompleted: PropTypes.bool,
  isLocked: PropTypes.bool,
  noStatus: PropTypes.bool,
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  titleDescription: PropTypes.string.isRequired,
  theme: PropTypes.string,
  warningMessage: PropTypes.string,
};

ApplySummary.defaultProps = {
  isOpen: false,
  isLocked: false,
  noStatus: false,
  hasWarning: false,
};

export default ApplySummary;
