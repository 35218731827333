import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import { shortStringWithoutCuttingWords } from 'lib/utils/stringUtils';

const messages = defineMessages({
  showMoreText: {
    id: 'TextSummary.showMore.text',
    defaultMessage: 'Show more',
  },
  showLessText: {
    id: 'TextSummary.showLess.text',
    defaultMessage: 'Show less',
  },
});

class TextSummary extends Component {
  static propTypes = {
    children: PropTypes.string.isRequired,
    previewLength: PropTypes.number,
    intl: intlShape.isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    previewLength: 130,
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpenDescription: false,
    };
  }

  toggleShowMore = () => {
    this.setState({
      isOpenDescription: !this.state.isOpenDescription,
    });
  };

  showLessShowMore() {
    const { isOpenDescription } = this.state;
    const {
      intl: { formatMessage },
    } = this.props;
    return isOpenDescription
      ? formatMessage(messages.showLessText)
      : formatMessage(messages.showMoreText);
  }

  render() {
    const { previewLength, children, className } = this.props;
    const longtext = children ? children.replace(/\s\s+/g, ' ') : '';
    const previewDescription = shortStringWithoutCuttingWords(
      longtext,
      previewLength,
    );
    const shortDescriptionLength = previewDescription.length;
    const stringNeedsTrimming = longtext.length > shortDescriptionLength;
    const description =
      !stringNeedsTrimming || this.state.isOpenDescription
        ? longtext
        : `${previewDescription}... `;
    return (
      <p className={className}>
        {description}
        <a onClick={this.toggleShowMore}>
          {stringNeedsTrimming ? this.showLessShowMore() : null}
        </a>
      </p>
    );
  }
}

export default injectIntl(TextSummary);
