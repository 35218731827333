/* eslint-disable no-unused-expressions */
import { createSelector } from 'reselect';
import { selectAggregator } from 'selectors/aggregatorSelectors';
import { advisor } from 'selectors/advisorSelectors';
import { advisorOrg } from 'selectors/advisorOrgSelectors';

export const token = (state) => state.privacyPolicy.token;
export const isTokenValid = (state) => state.privacyPolicy.isTokenValid;
export const clientName = (state) => state.privacyPolicy.clientName;
export const executingToken = (state) => state.privacyPolicy.executingToken;

export const selectPrivacyPolicyLink = createSelector(
  advisor,
  advisorOrg,
  selectAggregator,
  (_a, _ao, _agg) =>
    _a?.privacyPolicyLink ??
    _ao?.privacyPolicyLink ??
    _agg?.privacyPolicyLink ??
    'https://loanmarketgroup.com/consumer-privacy',
);
