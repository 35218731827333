import { MYCRM_TO_MILLI_FREQ_ID } from 'shared/lib/frequencyHelper';
import { historyBuilderForMilli } from './history';

export function incomeBuilderForMilli(income) {
  return {
    id: income.id,
    assetId: income.asset_id,
    propertyId: income.property_id,
    value: income.value,
    frequency: MYCRM_TO_MILLI_FREQ_ID[income.frequency_id],
    isGross: income.is_gross,
    category: income.category_id,
    type: income.type_id,
    clientIds: income.client_ids ? income.client_ids.sort() : undefined,
    description: income.description,
    employmentId: income.employment_id,
    ...historyBuilderForMilli(income),
  };
}
