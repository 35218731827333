import { createSelector } from 'reselect';
import _ from 'lodash';

import * as clientSelectors from 'selectors/clientSelectors';
import { makeFormalDisplayName } from 'lib/utils/formUtils';

export const workingEmployments = (state) => state.employment.working;

export const employments = (state) => state.employment.entities;
export const employmentOptions = createSelector(
  employments,
  clientSelectors.client,
  (a, b) => {
    const options = a.map((employment) => {
      const client = b(employment.clientId);
      return {
        key: employment.id,
        value: employment.id,
        name: `${employment.employerName} - ${makeFormalDisplayName(client)}`,
      };
    });
    options.push({
      key: 'new',
      value: 'new',
      name: '+ Add a new employer...',
    });

    return options;
  },
);

export const entity = createSelector(employments, (a) =>
  _.memoize((id) => a.find((e) => e.id === id)),
);
export const working = (state) => (id) => workingEmployments(state)[id];

export const clientsEmployments = createSelector(employments, (a) =>
  _.memoize((clientId) => a.filter((e) => e.clientId === clientId)),
);

export const primaryContactEmployments = createSelector(
  employments,
  clientSelectors.isPrimaryOrPartnerClient,
  (e, isPrimaryContact) =>
    e.filter(({ clientId }) => isPrimaryContact(clientId)),
);

export const NZBNLoading = (state) => state.employment.NZBNLoading;

export const hasOldInformation = createSelector(employments, (a) =>
  a.some((b) => b.isOldData),
);
