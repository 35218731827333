import {
  DIRECT_APPLICATIONS_API_PATH,
  DIRECT_GOAL_LOAN_APP_INFO_API_PATH,
} from 'shared/constants/apiPaths';

import {
  axiosWithAuth as directAxiosWithAuth,
  dataTransform as directDataTransform,
  returnJSON as directReturnJSON,
} from './mycrmBaseApi';

import { clientBuilderForMilli } from '../../shared/lib/builders/milli/client';
import { companyApplicantBuilderForMilli } from '../../shared/lib/builders/milli/companyApplicant';
import { fundingBuilderForMilli } from '../../shared/lib/builders/milli/funding';
import { propertyBuilderForMilli } from '../../shared/lib/builders/milli/property';
import { structureBuilderForMilli } from '../../shared/lib/builders/milli/structure';
import { securityBuilderForMilli } from '../../shared/lib/builders/milli/security';
import { documentBuilderForMilli } from '../../shared/lib/builders/milli/document';
import { foreseeableChangesBuilderForMilli } from '../../shared/lib/builders/milli/foreseeableChanges';
import { sharedLoanAppInfoBuilderForMilli } from '../../shared/lib/builders/milli/sharedLoanAppInfo';
import { loanApplicationBuilderForMilli } from '../../shared/lib/builders/milli/loanApplication';

import { loanApplicationBuilderForMyCRM } from '../../shared/lib/builders/mycrm/loanApplication';
import { clientBuilderForMyCRM } from '../../shared/lib/builders/mycrm/client';
import { companyApplicantBuilderForMyCRM } from '../../shared/lib/builders/mycrm/companyApplicant';
import { fundingBuilderForMyCRM } from '../../shared/lib/builders/mycrm/funding';
import { propertyBuilderForMyCRM } from '../../shared/lib/builders/mycrm/property';
import { structureBuilderForMyCRM } from '../../shared/lib/builders/mycrm/structure';
import { foreseeableChangesBuilderForMyCRM } from '../../shared/lib/builders/mycrm/foreseeableChanges';
import { TRANSACTION_OWNS } from '../../shared/constants/myCRMTypes/property';

// Begin port of serverless loanApplicationsApi
//
export const postPropertyForApplicationDirect = (loanApplicationId, data) => {
  return directAxiosWithAuth
    .post(
      `${DIRECT_APPLICATIONS_API_PATH}/${loanApplicationId}/properties`,
      data,
    )
    .then(directReturnJSON);
};

export const postApplicantDirect = (loanApplicationId, data) => {
  return directAxiosWithAuth
    .post(
      `${DIRECT_APPLICATIONS_API_PATH}/${loanApplicationId}/applicants`,
      data,
    )
    .then(directReturnJSON);
};

export const postFundingForLoanApplicationDirect = (
  loanApplicationId,
  data,
) => {
  return directAxiosWithAuth
    .post(`${DIRECT_APPLICATIONS_API_PATH}/${loanApplicationId}/fundings`, data)
    .then(directReturnJSON);
};

export const postStructureForLoanApplicationDirect = (
  loanApplicationId,
  data,
) => {
  return directAxiosWithAuth
    .post(
      `${DIRECT_APPLICATIONS_API_PATH}/${loanApplicationId}/structures`,
      data,
    )
    .then(directReturnJSON);
};

export const getLoanApplicantsDirect = (loanApplicationId) => {
  return directAxiosWithAuth
    .get(
      `${DIRECT_GOAL_LOAN_APP_INFO_API_PATH}/ScenarioApplicantListGet?loanScenarioId=${loanApplicationId}`,
    )
    .then(directReturnJSON);
};

export const postDocumentToLoanApplicationDirect = (
  loanApplicationId,
  data,
) => {
  return directAxiosWithAuth
    .post(
      `${DIRECT_APPLICATIONS_API_PATH}/${loanApplicationId}/documents`,
      data,
    )
    .then(directReturnJSON);
};
//
// End of port of serverless loanApplicationsApi

// port of serverless handler: loanApplications/loanApplications.get
export function getLoanApplication(loanApplicationId) {
  return directAxiosWithAuth
    .get(`${DIRECT_APPLICATIONS_API_PATH}/${loanApplicationId}`)
    .then(directDataTransform(loanApplicationBuilderForMilli))
    .then(directReturnJSON);
}

// port of serverless handler: loanApplications/loanApplications.put
export function putLoanApplication(loanApplication) {
  return directAxiosWithAuth
    .put(
      `${DIRECT_APPLICATIONS_API_PATH}/${loanApplication.id}`,
      loanApplicationBuilderForMyCRM(loanApplication),
    )
    .then(directReturnJSON);
}

// port of serverless handler: loanApplications/loanApplications.putStructures
export function putStructureForLoanApplication(structure) {
  return directAxiosWithAuth
    .put(
      `${DIRECT_APPLICATIONS_API_PATH}/${structure.loanApplicationId}/structures/${structure.id}`,
      structureBuilderForMyCRM(structure),
    )
    .then(directReturnJSON);
}

// port of serverless handler: loanApplications/loanApplications.postApplicants
export function postApplicant(applicant) {
  return directAxiosWithAuth
    .post(
      `${DIRECT_APPLICATIONS_API_PATH}/${applicant.loanApplicationId}/applicants`,
      clientBuilderForMyCRM({ ...applicant, isPrimary: true }),
    )
    .then(directDataTransform(clientBuilderForMilli))
    .then(directReturnJSON);
}

// port of serverless handler: loanApplications/loanApplications.postCompanyApplicants
export function postCompanyApplicant(applicant) {
  return directAxiosWithAuth
    .post(
      `${DIRECT_APPLICATIONS_API_PATH}/${applicant.loanApplicationId}/company-applicants`,
      companyApplicantBuilderForMyCRM({ ...applicant, isPrimary: true }),
    )
    .then(directDataTransform(companyApplicantBuilderForMilli))
    .then(directReturnJSON);
}

// port of serverless handler: loanApplications/loanApplications.getProperties
export function getProperties(loanApplicationId) {
  return directAxiosWithAuth
    .get(`${DIRECT_APPLICATIONS_API_PATH}/${loanApplicationId}/properties`)
    .then(({ data }) => {
      /* owned properties are handled in GET contacts/id/assets */
      const prospectiveOrSoldProperties = data.filter(
        (p) => p.transaction_id !== TRANSACTION_OWNS,
      );
      return prospectiveOrSoldProperties.map(propertyBuilderForMilli);
    })
    .then(directReturnJSON);
}

// port of serverless handler: loanApplications/loanApplications.getFundings
export function getFundingsFromLoanApplication(loanApplicationId) {
  return directAxiosWithAuth
    .get(`${DIRECT_APPLICATIONS_API_PATH}/${loanApplicationId}/fundings`)
    .then(({ data }) => {
      return data.map(fundingBuilderForMilli);
    })
    .then(directReturnJSON);
}

// port of serverless handler: loanApplications/loanApplications.getStructures
export function getStructuresFromLoanApplication(loanApplicationId) {
  return directAxiosWithAuth
    .get(`${DIRECT_APPLICATIONS_API_PATH}/${loanApplicationId}/structures`)
    .then(({ data }) => {
      return data.map((d) =>
        _.merge(structureBuilderForMilli(d), { loanApplicationId }),
      );
    })
    .then(directReturnJSON);
}

// port of serverless handler: loanApplications/loanApplications.getSecurities
export function getSecuritiesFromLoanApplication(loanApplicationId) {
  return directAxiosWithAuth
    .get(`${DIRECT_APPLICATIONS_API_PATH}/${loanApplicationId}/securities`)
    .then(({ data }) => {
      return data.map(securityBuilderForMilli);
    })
    .then(directReturnJSON);
}

// port of serverless handler: loanApplications/loanApplications.postStructures
export function postStructureForLoanApplication(structure) {
  const loanApplicationId = structure.loanApplicationId;
  return directAxiosWithAuth
    .post(
      `${DIRECT_APPLICATIONS_API_PATH}/${loanApplicationId}/structures`,
      structureBuilderForMyCRM(structure),
    )
    .then(({ data }) => {
      return _.merge(structureBuilderForMilli(data), { loanApplicationId });
    })
    .then(directReturnJSON);
}

// port of serverless handler: loanApplications/loanApplications.postFundings
export function postFundingForLoanApplication(funding) {
  const loanApplicationId = funding.loanApplicationId;
  return directAxiosWithAuth
    .post(`${DIRECT_APPLICATIONS_API_PATH}/${loanApplicationId}/fundings`, [
      fundingBuilderForMyCRM(funding),
    ])
    .then(({ data }) => {
      return data.map((d) =>
        _.merge(fundingBuilderForMilli(d), { loanApplicationId }),
      );
    })
    .then(directReturnJSON);
}

// port of serverless handler: loanApplications/loanApplications.postProperties
export function postPropertyForApplication(property) {
  return directAxiosWithAuth
    .post(
      `${DIRECT_APPLICATIONS_API_PATH}/${property.applicationId}/properties`,
      propertyBuilderForMyCRM(property),
    )
    .then(directDataTransform(propertyBuilderForMilli))
    .then(directReturnJSON);
}

// port of serverless handler: loanApplications/loanApplications.getDocuments
export function getDocumentsForLoanApplication(
  loanApplicationId,
  countryCode = 'AU',
) {
  return directAxiosWithAuth
    .get(`${DIRECT_APPLICATIONS_API_PATH}/${loanApplicationId}/documents`)
    .then(({ data }) => {
      return data.map((d) => documentBuilderForMilli({ ...d, countryCode }));
    })
    .then(directReturnJSON);
}

// port of serverless handler: loanApplications/loanApplications.getForeseeableChanges
export function getForeseeableChangesFromLoanApplication(loanApplicationId) {
  return directAxiosWithAuth
    .get(
      `${DIRECT_APPLICATIONS_API_PATH}/${loanApplicationId}/foreseeable-changes`,
    )
    .then(({ data }) => data.map(foreseeableChangesBuilderForMilli))
    .then(directReturnJSON);
}

// port of serverless handler: loanApplications/loanApplications.putForeseeableChanges
export function putForeseeableChangesForLoanApplication(forseeableChanges) {
  return directAxiosWithAuth
    .put(
      `${DIRECT_APPLICATIONS_API_PATH}/${forseeableChanges.loanApplicationId}/foreseeable-changes`,
      foreseeableChangesBuilderForMyCRM(forseeableChanges),
    )
    .then(directReturnJSON);
}

// port of serverless handler: loanApplications/loanApplications.getSharedLoanAppInfo
export const getSharedLoanAppInfo = (token) => {
  return directAxiosWithAuth
    .get(`${DIRECT_APPLICATIONS_API_PATH}/shared-loan-app-info`, {
      params: token ? { token } : {},
    })
    .then(({ data }) => {
      return sharedLoanAppInfoBuilderForMilli(data);
    })
    .then(directReturnJSON);
};
