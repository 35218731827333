import { historyBuilderForMilli } from './history';
import { realEstateAssetInfoBuilderForMilli } from './realEstateAsset';

const ASSET_TYPE_REAL_ESTATE = 'Real Estate';

export function assetBuilderForMilli(asset) {
  return {
    id: asset.id,
    value: asset.value,
    type: asset.type_id,
    clientIds: asset.client_ids ? asset.client_ids.sort() : undefined,
    category: asset.category_id,
    description: asset.description,
    make: asset.make,
    year: asset.year,
    ...historyBuilderForMilli(asset),
  };
}

export function assetsInfoBuilderForMilli(assetsInfo) {
  const assets = {};
  const realEstateAssets = {};
  assetsInfo.forEach((info) => {
    info.overview.FinancialInfo.forEach((f) => {
      const isRealEstate = f.Type === ASSET_TYPE_REAL_ESTATE;
      const existingAsset = isRealEstate
        ? realEstateAssets[f.Id]
        : assets[f.Id];
      const contactIds = existingAsset
        ? existingAsset.contactIds.concat(info.FamilyId)
        : [info.FamilyId];
      if (isRealEstate) {
        realEstateAssets[f.Id] = realEstateAssetInfoBuilderForMilli(
          f,
          contactIds,
        );
      } else {
        assets[f.Id] = assetInfoBuilderForMilli(f, contactIds);
      }
    });
  });

  return {
    assets: Object.keys(assets).reduce((a, k) => a.concat(assets[k]), []),
    realEstateAssets: Object.keys(realEstateAssets).reduce(
      (a, k) => a.concat(realEstateAssets[k]),
      [],
    ),
  };
}

export function assetInfoBuilderForMilli(asset, contactIds) {
  return {
    id: asset.Id,
    value: asset.Value,
    type: asset.TypeId,
    clientIds: asset.Borrowers
      ? asset.Borrowers.map((b) => b.BorrowerID)
      : undefined,
    category: asset.CategoryId,
    description: asset.Description,
    make: asset.Make,
    year: asset.Year,
    contactIds,
    ...historyBuilderForMilli(asset),
  };
}
