import { createSelector } from 'reselect';
import _ from 'lodash';

import locale from 'config/locale';
import { MONTHLY } from 'shared/constants/options';
import { convertFrequency } from 'shared/lib/numbrero';
import { isOrderlessEqual } from 'lib/utils/dataUtils';

import { totalLiabilityRepayments } from 'selectors/liabilitySelectors';
import { isPrimaryOrPartnerClient } from 'selectors/clientSelectors';
import { latestDataCollectionEventAt } from 'selectors/dataCollectionSelectors';
import * as expenseCategoryTypeSelectors from 'selectors/expenseCategoryTypeSelectors';

export const errors = (state) => state.expense.errors;
export const workingExpenses = (state) => state.expense.working;
export const expenses = (state) => state.expense.entities;

export const hasOldInformation = createSelector(expenses, (a) =>
  a.some((b) => b.isOldData),
);

export const primaryContactExpenses = createSelector(
  expenses,
  isPrimaryOrPartnerClient,
  (e, isPrimaryContact) =>
    e.filter(({ clientIds = [] }) => clientIds.some(isPrimaryContact)),
);

export const clientsExpenses = createSelector(expenses, (a) =>
  _.memoize(
    (clientIds) => a.filter((e) => isOrderlessEqual(e.clientIds, clientIds)),
    (clientIds) => clientIds.join('-'),
  ),
);

export const hasOldClientInformation = createSelector(clientsExpenses, (a) =>
  _.memoize((clientIds) => {
    const b = a(clientIds);
    return b.some((c) => c.isOldData);
  }),
);

export const expenseByCategoryId = createSelector(primaryContactExpenses, (a) =>
  _.groupBy(a, 'categoryId'),
);

export const totalMonthlyExpenses = createSelector(
  primaryContactExpenses,
  totalLiabilityRepayments,
  (a, b) =>
    _.sumBy(a, (v) =>
      convertFrequency(v.value, v.frequency || MONTHLY, MONTHLY),
    ) + b,
);

export const entity = createSelector(expenses, (selectedExpenses) =>
  _.memoize((id) => selectedExpenses.find((e) => e.id === id)),
);

export const working = createSelector(
  workingExpenses,
  (selectedWorkingExpenses) => _.memoize((id) => selectedWorkingExpenses[id]),
);

const expenseIsPending = createSelector(
  expenseCategoryTypeSelectors.typeInfo,
  (getTypeInfo) => (item) => {
    return false;
  },
);

export const anyExpensePending = createSelector(
  expenses,
  expenseIsPending,
  (a, b) => a.some((e) => b(e)),
);

const expenseByTypeId = createSelector(primaryContactExpenses, (a) =>
  _.memoize((typeId) => a.filter((e) => e.typeId === typeId)),
);

export const reportMetadataForTypeId = createSelector(
  expenseByTypeId,
  expenseIsPending,
  expenseCategoryTypeSelectors.typeInfo,
  (a, isPending, getTypeInfo) =>
    // eslint-disable-next-line sonarjs/cognitive-complexity
    _.memoize((typeId) => {
      const savedItems = a(typeId);
      const info = getTypeInfo(typeId);
      let items = [];

      // eslint-disable-next-line unicorn/explicit-length-check
      if (savedItems.length) {
        items = savedItems.map((item) => {
          return {
            name: info.isOther ? `Other: ${item.description || ''}` : info.name,
            icon: item.uncategorised ? 'sl-custom-question-mark' : info.icon,
            url: `/expense/${item.id}`,
            value: convertFrequency(item.value, item.frequency, MONTHLY),
            highlighted: false,
            nameInfoLabel: '',
            isOldData: item.isOldData,
          };
        });
        if (!info.isOther) {
          return items;
        }
      }

      items.push({
        name: info.isOther ? 'Other' : info.name,
        ignore: info.isOther,
        icon: info.isOther ? 'sl-custom-list-2-1' : info.icon,
        url: `/expense/new/?typeId=${info.id}`,
        value: undefined,
        highlighted: false,
        nameInfoLabel: '',
      });

      return items;
    }),
);

// TODO: Refactor to isolate metadata from this context, so it can be used to auto generate routes
export const reportMetadata = createSelector(
  reportMetadataForTypeId,
  expenseCategoryTypeSelectors.expenseCategoryTypes,
  (typeMetadata, expenseCategories) =>
    expenseCategories.map((category) => {
      const { types } = category;
      const items = [];
      types.forEach((type) => {
        if (
          type.availableCountries &&
          !type.availableCountries.includes(locale.data.countryCode)
        ) {
          return;
        }
        items.push(typeMetadata(type.id));
      });

      const flatItems = _.flatten(items);
      return {
        label: category.name,
        isAccordion: true,
        items: flatItems,
        isOpenByDefault: flatItems.some((i) => i.highlighted),
      };
    }),
);

export const displayDataCollectionBanners = createSelector(
  primaryContactExpenses,
  latestDataCollectionEventAt,
  (expenses, latestDataCollectionEventAt) => {
    if (latestDataCollectionEventAt !== undefined && expenses.length > 0) {
      return (
        expenses.filter((e) => {
          if (e.dateUpdated == null) {
            return false;
          }
          const updatedAtTicks = new Date(e.dateUpdated).getTime();
          const latestDataCollectionTicks =
            latestDataCollectionEventAt.getTime() + 1000; // Additional 1 second time skew
          return updatedAtTicks > latestDataCollectionTicks;
        }).length === 0
      );
    }
    return false;
  },
);
