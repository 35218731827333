import _ from 'lodash';
import {
  VARIABLE,
  FIXED_ONE_YEAR,
  FIXED_TWO_YEAR,
  FIXED_THREE_YEAR,
  FIXED_FIVE_YEAR,
} from 'shared/constants/interestType';
import { calculateAverageMonthlyRepayment } from 'shared/lib/calculators';

const PERIODICAL_INTEREST_PAYABLE_URL = '/fincalc/PeriodicalInterestPayable';

export const createInterestValuesRequest = ({ loanAmount, loanTerm, rate }) => {
  const params = {
    loanAmount,
    interestRate: rate,
    startPeriod: 1,
    repaymentFrequency: 12,
    loanTermYears: loanTerm,
  };

  const variable = {
    resource: PERIODICAL_INTEREST_PAYABLE_URL,
    method: 'GET',
    data: {},
    request_id: 'variable',
    parameters: {
      ...params,
      endPeriod: _.toNumber(loanTerm) * 12,
    },
  };
  return new Array(5).fill().reduce(
    (requests, v, k) =>
      k === 3
        ? requests // exclude fixed 4 years
        : [
            ...requests,
            {
              request_id: `fixed_${k + 1}_year`,
              resource: PERIODICAL_INTEREST_PAYABLE_URL,
              method: 'GET',
              data: {},
              parameters: {
                ...params,
                endPeriod: (k + 1) * 12,
              },
            },
          ],
    [variable],
  );
};

export const getInterestSavings = (
  type,
  loanTerm,
  totalCurrentPayable,
  initial,
  ongoing,
) => {
  const currentMonthly = totalCurrentPayable / 12 / loanTerm;
  let fixedTerm = loanTerm;
  let ongoingAmount = ongoing;
  switch (type) {
    case VARIABLE:
      ongoingAmount = 0;
      break;
    case FIXED_ONE_YEAR:
      fixedTerm = 1;
      break;
    case FIXED_TWO_YEAR:
      fixedTerm = 2;
      break;
    case FIXED_THREE_YEAR:
      fixedTerm = 3;
      break;
    case FIXED_FIVE_YEAR:
      fixedTerm = 5;
      break;
    default:
      throw new Error('This product type is not supported!');
  }

  return (
    currentMonthly -
    calculateAverageMonthlyRepayment(
      loanTerm,
      fixedTerm,
      initial,
      ongoingAmount,
    )
  );
};

export const attachSavingsValue = (
  loanAmount,
  loanTerm,
  estimateInterestValues,
  products,
) => {
  return products.map((product) => {
    const { initial, ongoing } = product.repayments.monthly;
    const totalCurrentPayable = estimateInterestValues[VARIABLE] + loanAmount;
    return {
      ...product,
      saving: getInterestSavings(
        product.type,
        loanTerm,
        totalCurrentPayable,
        initial.amount,
        ongoing.amount,
      ),
    };
  });
};

export function mapEstimateSavingValues(responses) {
  return responses.reduce((result, res) => {
    if (res.status !== 200) {
      console.error(res.data);
      return result;
    }
    result[res.request_id] = _.toNumber(res.data);
    return result;
  }, {});
}
