import { intlMobile } from 'shared/lib/utils';

export function companyApplicantBuilderForMyCRM(company) {
  const {
    loanApplicationId,
    businessName,
    description,
    nzbn,
    tfn,
    acn,
    officePhone,
    officeEmail,
    brokerId,
    contactId,
  } = company;
  const result = {
    id: company.companyId,
    name: businessName,
    description,
    tax_number: tfn,
    number: acn,
    co_applicant: true,
    phone_number: intlMobile(officePhone),
    email: officeEmail,
    scenario_id: loanApplicationId,
    family_id: contactId,
    nzbn,
  };
  if (brokerId) {
    result.allocated_broker_family_id = brokerId;
  }
  return result;
}
