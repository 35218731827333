import { takeEvery, all, put, select, call } from 'redux-saga/effects';

import * as contactSelectors from 'selectors/contactSelectors';

import {
  CREATE_ASSET,
  UPDATE_ASSET,
  DELETE_ASSET,
  CONFIRM_ASSETS,
} from 'actions/assetActionTypes';
import assetActions from 'actions/assetActions';

import { putAsset, delAsset } from 'services/assetApi';

import { postAssetForClient } from 'services/clientApi';

import { postAssetForContact } from 'services/contactApi';

import { monitorAsyncRequest } from 'lib/sagaHelpers.js';

import { ASYNC_REQUEST_TYPE } from 'constants/options';

export function* createAsset({ payload }) {
  let asset;
  if (payload.clientIds.length === 1) {
    asset = yield call(postAssetForClient, {
      ...payload,
      clientId: payload.clientIds[0],
    });
  } else {
    const primaryContact = yield select(contactSelectors.primaryContact);
    asset = yield call(postAssetForContact, primaryContact.contactId, payload);
  }
  yield put(assetActions.setNewAsset(asset));
}

export function* updateAsset({ payload }) {
  const asset = yield call(putAsset, payload);
  yield put(assetActions.setAsset({ ...payload, ...asset }));
}

export function* confirmAssets({ payload: { assets } }) {
  yield all(assets.map((payload) => call(updateAsset, { payload })));
}

export function* deleteAsset({ payload }) {
  const assetId = yield call(delAsset, payload);
  yield put(assetActions.removeAsset(assetId));
}

function* watchCreateAsset() {
  yield monitorAsyncRequest(
    takeEvery,
    CREATE_ASSET,
    createAsset,
    ASYNC_REQUEST_TYPE.FORM_POP_UP_REQUEST,
  );
}

function* watchUpdateAsset() {
  yield monitorAsyncRequest(
    takeEvery,
    UPDATE_ASSET,
    updateAsset,
    ASYNC_REQUEST_TYPE.FORM_POP_UP_REQUEST,
  );
}

function* watchDeleteAsset() {
  yield monitorAsyncRequest(
    takeEvery,
    DELETE_ASSET,
    deleteAsset,
    ASYNC_REQUEST_TYPE.FORM_POP_UP_REQUEST,
  );
}

function* watchConfirmAssets() {
  yield monitorAsyncRequest(takeEvery, CONFIRM_ASSETS, confirmAssets);
}

export default function* assetSagas() {
  yield all([
    watchCreateAsset(),
    watchUpdateAsset(),
    watchDeleteAsset(),
    watchConfirmAssets(),
  ]);
}
