import { DIRECT_ENTITIES_API_PATH } from 'shared/constants/apiPaths';
import {
  axiosWithAuth as directAxiosWithAuth,
  dataTransform as directDataTransform,
  returnJSON as directReturnJSON,
} from './mycrmBaseApi';
import { companyApplicantBuilderForMyCRM } from '../../shared/lib/builders/mycrm/companyApplicant';
import { companyApplicantBuilderForMilli } from '../../shared/lib/builders/milli/companyApplicant';

// Begin port of serverless companiesApi
//
const getCompanyDirect = (companyId, params) => {
  return directAxiosWithAuth
    .get(`${DIRECT_ENTITIES_API_PATH}/${companyId}`, {
      params: params,
    })
    .then(directReturnJSON);
};

export const putCompanyDirect = (companyId, data) => {
  return directAxiosWithAuth
    .put(`${DIRECT_ENTITIES_API_PATH}/${companyId}`, data)
    .then(directReturnJSON);
};

const delCompanyDirect = (companyId, applicationId) => {
  return directAxiosWithAuth
    .delete(`${DIRECT_ENTITIES_API_PATH}/${companyId}`, {
      params: {
        application_id: applicationId,
      },
    })
    .then(directReturnJSON);
};
//
// End port of serverless companiesApi

// port of serverless handler: companies/companies.get
export function getCompany(companyId, contactId) {
  return getCompanyDirect(
    companyId,
    companyApplicantBuilderForMyCRM({ contactId }),
  )
    .then(directDataTransform(companyApplicantBuilderForMilli))
    .then(directReturnJSON);
}

// port of serverless handler: companies/companies.put
export function updateCompany(companyId, data) {
  return putCompanyDirect(companyId, companyApplicantBuilderForMyCRM(data))
    .then(directDataTransform(companyApplicantBuilderForMilli))
    .then(directReturnJSON);
}

// port of serverless handler: companies/companies.del
export function deleteCompany(companyId, loanApplicationId) {
  return delCompanyDirect(companyId, loanApplicationId).then(directReturnJSON);
}
