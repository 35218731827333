import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { intlShape, injectIntl } from 'react-intl';

import liabilityActions from 'actions/liabilityActions';
import UIActions from 'actions/UIActions';
import { logEvent, EVENTS } from 'lib/amplitude';
import lenders from 'shared/lib/lenderHelper';

import * as liabilitySelectors from 'selectors/liabilitySelectors';
import * as clientSelectors from 'selectors/clientSelectors';
import * as UISelectors from 'selectors/UISelectors';
import * as applicationSelectors from 'selectors/applicationSelectors';

import FormPopup, { formPopupProps } from 'components/PopupItem/FormPopup';
import LiabilityForm from 'components/PopupForms/LiabilityForm';
import { LIABILITIES_SLUG } from 'constants/applyData';

class LiabilityPopup extends FormPopup {
  static propTypes = {
    ...formPopupProps,
    intl: intlShape.isRequired,
    creditors: PropTypes.arrayOf(PropTypes.object),
    setLiabilityClientIds: PropTypes.func.isRequired,
    setLiabilityType: PropTypes.func.isRequired,
    setLiabilityError: PropTypes.func.isRequired,
    setLiabilityTotalCardLimit: PropTypes.func.isRequired,
    setLiabilityOriginalLoanAmount: PropTypes.func.isRequired,
    setLiabilityDescription: PropTypes.func.isRequired,
    setLiabilityCategory: PropTypes.func.isRequired,
    setLiabilityCreditor: PropTypes.func.isRequired,
    setLiabilityRemainingYears: PropTypes.func.isRequired,
    setLiabilityRemainingMonths: PropTypes.func.isRequired,
    setLiabilityTotalLoanAmount: PropTypes.func.isRequired,
    setLiabilityRepaymentAmount: PropTypes.func.isRequired,
    setLiabilityRepaymentFrequency: PropTypes.func.isRequired,
  };

  title() {
    const { working } = this.props;
    const formStateTitle = super.title();
    if (formStateTitle) {
      return `${formStateTitle} liability`;
    }
    return working.id ? 'Edit your liability' : 'Add a liability';
  }

  value = () => undefined;

  logSaveEvent() {
    logEvent(EVENTS.SAVE_FINANCIALS, { section: LIABILITIES_SLUG });
  }

  logSaveAndAddEvent() {
    logEvent(EVENTS.SAVE_AND_ADD_FINANCIALS, { section: LIABILITIES_SLUG });
  }

  logRemoveEvent() {
    logEvent(EVENTS.REMOVE_FINANCIALS, { section: LIABILITIES_SLUG });
  }

  isInExpenseSection() {
    return /expenses/.test(this.props.location.pathname);
  }

  renderForm() {
    const {
      isLocked,
      working,
      creditors,
      clientIdOwnershipOptions,
      setLiabilityClientIds,
      setLiabilityType,
      setLiabilityError,
      setLiabilityTotalCardLimit,
      setLiabilityOriginalLoanAmount,
      setLiabilityDescription,
      setLiabilityCategory,
      setLiabilityCreditor,
      setLiabilityRemainingYears,
      setLiabilityRemainingMonths,
      setLiabilityTotalLoanAmount,
      setLiabilityRepaymentAmount,
      setLiabilityRepaymentFrequency,
    } = this.props;
    /*
     * EDIT ONLY ON REPAYMENT IS TEMPORORARILY TURNED OFF
     * this is to allow liabilities to be added from expenses
     * it may be reversed based on jasons thoughts.
     * - <3 BF
     */
    return (
      <LiabilityForm
        key={`liabilityPopupForm-${this.state.refreshKey}`}
        isLocked={isLocked}
        working={working}
        creditors={creditors}
        // onlyEditRepayments={this.isInExpenseSection()}
        clientIdOwnershipOptions={clientIdOwnershipOptions}
        setLiabilityClientIds={setLiabilityClientIds}
        setLiabilityType={setLiabilityType}
        setLiabilityError={setLiabilityError}
        setLiabilityTotalCardLimit={setLiabilityTotalCardLimit}
        setLiabilityOriginalLoanAmount={setLiabilityOriginalLoanAmount}
        setLiabilityDescription={setLiabilityDescription}
        setLiabilityCategory={setLiabilityCategory}
        setLiabilityCreditor={setLiabilityCreditor}
        setLiabilityRemainingYears={setLiabilityRemainingYears}
        setLiabilityRemainingMonths={setLiabilityRemainingMonths}
        setLiabilityTotalLoanAmount={setLiabilityTotalLoanAmount}
        setLiabilityRepaymentAmount={setLiabilityRepaymentAmount}
        setLiabilityRepaymentFrequency={setLiabilityRepaymentFrequency}
        save={this.save}
        remove={this.remove}
        saveAndAddAnother={this.saveAndAddAnother}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const paramId = ownProps.match.params.id;
  const id = parseInt(paramId, 10) || paramId || 'new';
  return {
    id,
    item: liabilitySelectors.entity(state)(id),
    working: liabilitySelectors.working(state)(id),
    clientIdOwnershipOptions: clientSelectors.clientIdOwnershipOptions(state),
    requestIsProcessing: UISelectors.requestProcessing(state)(id),
    errors: UISelectors.requestErrors(state),
    isLocked: applicationSelectors.getIsLocked(state),
    creditors: lenders.all.map((l) => ({
      key: l.lenderId,
      text: l.lenderName,
    })),
    formPopUpStatus: UISelectors.formPopUpStatus(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const paramId = ownProps.match.params.id;
  const id = parseInt(paramId, 10) || paramId || 'new';
  return bindActionCreators(
    {
      load: liabilityActions.loadLiability,
      create: liabilityActions.createLiability,
      update: liabilityActions.updateLiability,
      delete: liabilityActions.deleteLiability,
      clearWorking: liabilityActions.clearWorkingLiability,
      resetFormPopupStatus: UIActions.resetFormPopupStatus,
      clearErrors: UIActions.clearAsyncRequestErrors,
      setLiabilityClientIds: liabilityActions.setLiabilityClientIds(id),
      setLiabilityType: liabilityActions.setLiabilityType(id),
      setLiabilityError: liabilityActions.setLiabilityError(id),
      setLiabilityTotalCardLimit: liabilityActions.setLiabilityTotalCardLimit(
        id,
      ),
      setLiabilityOriginalLoanAmount: liabilityActions.setLiabilityOriginalLoanAmount(
        id,
      ),
      setLiabilityDescription: liabilityActions.setLiabilityDescription(id),
      setLiabilityCategory: liabilityActions.setLiabilityCategory(id),
      setLiabilityCreditor: liabilityActions.setLiabilityCreditor(id),
      setLiabilityRemainingYears: liabilityActions.setLiabilityRemainingYears(
        id,
      ),
      setLiabilityRemainingMonths: liabilityActions.setLiabilityRemainingMonths(
        id,
      ),
      setLiabilityTotalLoanAmount: liabilityActions.setLiabilityTotalLoanAmount(
        id,
      ),
      setLiabilityRepaymentAmount: liabilityActions.setLiabilityRepaymentAmount(
        id,
      ),
      setLiabilityRepaymentFrequency: liabilityActions.setLiabilityRepaymentFrequency(
        id,
      ),
    },
    dispatch,
  );
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(LiabilityPopup),
);
