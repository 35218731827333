/* eslint-disable sonarjs/no-identical-functions */
import { createSelector } from 'reselect';
import _ from 'lodash';

import { isOrderlessEqual } from 'lib/utils/dataUtils';
import * as clientSelectors from 'selectors/clientSelectors';

import {
  ADDRESS_TYPE_CURRENT,
  HISTORIC_ADDRESS_TYPES,
  ADDRESS_TYPE_POSTAL,
} from 'shared/constants/myCRMTypes/address';

export const addressHistories = (state) => {
  const addressTypeIds = HISTORIC_ADDRESS_TYPES.map((type) => type.id);
  return state.address.entities.filter((address) =>
    addressTypeIds.includes(address.typeId),
  );
};

export const addresses = (state) => state.address.entities;
export const workingAddresses = (state) => state.address.working;
export const working = (state) => (id) => workingAddresses(state)[id];

export const entity = createSelector(addressHistories, (a) =>
  _.memoize((id) => a.find((e) => e.id === id)),
);

export const clientsAddresses = createSelector(addressHistories, (a) =>
  _.memoize(
    (clientIds) => a.filter((e) => isOrderlessEqual(e.clientIds, clientIds)),
    (clientIds) => clientIds.join('-'),
  ),
);

export const hasOldInformation = createSelector(addressHistories, (a) =>
  a.some((b) => b.isOldData),
);

export const primaryContactAddressHistories = createSelector(
  clientSelectors.primaryApplicant,
  clientSelectors.primaryApplicantsPartner,
  clientsAddresses,
  (a, b, c) => {
    if (b) {
      return _.concat(c([a.id]), c([b.id]), c([a.id, b.id]));
    }
    return a ? c([a.id]) : [];
  },
);

export const clientAllAddresses = createSelector(addresses, (a) =>
  _.memoize((clientId) => a.filter((e) => e.clientIds.includes(clientId))),
);

export const workingClientAddresses = createSelector(workingAddresses, (a) =>
  _.memoize((clientId) =>
    _.filter(a, (e) => e && e.clientIds.includes(clientId)),
  ),
);

export const clientCurrentAddress = createSelector(clientAllAddresses, (a) =>
  _.memoize((clientId) =>
    a(clientId).find((e) => e.typeId === ADDRESS_TYPE_CURRENT.id),
  ),
);

export const primaryCurrentAddress = createSelector(
  clientSelectors.getPrimaryApplicantId,
  clientCurrentAddress,
  (a, b) => b(a),
);

export const workingClientCurrentAddress = createSelector(
  workingClientAddresses,
  (a) =>
    _.memoize((clientId) =>
      a(clientId).find((e) => e.typeId === ADDRESS_TYPE_CURRENT.id),
    ),
);

export const workingPrimaryCurrentAddress = createSelector(
  clientSelectors.getPrimaryApplicantId,
  workingClientCurrentAddress,
  (a, b) => b(a),
);

export const clientMailAddress = createSelector(clientAllAddresses, (a) =>
  _.memoize((clientId) =>
    a(clientId).find((e) => e.typeId === ADDRESS_TYPE_POSTAL.id),
  ),
);

export const primaryMailAddress = createSelector(
  clientSelectors.getPrimaryApplicantId,
  clientMailAddress,
  (a, b) => b(a),
);

export const workingClientMailAddress = createSelector(
  workingClientAddresses,
  (a) =>
    _.memoize((clientId) =>
      a(clientId).find((e) => e.typeId === ADDRESS_TYPE_POSTAL.id),
    ),
);

export const workingClientIsMailAddressSame = createSelector(
  workingClientCurrentAddress,
  workingClientMailAddress,
  (a, b) =>
    _.memoize((clientId) => {
      const currentAddress =
        a(clientId) && a(clientId).address.formattedAddress;
      const mailAddress = b(clientId) && b(clientId).address.formattedAddress;
      return currentAddress === mailAddress;
    }),
);

export const workingClientIsAtPrimaryAddress = createSelector(
  workingPrimaryCurrentAddress,
  workingClientCurrentAddress,
  (a, b) =>
    _.memoize((clientId) => {
      if (!b(clientId) || b(clientId).address.formattedAddress === undefined) {
        return;
      }
      return a && a.clientIds.includes(clientId);
    }),
);

export const primaryApplicantIsAtWorkingClientAddress = createSelector(
  clientSelectors.getPrimaryApplicantId,
  workingClientCurrentAddress,
  workingPrimaryCurrentAddress,
  (primaryClientId, workingAddress) =>
    _.memoize((clientId) => {
      const clientAddress = workingAddress(clientId);
      const hasClientAddress =
        clientAddress && clientAddress.address.formattedAddress;
      if (!hasClientAddress) {
        return;
      }

      const primaryClientIdIndex = clientAddress.clientIds.indexOf(
        primaryClientId,
      );
      return primaryClientIdIndex > 0;
    }),
);

export const hasCurrentAddressOldInformation = createSelector(
  primaryCurrentAddress,
  (address) => address?.isOldData,
);
