/* eslint-disable sonarjs/no-duplicate-string */
import React from 'react';
import { Route } from 'react-router-dom';
import _ from 'lodash';
import locale from 'config/locale';

import propsMixin from 'hocs/propsMixin';
import wrapAnimation from 'hocs/wrapAnimation';
import TransitionRoutes from 'routes/TransitionRoutes';
import {
  SIMPLE_IMAGE_MESSAGE_DATA,
  SIMPLE_HEADER_DATA,
} from 'constants/animationSequenceData';

import {
  HandholdComplete,
  NiceThumbsUp,
  StampDuty,
  LetsGetYouThatLoan,
  WillFindOptions,
  FillInYourDetails,
  MakeSureExistingInfoGood,
  YourClientHasProvidedMoreInformation,
  BankConnectOrSkip,
  CheckTransactionsFromOtherBanks,
  SecurelyClosedConnection,
  GotYourStatments,
  WantAnotherBank,
  LetBrokerKnow,
  BrokerWillAnswerQuestions,
  BrokerWillCallYou,
  BrokerWillReviewYourQuestion,
  BrokerWillHelpYouGetThere,
  BrokerMakesThingsSimple,
  SpeedThingsUp,
  WeHaveExperts,
  getHandholdStartWrapper,
  CheckoutAppointmentCompanion,
  CalculateServiceabilityFamily,
  OpenMyCRMGenerateGamePlan,
  PasswordRecoveryInstruction,
} from 'components/AnimationSequences/imageMessages';
import ImageMessage from 'components/AnimationSequences/ImageMessage';
import WelcomeBack from 'components/AnimationSequences/WelcomeBack';
import WelcomeTo from 'components/AnimationSequences/WelcomeTo';
import SimpleHeader from 'components/AnimationSequences/SimpleHeader';
import FindingLoan from 'components/AnimationSequences/FindingLoan';
import GridInbetweener from 'components/AnimationSequences/GridInbetweener';
import ApplyHandhold from 'components/AnimationSequences/ApplyHandhold';
import AskForReview from 'components/AnimationSequences/AskForReview';
import IntroduceBroker from 'components/AnimationSequences/IntroduceBroker';
import ForkChoices from 'components/AnimationSequences/ForkChoices';
import ChatSvg from '../assets/inbetweeners/chat_1_1.svg';

export const HISTORY_NAMESPACE = 'memoryHistory';

const simpleImageMessageRoutes = _.map(
  SIMPLE_IMAGE_MESSAGE_DATA,
  (data, path) => {
    return (
      <Route
        path={path}
        component={wrapAnimation(propsMixin(ImageMessage, data))}
      />
    );
  },
);
const simpleHeaderRoutes = _.map(SIMPLE_HEADER_DATA, (data, path) => {
  return (
    <Route
      path={path}
      component={wrapAnimation(propsMixin(SimpleHeader, data))}
    />
  );
});

export default function routes() {
  return (
    <TransitionRoutes>
      {simpleImageMessageRoutes}
      {simpleHeaderRoutes}
      <Route path='/ask-for-review' component={wrapAnimation(AskForReview)} />
      <Route
        path='/introduce-broker'
        component={wrapAnimation(IntroduceBroker)}
      />
      <Route path='/we-have-experts' component={wrapAnimation(WeHaveExperts)} />
      <Route
        path='/introduce-broker-handhold-start'
        component={wrapAnimation(getHandholdStartWrapper(IntroduceBroker))}
      />
      <Route
        path='/we-have-experts-handhold-start'
        component={wrapAnimation(getHandholdStartWrapper(WeHaveExperts))}
      />
      <Route
        path='/handhold-complete'
        component={wrapAnimation(HandholdComplete)}
      />
      <Route
        path='/apply-handhold/:slug'
        component={wrapAnimation(ApplyHandhold)}
      />
      <Route path='/apply-handhold' component={wrapAnimation(ApplyHandhold)} />
      <Route path='/stamp-duty' component={wrapAnimation(StampDuty)} />
      <Route path='/finding-loan' component={wrapAnimation(FindingLoan)} />
      <Route
        path='/searching-grid'
        component={wrapAnimation(
          propsMixin(GridInbetweener, {
            headings: [
              'Calculating how much you can borrow',
              'Searching Australia’s biggest banks and specialist lenders...',
              'Sorting today’s top deals',
              'Finding the right loans for you',
            ],
          }),
          'extendedLength',
        )}
      />
      <Route
        path='/searching-grid-savings'
        component={wrapAnimation(
          propsMixin(GridInbetweener, {
            headings: ['Calculating how much you can save'],
          }),
          'extendedLength',
        )}
      />
      <Route
        path='/searching-grid-compare'
        component={wrapAnimation(
          propsMixin(GridInbetweener, {
            headings: [
              'Searching Australia’s biggest banks and specialist lenders...',
            ],
            buttonText: 'Compare Home Loans',
          }),
          'extendedLength',
        )}
      />
      <Route
        path='/lets-get-you-that-loan'
        component={wrapAnimation(LetsGetYouThatLoan)}
      />
      <Route
        path='/profile-signup-grid'
        component={wrapAnimation(
          propsMixin(GridInbetweener, {
            image: ChatSvg,
            headings: [
              `Next, create a profile to compare rates from specialist lenders and a team member will be in touch.`,
            ],
          }),
          'extendedLength',
        )}
      />
      <Route
        path='/access-top-rates-grid'
        component={wrapAnimation(
          propsMixin(GridInbetweener, {
            headings: [
              `Access top rates from ${locale.data.englishName}’s biggest banks and specialist lenders`,
            ],
          }),
          'extendedLength',
        )}
      />
      <Route
        path='/max-borrow-grid'
        component={wrapAnimation(
          propsMixin(GridInbetweener, {
            headings: [
              'Searching Australia’s biggest banks and specialist lenders...',
              'Sorting today’s top deals',
              'Calculating how much you can borrow',
            ],
          }),
          'extendedLength',
        )}
      />
      <Route
        path='/welcome-back'
        component={wrapAnimation(propsMixin(WelcomeBack, { delay: 300 }))}
      />
      <Route
        path='/will-find-options'
        component={wrapAnimation(
          propsMixin(WillFindOptions, { alwaysShowImage: true }),
        )}
      />
      <Route path='/welcome-to' component={wrapAnimation(WelcomeTo)} />
      <Route
        path='/welcome-back-broker'
        component={wrapAnimation(
          propsMixin(WelcomeTo, { isBrokerLogin: true }),
        )}
      />
      <Route
        path='/fill-in-your-details'
        component={wrapAnimation(propsMixin(FillInYourDetails))}
      />
      <Route
        path='/make-sure-existing-info-good'
        component={wrapAnimation(
          propsMixin(MakeSureExistingInfoGood, {
            primaryButtonText: 'Got it',
            alwaysShowImage: true,
          }),
        )}
      />
      <Route
        path='/your-client-has-provided-more-information'
        component={wrapAnimation(
          propsMixin(YourClientHasProvidedMoreInformation, {
            primaryButtonText: 'Next',
            alwaysShowImage: true,
          }),
        )}
      />
      <Route
        path='/checkout-appointment-companion'
        component={wrapAnimation(propsMixin(CheckoutAppointmentCompanion))}
      />
      <Route
        path='/nice-work'
        component={wrapAnimation(
          propsMixin(NiceThumbsUp, {
            alwaysShowImage: true,
            whatIsNice: 'work',
          }),
        )}
      />
      <Route
        path='/nice-choice'
        component={wrapAnimation(
          propsMixin(NiceThumbsUp, {
            alwaysShowImage: true,
            whatIsNice: 'choice',
          }),
        )}
      />
      <Route
        path='/bank-connect-or-skip'
        component={wrapAnimation(propsMixin(BankConnectOrSkip))}
      />
      <Route
        path='/check-transactions-from-other-banks'
        component={wrapAnimation(CheckTransactionsFromOtherBanks)}
      />
      <Route
        path='/securely-closed-connection'
        component={wrapAnimation(SecurelyClosedConnection)}
      />
      <Route
        path='/got-your-statements'
        component={wrapAnimation(GotYourStatments)}
      />
      <Route
        path='/want-another-bank'
        component={wrapAnimation(WantAnotherBank)}
      />
      <Route path='/let-broker-know' component={wrapAnimation(LetBrokerKnow)} />
      <Route
        path='/broker-will-answer-questions'
        component={wrapAnimation(BrokerWillAnswerQuestions)}
      />
      <Route
        path='/broker-makes-things-simple'
        component={wrapAnimation(BrokerMakesThingsSimple)}
      />
      <Route
        path='/broker-will-call-you'
        component={wrapAnimation(BrokerWillCallYou)}
      />
      <Route
        path='/broker-will-review-your-question'
        component={wrapAnimation(BrokerWillReviewYourQuestion)}
      />
      <Route
        path='/broker-will-help-you-get-there'
        component={wrapAnimation(BrokerWillHelpYouGetThere)}
      />
      <Route path='/speed-things-up' component={wrapAnimation(SpeedThingsUp)} />
      <Route path='/fork-choices' component={wrapAnimation(ForkChoices)} />
      <Route
        path='/calculating-family-can-borrow'
        component={wrapAnimation(CalculateServiceabilityFamily)}
      />
      <Route
        path='/open-mycrm-generate-game-plan'
        component={wrapAnimation(OpenMyCRMGenerateGamePlan)}
      />
      <Route
        path='/password-recovery-instructions'
        component={wrapAnimation(PasswordRecoveryInstruction)}
      />
    </TransitionRoutes>
  );
}
