import { all } from 'redux-saga/effects';

import scenarioSagas from 'sagas/scenarioSagas';
import otherSagas from 'sagas/otherSagas';
import productsSagas from 'sagas/productsSagas';
import assetSagas from 'sagas/assetSagas';
import clientSagas from 'sagas/clientSagas';
import contactSagas from 'sagas/contactSagas';
import employmentSagas from 'sagas/employmentSagas';
import liabilitySagas from 'sagas/liabilitySagas';
import incomeSagas from 'sagas/incomeSagas';
import expenseSagas from 'sagas/expenseSagas';
import userSagas from 'sagas/userSagas';
import loanApplicationSagas from 'sagas/loanApplicationSagas';
import structureSagas from 'sagas/structureSagas';
import propertySagas from 'sagas/propertySagas';
import fundingSagas from 'sagas/fundingSagas';
import addressSagas from 'sagas/addressSagas';
import documentSagas from 'sagas/documentSagas';
import sequenceSagas from 'sagas/sequenceSagas';
import householdSagas from 'sagas/householdSagas';
import advisorSagas from 'sagas/advisorSagas';
import advisorOrgSagas from 'sagas/advisorOrgSagas';
import expenseCategoryTypeSagas from 'sagas/expenseCategoryTypeSagas';
import goalLoanAppInfoSagas from 'sagas/goal/goalLoanAppInfoSagas';
import aggregatorSagas from 'sagas/aggregatorSagas';
import privacyPolicySagas from 'sagas/privacyPolicySagas';
import dataCollectionSagas from './dataCollectionSaga';

export default function* rootSagas() {
  yield all([
    scenarioSagas(),
    otherSagas(),
    productsSagas(),
    assetSagas(),
    clientSagas(),
    contactSagas(),
    employmentSagas(),
    liabilitySagas(),
    incomeSagas(),
    expenseSagas(),
    userSagas(),
    loanApplicationSagas(),
    structureSagas(),
    propertySagas(),
    fundingSagas(),
    addressSagas(),
    documentSagas(),
    sequenceSagas(),
    householdSagas(),
    advisorSagas(),
    advisorOrgSagas(),
    expenseCategoryTypeSagas(),
    goalLoanAppInfoSagas(),
    aggregatorSagas(),
    privacyPolicySagas(),
    dataCollectionSagas(),
  ]);
}
