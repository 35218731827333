import {
  DIRECT_API_BASE,
  DIRECT_DATA_COLLECTION_API_PATH,
  DIRECT_IDENTITY_VERIFICATION_API_PATH,
  DIRECT_VERIFICATION_EXCHANGE_API_PATH,
} from 'shared/constants/apiPaths';
import {
  axiosWithAuth as directAxiosWithAuth,
  returnJSON as directReturnJSON,
} from './mycrmBaseApi.js';
import {
  dataCollectionStatusAndEventsBuilderForMilli,
  identityVerificationsBuilderForMyCRM,
} from 'shared/lib/builders/milli/dataCollection';

export async function postDataCollectionProcessEvent(
  loanScenarioId,
  dataCollectionProcess,
  dataCollectionEvent,
) {
  const response = await directAxiosWithAuth.post(
    `${DIRECT_DATA_COLLECTION_API_PATH}/${loanScenarioId}`,
    {
      Process: dataCollectionProcess,
      Event: dataCollectionEvent,
    },
  );
  return directReturnJSON(
    dataCollectionStatusAndEventsBuilderForMilli(response),
  );
}

export async function getDataCollectionStatusAndEvents(
  loanApplicationId,
  clientId,
) {
  const response = await directAxiosWithAuth.get(
    `${DIRECT_DATA_COLLECTION_API_PATH}/${loanApplicationId}/${clientId}`,
  );

  return directReturnJSON(
    dataCollectionStatusAndEventsBuilderForMilli(response),
  );
}

export async function postManualIdentityVerifications(data) {
  return directAxiosWithAuth
    .post(
      `${DIRECT_IDENTITY_VERIFICATION_API_PATH}/manual`,
      identityVerificationsBuilderForMyCRM(data),
    )
    .then(directReturnJSON);
}

export async function postIdentityVerifications(loanScenarioId) {
  return directAxiosWithAuth
    .post(
      `${DIRECT_IDENTITY_VERIFICATION_API_PATH}/${loanScenarioId}/automatic`,
      {},
    )
    .then(directReturnJSON)
    .then((data) => {
      return { sharedCode: data };
    });
}

export async function postCreditCheckRetry(loanScenarioId, address) {
  await directAxiosWithAuth.post(
    `${DIRECT_API_BASE}/lm-identity-verifications/${loanScenarioId}/retry`,
    { address },
  );
}

export async function getVerificationExchangeApi(loanScenarioId) {
  return await directAxiosWithAuth.get(
    `${DIRECT_VERIFICATION_EXCHANGE_API_PATH}/${loanScenarioId}/consent`,
  );
}

export async function postVerificationExchangeApi({ applicationId, status }) {
  return await directAxiosWithAuth.post(
    `${DIRECT_VERIFICATION_EXCHANGE_API_PATH}/${applicationId}/consent`,
    { isActive: status },
  );
}
