export function listingMapperForMilli(listing) {
  return {
    price: listing.price,
    state: listing.address.stateCode,
    postcode: listing.address.postCode,
    suburb: listing.address.suburb,
    address: listing.address.formatted,
    agents: listing.agents.map((a) => ({
      id: a.memberId,
      name: a.fullName,
    })),
    office: {
      id: listing.office.organisationId,
      name: listing.office.businessName,
    },
    types: listing.categories.map((c) => c.code),
  };
}
