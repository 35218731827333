import {
  getContactAssets as contactGetContactAssets,
  getContactLoanListDirect,
} from '../contactApi';
import { getLoanApplicationsForClientDirect } from '../clientApi';
import { contactApplicationsBuilderForMilli } from 'shared/lib/builders/milli/contactApplications';
import { clientApplicationsBuilderForMilli } from 'shared/lib/builders/milli/client';

// port of serverless handler: goalContacts/goalContacts.getAssets
export function getContactAssets(contactId) {
  return contactGetContactAssets(contactId);
}

// port of serverless handler: goalContacts/goalContacts.getApplications
export function getContactApplications(contactId, clientIds) {
  const primaryClientIds = clientIds.split(',').map((id) => Number(id));
  return getContactLoanListDirect(contactId).then((contactAppsList) => {
    return Promise.all(
      primaryClientIds.map((clientId) => {
        return getLoanApplicationsForClientDirect(clientId);
      }),
    ).then((clientsAppsList) => {
      const clientsAppIds = clientApplicationsBuilderForMilli(
        clientsAppsList,
        primaryClientIds,
      );
      return contactApplicationsBuilderForMilli(contactAppsList, clientsAppIds);
    });
  });
}
