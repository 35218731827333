import { COUNTRIES } from 'shared/constants/myCRMTypes/countries';

export function advisorOrgBuilderForMilli(advisorOrgInfo) {
  return {
    id: advisorOrgInfo.id,
    businessName: advisorOrgInfo.business_name,
    tradingName: advisorOrgInfo.trading_name,
    brandColor: advisorOrgInfo.branding_colour,
    brandLogoUrl: advisorOrgInfo.branding_logo_url,
    officePhone: advisorOrgInfo.office_phone,
    isOwnBrand: advisorOrgInfo.branding_category_id === 2,
    principleAdvisorFamilyId: advisorOrgInfo.principle_adviser_family_id,
    countryCode: advisorOrgInfo.countryId
      ? COUNTRIES.find((c) => c.id === advisorOrgInfo.countryId).countryCode
      : null,
    advisorOrgIds: advisorOrgInfo.adviser_org_ids || [advisorOrgInfo.id],
    aggregatorId: advisorOrgInfo.aggregator_id || 0,
    privacyPolicyLink: advisorOrgInfo.PrivacyPolicyLink,
  };
}
