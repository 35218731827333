export function companyApplicantBuilderForMilli(company) {
  return {
    id: company.id,
    contactId: company.family_id,
    businessName: company.name,
    officeAddress: { formattedAddress: company.address },
    description: company.description,
    nzbn: company.nzbn,
    officeEmail: company.email,
    tfn: company.tax_number,
    acn: company.number,
    officePhone: company.phone_number,
    type: 'company',
  };
}
