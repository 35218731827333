import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import NotificationBanner from 'components/NotificationBanner/NotificationBanner';
import styles from './ApplyHeader.css';
import NotifBannerStyles from '../NotificationBanner/NotificationBanner.css';

const ApplyHeader = ({
  isOpen,
  close,
  title,
  iconName,
  description,
  isFreezed,
  warningMessage,
  isLocked,
}) => (
  <>
    <section
      className={`${styles.root} ${isOpen ? styles.open : styles.close}`}
    >
      <div className={styles.iconAndHeaderSection}>
        <div className={`${styles.icon} ${iconName}`} />
        <div className={styles.headerAndContent}>
          <h4>{title}</h4>
          <div className={classNames(styles.content)}>{description}</div>
        </div>
      </div>
      {!isFreezed && (
        <div className={styles.closeSection}>
          <span className={styles.closeText} onClick={close}>
            Close
          </span>
          <i onClick={close} className='sl-custom-filled-arrow-circle-30' />
        </div>
      )}
    </section>
    <section>
      {warningMessage && warningMessage !== '' && !isLocked && (
        <NotificationBanner
          message={warningMessage}
          className={NotifBannerStyles.warning}
          align='center'
        />
      )}
    </section>
  </>
);

ApplyHeader.propTypes = {
  description: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  isFreezed: PropTypes.bool.isRequired,
  warningMessage: PropTypes.string,
  isLocked: PropTypes.bool,
};

ApplyHeader.defaultProps = {};

export default ApplyHeader;
