import { DIRECT_ADVISORS_API_PATH } from 'shared/constants/apiPaths';

import {
  axiosWithAuth as directAxiosWithAuth,
  returnJSON as directReturnJSON,
  dataTransform as directDataTransform,
} from './mycrmBaseApi.js';

import { advisorBuilderForMilli } from '../../shared/lib/builders/milli/advisor';
import LocalStorageProxy from 'lib/localStorageProxy';
import {
  getAdvisorEmailSignatureImageDirect,
  getAdvisorSubscriptionsDirect,
} from './contactApi';
import { featureFlagsAndSubsBuilderForMilli } from '../../shared/lib/builders/milli/featureFlagsAndSubs';

const getAdvisorByQuery = (params) => {
  return directAxiosWithAuth
    .get(`${DIRECT_ADVISORS_API_PATH}`, {
      params: params,
    })
    .then(directReturnJSON);
};

const getAdvisorEmail = (familyId) => {
  if (!LocalStorageProxy.token) {
    return Promise.resolve(undefined);
  }
  return directAxiosWithAuth
    .get(`${DIRECT_ADVISORS_API_PATH}/adviser-email`, {
      params: {
        familyId: familyId,
      },
    })
    .then(directReturnJSON);
};

export const checkLoanAppAccess = (loanApplicationId) => {
  return directAxiosWithAuth
    .get(`${DIRECT_ADVISORS_API_PATH}/permission/${loanApplicationId}/loan-app`)
    .then(directReturnJSON);
};

export const getAdvisorInfo = (params) => {
  return getAdvisorByQuery(params)
    .then((adviserByQueryData) => {
      if (!adviserByQueryData || !adviserByQueryData.length) {
        return Promise.reject(new Error('No advisor found'));
      }
      const myCrmAdviser = adviserByQueryData[0];
      const {
        head_shoot_url: headshotUrl,
        family_id: adviserContactId,
      } = myCrmAdviser;
      myCrmAdviser.imgUrl = headshotUrl;
      return getAdvisorEmail(adviserContactId).then((adviserEmailData) => {
        myCrmAdviser.email = adviserEmailData;
        return myCrmAdviser;
      });
    })
    .then(directDataTransform(advisorBuilderForMilli))
    .then(directReturnJSON);
};

// port of serverless handler: advisors/advisors.getAdvisorProfilePhoto
export const getAdvisorProfilePhoto = (params) => {
  const { familyId, type } = params;
  return getAdvisorEmailSignatureImageDirect(familyId, type).then((data) => {
    if (data && data.length) {
      const emailSignatureImage = data[0];
      return { profilePhoto: emailSignatureImage.DownloadLink };
    }
    return { profilePhoto: null };
  });
};

// port of serverless handler: advisors/advisors.getAdviserSubscriptions
export const getAdviserSubscriptions = (adviserId) => {
  return getAdvisorSubscriptionsDirect(adviserId).then((data) =>
    featureFlagsAndSubsBuilderForMilli(data || {}, {
      name: 'Title',
      enabled: 'IsActive',
    }),
  );
};
