import { takeEvery, all, put, select, call } from 'redux-saga/effects';
import { push } from '@loan_market/react-router-redux-multi';

import {
  LOAD_CLIENT,
  LOAD_CLIENT_ADDRESSES,
  LOAD_NEW_FAMILY_MEMBER,
  CREATE_CLIENT,
  READ_CLIENT,
  UPDATE_CLIENT,
  DELETE_CLIENT,
  UPDATE_CLIENT_LOGIN,
  CLEAR_WORKING_CLIENT,
  LOAD_DEPENDENTS_INTO_WORKING,
  CONFIRM_CLIENT,
  CONFIRM_CLIENTS,
  CONFIRM_CO_APPLICANTS,
} from 'actions/clientActionTypes';
import clientActions from 'actions/clientActions';
import addressActions from 'actions/addressActions';
import contactActions from 'actions/contactActions';
import scenarioActions from 'actions/scenarioActions';
import UIActions from 'actions/UIActions';
import loanApplicationActions from 'actions/loanApplicationActions';

import { createOrUpdateAddress } from 'sagas/addressSagas';

import {
  postClient,
  getClient,
  putClient,
  delClient,
} from 'services/clientApi';

import { postClientToFamily } from 'services/contactApi';

import { workingApplication } from 'selectors/applicationSelectors';
import * as advisorSelectors from 'selectors/advisorSelectors';
import * as addressSelectors from 'selectors/addressSelectors';
import * as clientSelectors from 'selectors/clientSelectors';
import { monitorAsyncRequest, checkIfNetworkError } from 'lib/sagaHelpers.js';
import { isGoalSetter } from 'lib/utils/browserUtils';
import { removeFromArray } from 'shared/lib/utils';
import * as authApi from 'services/auth/authApi';

import { PERSON, ASYNC_REQUEST_TYPE } from 'constants/options';
import * as SpinnerNames from 'constants/spinnerNames';
import { EMAIL_TAKEN_BY_ADVISER } from 'constants/messages/authError';

export function* workingAddresses(clientId) {
  const workingClientCurrentAddress = yield select(
    addressSelectors.workingClientCurrentAddress,
  );
  const workingClientMailAddress = yield select(
    addressSelectors.workingClientMailAddress,
  );
  return {
    currentAddress: workingClientCurrentAddress(clientId),
    mailAddress: workingClientMailAddress(clientId),
  };
}

export function* updatePrimaryCurrentAddressClients(clientId) {
  const primaryApplicantId = yield select(
    clientSelectors.getPrimaryApplicantId,
  );
  const primaryCurrentAddress = yield select(
    addressSelectors.primaryCurrentAddress,
  );
  const newPrimaryCurrentAddressClients = removeFromArray(
    primaryCurrentAddress.clientIds,
    clientId,
  );
  const updatedPrimaryCurrentAddress = {
    ...primaryCurrentAddress,
    clientIds: newPrimaryCurrentAddressClients,
  };
  yield call(
    createOrUpdateAddress,
    updatedPrimaryCurrentAddress,
    primaryApplicantId,
    false,
    false,
  );
}

export function* createOrUpdateAddresses(
  clientId,
  isClientNew,
  forCompany = false,
) {
  const addressClientId = isClientNew ? 'new' : clientId;
  const { currentAddress, mailAddress } = yield call(
    workingAddresses,
    addressClientId,
  );
  if (currentAddress) {
    yield call(
      createOrUpdateAddress,
      currentAddress,
      clientId,
      isClientNew,
      forCompany,
    );
    const primaryApplicantId = yield select(
      clientSelectors.getPrimaryApplicantId,
    );
    const primaryCurrentAddress = yield select(
      addressSelectors.primaryCurrentAddress,
    );
    const clientIsAtPrimaryAddress =
      primaryCurrentAddress &&
      primaryCurrentAddress.clientIds.includes(clientId);
    if (
      clientId !== primaryApplicantId &&
      clientIsAtPrimaryAddress &&
      currentAddress.id !== primaryCurrentAddress.id
    ) {
      yield call(updatePrimaryCurrentAddressClients, clientId);
    }
  }
  if (mailAddress) {
    yield call(
      createOrUpdateAddress,
      mailAddress,
      clientId,
      isClientNew,
      forCompany,
    );
  }
}

export function* createClient({ payload }) {
  const application = yield select(workingApplication);
  const newClient = { ...payload, loanApplicationId: application.id };
  const client = yield newClient.contactId
    ? call(postClientToFamily, newClient)
    : call(postClient, newClient);
  const primaryApplicantContactId = yield select(
    clientSelectors.primaryApplicantContactId,
  );
  client.isPartnerClient = newClient.contactId === primaryApplicantContactId;
  const brokerId = yield select(advisorSelectors.brokerId);
  yield put(clientActions.setNewClient(client));
  yield call(createOrUpdateAddresses, client.id, true);
  if (newClient.contactId && isGoalSetter()) {
    const contact = { ...client, clientId: client.id, id: newClient.contactId };
    yield put(contactActions.setNewContact(contact));
  }
  yield put(
    loanApplicationActions.setNewWorkingApplicant({ ...client, brokerId }),
  );
}

export function* readClient({ payload }) {
  const client = yield call(getClient, payload);
  yield put(clientActions.setNewClient(client));
}

export function* updateClient({ payload }) {
  const application = yield select(workingApplication);
  const updatedClient = { ...payload, loanApplicationId: application.id };
  const client = yield call(putClient, updatedClient.id, updatedClient);
  yield put(clientActions.setClient({ ...payload, ...client }));
  yield call(createOrUpdateAddresses, client.id, false);
}

export function* confirmClient({ payload: { client } }) {
  yield call(updateClient, { payload: client });
}

export function* confirmClients({
  payload: { primaryApplicantsPartner, dependents },
}) {
  yield all(dependents.map((payload) => call(updateClient, { payload })));
  yield call(updateClient, { payload: primaryApplicantsPartner });
}

export function* confirmCoapplicants({ payload: { coapplicants } }) {
  yield all(coapplicants.map((payload) => call(updateClient, { payload })));
}

export function* deleteClient({ payload }) {
  yield call(delClient, payload);
  yield put(clientActions.removeClient(payload));
}

export function* loadCurrentAddress(clientId) {
  const clientCurrentAddress = yield select(
    addressSelectors.clientCurrentAddress,
  );
  const currentAddress = yield call(clientCurrentAddress, clientId);
  if (currentAddress) {
    yield put(addressActions.loadAddress(currentAddress.id));
  } else {
    yield put(addressActions.loadNewCurrentAddress([clientId]));
  }
}

export function* loadMailAddress(clientId) {
  const clientMailAddress = yield select(addressSelectors.clientMailAddress);
  const mailAddress = yield call(clientMailAddress, clientId);
  if (mailAddress) {
    yield put(addressActions.loadAddress(mailAddress.id));
  } else {
    yield put(addressActions.loadNewMailAddress([clientId]));
  }
}

export function* loadPrimaryCurrentAddress() {
  const primaryCurrentAddress = yield select(
    addressSelectors.primaryCurrentAddress,
  );
  const primaryApplicantId = yield select(
    clientSelectors.getPrimaryApplicantId,
  );
  if (primaryCurrentAddress) {
    yield put(addressActions.loadAddress(primaryCurrentAddress.id));
  } else {
    yield put(addressActions.loadNewCurrentAddress([primaryApplicantId]));
  }
}

export function* loadClientAddresses({
  payload: clientId,
  contact = undefined,
}) {
  let client;
  if (!contact) {
    const clientSelector = yield select(clientSelectors.client);
    client = yield call(clientSelector, clientId);
  }
  if (client && client.isDependent) {
    return;
  }

  yield call(loadCurrentAddress, clientId);
  if (!contact || contact.type === PERSON) {
    yield call(loadMailAddress, clientId);
  }
  if (client && !client.primaryApplicant) {
    const primaryCurrentAddress = yield select(
      addressSelectors.primaryCurrentAddress,
    );
    if (primaryCurrentAddress) {
      yield call(loadPrimaryCurrentAddress);
    }
  }
}

export function* loadNewFamilyAddresses({ payload: { isDependent } }) {
  if (isDependent) {
    return;
  }
  yield put(addressActions.loadNewCurrentAddress(['new']));
  yield put(addressActions.loadNewMailAddress(['new']));
  const primaryCurrentAddress = yield select(
    addressSelectors.primaryCurrentAddress,
  );
  if (primaryCurrentAddress) {
    yield put(addressActions.loadAddress(primaryCurrentAddress.id));
  } else {
    const primaryApplicantId = yield select(
      clientSelectors.getPrimaryApplicantId,
    );
    yield put(addressActions.loadNewMailAddress([primaryApplicantId, 'new']));
  }
}

export function* clearClientAddresses({ payload: clientId }) {
  const selector = yield select(addressSelectors.workingClientAddresses);
  const workingClientAddresses = yield call(selector, clientId);
  if (workingClientAddresses) {
    // eslint-disable-next-line no-unused-vars
    for (const address of workingClientAddresses) {
      yield put(addressActions.clearWorkingAddress(address.id));
    }
  }
}

export function* loadDependentsToWorking() {
  const dependents = yield select(clientSelectors.primaryApplicantsDependents);
  // eslint-disable-next-line no-unused-vars
  for (const dependent of dependents) {
    yield put(clientActions.loadClient(dependent.id));
  }
}

export function* updateClientLogin({
  payload: { email, clientId, path, action },
}) {
  try {
    const {
      data: { available },
    } = yield call(authApi.getEmailAvailability, email, clientId);
    if (!available) {
      yield put(
        scenarioActions.setError({
          id: 'emailUpdate',
          text: EMAIL_TAKEN_BY_ADVISER,
          blocking: true,
        }),
      );
      yield put(UIActions.popSpinner(SpinnerNames.APPLY_SECTION));
      throw new Error(EMAIL_TAKEN_BY_ADVISER);
    }
    if (path) {
      yield put(push(path));
    }
    if (action) {
      yield call(action);
    }
  } catch (error) {
    yield call(checkIfNetworkError, error);
  }
}

export default function* clientSagas() {
  yield all([
    monitorAsyncRequest(
      takeEvery,
      CREATE_CLIENT,
      createClient,
      ASYNC_REQUEST_TYPE.FORM_POP_UP_REQUEST,
    ),
    monitorAsyncRequest(takeEvery, READ_CLIENT, readClient),
    monitorAsyncRequest(
      takeEvery,
      UPDATE_CLIENT,
      updateClient,
      ASYNC_REQUEST_TYPE.FORM_POP_UP_REQUEST,
    ),
    monitorAsyncRequest(
      takeEvery,
      DELETE_CLIENT,
      deleteClient,
      ASYNC_REQUEST_TYPE.FORM_POP_UP_REQUEST,
    ),
    monitorAsyncRequest(
      takeEvery,
      UPDATE_CLIENT_LOGIN,
      updateClientLogin,
      ASYNC_REQUEST_TYPE.FORM_POP_UP_REQUEST,
    ),
    monitorAsyncRequest(takeEvery, CONFIRM_CLIENT, confirmClient),
    monitorAsyncRequest(takeEvery, CONFIRM_CLIENTS, confirmClients),
    monitorAsyncRequest(takeEvery, CONFIRM_CO_APPLICANTS, confirmCoapplicants),
    takeEvery(LOAD_CLIENT, loadClientAddresses),
    takeEvery(LOAD_CLIENT_ADDRESSES, loadClientAddresses),
    takeEvery(LOAD_NEW_FAMILY_MEMBER, loadNewFamilyAddresses),
    takeEvery(CLEAR_WORKING_CLIENT, clearClientAddresses),
    takeEvery(LOAD_DEPENDENTS_INTO_WORKING, loadDependentsToWorking),
  ]);
}
