import moment from 'moment';

const LAST_MODIFIED_THRESHOLD_IN_MINUTES = parseFloat(
  process.env.LAST_MODIFIED_THRESHOLD_IN_MINUTES,
);

const DEFAULT_LAST_MODIFIED_THRESHOLD =
  LAST_MODIFIED_THRESHOLD_IN_MINUTES || 90;

export function hasLastModifiedWarning(
  dateUpdated,
  threshold = DEFAULT_LAST_MODIFIED_THRESHOLD,
) {
  const now = moment.utc();
  const modified = moment.utc(dateUpdated);
  const unit = LAST_MODIFIED_THRESHOLD_IN_MINUTES ? 'minutes' : 'days';
  return now > modified.toDate() && now.diff(modified, unit) > threshold;
}

export function historyBuilderForMilli(entity) {
  return {
    dateCreated: entity.CreatedDate,
    createdBy: entity.CreatedBy,
    dateUpdated: entity.LastModifiedDate,
    updatedBy: entity.ModifiedBy,
    sourcePlatform: entity.SourcePlatformDescription,
    isOldData: hasLastModifiedWarning(entity.LastModifiedDate),
  };
}
