import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { intlShape, injectIntl } from 'react-intl';

import clientActions from 'actions/clientActions';
import UIActions from 'actions/UIActions';

import * as clientSelectors from 'selectors/clientSelectors';
import * as contactSelectors from 'selectors/contactSelectors';
import * as addressSelectors from 'selectors/addressSelectors';
import * as UISelectors from 'selectors/UISelectors';
import * as applicationSelectors from 'selectors/applicationSelectors';

import FormPopup, { formPopupProps } from 'components/PopupItem/FormPopup';
import ClientForm from 'components/PopupForms/ClientForm';

import { EMAIL_TAKEN_BY_ADVISER } from 'constants/messages/authError';
import { mergeProps } from 'lib/popupHelper';

const getClientTitle = (isDependent, isPartner) => {
  if (isDependent === undefined) {
    return 'Family member';
  }

  if (isPartner) {
    return 'Partner';
  } else {
    return isDependent ? 'Child' : 'Co-applicant';
  }
};

class ClientPopup extends FormPopup {
  static propTypes = {
    ...formPopupProps,
    intl: intlShape.isRequired,
    primaryApplicant: PropTypes.object.isRequired,
    hasPartner: PropTypes.bool.isRequired,
    loadNewFamilyMember: PropTypes.func.isRequired,
    householdShape: PropTypes.string,
    isParent: PropTypes.bool,
    isCouple: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.noScroll = true;
  }

  onLoadNew() {
    const {
      loadNewFamilyMember,
      primaryApplicant,
      hasPartner,
      isParent,
      isCouple,
      working,
    } = this.props;
    let isDependent =
      isParent && ((isCouple && hasPartner) || !isCouple || undefined);
    if (working.isCoapplicant) {
      isDependent = false;
    }
    loadNewFamilyMember({
      contactId: primaryApplicant.contactId,
      isDependent,
      isCoapplicant: working.isCoapplicant,
      role: working.role,
    });
  }

  isLoaded() {
    const { working, primaryApplicant } = this.props;
    return working && working.contactId && primaryApplicant;
  }

  title() {
    const { working, item, primaryApplicant } = this.props;
    const contactId =
      (item && item.contactId) || (working && working.contactId);
    const isDependent =
      (item && item.isDependent) || (working && working.isDependent);
    const isPartner = primaryApplicant.contactId === contactId && !isDependent;
    const formStateTitle = super.title();
    const clientTitle = getClientTitle(isDependent, isPartner);

    if (formStateTitle) {
      return `${formStateTitle} Details`;
    }

    return working.id ? `Edit ${clientTitle}` : `Add a ${clientTitle}`;
  }

  value = () => undefined;

  hideErrorOnForm = (processing) => {
    const { errors } = this.props;
    if (!processing && errors.length > 0) {
      return errors[0].error.message !== EMAIL_TAKEN_BY_ADVISER;
    }
    return true;
  };

  renderForm() {
    const {
      isLocked,
      isShared,
      working,
      workingCurrentAddress,
      workingMailAddress,
      primaryCurrentAddress,
      clientCurrentAddress,
      isSameAsHomeAddress,
      isAtPrimaryAddress,
      isPrimaryAtPartnerAddress,
      primaryApplicant,
    } = this.props;
    return (
      <ClientForm
        key={`contactPopupForm-${this.state.refreshKey}`}
        isLocked={isLocked}
        isShared={isShared}
        popup
        primaryApplicant={primaryApplicant}
        working={working}
        workingCurrentAddress={workingCurrentAddress}
        workingMailAddress={workingMailAddress}
        primaryCurrentAddress={primaryCurrentAddress}
        clientCurrentAddress={clientCurrentAddress}
        isSameAsHomeAddress={isSameAsHomeAddress}
        isAtPrimaryAddress={isAtPrimaryAddress}
        isPrimaryAtPartnerAddress={isPrimaryAtPartnerAddress}
        questionKey='partnerDependent'
        save={this.save}
        remove={this.remove}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const paramId = ownProps.match.params.id;
  const id = parseInt(paramId, 10) || paramId || 'new';
  return {
    id,
    item: clientSelectors.client(state)(id),
    working: clientSelectors.working(state)(id),
    workingCurrentAddress: addressSelectors.workingClientCurrentAddress(state)(
      id,
    ),
    workingMailAddress: addressSelectors.workingClientMailAddress(state)(id),
    primaryCurrentAddress: addressSelectors.primaryCurrentAddress(state),
    clientCurrentAddress: addressSelectors.clientCurrentAddress(state)(id),
    isSameAsHomeAddress: addressSelectors.workingClientIsMailAddressSame(state)(
      id,
    ),
    isAtPrimaryAddress: addressSelectors.workingClientIsAtPrimaryAddress(state)(
      id,
    ),
    isPrimaryAtPartnerAddress: addressSelectors.primaryApplicantIsAtWorkingClientAddress(
      state,
    )(id),
    primaryApplicant: clientSelectors.primaryApplicant(state),
    hasPartner: !!clientSelectors.primaryApplicantsPartner(state),
    requestIsProcessing: UISelectors.requestProcessing(state)(id),
    errors: UISelectors.requestErrors(state),
    isLocked: applicationSelectors.getIsLocked(state),
    isShared: applicationSelectors.isSharedApplication(state),
    householdShape:
      state.household.householdShape || contactSelectors.householdShape(state),
    isCouple: contactSelectors.isCouple(state),
    isParent: contactSelectors.isParent(state),
    formPopUpStatus: UISelectors.formPopUpStatus(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      load: clientActions.loadClient,
      create: clientActions.createClient,
      update: clientActions.updateClient,
      delete: clientActions.deleteClient,
      clearWorking: clientActions.clearWorkingClient,
      clearErrors: UIActions.clearAsyncRequestErrors,
      resetFormPopupStatus: UIActions.resetFormPopupStatus,
      loadNewFamilyMember: clientActions.loadNewFamilyMember,
      updateClientLogin: clientActions.updateClientLogin,
    },
    dispatch,
  );
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(ClientPopup),
);
