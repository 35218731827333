import React from 'react';
import { Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import { RouteForLoggedin } from 'routes/helpers';

import GoalFutureLongTerm from './GoalFutureLongTerm';
import GoalFutureRetirement from './GoalFutureRetirement';
import GoalFutureHorizon from './GoalFutureHorizon';

import { featureFlags } from 'lib/rollout';

import {
  LONG_TERM_PATH,
  RETIREMENT_PATH,
  HORIZON_PATH,
} from 'shared/constants/paths';

const GoalFutureRoutes = (props) => {
  const { active } = props;
  const longTermPathAdditional = featureFlags[
    'complianceQuestions.reduceDuplication'
  ].isEnabled()
    ? '/:clientId'
    : '';

  return (
    <Switch>
      <RouteForLoggedin
        exact
        path={`/${active}${LONG_TERM_PATH}/:familyId${longTermPathAdditional}`}
        component={GoalFutureLongTerm}
      />
      <RouteForLoggedin
        exact
        path={`/${active}${RETIREMENT_PATH}/:familyId/:clientId`}
        component={GoalFutureRetirement}
      />
      <RouteForLoggedin
        exact
        path={`/${active}${HORIZON_PATH}/:familyId/:clientId`}
        component={GoalFutureHorizon}
      />
    </Switch>
  );
};

GoalFutureRoutes.propTypes = {
  active: PropTypes.number,
};

export default GoalFutureRoutes;
