import { DEFAULT_EXISTING_PROPERTY_ID } from 'shared/constants/defaults';
import * as myCRMGeneral from 'shared/constants/myCRMTypes/general';
import * as myCRMApplication from 'shared/constants/myCRMTypes/applications';
import * as myCRMFunding from 'shared/constants/myCRMTypes/funding';
import * as myCRMAsset from 'shared/constants/myCRMTypes/assets';

export const LOAN_FEATURES_MAP = {
  offsetAccount: myCRMApplication.FEATURE_OFFSET_ACCOUNT,
  redrawFacility: myCRMApplication.FEATURE_REDRAW_FACILITY,
  professionalPack: myCRMApplication.FEATURE_PROFESSIONAL_PACK,
  noOngoingFees: myCRMApplication.FEATURE_NO_ONGOING_FEES,
  introRate: myCRMApplication.FEATURE_INTRODUCTORY_RATE,
  // creditCard: '',
  // chequeBook: '',
  // capitaliseLmi: '',
  // nonConforming: '',
  // noGenuineSavings: '',
};

export const LOAN_TYPE_FEATURES_MAP = {
  all: [
    myCRMApplication.FEATURE_VARIABLE_INTEREST_RATE,
    myCRMApplication.FEATURE_FIXED_INTEREST_RATE,
  ],
  fixed: [myCRMApplication.FEATURE_FIXED_INTEREST_RATE],
  variable: [myCRMApplication.FEATURE_VARIABLE_INTEREST_RATE],
};

export const SCENARIO_PURPOSE_MAP = {
  firstHomeBuyer: myCRMApplication.PURPOSE_FIRST_HOME,
  move: myCRMApplication.PURPOSE_NEXT_HOME,
  buyAndInvest: myCRMApplication.PURPOSE_NEXT_HOME,
  justCompare: myCRMApplication.PURPOSE_NEXT_HOME,
  invest: myCRMApplication.PURPOSE_INVESTMENT,
};

export const REFINANCE_PURPOSE_MAP = {
  renovate: myCRMApplication.PURPOSE_RENOVATE,
  consolidateDebt: myCRMApplication.PURPOSE_CONSOLIDATE_DEBT,
  betterDeal: myCRMApplication.PURPOSE_BETTER_DEAL,
  additionalFunds: myCRMApplication.PURPOSE_EXTRA_FUNDS,
};

export const ASSET_TYPE_MAP = {
  [DEFAULT_EXISTING_PROPERTY_ID]: myCRMAsset.ASSET_REAL_ESTATE.id,
};

export const PROSPECTIVE_PROPERTY_FUNDING_MAP = {
  depositAmount: {
    type: myCRMFunding.FUNDING_TYPE_AVAILABLE,
    name: 'Deposit Amount',
  },
  conveyancerCost: {
    type: myCRMFunding.FUNDING_TYPE_REQUIRED,
    name: 'Legal Fees',
  },
  movingCost: {
    type: myCRMFunding.FUNDING_TYPE_REQUIRED,
    name: 'Moving Cost',
  },
  renovationCost: {
    type: myCRMFunding.FUNDING_TYPE_REQUIRED,
    name: 'Renovation Cost',
    notes: 'renovationPurpose',
  },
  // grant: { { /* we rely on myCrm's autogenerated fundings for these value */
  //   type: myCRMFunding.FUNDING_TYPE_AVAILABLE,
  //   name: 'First Home Owner Grant',
  // },
  // value:
  //   type: myCRMFunding.FUNDING_TYPE_REQUIRED,
  //   name: 'Purchase Price',
  // },
  // stampDutyPayable: {
  //   type: myCRMFunding.FUNDING_TYPE_REQUIRED,
  //   name: 'Stamp Duty',
  // },
  // mortgageRegFee: {
  //   type: myCRMFunding.FUNDING_TYPE_REQUIRED,
  //   name: 'Mortgage Fee',
  // },
  // transferFee: {
  //   type: myCRMFunding.FUNDING_TYPE_REQUIRED,
  //   name: 'Transfer Fee',
  // },
  // totalFee: {
  //   type: myCRMFunding.FUNDING_TYPE_REQUIRED,
  //   name: 'Total Fee',
  // },
};

export const OWNED_PROPERTY_FUNDING_MAP = {
  // mortgageAmount: {
  //   type: myCRMFunding.FUNDING_TYPE_REQUIRED,
  //   name: 'Outstanding Mortgage',
  //   notes: 'currentLender',
  // },
  realEstateCommissionPercentage: {
    type: myCRMFunding.FUNDING_TYPE_REQUIRED,
    name: 'Real Estate Commission',
    value: ({ value, realEstateCommissionPercentage, adsFee }) =>
      value * realEstateCommissionPercentage + adsFee,
  },
  renovationCost: {
    type: myCRMFunding.FUNDING_TYPE_REQUIRED,
    name: 'Renovation Cost',
    notes: 'renovationPurpose',
  },
  consolidateDebtAmount: {
    type: myCRMFunding.FUNDING_TYPE_REQUIRED,
    name: 'Consolidate Debt',
  },
  additionalFunds: {
    type: myCRMFunding.FUNDING_TYPE_REQUIRED,
    name: 'Additional Borrowing',
  },
  conveyancerCost: {
    type: myCRMFunding.FUNDING_TYPE_REQUIRED,
    name: 'Legal Fees',
  },
  movingCost: {
    type: myCRMFunding.FUNDING_TYPE_REQUIRED,
    name: 'Moving Cost',
  },
};

export const EXPENSE_MAP = {
  managementFee: {
    type_id: 25,
    frequency_id: myCRMGeneral.WEEKLY /* always weekly */,
    description: 'Property Management',
  },
  vacancyFee: {
    type_id: 25,
    frequency_id: myCRMGeneral.WEEKLY,
    description: 'Vacancy Allowance',
  },
  weeklyRepairFee: {
    type_id: 20,
    frequency_id: myCRMGeneral.WEEKLY,
    description: 'Repairs & Maintenance',
  },
};

export const GENDER_MAP = {
  male: 1,
  female: 2,
  undisclosed: 3,
};
