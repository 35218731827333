/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-extra-arguments */
/* eslint-disable unicorn/explicit-length-check */
import { takeEvery, all, call, put, select, fork } from 'redux-saga/effects';
import {
  CREATE_PROPERTY,
  UPDATE_PROPERTY,
  DELETE_PROPERTY,
  LOAD_PROPERTY,
  LOAD_RESIDENCE_PROPERTY,
  CLEAR_WORKING_PROPERTY,
  CONFIRM_PROPERTIES,
} from 'actions/applyPropertyActionTypes';
import { PROPERTY_NOT_OWNED_ID } from 'constants/tempIds';

import propertyActions from 'actions/applyPropertyActions';
import addressActions from 'actions/addressActions';
import incomeActions from 'actions/incomeActions';
import liabilityActions from 'actions/liabilityActions';
import fundingActions from 'actions/fundingActions';
import loanApplicationActions from 'actions/loanApplicationActions';

import { postRealEstateAssetForClient } from 'services/clientApi';
import { postPropertyForApplication } from 'services/loanApplicationApi';
import { postRealEstateAssetForContact } from 'services/contactApi';
import { putProperty, delProperty } from 'services/propertyApi';
import { putRealEstateAsset, delAsset } from 'services/assetApi';

import { newNotOwnResidencyProperty } from 'reducers/propertyReducer';
import { PROPERTY_OWNED, PROPERTY_PURCHASING } from 'shared/constants/options';
import { propertyDescriptionGenerator } from 'lib/utils/formUtils';
import { isPropertyOwnedByClient } from 'lib/propertyHelper';

import * as clientSelectors from 'selectors/clientSelectors';
import * as contactSelectors from 'selectors/contactSelectors';
import * as propertySelectors from 'selectors/propertySelectors';
import * as incomeSelectors from 'selectors/incomeSelectors';
import * as liabilitySelectors from 'selectors/liabilitySelectors';
import * as applicationSelectors from 'selectors/applicationSelectors';
import * as addressSelectors from 'selectors/addressSelectors';
import * as fundingSelectors from 'selectors/fundingSelectors';

import { createOrUpdateAddress } from 'sagas/addressSagas';
import {
  createLiability,
  updateLiability,
  deleteLiability,
  getLiabilities,
} from 'sagas/liabilitySagas';
import {
  createIncome,
  updateIncome,
  deleteIncome,
  getIncomes,
} from 'sagas/incomeSagas';
import { requestSecurities } from 'sagas/loanApplicationSagas';
import { requestFundingsFromProperty } from 'sagas/fundingSagas';
import { monitorAsyncRequest } from 'lib/sagaHelpers.js';
import { ASYNC_REQUEST_TYPE } from 'constants/options';
import * as _ from 'sagas/propertySagas';

const prospectiveRentalIncomeMapper = (income, property) => ({
  ...income,
  propertyId: property.id,
  description: propertyDescriptionGenerator(property),
});

const rentalIncomeMapper = (income, property, applicationId) => ({
  applicationId,
  assetId: property.id,
  description: `From ${propertyDescriptionGenerator(property)}`,
  ...income,
  clientIds: property.clientIds,
});

const liabilityMapper = (liability, property, applicationId) => ({
  applicationId,
  assetId: property.id,
  description: `Mortgage for ${propertyDescriptionGenerator(property)}`,
  ...liability,
  clientIds: property.clientIds,
});

const notOwnResidencyPropertyMapper = (
  currentAddress,
  primaryClient,
  ownership,
) => ({
  ...newNotOwnResidencyProperty(ownership),
  startDate: currentAddress.startDate,
  address: currentAddress.address,
  clientId: primaryClient.id,
});

export function* removeFundingsFromProperty(propertyId) {
  const fundingsByPropertyId = yield select(
    fundingSelectors.fundingsByPropertyId,
  );
  const fundings = fundingsByPropertyId(propertyId);
  if (fundings.length) {
    // eslint-disable-next-line no-unused-vars
    for (const funding of fundings) {
      // this should in the future only remove from our reducer
      // as MYCRM are implementing cascading deletion for properties
      yield put(fundingActions.removeFunding(funding.id));
    }
  }
}

export function* createResidenceOrPurchasing({
  payload,
  applicationId,
  primaryClient,
}) {
  const propertyData = { ...payload, applicationId };

  if (payload.residence) {
    const workingClientCurrentAddress = yield select(
      addressSelectors.workingClientCurrentAddress,
    );
    const currentAddress = workingClientCurrentAddress(primaryClient.id);
    yield fork(createOrUpdateAddress, currentAddress, primaryClient.id);

    const { owned: propertyOwnership } = payload;
    if (propertyOwnership && !isPropertyOwnedByClient(payload)) {
      const mappedResidenceProperty = notOwnResidencyPropertyMapper(
        currentAddress,
        primaryClient,
        propertyOwnership,
      );
      yield put(propertyActions.setNewProperty(mappedResidenceProperty));
      return;
    }
  }

  let property;
  if (payload.owned === PROPERTY_PURCHASING) {
    property = yield call(postPropertyForApplication, propertyData);
    yield put(propertyActions.setNewProperty(property));
  } else {
    if (payload.clientIds.length === 1) {
      property = yield call(postRealEstateAssetForClient, {
        ...propertyData,
        clientId: payload.clientIds[0],
      });
    } else {
      const primaryContact = yield select(contactSelectors.primaryContact);
      property = yield call(
        postRealEstateAssetForContact,
        primaryContact.contactId,
        propertyData,
      );
    }
    yield put(propertyActions.setNewProperty(property));
  }

  return property;
}

export function* createPropertyDetails({
  payload,
  applicationId,
  primaryClient,
  property,
}) {
  if (payload.existingMortgages) {
    const workingLiabilities = yield select(
      liabilitySelectors.workingLiabilities,
    );
    yield fork(createLiability, {
      payload: liabilityMapper(workingLiabilities.new, property, applicationId),
    });
  }

  if (payload.generateRentalIncome) {
    const workingIncomes = yield select(incomeSelectors.workingIncomes);
    const params =
      payload.owned === PROPERTY_PURCHASING
        ? prospectiveRentalIncomeMapper(
            workingIncomes.new,
            property,
            primaryClient,
          )
        : rentalIncomeMapper(workingIncomes.new, property, applicationId);
    yield fork(createIncome, { payload: params });
  }

  if (property.owned === PROPERTY_PURCHASING) {
    yield fork(requestFundingsFromProperty, { payload: property.id });
  } else if (property.owned === PROPERTY_OWNED && property.useAsSecurity) {
    yield fork(requestFundingsFromProperty, { payload: property.propertyId });
  }

  yield fork(requestSecurities, { payload: applicationId });
}

export function* createProperty({ payload }) {
  const primaryClient = yield select(clientSelectors.primaryApplicant);
  const applicationId = yield select(applicationSelectors.getApplicationId);
  const params = { payload, primaryClient, applicationId };
  const property = yield call(createResidenceOrPurchasing, params);
  if (property) {
    yield call(createPropertyDetails, { ...params, property });
    if (payload.owned === PROPERTY_PURCHASING) {
      yield put(
        loanApplicationActions.removeMetadata('lookingToBuyAnotherProperty'),
      );
    }
  }
}

export function* convertPropertyToOwned(clientIds, newPropertyData) {
  let property;
  if (clientIds.length === 1) {
    property = yield call(postRealEstateAssetForClient, {
      ...newPropertyData,
      clientId: clientIds[0],
      clientIds,
    });
  } else {
    const primaryContact = yield select(contactSelectors.primaryContact);
    property = yield call(
      postRealEstateAssetForContact,
      primaryContact.contactId,
      { ...newPropertyData, clientIds },
    );
  }
  yield put(propertyActions.removePropertyApply(PROPERTY_NOT_OWNED_ID));
  yield put(propertyActions.setNewProperty(property));
}

export function* updateResidenceOrPurchasing({
  payload,
  beforeProperty,
  propertyData,
}) {
  const primaryClient = yield select(clientSelectors.primaryApplicant);
  let property;
  if (payload.residence) {
    const workingClientCurrentAddress = yield select(
      addressSelectors.workingClientCurrentAddress,
    );
    const currentAddress = workingClientCurrentAddress(primaryClient.id);
    const { owned: ownership } = payload;
    if (ownership && !isPropertyOwnedByClient(payload)) {
      const params = notOwnResidencyPropertyMapper(
        currentAddress,
        primaryClient,
        payload.owned,
      );
      if (
        isPropertyOwnedByClient(beforeProperty) &&
        payload.id !== PROPERTY_NOT_OWNED_ID
      ) {
        const propertyId = yield call(delAsset, payload.id);
        yield put(propertyActions.removePropertyApply(propertyId));
        yield put(propertyActions.setNewProperty(params));
      } else {
        yield put(propertyActions.setProperty(params));
      }
      yield fork(createOrUpdateAddress, currentAddress, primaryClient.id);
      return;
    } else if (
      ownership === PROPERTY_OWNED &&
      (!isPropertyOwnedByClient(beforeProperty) ||
        beforeProperty.id === PROPERTY_NOT_OWNED_ID)
    ) {
      yield call(_.convertPropertyToOwned, payload.clientIds, propertyData);
    }
    yield fork(createOrUpdateAddress, currentAddress, primaryClient.id);
  }

  if (payload.id && payload.id !== PROPERTY_NOT_OWNED_ID) {
    property =
      payload.owned === PROPERTY_PURCHASING
        ? yield call(putProperty, propertyData)
        : yield call(putRealEstateAsset, propertyData);
    yield put(propertyActions.setProperty(property));
  }

  return property;
}

export function* updatePropertyDetails({
  payload,
  propertyData,
  beforeProperty,
  property,
  applicationId,
}) {
  if (payload.existingMortgages) {
    const workingLiabilityByAssetId = yield select(
      liabilitySelectors.workingLiabilityByAssetId,
    );
    const liability = workingLiabilityByAssetId(payload.id);

    if (liability.id) {
      yield fork(updateLiability, {
        payload: liabilityMapper(
          liability,
          property || propertyData,
          applicationId,
        ),
      });
    } else {
      yield fork(createLiability, {
        payload: liabilityMapper(
          liability,
          property || propertyData,
          applicationId,
        ),
      });
    }
  } else {
    const liability = yield select((state) =>
      liabilitySelectors.liabilityByAssetId(state)(payload.id),
    );
    if (liability) {
      yield fork(deleteLiability, { payload: liability.id });
    }
  }

  if (payload.generateRentalIncome) {
    const income = yield select((state) =>
      incomeSelectors.workingIncomeByAssetOrPropertyId(state)(payload.id),
    );
    const params =
      payload.owned === PROPERTY_PURCHASING
        ? prospectiveRentalIncomeMapper(income, property, applicationId)
        : rentalIncomeMapper(income, property, applicationId);
    if (income.id) {
      yield fork(updateIncome, { payload: params });
    } else {
      yield fork(createIncome, { payload: params });
    }
  } else {
    const incomeByAssetOrPropertyId = yield select(
      incomeSelectors.incomeByAssetOrPropertyId,
    );
    const income = incomeByAssetOrPropertyId(payload.id);
    if (income) {
      yield fork(deleteIncome, { payload: income.id });
    }
  }

  if (property.owned === PROPERTY_PURCHASING) {
    yield call(removeFundingsFromProperty, payload.id);
    yield fork(requestFundingsFromProperty, { payload: property.id });
  } else if (property.owned === PROPERTY_OWNED && property.useAsSecurity) {
    yield call(removeFundingsFromProperty, payload.propertyId);
    yield fork(requestFundingsFromProperty, { payload: property.propertyId });
  } else if (beforeProperty.useAsSecurity && !property.userAsSecurity) {
    yield fork(removeFundingsFromProperty, beforeProperty.propertyId);
  }

  yield fork(requestSecurities, { payload: applicationId });
}

export function* updateProperty({ payload }) {
  const beforeProperty = yield select((state) =>
    propertySelectors.entityById(state)(payload.id),
  );
  const applicationId = yield select(applicationSelectors.getApplicationId);
  const propertyData = {
    ...payload,
    applicationId,
  };
  const params = { payload, propertyData, beforeProperty, applicationId };
  const property = yield call(updateResidenceOrPurchasing, params);
  if (property) {
    yield call(updatePropertyDetails, { ...params, property });
  }
}

export function* confirmProperties({ payload: { properties } }) {
  yield all(properties.map((payload) => call(updateProperty, { payload })));
}

export function* deletePropertyDetails(property) {
  const propertyId = property.id;
  yield call(
    property.owned === PROPERTY_PURCHASING ? delProperty : delAsset,
    propertyId,
  );
  yield put(propertyActions.removePropertyApply(propertyId));

  yield fork(getLiabilities);
  yield fork(getIncomes);

  if (property.owned === PROPERTY_PURCHASING) {
    yield fork(removeFundingsFromProperty, propertyId);
  }

  const workingApplicationId = yield select(
    applicationSelectors.getApplicationId,
  );
  yield fork(requestSecurities, { payload: workingApplicationId });
}

export function* deleteProperty({ payload: propertyId }) {
  const property = yield select((state) =>
    state.property.entities.find((e) => e.id === propertyId),
  );
  yield call(deletePropertyDetails, property);
}

export function* loadCurrentAddress() {
  const primaryCurrentAddress = yield select(
    addressSelectors.primaryCurrentAddress,
  );
  if (primaryCurrentAddress) {
    yield put(addressActions.loadAddress(primaryCurrentAddress.id));
  } else {
    const primaryApplicantId = yield select(
      clientSelectors.getPrimaryApplicantId,
    );
    yield put(addressActions.loadNewCurrentAddress([primaryApplicantId]));
  }
}

export function* loadDependencies({ payload }) {
  const income = yield select((state) =>
    incomeSelectors.incomeByAssetOrPropertyId(state)(payload),
  );
  const liabilities = yield select((state) =>
    liabilitySelectors.liabilitiesByAssetId(state)(payload),
  );
  if (income) {
    yield put(incomeActions.loadIncome(income.id));
  }
  if (liabilities) {
    for (const liability of liabilities) {
      yield put(liabilityActions.loadLiability(liability.id));
    }
  }

  const property = yield select((state) =>
    state.property.entities.find((e) => e.id === payload),
  );
  if (property.residence) {
    yield loadCurrentAddress();
  }
}

export function* clearWorkingDependencies({ payload }) {
  const workingIncomeByAssetOrPropertyId = yield select(
    incomeSelectors.workingIncomeByAssetOrPropertyId,
  );
  const income = workingIncomeByAssetOrPropertyId(payload);
  const workingLiabilityByAssetId = yield select(
    liabilitySelectors.workingLiabilityByAssetId,
  );
  const liability = workingLiabilityByAssetId(payload);
  yield put(
    incomeActions.clearWorkingIncome(income && income.id ? income.id : 'new'),
  );
  yield put(
    liabilityActions.clearWorkingLiability(
      liability && liability.id ? liability.id : 'new',
    ),
  );
  const workingCurrentAddress = yield select(
    addressSelectors.workingPrimaryCurrentAddress,
  );
  if (workingCurrentAddress) {
    yield put(addressActions.clearWorkingAddress(workingCurrentAddress.id));
    yield put(addressActions.clearWorkingAddress('new'));
  }
}

function* watchCreateProperty() {
  yield monitorAsyncRequest(
    takeEvery,
    CREATE_PROPERTY,
    createProperty,
    ASYNC_REQUEST_TYPE.FORM_POP_UP_REQUEST,
  );
}

function* watchUpdateProperty() {
  yield monitorAsyncRequest(
    takeEvery,
    UPDATE_PROPERTY,
    updateProperty,
    ASYNC_REQUEST_TYPE.FORM_POP_UP_REQUEST,
  );
}

function* watchDeleteProperty() {
  yield monitorAsyncRequest(
    takeEvery,
    DELETE_PROPERTY,
    deleteProperty,
    ASYNC_REQUEST_TYPE.FORM_POP_UP_REQUEST,
  );
}

function* watchLoadProperty() {
  yield takeEvery(LOAD_PROPERTY, loadDependencies);
}

function* watchLoadResidenceProperty() {
  yield takeEvery(LOAD_RESIDENCE_PROPERTY, loadCurrentAddress);
}

function* watchClearWorkingProperty() {
  yield takeEvery(CLEAR_WORKING_PROPERTY, clearWorkingDependencies);
}

function* watchConfirmProperties() {
  yield monitorAsyncRequest(takeEvery, CONFIRM_PROPERTIES, confirmProperties);
}

export default function* propertySagas() {
  yield all([
    watchCreateProperty(),
    watchUpdateProperty(),
    watchDeleteProperty(),
    watchLoadProperty(),
    watchLoadResidenceProperty(),
    watchClearWorkingProperty(),
    watchConfirmProperties(),
  ]);
}
