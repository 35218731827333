import { takeEvery, all, put, select } from 'redux-saga/effects';

import {
  CREATE_FUNDING,
  UPDATE_FUNDING,
  DELETE_FUNDING,
  REQUEST_FUNDINGS_FROM_PROPERTY,
} from 'actions/fundingActionTypes';
import fundingActions from 'actions/fundingActions';

import { putFunding, delFunding } from 'services/fundingsApi';

import { postFundingForLoanApplication } from 'services/loanApplicationApi';

import {
  getFundingsFromProperty,
  postFundingsForProperty,
} from 'services/propertyApi';

import { workingApplication } from 'selectors/applicationSelectors';

import { monitorAsyncRequest } from 'lib/sagaHelpers.js';
import { ASYNC_REQUEST_TYPE } from 'constants/options';

export function* createFunding({ payload }) {
  const application = yield select(workingApplication);
  const newFunding = { ...payload, loanApplicationId: application.id };
  const fundings = payload.propertyId
    ? yield postFundingsForProperty([newFunding])
    : yield postFundingForLoanApplication(newFunding);
  // In Apply we only ever post one funding at a time
  if (fundings && fundings.length > 0) {
    yield put(fundingActions.setNewFunding(fundings[0]));
  }
}

export function* updateFunding({ payload }) {
  yield putFunding(payload);
  yield put(fundingActions.setFunding(payload));
}

export function* deleteFunding({ payload }) {
  yield delFunding(payload);
  yield put(fundingActions.removeFunding(payload));
}

export function* requestFundingsFromProperty({ payload }) {
  const fundings = yield getFundingsFromProperty(payload);
  // eslint-disable-next-line no-unused-vars
  for (const funding of fundings) {
    yield put(fundingActions.setNewFunding(funding));
  }
}

function* watchCreateFunding() {
  yield monitorAsyncRequest(
    takeEvery,
    CREATE_FUNDING,
    createFunding,
    ASYNC_REQUEST_TYPE.FORM_POP_UP_REQUEST,
  );
}

function* watchUpdateFunding() {
  yield monitorAsyncRequest(
    takeEvery,
    UPDATE_FUNDING,
    updateFunding,
    ASYNC_REQUEST_TYPE.FORM_POP_UP_REQUEST,
  );
}

function* watchDeleteFunding() {
  yield monitorAsyncRequest(
    takeEvery,
    DELETE_FUNDING,
    deleteFunding,
    ASYNC_REQUEST_TYPE.FORM_POP_UP_REQUEST,
  );
}

function* watchRequestFundingsFromProperty() {
  yield monitorAsyncRequest(
    takeEvery,
    REQUEST_FUNDINGS_FROM_PROPERTY,
    requestFundingsFromProperty,
  );
}

export default function* fundingSagas() {
  yield all([
    watchCreateFunding(),
    watchUpdateFunding(),
    watchDeleteFunding(),
    watchRequestFundingsFromProperty(),
  ]);
}
