import {
  advisorLoginAsAuthDirect,
  confirmClientLoginWithClientIdHeaderDirect,
  getClientsMatchingAuthEmailDirect,
} from './clientApi';
import { checkLoanAppAccess } from './advisorsApi';
import LocalStorageProxy from '../lib/localStorageProxy';

// All this code was copied from serverless lib/authenticate.js, and minimally reworked for direct-to-mycrmapi

export class AuthenticationError extends Error {
  constructor(message) {
    super(message);
    this.status = 401;
    this.name = 'AuthenticationError';
  }
}

export class BrokerPermissionDeniedError extends Error {
  constructor(message) {
    super(message);
    this.status = 403;
    this.name = 'BrokerPermissionDeniedError';
  }
}

export class PermissionDeniedError extends Error {
  constructor(message) {
    super(message);
    this.status = 403;
    this.name = 'PermissionDeniedError';
  }
}

const buildUser = (user) => ({
  user,
});

const authenticateAsClient = (clientId) => {
  return advisorLoginAsAuthDirect(Number(clientId))
    .then(buildUser)
    .catch(() => {
      throw new BrokerPermissionDeniedError(
        'Broker has no permission to access this client',
      );
    });
};

const confirmPermissionToSharedClientId = (clientId) => {
  return confirmClientLoginWithClientIdHeaderDirect(Number(clientId))
    .then(buildUser)
    .catch((error) => {
      throw new PermissionDeniedError('Unable to access this client: ' + error);
    });
};

const selectClientOfBroker = (brokerFamilyId, advisorOrgSlug) => {
  return getClientsMatchingAuthEmailDirect(brokerFamilyId, advisorOrgSlug)
    .then((clients) => {
      if (!clients) {
        throw new PermissionDeniedError('No client found');
      }

      const client = clients[0];
      return buildUser({
        env_metadata: { clientId: client.id, familyId: client.family_id },
      });
    })
    .catch((error) => {
      throw new PermissionDeniedError('Unable to access this client: ' + error);
    });
};

function authenticateAsLoanApp(loanApplicationId) {
  return checkLoanAppAccess(Number(loanApplicationId)).then((hasAccess) => {
    if (hasAccess) {
      return buildUser({ env_metadata: { loanApplicationId } });
    }
    throw new BrokerPermissionDeniedError(
      'Broker has no permission to access this loan application',
    );
  });
}

function getUserFromToken() {
  const { accessToken } = JSON.parse(
    localStorage.getItem('mycrm-tokens') || '{}',
  );
  const { clientId, familyId, loanApplicationId } = accessToken?.claims || {};
  return {
    env_metadata: {
      clientId: clientId,
      familyId: familyId,
      loanApplicationId: loanApplicationId,
    },
  };
}

function authenticate(event) {
  if (!LocalStorageProxy.token) {
    throw new AuthenticationError('Missing token, unauthorised');
  }

  const loginAsClientId = LocalStorageProxy.loginAsClientId;
  if (loginAsClientId) {
    return authenticateAsClient(loginAsClientId);
  }

  const loginAsLoanAppId = LocalStorageProxy.loginAsLoanApplicationId;
  if (loginAsLoanAppId) {
    return authenticateAsLoanApp(loginAsLoanAppId);
  }

  const selectedClientId =
    LocalStorageProxy.selectedClientId || LocalStorageProxy.currentClientId;
  if (selectedClientId) {
    return confirmPermissionToSharedClientId(selectedClientId);
  }

  const brokerFamilyId = LocalStorageProxy.brokerFamilyId;
  if (brokerFamilyId) {
    return selectClientOfBroker(brokerFamilyId, '');
  }

  const advisorOrgSlug = LocalStorageProxy.advisorOrgSlug;
  if (advisorOrgSlug) {
    return selectClientOfBroker(0, advisorOrgSlug);
  }

  return Promise.resolve(buildUser(getUserFromToken()));
}

export default authenticate;
