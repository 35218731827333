import { DIRECT_SERVICEABILITY_API_PATH } from 'shared/constants/apiPaths';
import {
  axiosWithAuth as directAxiosWithAuth,
  returnJSON as directReturnJSON,
} from './mycrmBaseApi';
import { maxBorrowBuilderForMilli } from 'shared/lib/builders/milli/serviceabilityBuilder';

// Begin port of serverless serviceabilityApi
//
const getNextGenAssessmentAuDirect = (params) => {
  return directAxiosWithAuth
    .get(`${DIRECT_SERVICEABILITY_API_PATH}/NextGenAssessment`, {
      params,
    })
    .then(directReturnJSON);
};

const getServiceabilityLendersListGetNzDirect = (params) => {
  return directAxiosWithAuth
    .get(`${DIRECT_SERVICEABILITY_API_PATH}/ServiceabilityLendersListGet`, {
      params,
    })
    .then(directReturnJSON);
};

const getMaxBorrowCalculationNzDirect = (params) => {
  return directAxiosWithAuth
    .get(`${DIRECT_SERVICEABILITY_API_PATH}/calculation`, {
      params,
    })
    .then(directReturnJSON);
};
//
// End port of serverless serviceabilityApi

// port of serverless handler: others/serviceability.getMaxborrow
export const getMaxBorrow = (params) => {
  const {
    loanAppId: loanScenarioId,
    countryCode,
    clientIds,
    familyId,
    brokerId,
    accreditedOnly,
    nzNewServiceability,
  } = params;
  const isAU = countryCode === 'AU';
  const directParams = {
    brokerEventID: 0,
    loanScenarioId,
    clientIds,
    familyId,
    brokerId,
    accreditedOnly,
  };
  let promise;
  if (isAU) {
    promise = getNextGenAssessmentAuDirect(directParams);
  } else if (nzNewServiceability === 'true') {
    promise = getMaxBorrowCalculationNzDirect(directParams);
  } else {
    promise = getServiceabilityLendersListGetNzDirect(directParams);
  }
  return promise.then((data) => ({
    results: data.map(maxBorrowBuilderForMilli),
  }));
};
