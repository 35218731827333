import _ from 'lodash';
import { createSelector } from 'reselect';

import {
  isPrimaryOrPartnerClient,
  getPrimaryApplicantId,
} from 'selectors/clientSelectors';

import { PROPERTY_PURCHASING, PROPERTY_OWNED } from 'shared/constants/options';
import { ASSET_REAL_ESTATE_CATEGORY } from 'shared/constants/myCRMTypes/assets';
import { propertyDescriptionGenerator } from 'lib/utils/formUtils';

export const properties = (state) => state.property.entities;
export const primaryContactProperties = createSelector(
  properties,
  isPrimaryOrPartnerClient,
  (p, isPrimaryContact) =>
    p.filter(({ clientIds = [] }) => clientIds.some(isPrimaryContact)),
);

export const residenceEntity = createSelector(
  properties,
  getPrimaryApplicantId,
  (selectedProperties, primaryApplicantId) =>
    selectedProperties.find((p) => {
      const isClientResidence =
        p.clientId === primaryApplicantId ||
        (p.clientIds || []).includes(primaryApplicantId);
      return p.residence && isClientResidence;
    }),
);

export const entityById = createSelector(properties, (a) =>
  _.memoize((id) => a.find((e) => e.id === id)),
);

export const securities = createSelector(properties, (selectedProperties) =>
  selectedProperties.filter(
    (p) =>
      p.owned === PROPERTY_PURCHASING ||
      (p.owned === PROPERTY_OWNED && p.useAsSecurity),
  ),
);

export const nonResidenceOwnEntities = createSelector(
  primaryContactProperties,
  (selectedProperties) =>
    selectedProperties.filter(
      (p) => !p.residence && p.owned === PROPERTY_OWNED,
    ),
);

export const prospectiveProperties = createSelector(
  properties,
  (selectedProperties) =>
    selectedProperties.filter((p) => p.owned === PROPERTY_PURCHASING),
);

export const realEstateAssets = createSelector(properties, (a) =>
  a
    .filter((p) => p.owned === PROPERTY_OWNED)
    .map((asset) => ({ ...asset, category: ASSET_REAL_ESTATE_CATEGORY.id })),
);

export const propertyOptionsForFunding = createSelector(securities, (a) =>
  a.map((p) => ({
    value: p.owned === PROPERTY_PURCHASING ? p.id : p.propertyId,
    key: p.id,
    name: propertyDescriptionGenerator(p),
    label: propertyDescriptionGenerator(p),
  })),
);

export const hasToPurchaseOldInformation = createSelector(
  prospectiveProperties,
  (properties) => properties.some((b) => b.isOldData),
);

export const hasOtherPropertiesOldInformation = createSelector(
  nonResidenceOwnEntities,
  (properties) => properties.some((b) => b.isOldData),
);
