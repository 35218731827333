import moment from 'moment';
import { arrayOfTruthyKeys } from 'shared/lib/utils';
import { COMMON_SHORT_DATE_FORMAT } from 'shared/constants/dateFormats';
import { historyBuilderForMyCRM } from './history';

export function loanApplicationBuilderForMyCRM(application) {
  const isUpdateApplicant =
    application.hasCreditCheckRetried || application.hasSeenContext;
  return {
    purposes: arrayOfTruthyKeys(application.primaryGoals),
    features: arrayOfTruthyKeys(application.loanFeatures),
    specific_goals: application.specificGoal,
    what_you_want_todo: application.whatYouWantTodo,
    settlement_date: application.hasSettlementDate
      ? moment(application.settlementDate).format(COMMON_SHORT_DATE_FORMAT)
      : '',
    finance_date: application.hasFinanceDate
      ? moment(application.financeDate).format(COMMON_SHORT_DATE_FORMAT)
      : '',
    auction_date: application.hasAuctionDate
      ? moment(application.auctionDate).format(COMMON_SHORT_DATE_FORMAT)
      : '',
    has_settlement_date: application.hasSettlementDate,
    has_finance_date: application.hasFinanceDate,
    has_auction_date: application.hasAuctionDate,
    metadata: application.metadata ? JSON.stringify(application.metadata) : '',
    bankrupt: application.bankrupt,
    bankrupt_note: application.bankrupt
      ? application.bankruptDetail
      : undefined,
    behind_payments: application.behindInPayments,
    behind_payments_note: application.behindInPayments
      ? application.behindInPaymentsDetail
      : undefined,
    director: application.director,
    director_note: application.director
      ? application.directorDetail
      : undefined,
    status: application.status,
    current_family_id: application.currentFamilyId,
    current_client_id: application.currentClientId,
    has_signed_privacy_policy: application.hasSignedPrivacyPolicy,
    conduct: application.conduct,
    ...(isUpdateApplicant && {
      applicants: [
        {
          client_id: application.currentClientId,
          family_id: application.currentFamilyId,
          has_credit_check_retried: application.hasCreditCheckRetried,
          has_seen_context: application.hasSeenContext,
        },
      ],
    }),
    ...historyBuilderForMyCRM(application),
  };
}
