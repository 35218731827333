import { takeEvery, all, put, select, call } from 'redux-saga/effects';

import {
  CREATE_EMPLOYMENT,
  UPDATE_EMPLOYMENT,
  DELETE_EMPLOYMENT,
  CONFIRM_EMPLOYMENTS,
} from 'actions/employmentActionTypes';

import { SET_EMPLOYMENT_NZBN } from 'actions/individualEmploymentActionTypes';
import employmentActions from 'actions/employmentActions';
import incomeActions from 'actions/incomeActions';

import { getIncomes } from 'sagas/incomeSagas';

import { delEmployment, validateNZBN } from 'services/employmentApi';
import { postContactEmployment } from 'services/contactApi';
import * as contactSelectors from 'selectors/contactSelectors';
import * as employmentSelectors from 'selectors/employmentSelectors';

import { monitorAsyncRequest } from 'lib/sagaHelpers.js';
import { NZBN_NO_ERROR, NZBN_INVALID, isValidNZBN } from 'lib/nzbnHelpers';

import { EMPLOYMENT_STATUS_PREVIOUS_EMPLOYMENT } from 'shared/constants/myCRMTypes/employments';
import { ASYNC_REQUEST_TYPE } from 'constants/options';

export function* createEmployment({ payload }) {
  const primaryContact = yield select(contactSelectors.primaryContact);
  const newEmployment = { ...payload, contactId: primaryContact.contactId };
  const employment = yield postContactEmployment(newEmployment);
  yield put(employmentActions.setNewEmployment(employment));
  return employment;
}

export function* updateEmployment({ payload }) {
  const entitySelector = yield select(employmentSelectors.entity);
  const oldEmployment = entitySelector(Number(payload.id));

  const employment = yield postContactEmployment(payload);
  yield put(employmentActions.setEmployment(employment));

  const hasStatusChanged = employment.statusId !== oldEmployment.statusId;
  const hasEmployerNameChange =
    employment.employerName !== oldEmployment.employerName;

  const shouldRefreshIncomes =
    hasStatusChanged ||
    (hasEmployerNameChange &&
      employment.statusId !== EMPLOYMENT_STATUS_PREVIOUS_EMPLOYMENT.id);
  if (shouldRefreshIncomes) {
    yield call(getIncomes);
  }
}

export function* confirmEmployment({ payload: { employments } }) {
  yield all(employments.map((payload) => call(updateEmployment, { payload })));
}

export function* deleteEmployment({ payload }) {
  const employmentId = yield delEmployment(payload);
  yield put(employmentActions.removeEmployment(employmentId));
  yield call(getIncomes);
}

export function* updateNZBN({ payload }) {
  try {
    if (isValidNZBN(payload.value)) {
      yield put(employmentActions.setNzbnLoading(true));
      yield validateNZBN(payload.value);
      yield put(
        employmentActions.setEmploymentError(payload.id)(NZBN_NO_ERROR),
      );
      yield put(incomeActions.setIncomeError(payload.id)(NZBN_NO_ERROR));
      yield put(employmentActions.setNzbnLoading(false));
    }
  } catch (error) {
    yield put(employmentActions.setEmploymentError(payload.id)(NZBN_INVALID));
    yield put(incomeActions.setIncomeError(payload.id)(NZBN_INVALID));
    yield put(employmentActions.setNzbnLoading(false));
  }
}

function* watchUpdateNZBN() {
  yield takeEvery(SET_EMPLOYMENT_NZBN, updateNZBN);
}

function* watchCreateEmployment() {
  yield monitorAsyncRequest(
    takeEvery,
    CREATE_EMPLOYMENT,
    createEmployment,
    ASYNC_REQUEST_TYPE.FORM_POP_UP_REQUEST,
  );
}

function* watchUpdateEmployment() {
  yield monitorAsyncRequest(
    takeEvery,
    UPDATE_EMPLOYMENT,
    updateEmployment,
    ASYNC_REQUEST_TYPE.FORM_POP_UP_REQUEST,
  );
}

function* watchDeleteEmployment() {
  yield monitorAsyncRequest(
    takeEvery,
    DELETE_EMPLOYMENT,
    deleteEmployment,
    ASYNC_REQUEST_TYPE.FORM_POP_UP_REQUEST,
  );
}

function* watchConfirmEmployments() {
  yield monitorAsyncRequest(takeEvery, CONFIRM_EMPLOYMENTS, confirmEmployment);
}

export default function* employmentSagas() {
  yield all([
    watchCreateEmployment(),
    watchUpdateEmployment(),
    watchDeleteEmployment(),
    watchUpdateNZBN(),
    watchConfirmEmployments(),
  ]);
}
