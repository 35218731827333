import {
  DIRECT_APPLICATIONS_API_PATH,
  DIRECT_GOAL_LOAN_APP_INFO_API_PATH,
} from 'shared/constants/apiPaths';
import {
  axiosWithAuth as directAxiosWithAuth,
  dataTransform as directDataTransform,
  returnJSON as directReturnJSON,
} from '../mycrmBaseApi';

import { fundingBuilderForMilli } from 'shared/lib/builders/milli/funding';
import { propertyBuilderForMilli } from 'shared/lib/builders/milli/property';
import { structureBuilderForMilli } from 'shared/lib/builders/milli/structure';
import { securityBuilderForMilli } from 'shared/lib/builders/milli/security';
import { loanApplicationBuilderForMilli } from 'shared/lib/builders/milli/loanApplication';

import { loanApplicationBuilderForMyCRM } from 'shared/lib/builders/mycrm/loanApplication';
import { fundingBuilderForMyCRM } from 'shared/lib/builders/mycrm/funding';
import { structureBuilderForMyCRM } from 'shared/lib/builders/mycrm/structure';
import { TRANSACTION_OWNS } from 'shared/constants/myCRMTypes/property';
import { assetsInfoBuilderForMilli } from 'shared/lib/builders/milli/asset';

// port of serverless handler: goalLoanApplications/goalLoanApplications.get
export function getLoanApplication(id) {
  return directAxiosWithAuth
    .get(`${DIRECT_APPLICATIONS_API_PATH}/${id}`)
    .then(directDataTransform(loanApplicationBuilderForMilli))
    .then(directReturnJSON);
}

// port of serverless handler: goalLoanApplications/goalLoanApplications.put
export function putLoanApplication(loanApplication) {
  return directAxiosWithAuth
    .put(
      `${DIRECT_APPLICATIONS_API_PATH}/${loanApplication.id}`,
      loanApplicationBuilderForMyCRM(loanApplication),
    )
    .then(directReturnJSON);
}

// port of serverless handler: goalLoanApplications/goalLoanApplications.getFundings
export function getFundingsFromLoanApplication(id) {
  return directAxiosWithAuth
    .get(`${DIRECT_APPLICATIONS_API_PATH}/${id}/fundings`)
    .then(({ data }) => {
      return data.map(fundingBuilderForMilli);
    })
    .then(directReturnJSON);
}

// port of serverless handler: goalLoanApplications/goalLoanApplications.getStructures
export function getStructuresFromLoanApplication(id) {
  return directAxiosWithAuth
    .get(`${DIRECT_APPLICATIONS_API_PATH}/${id}/structures`)
    .then(({ data }) => {
      return data.map((d) => _.merge(structureBuilderForMilli(d), { id }));
    })
    .then(directReturnJSON);
}

// port of serverless handler: goalLoanApplications/goalLoanApplications.getSecurities
export function getSecuritiesFromLoanApplication(id) {
  return directAxiosWithAuth
    .get(`${DIRECT_APPLICATIONS_API_PATH}/${id}/securities`)
    .then(({ data }) => {
      return data.map(securityBuilderForMilli);
    })
    .then(directReturnJSON);
}

// port of serverless handler: goalLoanApplications/goalLoanApplications.getProperties
export function getProperties(id) {
  return directAxiosWithAuth
    .get(`${DIRECT_APPLICATIONS_API_PATH}/${id}/properties`)
    .then(({ data }) => {
      /* owned properties are handled in GET contacts/id/assets */
      const prospectiveOrSoldProperties = data.filter(
        (p) => p.transaction_id !== TRANSACTION_OWNS,
      );
      return prospectiveOrSoldProperties.map(propertyBuilderForMilli);
    })
    .then(directReturnJSON);
}

// port of serverless handler: goalLoanApplications/goalLoanApplications.postFundings
export function postFundingForLoanApplication(funding) {
  const loanApplicationId = funding.loanApplicationId;
  return directAxiosWithAuth
    .post(`${DIRECT_APPLICATIONS_API_PATH}/${loanApplicationId}/fundings`, [
      fundingBuilderForMyCRM(funding),
    ])
    .then(({ data }) => {
      return data.map((d) =>
        _.merge(fundingBuilderForMilli(d), { loanApplicationId }),
      );
    })
    .then(directReturnJSON);
}

// port of serverless handler: goalLoanApplications/goalLoanApplications.getAssets
export function getAssets(id) {
  return directAxiosWithAuth
    .get(
      `${DIRECT_GOAL_LOAN_APP_INFO_API_PATH}/AssetInfoGet?loanScenarioId=${id}`,
    )
    .then(directDataTransform(assetsInfoBuilderForMilli))
    .then(directReturnJSON);
}

// port of serverless handler: goalLoanApplications/goalLoanApplications.putStructures
export function putStructures(structure) {
  const { loanApplicationId, id } = structure;

  return directAxiosWithAuth
    .put(
      `${DIRECT_APPLICATIONS_API_PATH}/${loanApplicationId}/structures/${id}`,
      structureBuilderForMyCRM(structure),
    )
    .then(directReturnJSON);
}
