import {
  isNotABot,
  buildTrackedData,
  buildApplicantData,
  buildScenarioData,
} from 'lib/trackAnalyticsHelper';
import { toPageTitle } from 'lib/utils/stringUtils';
import {
  TRACK_CURRENT_USER,
  TRACK_EMAIL_CAPTURE,
  TRACK_PASSWORD_CAPTURE,
  TRACK_MOBILE_CAPTURE,
  TRACK_SUBMITTED_FOR_REVIEW,
  CONVERT_SCENARIO,
  SET_LOAN_PURPOSE,
  SET_SCENARIO_NAME,
  TRACK_OKTA_SESSION_EXPIRED,
  TRACK_OKTA_LOGIN_ATTEMPT,
  TRACK_OKTA_SMS_AUTH_VERIFY,
  TRACK_OKTA_GOOGLE_AUTH_VERIFY,
  TRACK_OKTA_LOGIN_SUCCESS,
  TRACK_MLG_SIGNUP_ATTEMPT,
  TRACK_ORGANIC_SIGNUP_ATTEMPT,
  TRACK_FACT_FIND_SIGNUP_ATTEMPT,
  TRACK_MLG_SIGNUP_SUCCESS,
  TRACK_ORGANIC_SIGNUP_SUCCESS,
  TRACK_FACT_FIND_SIGNUP_SUCCESS,
  TRACK_RECOVER_PASSWORD,
  TRACK_RESET_PASSWORD_ATTEMPT,
  TRACK_RESET_PASSWORD_SUCCESS,
} from 'actions/scenarioActionTypes';
import {
  CLEAR_HANDHOLD_SECTIONS,
  SET_FULL_HANDHOLD_SECTIONS,
  SET_FULL_HANDHOLD_NO_FORESEEABLE_SLUG_SECTIONS,
  SET_APPLICATION_TESTED,
  SET_PAGE_ERROR,
  SET_PAGE_GHOST_ERROR,
} from 'actions/UIActionTypes';
import {
  CUSTOMER_IO_TRIGGER_EVENT,
  ERROR_EVENT,
  GHOST_ERROR_EVENT,
  LEAD_CONVERTED_EVENT,
  LEAD_EMAIL_CAPTURED_EVENT,
  LEAD_PASSWORD_CAPTURED_EVENT,
  LEAD_MOBILE_CAPTURED_EVENT,
  SET_IS_APPLICATION_TESTED_EVENT,
  SET_IS_HANDHOLD_EVENT,
  SET_LOAN_PURPOSE_EVENT,
  SET_LOAN_SCENARIO_EVENT,
  OKTA_SESSION_EXPIRED_EVENT,
  USER_DATA_EVENT,
  VIRTUAL_PAGE_VIEW_EVENT,
  OKTA_LOGIN_ATTEMPT_EVENT,
  OKTA_SMS_AUTH_VERIFY_EVENT,
  OKTA_GOOGLE_AUTH_VERIFY_EVENT,
  OKTA_LOGIN_SUCCESS_EVENT,
  MLG_SIGNUP_ATTEMPT_EVENT,
  FACT_FIND_SIGNUP_ATTEMPT_EVENT,
  ORGANIC_SIGNUP_ATTEMPT_EVENT,
  MLG_SIGNUP_SUCCESS_EVENT,
  ORGANIC_SIGNUP_SUCCESS_EVENT,
  FACT_FIND_SIGNUP_SUCCESS_EVENT,
  RECOVER_PASSWORD_EVENT,
  RESET_PASSWORD_ATTEMPT_EVENT,
  RESET_PASSWORD_SUCCESS_EVENT,
  GENERIC_EVENT,
  SEND_FOR_REVIEW_EVENT,
} from 'constants/GTMEvents';

import * as advisorSelectors from 'selectors/advisorSelectors';
import * as clientSelectors from 'selectors/clientSelectors';
import { getErrorDetails } from 'lib/errorHelper';

const SUBMITTED_FOR_REVIEW_TRIGGER = 'trigger_submitted_for_review_workflow';

const OKTA_EVENTS = {
  [TRACK_OKTA_LOGIN_ATTEMPT]: OKTA_LOGIN_ATTEMPT_EVENT,
  [TRACK_OKTA_LOGIN_SUCCESS]: OKTA_LOGIN_SUCCESS_EVENT,
  [TRACK_OKTA_SMS_AUTH_VERIFY]: OKTA_SMS_AUTH_VERIFY_EVENT,
  [TRACK_OKTA_GOOGLE_AUTH_VERIFY]: OKTA_GOOGLE_AUTH_VERIFY_EVENT,
};

function trackAnalytics() {
  // eslint-disable-next-line sonarjs/cognitive-complexity
  return (store) => (next) => (action) => {
    const state = store.getState();
    if (isNotABot(navigator.userAgent)) {
      const { scenario, advisor, advisorOrg } = state;
      const workingApplication = state.application?.working;
      const primaryApplicant = clientSelectors.primaryApplicant(state);
      const defaultParams = buildTrackedData({
        ...buildScenarioData(scenario),
        ...buildApplicantData(primaryApplicant),
        appId: workingApplication?.id,
        advisor,
        advisorOrg,
      });
      switch (action.type) {
        case TRACK_SUBMITTED_FOR_REVIEW: {
          const { completionPercent, completedSections } = action.payload;
          window.dataLayer.push({
            ...defaultParams,
            event: CUSTOMER_IO_TRIGGER_EVENT,
            label: SUBMITTED_FOR_REVIEW_TRIGGER,
            ...buildTrackedData(action.payload),
          });
          window.dataLayer.push({
            ...defaultParams,
            event: SEND_FOR_REVIEW_EVENT,
            label: SEND_FOR_REVIEW_EVENT,
            completionPercent: Math.round(completionPercent * 100),
            completedSections: Object.keys(completedSections).reduce(
              (arr, key) => {
                if (completedSections[key]) {
                  arr.push(key);
                }
                return arr;
              },
              [],
            ),
            ...buildTrackedData(action.payload),
          });
          break;
        }
        case TRACK_CURRENT_USER: {
          const subscriptions = advisorSelectors.hasBankConnectSubscription(
            state,
          )
            ? 'Bank Connect'
            : '';
          const userData = {
            ...defaultParams,
            event: USER_DATA_EVENT,
            ...buildTrackedData(action.payload),
            user_subscriptions: subscriptions,
          };

          window.dataLayer.push(userData);
          break;
        }
        case TRACK_PASSWORD_CAPTURE:
          if (!scenario.passwordCaptured) {
            window.dataLayer.push({
              ...defaultParams,
              event: GENERIC_EVENT,
              label: LEAD_PASSWORD_CAPTURED_EVENT,
            });
          }
          break;
        case TRACK_OKTA_LOGIN_ATTEMPT:
        case TRACK_OKTA_SMS_AUTH_VERIFY:
        case TRACK_OKTA_GOOGLE_AUTH_VERIFY:
        case TRACK_OKTA_LOGIN_SUCCESS:
          window.dataLayer.push({
            event: GENERIC_EVENT,
            label: OKTA_EVENTS[action.type],
            user_email: action.payload?.email,
            user_client_id: action.payload?.clientId,
            user_family_id: action.payload?.familyId,
            user_full_name: action.payload
              ? `${action.payload?.given_name} ${action.payload?.family_name}`
              : undefined,
            user_mobile: action.payload?.mobile,
          });
          break;
        case TRACK_OKTA_SESSION_EXPIRED: {
          const { clientId, brokerFamilyId } = action.payload;
          window.dataLayer.push({
            ...defaultParams,
            event: OKTA_SESSION_EXPIRED_EVENT,
            client_id: clientId,
            broker_family_id: brokerFamilyId,
          });
          break;
        }
        case TRACK_MLG_SIGNUP_ATTEMPT:
          window.dataLayer.push({
            ...defaultParams,
            event: GENERIC_EVENT,
            label: MLG_SIGNUP_ATTEMPT_EVENT,
          });
          break;
        case TRACK_ORGANIC_SIGNUP_ATTEMPT:
          window.dataLayer.push({
            ...defaultParams,
            event: GENERIC_EVENT,
            label: ORGANIC_SIGNUP_ATTEMPT_EVENT,
          });
          break;
        case TRACK_FACT_FIND_SIGNUP_ATTEMPT:
          window.dataLayer.push({
            ...defaultParams,
            event: GENERIC_EVENT,
            label: FACT_FIND_SIGNUP_ATTEMPT_EVENT,
            recovery_token: action.payload.recoveryToken,
            user_client_id: action.payload.clientId,
            user_loan_app_id: action.payload.loanApplicationId,
          });
          break;
        case TRACK_RESET_PASSWORD_ATTEMPT:
          window.dataLayer.push({
            event: GENERIC_EVENT,
            label: RESET_PASSWORD_ATTEMPT_EVENT,
            recovery_token: action.payload,
          });
          break;
        case TRACK_RESET_PASSWORD_SUCCESS:
          window.dataLayer.push({
            event: GENERIC_EVENT,
            label: RESET_PASSWORD_SUCCESS_EVENT,
            recovery_token: action.payload,
          });
          break;
        case TRACK_MLG_SIGNUP_SUCCESS:
          window.dataLayer.push({
            ...defaultParams,
            event: GENERIC_EVENT,
            label: MLG_SIGNUP_SUCCESS_EVENT,
          });
          break;
        case TRACK_ORGANIC_SIGNUP_SUCCESS:
          window.dataLayer.push({
            ...defaultParams,
            event: GENERIC_EVENT,
            label: ORGANIC_SIGNUP_SUCCESS_EVENT,
          });
          break;
        case TRACK_FACT_FIND_SIGNUP_SUCCESS:
          window.dataLayer.push({
            ...defaultParams,
            event: GENERIC_EVENT,
            label: FACT_FIND_SIGNUP_SUCCESS_EVENT,
            recovery_token: action.payload.recoveryToken,
            user_client_id: action.payload.clientId,
            user_loan_app_id: action.payload.loanApplicationId,
          });
          break;
        case TRACK_RECOVER_PASSWORD:
          window.dataLayer.push({
            event: RECOVER_PASSWORD_EVENT,
            user_email: action.payload,
          });
          break;
        case TRACK_MOBILE_CAPTURE:
          if (!scenario.mobileCaptured) {
            window.dataLayer.push({
              ...defaultParams,
              event: LEAD_MOBILE_CAPTURED_EVENT,
            });
          }
          break;
        case TRACK_EMAIL_CAPTURE:
          if (!scenario.emailCaptured) {
            window.dataLayer.push({
              ...defaultParams,
              event: LEAD_EMAIL_CAPTURED_EVENT,
              ...buildTrackedData(action.payload),
            });
          }
          break;
        case CONVERT_SCENARIO:
          if (!scenario.converted) {
            window.dataLayer.push({
              ...defaultParams,
              event: LEAD_CONVERTED_EVENT,
              ...buildTrackedData(action.payload),
            });
          }
          break;
        case SET_LOAN_PURPOSE:
          window.dataLayer.push({
            ...defaultParams,
            event: SET_LOAN_PURPOSE_EVENT,
            loan_purpose: action.payload,
          });
          break;
        case SET_SCENARIO_NAME:
          window.dataLayer.push({
            ...defaultParams,
            event: SET_LOAN_SCENARIO_EVENT,
            loan_scenario: action.payload,
          });
          break;
        case '@@router/LOCATION_CHANGE': {
          const {
            location: { pathname },
            namespace,
          } = action.payload;
          if (!namespace || pathname === '/') {
            return;
          }

          window.dataLayer.push({
            ...defaultParams,
            event: VIRTUAL_PAGE_VIEW_EVENT,
            virtualPagePath: pathname,
            virtualPageTitle: toPageTitle(pathname),
          });
          break;
        }
        case SET_FULL_HANDHOLD_SECTIONS:
        case SET_FULL_HANDHOLD_NO_FORESEEABLE_SLUG_SECTIONS:
        case CLEAR_HANDHOLD_SECTIONS: {
          window.dataLayer.push({
            ...defaultParams,
            event: SET_IS_HANDHOLD_EVENT,
            is_handhold: [
              SET_FULL_HANDHOLD_SECTIONS,
              SET_FULL_HANDHOLD_NO_FORESEEABLE_SLUG_SECTIONS,
            ].includes(action.type),
          });
          break;
        }
        case SET_APPLICATION_TESTED: {
          window.dataLayer.push({
            ...defaultParams,
            event: SET_IS_APPLICATION_TESTED_EVENT,
            is_application_tested: action.payload,
          });
          break;
        }
        case SET_PAGE_ERROR: {
          const { name, details, status } = getErrorDetails(
            action.payload.status,
          );
          window.dataLayer.push({
            ...defaultParams,
            event: ERROR_EVENT,
            name,
            details,
            status,
            error: action.payload.error,
          });
          break;
        }
        case SET_PAGE_GHOST_ERROR: {
          const { name, details, status } = getErrorDetails(
            action.payload.status,
          );
          window.dataLayer.push({
            ...defaultParams,
            event: GHOST_ERROR_EVENT,
            name,
            details,
            status,
          });
          break;
        }
        default:
          break;
      }
    }
    return next(action);
  };
}

export default trackAnalytics;
