import { EXPENSE_CATEGORY_NAMES } from '../../../constants/expenseCategoryTypesV2';

export const expenseCategoryTypeBuilderForMilli = (categoryTypes) => {
  return (
    categoryTypes &&
    categoryTypes.map((category) => ({
      ...category,
      name: EXPENSE_CATEGORY_NAMES[category.id] || category.name,
    }))
  );
};
