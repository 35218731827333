import Rox from 'rox-browser';

export const featureFlags = {
  testFlag: new Rox.Flag(),
  internationalPhoneNumbers: new Rox.Flag(),
  internationalAddresses: new Rox.Flag(),
  gamePlan: new Rox.Flag(),
  disableMilliForeseeableChanges: new Rox.Flag(),
  livingExpenseV2: new Rox.Flag(),
  importantDatesInMilli: new Rox.Flag(),
  constructionLoanApps: new Rox.Flag(),
  lendersByAccreditation: new Rox.Flag(),
  nzNewServiceability: new Rox.Flag(),
  fileInviteDocs: new Rox.Flag(),
  oneTouchPrivacy: new Rox.Flag(),
  goalSetterFactFindMerge: new Rox.Flag(),
  financialGoalSummary: new Rox.Flag(),
  undisclosedGender: new Rox.Flag(),
  antiHawking: new Rox.Flag(),
  'complianceQuestions.reduceDuplication': new Rox.Flag(),
  'consumer.data-collection': new Rox.Flag(),
  'consumer.direct-to-mycrm-api.financial-goal': new Rox.Flag(),
  'consumer.manual-id-verification': new Rox.Flag(),
};

export const setCustomProperty = (key, value) => {
  if (!key) {
    return;
  }
  switch (typeof value) {
    // TEMP console logs for testing
    case 'boolean':
      Rox.setCustomBooleanProperty(key, value);
      break;
    case 'string':
      Rox.setCustomStringProperty(key, value === '' ? 'NULL' : value);
      break;
    case 'number':
      if (!value) {
        return;
      }
      Rox.setCustomNumberProperty(key, value);
      break;
    default:
      break;
  }
};

export const setCustomProperties = (properties) => {
  if (!properties || typeof properties !== 'object') {
    return;
  }
  Object.keys(properties).forEach((key) =>
    setCustomProperty(key, properties[key]),
  );
};

export const buildRolloutCustomProperties = (trackedData) => {
  if (!trackedData) {
    return null;
  }
  const isClient = trackedData.user_is_client === 'true';
  return {
    'Adviser Branding': trackedData.user_branding_category,
    'Adviser Org ID': Number(trackedData.user_adviser_org_id),
    'Adviser Org Name': trackedData.user_actual_adviser_org_name,
    'Adviser Id': Number(trackedData.user_assigned_adviser_id),
    Email: trackedData.user_adviser_email,
    'User Country': trackedData.user_country,
    'User ID': Number(trackedData.user_family_id),
    'User is Adviser': !isClient,
    'User is Assistant': trackedData.user_is_assistant === 'true',
    'User is Corporate': trackedData.user_is_corporate === 'true',
    'User Name': trackedData.user_adviser_email,
    'User State': trackedData.user_reporting_state_name,
    // 'Platform is Goal Setter': true @TODO use isGoalSetter function from remove-opsworks
  };
};

export default async () => {
  try {
    Rox.register('', featureFlags);
    await Rox.setup(window.LM_CONFIG.ROLLOUT_API_KEY, {
      freeze: 'none',
    });
    if (process.env.NODE_ENV === 'development') {
      window.rolloutOverride = () => Rox.showOverrides();
    }
  } catch (error) {
    console.error('Rollout Setup Failed:', error);
  }
};
