export const CLEAR_WORKING_PROPERTY = 'clearWorkingProperty';
export const LOAD_RESIDENCE_PROPERTY = 'loadResidenceProperty';
export const LOAD_PROSPECTIVE_PROPERTY = 'loadProspectiveProperty';
export const LOAD_PROPERTY = 'loadProperty';
export const SET_PROPERTY = 'setProperty';
export const REMOVE_PROPERTY_APPLY = 'removePropertyApply';
export const SET_NEW_PROPERTY = 'setNewProperty';
export const SET_NEW_PROPERTIES = 'setNewProperties';
export const INSERT_NEW_PROPERTIES = 'insertNewProperties';
export const SET_PROPERTY_INTL_ADDRESS = 'setPropertyIntlAddress';

export const CREATE_PROPERTY = 'createProperty';
export const UPDATE_PROPERTY = 'updateProperty';
export const DELETE_PROPERTY = 'deleteProperty';

export const CONFIRM_PROPERTIES = 'confirmProperties';
