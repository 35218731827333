/* eslint-disable security/detect-object-injection */
import {
  DISPLAY_OPTIONS,
  NATURE_OF_CHANGE,
  HIDDEN_SUBQS,
  OTHER_QS,
  CUSTOM_CONTENT,
  CUSTOM_DISPLAY_TYPE_IDS,
  CUSTOM_SPAN_CONTENT,
  QUESTION_FILTERS,
  QUESTION_FILTERS_OVERRIDE,
  QUESTION_VALIDATIONS,
  LONG_TERM_GOALS_ICONS,
  OPTIONAL_DETAILS_PLACEHOLDER,
  INLINED_QS,
  CUSTOM_PLACEHOLDERS,
  CUSTOM_SUBQUESTIONS,
  CUSTOM_TITLE,
  CUSTOM_SUBCONTENT,
  CUSTOM_NOTES,
  CUSTOM_SUBCONTENT_HEADER,
  CUSTOM_OPTION_ICONS,
  CUSTOM_OPTION_LABELS,
  CUSTOM_OPTION_DESCRIPTIONS,
  RATE_PREFERENCES_OPTIONS_FILTER,
  LOAN_OPTIONS,
  PREFERRED_BANKS,
  HAS_PREFERRED_LENDERS_Q,
  LOAN_OPTIONS_QS,
  QUESTION_IDS,
  WEALTH_CREATION_GOAL_TYPE_ID,
  SUMMARY_GOAL_TYPE_ID,
  REQUIRED,
  QUESTIONS_OVERRIDE,
  EXTRACTABLE_CONTENT_REGEX,
  EXTRACTABLE_SUBCONTENT_REGEX,
} from 'shared/constants/goalLoanAppInfo';

const {
  CURRENT_BANK_OTHER,
  OTHER_CURRENT_BANK,
  HAPPY_WITH_CURRENT_BANK,
  PREFERRED_LENDERS_OTHER,
  OTHER_PREFERRED_LENDER,
  LIFESTYLE_INSURANCE_OBLIGATION,
  NEW_GENERAL_INSURANCE,
  GENERAL_INSURANCE,
  MOVING_SERVICES,
  LIFESTYLE_INSURANCE,
  LIFESTYLE_INSURANCE_ALI,
  REPAY_LOAN_METHOD_OTHER,
  LIFESTYLE_INSURANCE_ADVISER,
  LIFESTYLE_INSURANCE_RISK,
  RATE_PREFERENCES_IMPORTANT,
  LIFE_AND_HEALTH_REASON,
  GENERAL_INSURANCE_NZ_REASON,
  LIFE_AND_HEALTH,
  GENERAL_INSURANCE_NZ,
  UK_PENSION_TRANSFERS,
  ASSET_FINANCE,
  CURRENCY_EXCHANGE,
  KIWI_SAVER,
  HOME_NOW,
  ADEQUATE_INSURANCE_FAMILY_YES_NO,
  ADEQUATE_INSURANCE_HOME_YES_NO,
} = QUESTION_IDS;

const overrideQuestions = (constValue, constName, features = []) => {
  let returnValue = { ...constValue };
  Object.keys(QUESTIONS_OVERRIDE).forEach((featureName) => {
    if (
      QUESTIONS_OVERRIDE[featureName]?.[constName] &&
      features.includes(featureName)
    ) {
      returnValue = {
        ...returnValue,
        ...QUESTIONS_OVERRIDE[featureName][constName],
      };
    }
  });
  return returnValue;
};

const optionsMapper = (options) =>
  options.reduce((map, q) => {
    map[q.QuestionId] = q.ObjectiveList.map((l) => ({
      name: l.Name,
      label: l.Name,
      value: l.Value,
      key: l.Value,
    }));
    return map;
  }, {});

export const natureOfChangeMapper = (d) => ({
  planOrAnticipateId: d.PlanOrAnticipateId,
  optionName: d.OptionName,
  details: d.Details,
  objectiveListValue: d.ObjectiveListValue,
  natureOfChangeId: d.NatureOfChangeId,
  loanScenarioId: d.LoanScenarioId,
  objectiveListName: d.ObjectiveListName,
  periodOfImpactDuration: d.PeriodOfImpactDuration,
  periodOfImpactUnit: d.PeriodOfImpactUnit,
  financialImpactOperator: d.FinancialImpactOperator,
  financialImpactAmount: d.FinancialImpactAmount,
  clientId: d.ClientId,
  startDate: d.StartDate,
  optionId: d.OptionId,
  natureOfChange: d.NatureOfChange,
  other: d.Other,
});

export function adverseChangesBuilderForMilli(data, natureOfChange) {
  const mappedNatureOfChange = natureOfChange.Data
    ? natureOfChange.Data.reduce((map, d) => {
        if (!map[d.ClientId]) {
          map[d.ClientId] = {
            [`${NATURE_OF_CHANGE}-${d.NatureOfChange}`]: natureOfChangeMapper(
              d,
            ),
          };
        } else {
          map[d.ClientId][
            `${NATURE_OF_CHANGE}-${d.NatureOfChange}`
          ] = natureOfChangeMapper(d);
        }
        return map;
      }, {})
    : {};

  return data.Data.reduce((map, d) => {
    if (!map[d.ClientId]) {
      map[d.ClientId] = { [d.QuestionId]: d.Answer };
    } else {
      map[d.ClientId][d.QuestionId] = d.Answer;
    }
    if (mappedNatureOfChange[d.ClientId]) {
      map[d.ClientId] = {
        ...mappedNatureOfChange[d.ClientId],
        ...map[d.ClientId],
      };
    }
    return map;
  }, {});
}

export function longTermGoalConsolidatedBuilderForMilli({ Data }, ids) {
  let filteredAnswers = Data
    ? Data.filter((item) => ids.includes(item.QuestionId))
    : [];
  if (!filteredAnswers.length) {
    return {};
  }

  // Note: This remove duplication by ClientId
  filteredAnswers = filteredAnswers.reduce((acc, current) => {
    const duplicate = acc.find((item) => item.ClientId === current.ClientId);
    if (!duplicate) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);

  return adverseChangesBuilderForMilli({ Data: filteredAnswers }, {});
}

export function longTermGoalsBuilderForMilli(data) {
  return data.Data.reduce((map, d) => {
    map[d.FutureFinancialGoalId] = d.Details;
    return map;
  }, {});
}

export const subQsMapper = (
  subQuestions,
  parentId,
  parentDisplayTypeId,
  options,
  isNZ,
) => {
  const qIdx = isNZ ? 1 : 0;
  if (subQuestions.length === 0) {
    return subQuestions;
  }
  return subQuestions.map((s) => {
    let parentShowValue;
    switch (parentDisplayTypeId) {
      case DISPLAY_OPTIONS.YES_NO_ONLY:
        if (
          [LIFE_AND_HEALTH_REASON, GENERAL_INSURANCE_NZ_REASON].includes(
            s.QuestionId,
          )
        ) {
          parentShowValue = '0';
        } else {
          parentShowValue = '1';
        }
        break;
      case DISPLAY_OPTIONS.YES_NO_ONLY_BOOL:
        parentShowValue = parentId !== HAPPY_WITH_CURRENT_BANK[qIdx];
        break;
      case DISPLAY_OPTIONS.CHECK_BOX:
      case DISPLAY_OPTIONS.SINGLE_DROPDOWN:
      case DISPLAY_OPTIONS.SINGLE_DROPDOWN_STRING:
      case DISPLAY_OPTIONS.MULTI_DROPDOWN:
        parentShowValue = 'Other';
        break;
      case DISPLAY_OPTIONS.MULTI_SELECT_WITH_LOGO:
        parentShowValue = 'any';
        if (s.QuestionId === CURRENT_BANK_OTHER) {
          parentShowValue = OTHER_CURRENT_BANK;
        } else if (s.QuestionId === PREFERRED_LENDERS_OTHER) {
          parentShowValue = OTHER_PREFERRED_LENDER;
        }
        break;
      default:
        parentShowValue = undefined;
        break;
    }
    s.ParentId = parentId;
    return {
      parentId,
      parentDisplayTypeId,
      parentShowValue,
      ...loanAppQsMapper(s, options, isNZ),
    };
  });
};

export const iconsToOptionsMapper = (q, options, isNZ) => {
  const { QuestionId: questionId, ParentId, Options } = q;
  let qOptions = options[questionId];
  if (questionId === RATE_PREFERENCES_IMPORTANT[isNZ ? 1 : 0]) {
    qOptions = qOptions.filter((o) =>
      RATE_PREFERENCES_OPTIONS_FILTER[ParentId].includes(Number(o.value)),
    );
  }
  if (
    ![
      DISPLAY_OPTIONS.SINGLE_SELECT_POPUP,
      DISPLAY_OPTIONS.MULTI_SELECT_POPUP,
    ].includes(CUSTOM_DISPLAY_TYPE_IDS[questionId])
  ) {
    return qOptions || Options;
  }
  const icons = CUSTOM_OPTION_ICONS[questionId] || {};
  const labels = CUSTOM_OPTION_LABELS[questionId] || {};
  const descriptions = CUSTOM_OPTION_DESCRIPTIONS[questionId] || {};
  return qOptions.map((o) => ({
    ...o,
    icon: icons[o.value],
    label: labels[o.value] || o.label,
    name: labels[o.value] || o.name,
    description: descriptions[o.value],
  }));
};

export const getQuestionMetaContent = (questionContent, pattern) =>
  questionContent.match(pattern).pop().trim();

export const loanAppQsMapper = (q, options, isNZ, features = []) => {
  let filteredSubQs = q.SubQuestion.filter(
    (s) => !HIDDEN_SUBQS.includes(s.QuestionId),
  );
  if (q.QuestionId === REPAY_LOAN_METHOD_OTHER[0]) {
    filteredSubQs = [];
  }
  const parentDisplayTypeId =
    CUSTOM_DISPLAY_TYPE_IDS[q.QuestionId] || q.QuestionDisplayTypeId;
  const customContent = isNZ
    ? CUSTOM_CONTENT
    : overrideQuestions(CUSTOM_CONTENT, 'CUSTOM_CONTENT', features);

  const customSubcontent = isNZ
    ? CUSTOM_SUBCONTENT
    : overrideQuestions(CUSTOM_SUBCONTENT, 'CUSTOM_SUBCONTENT', features);

  const customTitle = isNZ
    ? CUSTOM_TITLE
    : overrideQuestions(CUSTOM_TITLE, 'CUSTOM_TITLE', features);

  const customNotes = isNZ
    ? CUSTOM_NOTES
    : overrideQuestions(CUSTOM_NOTES, 'CUSTOM_NOTES', features);

  let questionContent = customContent[q.QuestionId] || q.QuestionContent;
  let questionSubContent = q.CustomSubcontent || customSubcontent[q.QuestionId];

  if (EXTRACTABLE_SUBCONTENT_REGEX[q.QuestionId]) {
    questionSubContent = getQuestionMetaContent(
      questionContent,
      EXTRACTABLE_SUBCONTENT_REGEX[q.QuestionId],
    );
  }

  if (EXTRACTABLE_CONTENT_REGEX[q.QuestionId]) {
    questionContent = getQuestionMetaContent(
      questionContent,
      EXTRACTABLE_CONTENT_REGEX[q.QuestionId],
    );
  }

  return {
    questionId: q.QuestionId,
    subQuestions: subQsMapper(
      filteredSubQs,
      q.QuestionId,
      parentDisplayTypeId,
      options,
      isNZ,
    ),
    content: questionContent,
    displayTypeId: q.QuestionDisplayTypeId,
    goalTypeId: q.GoalTypeId,
    options: iconsToOptionsMapper(q, options, isNZ),
    customDisplayTypeId: CUSTOM_DISPLAY_TYPE_IDS[q.QuestionId],
    isOtherField: OTHER_QS.includes(q.QuestionId),
    spanContent: CUSTOM_SPAN_CONTENT[q.QuestionId],
    validations:
      q.CustomValidations || QUESTION_VALIDATIONS[q.QuestionId] || [],
    icon: q.Icon,
    inlinedNextQ: INLINED_QS[q.QuestionId],
    customPlaceholder: q.CustomPlaceholder || CUSTOM_PLACEHOLDERS[q.QuestionId],
    customTitle: customTitle[q.QuestionId],
    customSubcontent: questionSubContent,
    customSubcontentHeader: CUSTOM_SUBCONTENT_HEADER[q.QuestionId],
    customNotes: q.CustomNotes || customNotes[q.QuestionId],
  };
};

export const contactGoalToQsMapper = (data, disclaimer) =>
  data.Data.map((q) => ({
    QuestionId: q.FutureFinancialGoalId,
    SubQuestion: [],
    QuestionContent:
      q.FutureFinancialGoalTypeId === SUMMARY_GOAL_TYPE_ID
        ? `${q.FutureFinancialGoalType} *`
        : q.FutureFinancialGoalType,
    CustomSubcontent: q.FutureFinancialGoalTypeDescription,
    QuestionDisplayTypeId: DISPLAY_OPTIONS.TEXT_ONLY,
    GoalTypeId: q.FutureFinancialGoalTypeId,
    Icon: LONG_TERM_GOALS_ICONS[q.FutureFinancialGoalTypeId],
    CustomPlaceholder:
      q.FutureFinancialGoalTypeId === SUMMARY_GOAL_TYPE_ID
        ? 'Details'
        : OPTIONAL_DETAILS_PLACEHOLDER,
    CustomNotes:
      q.FutureFinancialGoalTypeId === WEALTH_CREATION_GOAL_TYPE_ID
        ? disclaimer
        : undefined,
    CustomValidations:
      q.FutureFinancialGoalTypeId === SUMMARY_GOAL_TYPE_ID ? [REQUIRED] : [],
  }));

export function customSubQuestionsMapper(q, questions, isNZ) {
  const customSubQs = CUSTOM_SUBQUESTIONS[isNZ ? 'NZ' : 'AU'][q.QuestionId];
  if (q.SubQuestion.length > 0) {
    q.SubQuestion = q.SubQuestion.map((subQ) =>
      customSubQuestionsMapper(subQ, questions, isNZ),
    );
  }
  if (!customSubQs) {
    return q;
  }
  customSubQs.forEach((s) => {
    if (typeof s === 'object') {
      q.SubQuestion.push(s);
    } else {
      let subQ = questions.find((r) => `${r.QuestionId}` === `${s}`);
      const subQIds = q.SubQuestion.map((r) => r.QuestionId);
      if (subQ && !subQIds.includes(s)) {
        subQ = customSubQuestionsMapper(subQ, questions, isNZ);
        q.SubQuestion.push(subQ);
      }
    }
  });
  return q;
}

export function loanAppQsBuilderForMilli(
  data,
  options,
  section,
  isNZ = false,
  features = [],
) {
  const questions = [...data];
  const site = isNZ ? 'NZ' : 'AU';
  if (questions.length > 0 && section === LOAN_OPTIONS) {
    LOAN_OPTIONS_QS[site].forEach((q) => {
      questions.push(q);
    });
  }
  if (questions.length > 0 && section === PREFERRED_BANKS) {
    questions.push(HAS_PREFERRED_LENDERS_Q);
  }

  let combinedFilters = QUESTION_FILTERS[site];
  if (features) {
    features.forEach((feature) => {
      if (
        QUESTION_FILTERS_OVERRIDE[feature] &&
        QUESTION_FILTERS_OVERRIDE[feature][site]
      ) {
        combinedFilters = {
          ...combinedFilters,
          ...QUESTION_FILTERS_OVERRIDE[feature][site],
        };
      }
    });
  }
  const filters = combinedFilters[section];

  return questions.reduce((map, q) => {
    if (section && filters.length > 0 && !filters.includes(q.QuestionId)) {
      return map;
    }
    const newQ = customSubQuestionsMapper(q, questions, isNZ);
    map[q.QuestionId] = loanAppQsMapper(
      newQ,
      optionsMapper(options),
      isNZ,
      features,
    );
    map[q.QuestionId].index = filters.indexOf(q.QuestionId);
    return map;
  }, {});
}

export function contactGoalBuilderForMyCRM(data) {
  return {
    FutureFinancialGoalId: data.questionId,
    FutureFinancialGoalTypeId: data.goalTypeId,
    FamilyId: data.familyId,
    Details: data.answer,
  };
}

// TODO: refactor code to reduce cognitive complexity
// eslint-disable-next-line sonarjs/cognitive-complexity
export function teamAnswersBuilderForMilli(data) {
  const answers = data.reduce((map, d) => {
    let questionId = d.QuestionId;
    let value;
    switch (d.QuestionId) {
      case GENERAL_INSURANCE:
        value = d.answerInt.includes(1) ? '1' : '0';
        break;
      case LIFESTYLE_INSURANCE_ALI:
        if (d.answerBool.includes(true)) {
          questionId = LIFESTYLE_INSURANCE_ADVISER;
          value = '1';
        }
        break;
      case LIFESTYLE_INSURANCE_ADVISER:
        if (map[questionId] === '1') {
          value = '1';
        } else {
          value = d.answerBool.includes(true) ? '1' : '0';
        }
        break;
      case NEW_GENERAL_INSURANCE:
      case MOVING_SERVICES:
      case LIFESTYLE_INSURANCE:
      case LIFESTYLE_INSURANCE_OBLIGATION:
      case LIFE_AND_HEALTH:
      case GENERAL_INSURANCE_NZ:
      case UK_PENSION_TRANSFERS:
      case ASSET_FINANCE:
      case CURRENCY_EXCHANGE:
      case KIWI_SAVER:
      case HOME_NOW:
        value = d.answerBool.includes(true) ? '1' : '0';
        break;
      case LIFESTYLE_INSURANCE_RISK:
        value = d.answerBool.includes(true) ? '1' : undefined;
        break;
      default:
        if (d.answerBool && d.answerBool.length === 1) {
          value = d.answerBool[0];
        } else if (d.answerString && d.answerString.length === 1) {
          value = d.answerString[0];
        } else if (d.answerInt) {
          value = d.answerInt;
        }
        break;
    }
    map[questionId] = value;
    return map;
  }, {});
  if (answers[MOVING_SERVICES] === undefined) {
    answers[MOVING_SERVICES] = '0';
  }
  if (answers[HOME_NOW] === undefined) {
    answers[HOME_NOW] = '0';
  }
  return answers;
}

export function teamAnswerBuilderForMyCRM(data) {
  const answerValue = {};
  let displayOptionId =
    CUSTOM_DISPLAY_TYPE_IDS[data.questionId] || DISPLAY_OPTIONS.TEXT_ONLY;
  const questionId = Number(data.questionId);
  switch (questionId) {
    case GENERAL_INSURANCE:
      displayOptionId = DISPLAY_OPTIONS.SINGLE_DROPDOWN;
      answerValue.answerInt = data.value === '1' ? [1] : [2];
      break;
    case LIFESTYLE_INSURANCE_RISK:
      displayOptionId = DISPLAY_OPTIONS.RADIO_BOX;
      answerValue.answerBool = data.value === '1' ? [true] : [false];
      break;
    case NEW_GENERAL_INSURANCE:
    case LIFESTYLE_INSURANCE:
    case LIFE_AND_HEALTH:
    case GENERAL_INSURANCE_NZ:
    case UK_PENSION_TRANSFERS:
    case ASSET_FINANCE:
    case CURRENCY_EXCHANGE:
    case KIWI_SAVER:
      displayOptionId = DISPLAY_OPTIONS.YES_NO_ONLY;
      answerValue.answerBool = data.value === '1' ? [true] : [false];
      break;
    case HOME_NOW:
    case MOVING_SERVICES:
    case LIFESTYLE_INSURANCE_OBLIGATION:
    case LIFESTYLE_INSURANCE_ADVISER:
      answerValue.answerBool = data.value === '1' ? [true] : [false];
      break;
    default:
      answerValue.answerString = [data.value];
      displayOptionId = DISPLAY_OPTIONS.TEXT_ONLY;
      break;
  }

  return {
    LoanScenarioId: data.loanScenarioId,
    FamilyId: data.familyId,
    QuestionSectionId: 0,
    QuestionId: questionId,
    DisplayOptionId: displayOptionId,
    ...answerValue,
  };
}

export function answersBuilderForMilli(data) {
  return data.reduce((map, d) => {
    if (
      [
        ADEQUATE_INSURANCE_FAMILY_YES_NO,
        ADEQUATE_INSURANCE_HOME_YES_NO,
      ].includes(d.QuestionId)
    ) {
      map[d.QuestionId] = d.answerBool.includes(true) ? '1' : '0';
    } else if (d.answerBool && d.answerBool.length === 1) {
      map[d.QuestionId] = d.answerBool[0];
    } else if (d.answerString && d.answerString.length === 1) {
      map[d.QuestionId] = d.answerString[0];
    } else if (d.answerInt) {
      map[d.QuestionId] = d.answerInt;
    }
    return map;
  }, {});
}

export function answerBuilderForMyCRM(data) {
  const answerValue = {};
  let displayOptionId;
  switch (typeof data.value) {
    case 'boolean':
      displayOptionId = DISPLAY_OPTIONS.YES_NO_ONLY;
      answerValue.answerBool = [data.value];
      break;
    case 'string':
      if (
        [
          ADEQUATE_INSURANCE_FAMILY_YES_NO,
          ADEQUATE_INSURANCE_HOME_YES_NO,
        ].includes(parseInt(data.questionId))
      ) {
        displayOptionId = DISPLAY_OPTIONS.YES_NO_ONLY;
        answerValue.answerBool = data.value === '1' ? [true] : [false];
      } else {
        displayOptionId = DISPLAY_OPTIONS.TEXT_ONLY;
        answerValue.answerString = [data.value];
      }
      break;
    default:
      displayOptionId = DISPLAY_OPTIONS.MULTI_DROPDOWN;
      answerValue.answerInt = data.value;
      break;
  }
  return {
    LoanScenarioId: data.loanScenarioId,
    FamilyId: data.familyId,
    QuestionSectionId: 0,
    QuestionId: data.questionId,
    DisplayOptionId: displayOptionId,
    ...answerValue,
  };
}
