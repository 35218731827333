import { ADDRESS_TYPE_CURRENT } from 'shared/constants/myCRMTypes/address';
import { unformatDate } from 'shared/lib/utils';
import { historyBuilderForMilli } from './history';

export function addressBuilderForMilli(address) {
  const startDate = address.start_date ? unformatDate(address.start_date) : {};
  const typeId = address.address_type_id;
  const endDate =
    typeId !== ADDRESS_TYPE_CURRENT.id ? unformatDate(address.end_date) : {};
  const clientIds =
    address.client_ids && address.client_ids.length
      ? address.client_ids.sort()
      : address.entity_ids;

  const builtAddress = {
    formattedAddress: address.formatted_address,
  };

  if (address.completeDetails) {
    builtAddress.countryCode =
      address.completeDetails.countryCodeInTwoLetter ||
      address.completeDetails.CountryCode;
    builtAddress.state = address.completeDetails.stateShort_Name;
    builtAddress.streetAddress = address.completeDetails.street_address;
    builtAddress.streetNumber =
      address.completeDetails.street_number &&
      address.completeDetails.street_number.replace(/street/i, '').trim();
    builtAddress.streetName = address.completeDetails.StreetName;
    builtAddress.streetType = address.completeDetails.StreetType;
    builtAddress.suburb = address.completeDetails.locality;
    builtAddress.buildingName = address.completeDetails.BuildingName;
    builtAddress.level =
      address.completeDetails.level &&
      address.completeDetails.level.replace(/level/i, '').trim();
    builtAddress.premise = address.completeDetails.premise;
    builtAddress.subpremise =
      address.completeDetails.subpremise &&
      address.completeDetails.subpremise.replace(/unit/i, '').trim();
  }

  return {
    id: address.id,
    clientIds,
    address: builtAddress,
    typeId,
    startDate,
    endDate,
    ownershipTypeId: address.ownership_type_id,
    ...historyBuilderForMilli(address),
  };
}
