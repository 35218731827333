import { handleActions } from 'redux-actions';
import _ from 'lodash';

import * as assetActionTypes from 'actions/assetActionTypes';
import * as individualAssetActionTypes from 'actions/individualAssetActionTypes';

import { unformatCurrency } from 'shared/lib/numbrero';
import { assetTypeOptions } from 'constants/options';

import {
  setWorkingValue,
  clearWorking,
  removeEntity,
  loadEntityIntoWorking,
  setEntity,
  setNewEntity,
  setNewEntities,
  insertNewEntities,
  setWorkingClientIds,
  setError,
} from 'lib/reducerHelper';

const actionTypes = { ...assetActionTypes, ...individualAssetActionTypes };
const newAsset = {
  errors: {},
};

export const initialState = {
  errors: {},
  entities: [],
  working: {
    new: newAsset,
  },
};

const assetReducer = handleActions(
  {
    [actionTypes.CLEAR_WORKING_ASSET](state, action) {
      const resetAssets = {
        ...newAsset,
        clientIds: state.working.new.clientIds,
      };
      return clearWorking(state, action, resetAssets);
    },
    [actionTypes.LOAD_ASSET](state, action) {
      return loadEntityIntoWorking(state, action, newAsset);
    },
    [actionTypes.REMOVE_ASSET](state, action) {
      return removeEntity(state, action);
    },
    [actionTypes.SET_NEW_ASSET](state, action) {
      return setNewEntity(state, action);
    },
    [actionTypes.SET_NEW_ASSETS](state, action) {
      return setNewEntities(state, action);
    },
    [actionTypes.INSERT_NEW_ASSETS](state, action) {
      return insertNewEntities(state, action);
    },
    [actionTypes.SET_ASSET](state, action) {
      return setEntity(state, action);
    },
    [actionTypes.SET_ASSET_TYPE](state, action) {
      return setWorkingValue(state, action.payload, 'type', _.toNumber);
    },
    [actionTypes.SET_ASSET_CATEGORY](state, action) {
      const { id, value: category } = action.payload;
      let newState = setWorkingValue(
        state,
        action.payload,
        'category',
        _.toNumber,
      );

      const availableTypes = assetTypeOptions(category);
      if (availableTypes.length === 1) {
        newState = setWorkingValue(
          newState,
          { id, value: availableTypes[0].value },
          'type',
          _.toNumber,
        );
      }
      return newState;
    },
    [actionTypes.SET_ASSET_CLIENT_IDS](state, action) {
      return setWorkingClientIds(state, action);
    },
    [actionTypes.SET_ASSET_MAKE](state, action) {
      return setWorkingValue(state, action.payload, 'make');
    },
    [actionTypes.SET_ASSET_MODEL](state, action) {
      return setWorkingValue(state, action.payload, 'model');
    },
    [actionTypes.SET_ASSET_YEAR](state, action) {
      return setWorkingValue(state, action.payload, 'year');
    },
    [actionTypes.SET_ASSET_BANK](state, action) {
      return setWorkingValue(state, action.payload, 'bank');
    },
    [actionTypes.SET_ASSET_VALUE](state, action) {
      return setWorkingValue(state, action.payload, 'value', unformatCurrency);
    },
    [actionTypes.SET_ASSET_ADDRESS](state, action) {
      return setWorkingValue(state, action.payload, 'address');
    },
    [actionTypes.SET_ASSET_RENT](state, action) {
      return setWorkingValue(state, action.payload, 'rent');
    },
    [actionTypes.SET_ASSET_DESCRIPTION](state, action) {
      return setWorkingValue(state, action.payload, 'description');
    },
    [actionTypes.SET_ASSET_ERROR](state, action) {
      return setError(state, action);
    },
  },
  initialState,
);

export default assetReducer;
