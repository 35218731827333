/* eslint-disable sonarjs/no-duplicate-string */
import FlagSVG from 'assets/inbetweeners/flag.svg';
import ConnectionSVG from 'assets/inbetweeners/connection_2.svg';
import HouseSVG from 'assets/inbetweeners/house_2.svg';
import CourtSVG from 'assets/inbetweeners/court.svg';
import BuildingSVG from 'assets/inbetweeners/building.svg';
import CarSVG from 'assets/inbetweeners/car_1.svg';
import CreditCardSVG from 'assets/inbetweeners/credit_card.svg';
import CoinsSVG from 'assets/inbetweeners/coins_1.svg';
import AtmSVG from 'assets/inbetweeners/atm.svg';
import IdSVG from 'assets/inbetweeners/id_1.svg';
import UserAddSVG from 'assets/inbetweeners/user_add_1.svg';
import DollarBagSVG from 'assets/inbetweeners/dollar_bag.svg';
import IdFiveSVG from 'assets/inbetweeners/id_5.svg';
import FilesSVG from 'assets/inbetweeners/files_1.svg';
import Star5SVG from 'assets/inbetweeners/sl-star-5.svg';
import QuestionMarkSVG from 'assets/inbetweeners/question_mark.svg';
import MailBoxSVG from 'assets/inbetweeners/mail_box_3.svg';

import { PROFILE_SECTIONS } from 'constants/profileSections';

export const GOAL_SLUG = 'goal';
export const FAMILY_SLUG = 'family';
export const RESIDENCE_SLUG = 'residence';
export const TO_PURCHASE_SLUG = 'to-purchase';
export const PROPERTIES_SLUG = 'properties';
export const ASSETS_SLUG = 'assets';
export const LIABILITIES_SLUG = 'liabilities';
export const INCOMES_SLUG = 'incomes';
export const EXPENSES_SLUG = 'expenses';
export const APPLICANT_SLUG = 'applicant';
export const CO_APPLICANTS_SLUG = 'coapplicants';
export const ADDRESSES_SLUG = 'addresses';
export const EMPLOYMENTS_SLUG = 'employments';
export const FORESEEABLE_SLUG = 'foreseeable-changes';
export const CREDIT_SLUG = 'credit-history';
export const LOAN_AMOUNT_SLUG = 'loan-amount';
export const DOCUMENTS_SLUG = 'documents';
export const SELECTED_LOAN_SLUG = 'selected-loan';

export const FULL_HANDHOLD = [
  GOAL_SLUG,
  APPLICANT_SLUG,
  FAMILY_SLUG,
  CO_APPLICANTS_SLUG,
  RESIDENCE_SLUG,
  TO_PURCHASE_SLUG,
  PROPERTIES_SLUG,
  ASSETS_SLUG,
  LIABILITIES_SLUG,
  INCOMES_SLUG,
  EXPENSES_SLUG,
  ADDRESSES_SLUG,
  EMPLOYMENTS_SLUG,
  FORESEEABLE_SLUG,
  CREDIT_SLUG,
  LOAN_AMOUNT_SLUG,
  DOCUMENTS_SLUG,
];

export const FULL_HANDHOLD_NO_FORESEEABLE_SLUG = FULL_HANDHOLD.filter(
  (o) => o !== FORESEEABLE_SLUG,
);

export const FULL_HANDHOLD_FIRST_PART_LAST_POSITION =
  FULL_HANDHOLD.indexOf(EXPENSES_SLUG) + 1;
export const FULL_HANDHOLD_LAST_POSITION = FULL_HANDHOLD.length;
export const FULL_HANDHOLD_CUTS = [
  FULL_HANDHOLD_FIRST_PART_LAST_POSITION,
  FULL_HANDHOLD_LAST_POSITION,
];

export const TO_SLUG = {
  [PROFILE_SECTIONS.GOAL]: GOAL_SLUG,
  [PROFILE_SECTIONS.FAMILY]: FAMILY_SLUG,
  [PROFILE_SECTIONS.RESIDENCE]: RESIDENCE_SLUG,
  [PROFILE_SECTIONS.TO_PURCHASE]: TO_PURCHASE_SLUG,
  [PROFILE_SECTIONS.PROPERTIES]: PROPERTIES_SLUG,
  [PROFILE_SECTIONS.ASSETS]: ASSETS_SLUG,
  [PROFILE_SECTIONS.LIABILITIES]: LIABILITIES_SLUG,
  [PROFILE_SECTIONS.INCOMES]: INCOMES_SLUG,
  [PROFILE_SECTIONS.EXPENSES]: EXPENSES_SLUG,
  [PROFILE_SECTIONS.APPLICANT]: APPLICANT_SLUG,
  [PROFILE_SECTIONS.COAPPLICANTS]: CO_APPLICANTS_SLUG,
  [PROFILE_SECTIONS.ADDRESSES]: ADDRESSES_SLUG,
  [PROFILE_SECTIONS.EMPLOYMENTS]: EMPLOYMENTS_SLUG,
  [PROFILE_SECTIONS.FORESEEABLE_CHANGES]: FORESEEABLE_SLUG,
  [PROFILE_SECTIONS.CREDIT_HISTORY]: CREDIT_SLUG,
  [PROFILE_SECTIONS.DOCUMENTS]: DOCUMENTS_SLUG,
};

export const FINAL_SEQUENCE = ['/handhold-complete'];

export const PARTIAL_HANDHOLD_INBETWEENER = {
  [GOAL_SLUG]: {
    image: FlagSVG,
    heading: 'Please complete all questions within your Goals',
  },
  [FAMILY_SLUG]: {
    image: ConnectionSVG,
    heading: 'Please complete all questions within your Household',
  },
  [RESIDENCE_SLUG]: {
    image: HouseSVG,
    heading: 'Please complete all questions within your Home',
  },
  [TO_PURCHASE_SLUG]: {
    image: CourtSVG,
    heading: 'Please complete all questions within Purchase Details',
  },
  [PROPERTIES_SLUG]: {
    image: BuildingSVG,
    heading: 'Please complete all questions about other properties you own',
  },
  [ASSETS_SLUG]: {
    image: CarSVG,
    heading: 'Please complete all questions within Assets',
  },
  [LIABILITIES_SLUG]: {
    image: CreditCardSVG,
    heading: 'Please complete all questions within Liabilities',
  },
  [INCOMES_SLUG]: {
    image: CoinsSVG,
    heading: 'Please complete all questions within Incomes',
  },
  [EXPENSES_SLUG]: {
    image: AtmSVG,
    heading: 'Please complete all questions within Expenses',
  },
  [APPLICANT_SLUG]: {
    image: IdSVG,
    heading: 'Please complete all questions within Your Details',
  },
  [CO_APPLICANTS_SLUG]: {
    image: UserAddSVG,
    heading: 'Please complete all questions within Co-applicants',
  },
  [ADDRESSES_SLUG]: {
    image: MailBoxSVG,
    heading: 'Please complete all questions within Address History',
  },
  [EMPLOYMENTS_SLUG]: {
    image: IdFiveSVG,
    heading: 'Please complete all questions within Employment History',
  },
  [FORESEEABLE_SLUG]: {
    image: QuestionMarkSVG,
    heading: 'Please complete all questions within Foreseeable Changes',
  },
  [CREDIT_SLUG]: {
    image: Star5SVG,
    heading: 'Please complete all questions within Credit History',
  },
  [LOAN_AMOUNT_SLUG]: {
    image: DollarBagSVG,
    heading: 'Please review your Loan Amount',
  },
  [DOCUMENTS_SLUG]: {
    image: FilesSVG,
    heading: 'Please upload supporting Documents',
  },
};

export const fullHandholdInbetweener = (isFork, isCustomerCare) => ({
  [GOAL_SLUG]: {
    image: FlagSVG,
    heading: 'Ok - let’s talk about your goals.',
    primaryButtonText: 'Your Goals',
    secondaryButtonText: 'I’ll do it later',
  },
  [FAMILY_SLUG]: {
    exclamationPath: '/great',
    image: ConnectionSVG,
    heading: 'Do you live with a partner or dependent children?',
    primaryButtonText: 'Add family members',
    secondaryButtonText: 'I live on my own',
    secondaryActionMetadata: {
      hasHousehold: false,
    },
  },
  [RESIDENCE_SLUG]: {
    exclamationPath: '/got-it',
    image: HouseSVG,
    heading: 'Please tell us about where you live today.',
    primaryButtonText: 'Your Home',
    secondaryButtonText: 'I’ll do it later',
  },
  [TO_PURCHASE_SLUG]: {
    exclamationPath: '/ok',
    image: CourtSVG,
    heading: 'Let’s make sure your purchase details are correct.',
    primaryButtonText: 'Review Purchase Details',
    secondaryButtonText: 'I’m just refinancing',
    secondaryActionMetadata: {
      lookingToBuyProperty: false,
    },
  },
  [PROPERTIES_SLUG]: {
    exclamationPath: '/alright',
    image: BuildingSVG,
    heading: 'Let’s chat about any other properties you own.',
    primaryButtonText: 'Other Properties',
    secondaryButtonText: 'I don’t own any other properties',
    secondaryActionMetadata: {
      ownOtherProperties: false,
    },
  },
  [ASSETS_SLUG]: {
    // forkPath: featureOn(BANK_CONNECT) && locale.isAU ? ['/got-it', '/few-questions-to-go', '/well-ask-about', '/how-bank-connect-works'] : undefined,
    exclamationPath: '/ok',
    image: CarSVG,
    heading: 'Let’s look at your assets.',
    primaryButtonText: 'Assets',
    secondaryButtonText: 'I don’t own any assets',
    secondaryActionMetadata: {
      hasAssets: false,
    },
  },
  [LIABILITIES_SLUG]: {
    exclamationPath: '/alright',
    image: CreditCardSVG,
    heading: 'Now let’s check your liabilities (eg. credit cards, car loans).',
    primaryButtonText: 'Liabilities',
    secondaryButtonText: 'I don’t have any liabilities',
    secondaryActionMetadata: {
      hasLiabilities: false,
    },
  },
  [INCOMES_SLUG]: {
    exclamationPath: '/ok',
    image: CoinsSVG,
    heading: 'Next, we’ll look at your income.',
    primaryButtonText: 'Income',
    secondaryButtonText: 'I’ll do this later',
  },
  [EXPENSES_SLUG]: {
    exclamationPath: '/almost-there',
    image: AtmSVG,
    heading: 'Finally, let’s check your expenses.',
    primaryButtonText: 'Expenses Calculator',
    secondaryButtonText: 'I’ll do this later',
  },
  [APPLICANT_SLUG]: {
    exclamationPath: '/ok-emphasis',
    image: IdSVG,
    heading: 'Now let’s check your contact details are correct.',
    primaryButtonText: 'Your Details',
    secondaryButtonText: 'I’ll do this later',
  },
  [CO_APPLICANTS_SLUG]: {
    exclamationPath: '/nice',
    image: UserAddSVG,
    heading: 'Let us know if you’ll be applying with anyone else.',
    primaryButtonText: 'Co-Applicants',
    secondaryButtonText: 'I’m not applying with anyone else',
    secondaryActionMetadata: {
      hasCoapplicants: false,
    },
  },
  [ADDRESSES_SLUG]: {
    exclamationPath: isFork
      ? ['/nice-work', 'ask-for-review']
      : [
          '/nice-work',
          (isCustomerCare && '/we-have-experts') || '/introduce-broker',
          'ask-for-review',
        ],
    image: MailBoxSVG,
    heading: 'Next, tell us about where you’ve lived for the past 3 years.',
    primaryButtonText: 'Address History',
    secondaryButtonText: 'I’ll do this later',
  },
  [EMPLOYMENTS_SLUG]: {
    exclamationPath: '/got-it',
    image: IdFiveSVG,
    heading: 'Now let’s take a look at your employment history.',
    primaryButtonText: 'Employment',
    secondaryButtonText: 'I’ll do this later',
  },
  [FORESEEABLE_SLUG]: {
    exclamationPath: '/nice',
    image: QuestionMarkSVG,
    heading: 'Let’s see if there are any changes on the horizon.',
    primaryButtonText: 'Foreseeable Changes',
    secondaryButtonText: 'I’ll do this later',
  },
  [CREDIT_SLUG]: {
    exclamationPath: '/alright',
    image: Star5SVG,
    heading: 'Let’s take a quick look at your credit history.',
    primaryButtonText: 'Credit history',
    secondaryButtonText: 'I’ll do this later',
  },
  [LOAN_AMOUNT_SLUG]: {
    exclamationPath: '/ok',
    image: DollarBagSVG,
    heading: 'Take a second to make sure your loan amount looks right.',
    primaryButtonText: 'Loan Amount',
    secondaryButtonText: 'I’ll do this later',
  },
  [DOCUMENTS_SLUG]: {
    exclamationPath: '/great-emphasis',
    image: FilesSVG,
    heading: 'Next, upload your supporting documents here.',
    primaryButtonText: 'Documents',
    secondaryButtonText: 'I’ll do this later',
  },
});
