import * as actionTypes from 'actions/UIActionTypes';
import update from 'immutability-helper';
import { handleActions } from 'redux-actions';
import {
  FULL_HANDHOLD,
  FULL_HANDHOLD_NO_FORESEEABLE_SLUG,
} from 'constants/applyData';
import * as SpinnerNames from 'constants/spinnerNames';

export const initialState = {
  nextPath: [],
  animationSequence: [],
  nextAnimationSequence: [],
  saveOrContinue: undefined,
  requests: [],
  applicationTested: false,
  applicationIsRead: false,
  isOneTimePrivacyPolicy: false,
  handholdSections: [],
  spinners: [],
  formPopUpStatus: {
    processing: false,
    processed: false,
  },
};

const UIReducer = handleActions(
  {
    [actionTypes.SET_APPLICATION_TESTED](state, action) {
      return { ...state, applicationTested: action.payload };
    },
    [actionTypes.SET_APPLICATION_IS_READ](state) {
      return { ...state, applicationIsRead: true };
    },
    [actionTypes.RESET_APPLICATION_IS_READ](state) {
      return { ...state, applicationIsRead: false };
    },
    [actionTypes.START_IS_ONE_TIME_PRIVACY_POLICY](state) {
      return { ...state, isOneTimePrivacyPolicy: true };
    },
    [actionTypes.SET_ANIMATION_SEQUENCE](state, action) {
      return { ...state, animationSequence: action.payload };
    },
    [actionTypes.UNSHIFT_ANIMATION_SEQUENCE](state, action) {
      return {
        ...state,
        animationSequence: [...action.payload, ...state.animationSequence],
      };
    },
    [actionTypes.SET_NEXT_ANIMATION_SEQUENCE](state, action) {
      return { ...state, nextAnimationSequence: action.payload };
    },
    [actionTypes.SET_PAGE_ERROR](state, action) {
      const { status = 500 } = action.payload;
      return { ...state, pageError: { status } };
    },
    [actionTypes.SET_PAGE_GHOST_ERROR](state, action) {
      const { status = 500 } = action.payload;
      return { ...state, pageError: { status } };
    },
    [actionTypes.SET_HANDHOLD_SECTIONS](state, action) {
      const { handholdSections, nextHandholdSequence } = action.payload;
      return { ...state, handholdSections, nextHandholdSequence };
    },
    [actionTypes.SET_FULL_HANDHOLD_SECTIONS](state) {
      return {
        ...state,
        handholdSections: FULL_HANDHOLD,
        isFullHandhold: true,
      };
    },
    [actionTypes.SET_FULL_HANDHOLD_NO_FORESEEABLE_SLUG_SECTIONS](state) {
      return {
        ...state,
        handholdSections: FULL_HANDHOLD_NO_FORESEEABLE_SLUG,
        isFullHandhold: true,
      };
    },
    [actionTypes.CLEAR_HANDHOLD_SECTIONS](state) {
      return {
        ...state,
        handholdSections: [],
        isFullHandhold: undefined,
        nextHandholdSequence: null,
      };
    },
    [actionTypes.CLEAR_PAGE_ERROR](state) {
      return { ...state, pageError: undefined };
    },
    [actionTypes.REMOVE_SPINNERS](state) {
      return { ...state, spinners: [] };
    },
    [actionTypes.POP_SPINNER](state, action) {
      const spinnerName = action.payload || SpinnerNames.DEFAULT_SPINNER;
      const spinners = state.spinners.filter((o) => o !== spinnerName);
      return { ...state, spinners };
    },
    [actionTypes.PUSH_SPINNER](state, action) {
      const spinnerName = action.payload || SpinnerNames.DEFAULT_SPINNER;
      const spinners = state.spinners.includes(spinnerName)
        ? state.spinners
        : [...state.spinners, spinnerName];
      return { ...state, spinners };
    },
    [actionTypes.START_FULL_PAGE_SPINNER](state) {
      return { ...state, fullPageSpinnerLoading: true };
    },
    [actionTypes.STOP_FULL_PAGE_SPINNER](state) {
      return { ...state, fullPageSpinnerLoading: false };
    },
    [actionTypes.SET_NEXT_PATH](state, action) {
      return update(state, {
        nextPath: action.payload
          ? { $push: [action.payload] }
          : { $splice: [[state.nextPath.length - 1, 1]] },
      });
    },
    [actionTypes.SET_SAVE_OR_CONTINUE](state, action) {
      return { ...state, saveOrContinue: action.payload };
    },
    [actionTypes.START_ASYNC_REQUEST](state, action) {
      const { id, type } = action.payload;
      const index = state.requests.findIndex(
        (e) => e.id === id && e.type === type,
      );
      if (index !== -1) {
        return state;
      }
      const request = {
        id,
        type,
        error: undefined,
      };
      return update(state, {
        requests: {
          $push: [request],
        },
      });
    },
    [actionTypes.END_ASYNC_REQUEST](state, action) {
      const { id, type } = action.payload;
      const index = state.requests.findIndex(
        (e) => e.id === id && e.type === type,
      );

      if (index === -1) {
        return state;
      }

      return update(state, {
        requests: {
          $splice: [[index, 1]],
        },
      });
    },
    [actionTypes.PROCESSING_FORM_POPUP_STATUS](state) {
      return update(state, {
        formPopUpStatus: {
          $set: {
            processing: true,
            processed: false,
          },
        },
      });
    },
    [actionTypes.PROCESSED_FORM_POPUP_STATUS](state) {
      return update(state, {
        formPopUpStatus: {
          $set: {
            processing: false,
            processed: true,
          },
        },
      });
    },
    [actionTypes.RESET_FORM_POPUP_STATUS](state) {
      return update(state, {
        formPopUpStatus: {
          $set: {
            processing: false,
            processed: false,
          },
        },
      });
    },
    [actionTypes.SET_ASYNC_REQUEST_ERROR](state, action) {
      const { id, type, error } = action.payload;
      const index = state.requests.findIndex(
        (e) => e.id === id && e.type === type,
      );
      if (index === -1) {
        return state;
      }
      return update(state, {
        requests: {
          [index]: {
            error: { $set: error },
          },
        },
      });
    },
    [actionTypes.CLEAR_ASYNC_REQUEST_ERRORS](state) {
      return update(state, {
        requests: { $set: state.requests.filter((r) => !r.error) },
      });
    },
    [actionTypes.SET_HIDE_COMPARE_SELECT_BTN](state) {
      return update(state, {
        hideSelectButton: { $set: true },
      });
    },
    [actionTypes.SET_PARTIAL_PROFILE_SECTIONS](state, action) {
      const { payload: sections } = action;
      return update(state, {
        partialProfileSections: { $set: sections },
      });
    },
  },
  initialState,
);

export default UIReducer;
