import moment from 'moment';
import { isEmptyObject } from 'shared/lib/utils';

export function addressBuilderForMyCRM(address) {
  const params = {
    id: address.id,
    formatted_address: address.address.formattedAddress,
    address_type_id: address.typeId,
    start_date: moment.utc(address.startDate).format(),
    end_date: isEmptyObject(address.endDate)
      ? null
      : moment.utc(address.endDate).format(),
    ownership_type_id: address.ownershipTypeId,
    AddressId: address.addressId,
  };
  if (address.forCompany) {
    params.entity_ids = address.clientIds;
  } else {
    params.client_ids = address.clientIds;
  }

  return params;
}
