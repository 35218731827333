/* eslint-disable unicorn/explicit-length-check */
import _ from 'lodash';

import { VARIABLE_RATE, FIXED_RATE } from 'shared/constants/interestType';
import { REPAYMENT_TYPE_INTEREST_ONLY_VALUE } from 'shared/constants/repaymentTypes';
import {
  ORDER_INTEREST_LOWEST,
  ORDER_COMPARISON_LOWEST,
} from 'shared/constants/productOrderOptions';
import { WEEKLY, FORTNIGHTLY } from 'shared/constants/options';
import { COUNTRY_AUSTRALIA } from 'shared/constants/myCRMTypes/countries';

import { notifyOrLog } from 'shared/lib/errorHandler';
import { convertKeysToCamelCase } from 'shared/lib/utils';

export const MYCRM_PRODUCT_LIST_API_PATH = '/lm-products/au';
export const commonBatchRequestConfig = {
  resource: MYCRM_PRODUCT_LIST_API_PATH,
  method: 'GET',
  data: {},
};

const FORTNIGHTLY_FREQUENCY = 'Fortnightly';
const WEEKLY_FREQUENCY = 'Weekly';
const MONTHLY_FREQUENCY = 'Monthly';

const DEFAULT_FREQUENCY_VALUES = ['Monthly', 'Weekly', 'Fortnightly'];

const PRODUCT_LIST_SIZE = 25;

const SUPPORTED_LENDERS = async (lenderList) => {
  return lenderList.filter((l) => l.countryCode === COUNTRY_AUSTRALIA.id);
};

const GET_LENDERS_IDS = async (lendersList) => {
  const ALL_LENDER_IDS = lendersList.map((bank) => bank.lenderId); // Since product list and max borrow is currently implemented only for AU, keep this AU lender list for now
  const ALL_LENDER_IDS_STRING = ALL_LENDER_IDS.join();
  const LENDER_IDS_FOR_LOWEST_RATE = lendersList
    .filter((bank) => !bank.excludeFromLowestRate)
    .map((bank) => bank.lenderId)
    .join();
  return {
    ALL_LENDER_IDS,
    ALL_LENDER_IDS_STRING,
    LENDER_IDS_FOR_LOWEST_RATE,
  };
};

const RATE_TYPES = {
  fixed: 'fixed',
  standardVariable: 'standard_variable',
  basicVariable: 'basic_variable',
};

export const FEATURED_LOWEST_INTEREST_RATE = 'featured_lowest_interest_rate';
export const FEATURED_BIG_FOUR = 'featured_big_four';
export const FEATURED_LOWEST_INTEREST_RATE_ALL_LENDERS =
  'featured_lowest_interest_rate_all_lenders';
export const FEATURED_LOWEST_REPAYMENT = 'featured_lowest_repayment';
export const FEATURED_EXCLUSIVE_MEMBER_OFFER =
  'featured_exclusive_member_offer';
export const FEATURED_BANK_OF_THE_YEAR = 'featured_bank_of_the_year';
export const PRODUCT_LIST = 'product_list';

export const BATCH_REQUEST = {
  [FEATURED_LOWEST_INTEREST_RATE]: 'lowestInterestRateProduct',
  [FEATURED_BIG_FOUR]: 'bigFourProduct',
  [FEATURED_LOWEST_INTEREST_RATE_ALL_LENDERS]:
    'lowestInterestRateProductAllLenders',
  [FEATURED_LOWEST_REPAYMENT]: 'lowestRepaymentProduct',
  [FEATURED_EXCLUSIVE_MEMBER_OFFER]: 'exclusiveMemberOfferProduct',
  [FEATURED_BANK_OF_THE_YEAR]: 'bankOfTheYearProduct',
  [PRODUCT_LIST]: 'productList',
};

export const OMIT_PROPERTIES = ['notes', 'repaymentOngoing'];

const setValueToNullWhenAll = (value, formatedValue = null) =>
  value && value !== 'all' ? formatedValue || value : null;

const getRepaymentByFrequency = (repayment, frequencies, group = 'initial') => {
  const result = {};
  frequencies.forEach((frequency) => {
    if (!repayment[frequency]) {
      return;
    }
    result[frequency] = _.omit(repayment[frequency][group], ['type']);
  });
  return result;
};

const getFrequencyValue = (frequency) => {
  switch (frequency) {
    case FORTNIGHTLY:
      return FORTNIGHTLY_FREQUENCY;
    case WEEKLY:
      return WEEKLY_FREQUENCY;
    default:
      return MONTHLY_FREQUENCY;
  }
};

const groupByKey = (object, key, useKey = null) =>
  _.chain(object)
    .keyBy(key)
    .mapValues((v) => (useKey ? v[useKey] : _.omit(v, key)))
    .value();

const mapProductProperties = (options) => (product) => {
  const { pick, omit } = options || {};
  const { repayments, rates, fees, notes } = product;
  const extraKeysProduct = {
    ...product,
    rates: groupByKey(rates, 'name'),
    repayments: groupByKey(repayments, 'period'),
    fees: groupByKey(fees, 'name', 'amount'),
    notes: groupByKey(notes, 'type', 'note'),
    repaymentInitial: getRepaymentByFrequency(
      groupByKey(repayments, 'period'),
      DEFAULT_FREQUENCY_VALUES,
    ),
    repaymentOngoing: getRepaymentByFrequency(
      groupByKey(repayments, 'period'),
      DEFAULT_FREQUENCY_VALUES,
      'ongoing',
    ),
  };
  let omittedProduct = _(convertKeysToCamelCase(extraKeysProduct));
  if (omit) {
    omittedProduct = omittedProduct.omit(omit);
  }
  if (pick) {
    omittedProduct = omittedProduct.pick(pick);
  }
  return omittedProduct.value();
};

const splitLenderIds = (lenderIds) => lenderIds.split(',').map(_.toNumber);

export const createGroupingProductsBatchRequest = (
  params,
  batchRequests = [],
) => {
  const lenderIds = splitLenderIds(params.lender_ids);
  lenderIds.forEach((lenderId) => {
    batchRequests.push({
      ...commonBatchRequestConfig,
      request_id: `${PRODUCT_LIST}_for_lender_${lenderId}`,
      parameters: {
        ...params,
        lender_ids: lenderId,
        loan_amount: params.loan_amount,
        loan_term: params.loan_term,
        country: 'au',
        page_index: 1,
        page_size: 1,
      },
    });
  });
  return batchRequests;
};

export const sortingFilterProductsResult = (query, result) => {
  const productListKey = BATCH_REQUEST[PRODUCT_LIST];
  switch (query.order_by) {
    case ORDER_COMPARISON_LOWEST:
      result[productListKey] = _.orderBy(
        result[productListKey],
        (p) => p.rates.comparison.rate,
      );
      break;
    case ORDER_INTEREST_LOWEST:
      result[productListKey] = _.orderBy(
        result[productListKey],
        (p) => p.rates.initial.rate,
      );
      break;
    default:
      result[productListKey] = _.orderBy(result[productListKey], (product) =>
        query.repayment_type === REPAYMENT_TYPE_INTEREST_ONLY_VALUE
          ? product.repayments[_.camelCase(query.frequency)].initial
              .interestOnlyAmount
          : product.repayments[_.camelCase(query.frequency)].initial
              .principalAndInterestAmount,
      );
      break;
  }
  return result;
};

export const mapProductListBatchResponses = (responses) => {
  const result = {};
  const productListKey = BATCH_REQUEST[PRODUCT_LIST];
  responses.forEach((res) => {
    if (res.status !== 200) {
      notifyOrLog({ status: res.status, message: res.data });
      return;
    }
    const productList = res.data;
    if (
      BATCH_REQUEST[res.request_id] &&
      (!productList || productList.length === 0)
    ) {
      result[BATCH_REQUEST[res.request_id]] = null;
    } else {
      const mappedProductList = productList.map(
        mapProductProperties({ omit: OMIT_PROPERTIES }),
      );
      if (res.request_id === PRODUCT_LIST) {
        result[productListKey] = mappedProductList;
      } else if (res.request_id.startsWith(PRODUCT_LIST)) {
        if (!result[productListKey]) {
          result[productListKey] = [];
        }
        if (mappedProductList[0]) {
          result[productListKey].push(mappedProductList[0]);
        }
      }
    }
  });
  return result;
};

export const mapProductListResponse = (productList) => {
  const result = {};
  result[BATCH_REQUEST[PRODUCT_LIST]] = productList.map(
    mapProductProperties({ omit: OMIT_PROPERTIES }),
  );
  return result;
};

export const mapLenderFeaturedProductsResponses = (responses) => {
  const result = {};
  responses.forEach((res) => {
    if (res.status !== 200) {
      notifyOrLog({ status: res.status, message: res.data });
      return;
    }
    const productList = res.data;
    if (!productList || productList.length === 0) {
      result[res.request_id] = null;
    } else {
      const pick = ['id', 'name', 'lender', 'rates', 'repayments'];
      const mappedProduct = productList.map(mapProductProperties({ pick }));
      result[res.request_id] = mappedProduct[0];
    }
  });
  return result;
};

export const mapInterestProductsResponses = (responses) => {
  const result = [];
  responses.forEach((res) => {
    if (res.status !== 200) {
      console.log(res);
      notifyOrLog({ status: res.status, message: res.data });
      return;
    }
    const productList = res.data;
    if (productList && productList.length > 0) {
      const pick = ['id', 'name', 'lender', 'rates', 'repayments'];
      const mappedProduct = _.first(
        productList.map(mapProductProperties({ pick })),
      );
      mappedProduct.type = res.request_id;
      result.push(mappedProduct);
    }
  });
  return result;
};

export const mapProductDetailResponse = (product) =>
  mapProductProperties()(product);

export const mapLowestRateProductResponse = (product) => {
  const { lender, rates, fees } = product;
  const groupFees = _.keyBy(fees, 'name');
  const groupRates = _.keyBy(rates, 'name');
  return {
    lenderName: lender.name,
    totalUpfrontFee: groupFees.total_upfront.amount,
    ongoingAnnualFee: groupFees.annual.amount,
    interestRate: groupRates.initial.rate,
    comparisonRate: groupRates.comparison.rate,
  };
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const createFilterCriterias = async (params, lendersList) => {
  const {
    features,
    loanAmount,
    loanTerm,
    productType,
    lvr,
    financials,
    repaymentType,
    lenders,
    interestType,
    initialPeriod,
    interestOnlyTerm,
    orderField,
    repaymentFrequency,
    isGrouping,
    page,
    size,
  } = params;
  const interestTypeArray = (interestType && interestType.split(',')) || null;
  const LENDER_IDS = await GET_LENDERS_IDS(lendersList);
  const query = {
    loan_amount: loanAmount,
    loan_term: loanTerm,

    lender_ids: lenders || LENDER_IDS.ALL_LENDER_IDS_STRING,

    documentation: setValueToNullWhenAll(financials),
    product_type: productType,
    lvr_maximum: lvr,
    repayment_type: repaymentType,

    interest_term_period:
      interestOnlyTerm === REPAYMENT_TYPE_INTEREST_ONLY_VALUE
        ? setValueToNullWhenAll(interestOnlyTerm, `${interestOnlyTerm}-`)
        : null,

    initial_period:
      interestTypeArray &&
      interestTypeArray.length &&
      interestTypeArray.includes(FIXED_RATE)
        ? setValueToNullWhenAll(
            initialPeriod,
            `${12 * initialPeriod}${initialPeriod === '6' ? '-' : ''}`,
          )
        : null,

    features: features || null,

    order_by: orderField,
    page_index: page || 1,
    page_size: size || PRODUCT_LIST_SIZE,
    frequency: getFrequencyValue(repaymentFrequency),
    is_grouping: isGrouping === true || isGrouping === 'true',
  };

  // Interest Type criterias
  if (!interestTypeArray || !interestTypeArray.length) {
    query.interest_type = _.values(RATE_TYPES).join();
  } else if (interestTypeArray && interestTypeArray.length > 0) {
    const rateTypeValues = [];

    interestTypeArray.forEach((key) => {
      if (VARIABLE_RATE === key) {
        // Variable Rate
        if (!rateTypeValues.includes(RATE_TYPES.standardVariable)) {
          rateTypeValues.push(RATE_TYPES.standardVariable);
        }
        if (!rateTypeValues.includes(RATE_TYPES.basicVariable)) {
          rateTypeValues.push(RATE_TYPES.basicVariable);
        }
        // Fixed Rate
      } else if (!rateTypeValues.includes(RATE_TYPES.fixed)) {
        rateTypeValues.push(RATE_TYPES.fixed);
      }
    });
    if (rateTypeValues.length > 0) {
      query.interest_type = rateTypeValues.join();
    }
  }
  return _.omitBy(query, _.isNil);
};

export const createLowestRateProductRequest = async (params, lenderList) => {
  const LENDER_IDS = await GET_LENDERS_IDS(lenderList);
  return {
    ...params,
    country: 'au',
    page_index: 1,
    page_size: 1,
    lender_ids: LENDER_IDS.LENDER_IDS_FOR_LOWEST_RATE,
    order_by: ORDER_INTEREST_LOWEST,
  };
};

export const createLenderFeaturedProductsRequest = ({
  lender,
  loanAmount,
  productType,
  lvr,
}) => {
  const params = {
    lender_ids: lender,
    loan_amount: loanAmount,
    product_type: productType,
    lvr_maximum: lvr,
    country: 'au',
    page_index: 1,
    page_size: 1,
  };
  const variable = {
    request_id: 'variable',
    ...commonBatchRequestConfig,
    parameters: {
      interest_type: `${RATE_TYPES.basicVariable},${RATE_TYPES.standardVariable}`,
      ...params,
    },
  };

  return [variable].concat(
    [1, 2, 3, 5].map((n) => ({
      request_id: `fixed_${n}_year`,
      ...commonBatchRequestConfig,
      parameters: {
        interest_type: RATE_TYPES.fixed,
        initial_period: n * 12,
        ...params,
      },
    })),
  );
};

export const createInterestProductsRequest = async (
  { loanAmount, loanTerm, productType, lvr, aggregatorId },
  lendersList,
) => {
  const params = {
    loan_amount: loanAmount,
    loan_term: loanTerm,
    lvr_maximum: lvr,
    product_type: productType,
    country: 'au',
    page_index: 1,
    page_size: 1,
    order_by: ORDER_INTEREST_LOWEST,
  };
  const lenders = await SUPPORTED_LENDERS(lendersList);
  const lenderList =
    aggregatorId && aggregatorId !== '0'
      ? lenders.filter((l) => l.aggregatorIds.includes(Number(aggregatorId)))
      : lenders;

  return lenderList
    .map((l) => l.lenderId)
    .reduce((batchRequests, lenderId) => {
      const variable = {
        request_id: 'variable',
        ...commonBatchRequestConfig,
        parameters: {
          interest_type: `${RATE_TYPES.basicVariable},${RATE_TYPES.standardVariable}`,
          lender_ids: lenderId,
          ...params,
        },
      };

      return batchRequests.concat(
        new Array(5).fill().reduce(
          (requests, v, k) =>
            k === 3
              ? requests // exclude fixed 4 years
              : [
                  ...requests,
                  {
                    request_id: `fixed_${k + 1}_year`,
                    ...commonBatchRequestConfig,
                    parameters: {
                      lender_ids: lenderId,
                      interest_type: RATE_TYPES.fixed,
                      initial_period: (k + 1) * 12,
                      ...params,
                    },
                  },
                ],
          [variable],
        ),
      );
    }, []);
};
