import _mapKeys from 'lodash/mapKeys';
import _upperFirst from 'lodash/upperFirst';
import _camelCase from 'lodash/camelCase';
import _transform from 'lodash/transform';
import _isArray from 'lodash/isArray';
import _isObject from 'lodash/isObject';

function camelizeKeys(data) {
  return _transform(data, (acc, value, key, target) => {
    const camelKey = _isArray(target) ? key : _camelCase(key);
    acc[camelKey] = _isObject(value) ? camelizeKeys(value) : value;
  });
}

export function dataCollectionStatusAndEventsBuilderForMilli(data) {
  return camelizeKeys(data);
}

export function identityVerificationsBuilderForMyCRM(data) {
  return _mapKeys(data, (v, k) => _upperFirst(k));
}
