import { DIRECT_PRODUCTS_API_PATH } from 'shared/constants/apiPaths';
import locale from 'config/locale';

import {
  axiosWithAuth as directAxiosWithAuth,
  batchRequest,
  dataTransform as directDataTransform,
  returnJSON as directReturnJSON,
} from './mycrmBaseApi';
import {
  createFilterCriterias,
  createInterestProductsRequest,
  createLenderFeaturedProductsRequest,
  mapInterestProductsResponses,
  mapLenderFeaturedProductsResponses,
  mapProductDetailResponse,
  mapProductListResponse,
  createGroupingProductsBatchRequest,
  mapProductListBatchResponses,
  sortingFilterProductsResult,
} from '../../shared/lib/productHelper';
import { getLendersListApi } from './lendersApi';

import {
  attachSavingsValue,
  createInterestValuesRequest,
  mapEstimateSavingValues,
} from '../../shared/lib/financialHelper';

export const createParamsFromRequest = (query) => {
  return getLendersListApi()
    .then((data) => createFilterCriterias(query, data))
    .catch((error) => {
      return Promise.reject(
        new Error(
          `Error creating filter criteria to filter product: ${error.message}`,
        ),
      );
    });
};

// port of serverless handler: products/products.get
export const getDetail = (productId, loanAmount, loanTerm, repaymentType) => {
  if (!productId) {
    return Promise.reject(
      new Error('productId is required to get product details.'),
    );
  }

  return directAxiosWithAuth
    .get(`${DIRECT_PRODUCTS_API_PATH}/${locale.countryCode}/${productId}`, {
      params: {
        loan_amount: loanAmount,
        loan_term: loanTerm,
        repayment_type: repaymentType,
      },
    })
    .then(directDataTransform(mapProductDetailResponse))
    .then(directReturnJSON);
};

// port of serverless handler: products/products.find
export const find = (params) => {
  if (!params) {
    return Promise.reject(new Error('Query parameters are missing.'));
  }

  return createParamsFromRequest(params).then((query) => {
    if (!query) {
      return Promise.reject(new Error('Query parameters are missing.'));
    }
    if (query.is_grouping) {
      const batchRequests = createGroupingProductsBatchRequest(query);
      return batchRequest(batchRequests)
        .then(mapProductListBatchResponses)
        .then((products) => sortingFilterProductsResult(query, products))
        .then(directReturnJSON);
    }

    return directAxiosWithAuth
      .get(`${DIRECT_PRODUCTS_API_PATH}/${locale.countryCode}`, {
        params: query,
      })
      .then(directDataTransform(mapProductListResponse))
      .then(directReturnJSON);
  });
};

// port of serverless handler: products/products.interestProducts
export const getInterest = (params) => {
  if (
    !(
      _.has(params, 'loanAmount') &&
      _.has(params, 'loanTerm') &&
      _.has(params, 'lvr') &&
      _.has(params, 'rate')
    )
  ) {
    return Promise.reject(
      new Error(
        'loanAmount, loanTerm, lvr and rate are required to get interest products.',
      ),
    );
  }

  const { loanAmount, loanTerm } = params;
  return getLendersListApi().then((data) =>
    createInterestProductsRequest(params, data).then(
      (interestProductRequest) => {
        return Promise.all([
          batchRequest(createInterestValuesRequest(params)),
          batchRequest(interestProductRequest),
        ]).then(([values, interestProducts]) => {
          const estimateSavingResult = mapEstimateSavingValues(values);
          const products = mapInterestProductsResponses(interestProducts);
          const productsWithInterestValues = attachSavingsValue(
            Number(loanAmount),
            Number(loanTerm),
            estimateSavingResult,
            products,
          );
          const lenderMap = {};
          const groupedProducts = productsWithInterestValues.reduce(
            (curObj, product) => {
              const key = product.lender.id;
              const productList = curObj[key];
              lenderMap[key] = product.lender;

              if (productList) {
                productList.products.push(product);
              } else {
                curObj[key] = {
                  products: [product],
                };
              }
              return curObj;
            },
            {},
          );
          const productsByLender = _(
            Object.keys(groupedProducts).map((key) => {
              return {
                lender: lenderMap[key],
                products: groupedProducts[key].products,
              };
            }),
          ).orderBy(
            (group) =>
              _.maxBy(group.products, (product) => product.saving).saving,
            'desc',
          );
          return {
            estimateSaving: estimateSavingResult,
            products: productsByLender.toArray().value(),
          };
        });
      },
    ),
  );
};

// port of serverless handler: products/products.lenderFeaturedProducts
export const getFeaturedProducts = (lender, loanAmount, productType, lvr) => {
  if (!lender || Number.isNaN(lender)) {
    return Promise.reject(
      new Error('Lender is required to get featured products.'),
    );
  }
  return batchRequest(
    createLenderFeaturedProductsRequest({
      lender,
      loanAmount,
      productType,
      lvr,
    }),
  )
    .then(mapLenderFeaturedProductsResponses)
    .then(directReturnJSON);
};
