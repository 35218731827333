import { DIRECT_INCOMES_API_PATH } from 'shared/constants/apiPaths';
import {
  axiosWithAuth as directAxiosWithAuth,
  returnJSON as directReturnJSON,
} from './mycrmBaseApi';
import { incomeTypesBuilderForMilli } from 'shared/lib/builders/incomeTypesBuilder';

// Begin port of serverless incomesApi
//
const getAllIncomeTypes = () =>
  directAxiosWithAuth
    .get(`${DIRECT_INCOMES_API_PATH}/types`)
    .then(directReturnJSON);
//
// End port of serverless incomesApi

// port of serverless handler: incomes/incomes.getIncomeTypes
export const getIncomeTypes = (countryCode = 'AU') => {
  return getAllIncomeTypes().then((data) =>
    incomeTypesBuilderForMilli(data, countryCode),
  );
};
