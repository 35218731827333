import * as actionTypes from 'actions/dataCollectionActionTypes';

import { handleActions } from 'redux-actions';

export const initialState = {
  statusAndEvents: {},
  verificationExchange: {},
};

const dataCollectionReducer = handleActions(
  {
    [actionTypes.SET_DATA_COLLECTION_STATUS_AND_EVENTS](state, action) {
      return { ...state, statusAndEvents: { ...action.payload } };
    },
    [actionTypes.SET_VERIFICATION_EXCHANGE](state, action) {
      return {
        ...state,
        verificationExchange: {
          ...state.verificationExchange,
          ...action.payload,
        },
      };
    },
  },
  initialState,
);

export default dataCollectionReducer;
