export const CUSTOMER_IO_TRIGGER_EVENT = 'customer-io-trigger';
export const USER_DATA_EVENT = 'user_data';
export const LEAD_EMAIL_CAPTURED_EVENT = 'lead_email_captured';
export const LEAD_PASSWORD_CAPTURED_EVENT = 'lead_password_captured';
export const LEAD_MOBILE_CAPTURED_EVENT = 'lead_mobile_captured';
export const LEAD_CONVERTED_EVENT = 'lead_converted';
export const SET_LOAN_PURPOSE_EVENT = 'set_loan_purpose';
export const SET_LOAN_SCENARIO_EVENT = 'set_loan_scenario';
export const VIRTUAL_PAGE_VIEW_EVENT = 'virtualPageview';
export const SET_IS_HANDHOLD_EVENT = 'set_is_handhold';
export const SET_IS_GOAL_SETTER_EVENT = 'set_is_goal_setter';
export const SET_IS_APPLICATION_TESTED_EVENT = 'set_is_application_tested';
export const OKTA_SESSION_EXPIRED_EVENT = 'okta_session_expired';
export const BACK_BUTTON_EVENT = 'back_button';
export const ERROR_EVENT = 'error';
export const GHOST_ERROR_EVENT = 'ghost_error';
export const VALIDATION_EVENT = 'validation';
export const OKTA_LOGIN_ATTEMPT_EVENT = 'okta_login_attempt';
export const OKTA_SMS_AUTH_VERIFY_EVENT = 'okta_sms_auth_verify';
export const OKTA_GOOGLE_AUTH_VERIFY_EVENT = 'okta_google_auth_verify';
export const OKTA_LOGIN_SUCCESS_EVENT = 'okta_login_success';
export const ORGANIC_SIGNUP_ATTEMPT_EVENT = 'organic_signup_attempt';
export const MLG_SIGNUP_ATTEMPT_EVENT = 'mlg_signup_attempt';
export const FACT_FIND_SIGNUP_ATTEMPT_EVENT = 'fact_find_signup_attempt';
export const RESET_PASSWORD_ATTEMPT_EVENT = 'reset_password_attempt';
export const ORGANIC_SIGNUP_SUCCESS_EVENT = 'organic_signup_success';
export const MLG_SIGNUP_SUCCESS_EVENT = 'mlg_signup_success';
export const FACT_FIND_SIGNUP_SUCCESS_EVENT = 'fact_find_signup_success';
export const RESET_PASSWORD_SUCCESS_EVENT = 'reset_password_success';
export const RECOVER_PASSWORD_EVENT = 'recover_password';
export const GENERIC_EVENT = 'generic';
export const SEND_FOR_REVIEW_EVENT = 'send_for_review';
