export const POLL_DATA_COLLECTION_STATUS_AND_EVENTS =
  'pollDataCollectionStatusAndEvents';
export const POLL_DATA_COLLECTION_RETRY_STATUS_AND_EVENTS =
  'pollDataCollectionRetryStatusAndEvents';
export const GET_DATA_COLLECTION_STATUS_AND_EVENTS =
  'getDataCollectionStatusAndEvents';
export const SET_DATA_COLLECTION_STATUS_AND_EVENTS =
  'setDataCollectionStatusAndEvents';
export const GET_VERIFICATION_EXCHANGE = 'getVerificationExchange';
export const SET_VERIFICATION_EXCHANGE = 'setVerificationExchange';
export const POST_VERIFICATION_EXCHANGE = 'postVerificationExchange';
