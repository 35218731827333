import {
  arrayToTruthyObj,
  tryParseJson,
  decodeHtmlEncodedText,
  unformatDate,
} from 'shared/lib/utils';

import _camelCase from 'lodash/camelCase';
import { historyBuilderForMilli } from './history';

// eslint-disable-next-line sonarjs/cognitive-complexity
export function loanApplicationBuilderForMilli(application) {
  const {
    id,
    applicants,
    purposes,
    features,
    settlement_date: settlementDate,
    finance_date: financeDate,
    auction_date: auctionDate,
    specific_goals: specificGoals,
    what_you_want_todo: whatYouWantTodo,
    metadata,
    is_shared: isShared,
    is_locked: isLocked,
    country,
    bankrupt,
    bankrupt_note: bankruptNote,
    behind_payments: behindPayments,
    behind_payments_note: behindPaymentsNote,
    director,
    director_note: directorNote,
    conduct,
    date_created: dateCreated,
    PrivacyPolicyDetails: privacyPolicyDetails,
    PartialSections: partialSections,
    IsPartialFactFind: isPartialFactFind,
    IsPrivacyPolicyOnly: isPrivacyPolicyOnly,
    hasNZPrivacyConsent,
    documentRequestMethod,
    data_collection_enabled: dataCollectionEnabled,
  } = application;

  return {
    id,
    applicants: applicants
      .filter((a) => a.family_id)
      .map((a) => {
        const privacyPolicy =
          (privacyPolicyDetails &&
            privacyPolicyDetails.find(
              (p) => p.ClientId === a.client_id && p.LoanApplicationId === id,
            )) ||
          {};
        return {
          clientId: a.client_id,
          contactId: a.family_id,
          companyId: a.entity_id,
          brokerId: a.broker_id,
          isGuarantor: !!a.is_guarantor,
          isBrokerCustomerCare: a.is_broker_customer_care,
          hasCreditCheckRetried: a.has_credit_check_retried,
          hasSeenContext: a.has_seen_context,
          hasSignedPrivacyPolicy:
            privacyPolicy.PrivacyConsent ?? hasNZPrivacyConsent ?? false,
        };
      }),
    primaryGoals: arrayToTruthyObj(purposes),
    loanFeatures: arrayToTruthyObj(features),
    specificGoal: specificGoals,
    whatYouWantTodo,
    hasSettlementDate: !!settlementDate,
    hasFinanceDate: !!financeDate,
    hasAuctionDate: !!auctionDate,
    hasImportantDates: !!settlementDate || !!financeDate || !!auctionDate,
    metadata: tryParseJson(decodeHtmlEncodedText(metadata)) || {},
    isLocked: isLocked || false,
    isShared: isShared || false,
    countryCode: country ? country.code : null,
    settlementDate: settlementDate ? unformatDate(settlementDate) : {},
    financeDate: financeDate ? unformatDate(financeDate) : {},
    auctionDate: auctionDate ? unformatDate(auctionDate) : {},
    bankrupt,
    bankruptDetail: bankruptNote,
    behindInPayments: behindPayments,
    behindInPaymentsDetail: behindPaymentsNote,
    director,
    directorDetail: directorNote,
    conduct,
    dateCreated,
    isPrivacyPolicyOnly,
    partialSections:
      isPartialFactFind && partialSections && partialSections.length
        ? partialSections.map((section) => _camelCase(section))
        : null,
    isFileInvite:
      documentRequestMethod === 0 ? undefined : documentRequestMethod === 1,
    dataCollectionEnabled,
    ...historyBuilderForMilli(application),
  };
}
