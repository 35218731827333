import locale from 'config/locale';
import _ from 'lodash';

import { DIRECT_LOCALITIES_API_PATH } from 'shared/constants/apiPaths';
import {
  axiosWithAuth as directAxiosWithAuth,
  dataTransform as directDataTransform,
  returnJSON as directReturnJSON,
} from './mycrmBaseApi';
import { serialiseLocality } from 'shared/lib/localityMapperHelper';

// Begin port/copy of serverless localities
//
function removeStateFromKeyword(keyword) {
  // We do this because AusPost isn't able to auto suggest when the state is
  // appended to the keyword
  const state = /\s(ACT|NSW|QLD|VIC|WA|NT|SA)$/;
  return keyword.replace(state, '');
}

function formatAUResult(response) {
  const localities = []
    .concat(response.data.Localities?.Locality || response.data.Locality)
    .filter(
      (locality) =>
        locality.Postcode !== undefined &&
        locality.Postcode > 999 &&
        locality.Postcode < 10000,
    )
    .filter(_.identity);
  return localities.map((locality) => {
    const { Location, State, Postcode, Latitude, Longitude } = locality;
    const suburb = _.startCase(_.lowerCase(Location));
    return {
      suburb,
      state: State,
      postcode: Postcode,
      country: 'Australia',
      latitude: Latitude === 0 ? undefined : Latitude,
      longitude: Longitude === 0 ? undefined : Longitude,
      text: serialiseLocality({ suburb, postcode: Postcode, state: State }),
    };
  });
}

function extractNZSuburb(partial) {
  return partial.split(',')[0];
}

function isRoadPartial(partial) {
  /* 4 types of partials */
  /* IGNORE last two types (with road and postcode) */

  // 'Wellington', // city or suburb only
  // 'Welcome Bay, Tauranga', // suburb, city
  // 'Aubrey Road, Wanaka 9305', // road, suburb postcode
  // 'Wellington Road, Wainuiomata, Lower Hutt 5014', // road, suburb, city postcode
  return /\d{4}$/.test(partial);
}

function formatNZResult(response) {
  const suburbs = response.data.Addresses.filter(
    (a) => !isRoadPartial(a.FullPartial),
  ).map((a) => extractNZSuburb(a.FullPartial));
  return _.uniq(suburbs).map((suburb) => ({
    country: 'New Zealand',
    suburb,
    text: suburb,
  }));
}

function searchAULocalityDirect(keyword) {
  const cleanKeyword = removeStateFromKeyword(keyword);
  return directAxiosWithAuth
    .get(
      `${DIRECT_LOCALITIES_API_PATH}/searchAu?keyword=${encodeURIComponent(
        cleanKeyword,
      )}`,
    )
    .then(formatAUResult)
    .then(directDataTransform((data) => data))
    .then(directReturnJSON);
}

function searchNZLocalityDirect(keyword) {
  return directAxiosWithAuth
    .get(
      `${DIRECT_LOCALITIES_API_PATH}/searchNz?keyword=${encodeURIComponent(
        keyword,
      )}`,
    )
    .then(formatNZResult)
    .then(directDataTransform((data) => data))
    .then(directReturnJSON);
}

function searchLocality(keyword, countryCode) {
  if (countryCode === 'NZ') {
    return searchNZLocalityDirect(keyword);
  }
  return searchAULocalityDirect(keyword);
}
//
// End port/copy of serverless localities

export default function find(keyword) {
  return searchLocality(keyword, locale.data.countryCode);
}
