import _ from 'lodash';
import moment from 'moment';

import { YEARS, MONTHS } from 'shared/constants/options';

export function getAgeAndAgeUnitFromDob(dob) {
  if (!dob || _.isEmpty(dob)) {
    return { age: undefined, ageUnit: YEARS };
  }

  let age;
  let ageUnit;
  const diff = moment().diff(moment(dob));
  ageUnit = YEARS;
  age = moment.duration(diff).asYears();
  if (age % 1 > 0.95 && age % 1 < 1) {
    age = Math.ceil(age);
  }
  if (age < 1) {
    ageUnit = MONTHS;
    age = moment().diff(dob, ageUnit);
  }

  return { age: Math.round(age * 10) / 10, ageUnit };
}
