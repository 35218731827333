export function securityBuilderForMilli(security) {
  return {
    id: security.id,
    address: security.address,
    type: security.security_type_id,
    value: security.value,
    futureValue: security.future_value,
    loanApplicationId: security.scenario_id,
    isPreapproved: security.is_preapproved,
    transactionId: security.transaction_id,
  };
}
