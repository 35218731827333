/* eslint-disable prettier/prettier */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import { bindActionCreators } from 'redux';

import applyOwnPropsChecker from 'lib/applyOwnPropsChecker';
import { formatPeriod } from 'lib/utils/stringUtils';
import { findOption } from 'lib/optionHelpers';
import { logEvent, EVENTS } from 'lib/amplitude';

import { EMPLOYMENT_STATUS_OPTIONS } from 'constants/options';
import * as employmentSelectors from 'selectors/employmentSelectors';
import * as applicationSelectors from 'selectors/applicationSelectors';

import employmentActions from 'actions/employmentActions';

import applySection from 'hocs/applySection';
import { accordionPropTypes } from 'types/customPropTypes';

import Button from 'components/Button/Button';
import EditableItem from 'components/EditableItem/EditableItem';
import ApplyItemContainer from 'components/ApplyItemContainer/ApplyItemContainer';

import styles from './EmploymentsApply.css';
import { EMPLOYMENTS_SLUG } from 'constants/applyData';

const messages = defineMessages({
  title: {
    id: 'EmploymentsApply.title',
    defaultMessage: 'Employment',
  },
  titleDescription: {
    id: 'EmploymentsApply.titleDescription',
    defaultMessage: 'Tell us about your employment history',
  },
  headerDescription: {
    id: 'EmploymentsApply.headerDescription',
    defaultMessage: 'Where have you worked for the past three years?',
  },
  titleDescriptionCompleted: {
    id: 'EmploymentsApply.titleDescriptionCompleted',
    defaultMessage:
      '{totalEmploymentsCount} {totalEmploymentsCount, plural, =1 {job} other {jobs}}',
  },
  headerDescriptionCompleted: {
    id: 'EmploymentsApply.headerDescriptionCompleted',
    defaultMessage: 'Your employment history.',
  },
  addEmployment: {
    id: 'EmploymentsApply.addEmployment',
    defaultMessage: 'Add a job',
  },
  editJob: {
    id: 'EmploymentsApply.editJob',
    defaultMessage: 'Edit job',
  },
  detailEmploymentHistory: {
    id: 'EmploymentsApply.detailEmploymentHistory',
    defaultMessage: 'Detail employment history for the last 3 years',
  },
  threeYears: {
    id: 'EmploymentsApply.threeYears',
    defaultMessage: '3 years employment history',
  },
  almostThere: {
    id: 'EmploymentsApply.almostThere',
    defaultMessage: `Lenders require 3 years of employment history`,
  },
  threeYearHistory: {
    id: 'EmploymentsApply.threeYearHistory',
    defaultMessage: 'Most lenders require a 3 year history.',
  },
});

export const furtherDecoration = (props) => {
  const {
    intl: { formatMessage },
    totalEmploymentsCount,
    isCompleted,
    hasExpandedEmployments,
    hasOldInformation,
    warningMessage,
  } = props;
  const postfix = isCompleted ? 'Completed' : '';
  const showWarningMessage =
    !isCompleted && !!totalEmploymentsCount && hasExpandedEmployments;

  let warningMessageFormatted = '';
  if (showWarningMessage) {
    warningMessageFormatted = formatMessage(messages.almostThere);
  } else if (hasOldInformation) {
    warningMessageFormatted = warningMessage;
  }

  return {
    title: formatMessage(messages.title),
    titleDescription: formatMessage(messages[`titleDescription${postfix}`], {
      totalEmploymentsCount,
    }),
    headerDescription: formatMessage(messages[`headerDescription${postfix}`]),
    warningMessage: warningMessageFormatted,
  };
};

const confirmEntities = (props) => {
  const {
    confirmEmployments,
    primaryApplicantsEmployments: employments,
    applicationId,
  } = props;

  confirmEmployments({
    id: applicationId,
    employments,
  });
};

class EmploymentsApply extends Component {
  static displayName = 'EmploymentsApply';
  static propTypes = {
    intl: intlShape.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    primaryApplicant: PropTypes.object.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    primaryApplicantsPartner: PropTypes.object,
    accordionProps: PropTypes.shape(accordionPropTypes).isRequired,
    primaryApplicantsEmployments: PropTypes.arrayOf(PropTypes.object)
      .isRequired,
    primaryApplicantsPartnersEmployments: PropTypes.arrayOf(PropTypes.object),
    totalEmploymentsCount: PropTypes.number.isRequired,
    hasExpandedEmployments: PropTypes.bool,
    isOpen: PropTypes.bool.isRequired,
    setMetadata: PropTypes.func.isRequired,
    isCompleted: PropTypes.bool.isRequired,
    urlPath: PropTypes.string.isRequired,
  };

  static defaultProps = {
    primaryApplicantsPartnersEmployments: [],
  };

  componentDidUpdate(prevProps) {
    const { totalEmploymentsCount, isOpen, setMetadata } = this.props;
    if (!prevProps.isOpen && isOpen) {
      setMetadata({ hasExpandedEmployments: !!totalEmploymentsCount });
    }
  }

  newEmploymentUrl = () => `${this.props.urlPath}/employments/employment/new`;
  editEmploymentUrl = (id) =>
    `${this.props.urlPath}/employments/employment/${id}`;

  editableEmploymentItem = (employment) => {
    const { formatDate } = this.props.intl;
    const statusOption =
      findOption(EMPLOYMENT_STATUS_OPTIONS, employment.statusId) || {};
    return (
      <EditableItem
        key={`employment-${employment.id}`}
        url={this.editEmploymentUrl(employment.id)}
        leftIcon='sl-custom-id-5'
        leftLabel={statusOption.name}
        leftDescription={formatPeriod(
          formatDate,
          employment.dateStarted,
          employment.dateEnded,
        )}
        rightLabel={employment.role}
        rightDescription={employment.employerName}
      />
    );
  };

  onAddEmployment = () => {
    logEvent(EVENTS.ADD_HISTORY, { section: EMPLOYMENTS_SLUG });
  };

  renderHasEmployments() {
    const {
      primaryApplicantsEmployments,
      primaryApplicantsPartnersEmployments,
      isCompleted,
      hasExpandedEmployments,
    } = this.props;
    const showHint = !isCompleted && !hasExpandedEmployments;

    return (
      <div id='employmentsApply'>
        {primaryApplicantsEmployments.length > 0 && [
          <h4 key='your-title'>You</h4>,
          <ApplyItemContainer
            key='applyItemContainer'
            showHint={
              showHint && primaryApplicantsPartnersEmployments.length === 0
            }
          >
            {primaryApplicantsEmployments.map(this.editableEmploymentItem)}
          </ApplyItemContainer>,
        ]}
        {primaryApplicantsPartnersEmployments.length > 0 && [
          <h4 key='partner-title'>Your partner</h4>,
          <ApplyItemContainer key='applyItemContainer' showHint={showHint}>
            {primaryApplicantsPartnersEmployments.map(
              this.editableEmploymentItem,
            )}
          </ApplyItemContainer>,
        ]}
        {showHint && this.renderHint()}
      </div>
    );
  }

  renderInstructions = () => (
    <div key='residenceInstruction'>
      <p>
        Tell us where you’ve worked for the last three years. Lenders want to
        see that you have job stability.
      </p>
      <p>Examples include.</p>
      <ul>
        <li>Marketing Manager - 2 years</li>
        <li>Retail Assistant - 1 year 6 months.</li>
      </ul>
    </div>
  );

  renderHint = () => {
    const {
      intl: { formatMessage },
    } = this.props;
    return (
      <div className={styles.employmentHint}>
        <i className='sl-custom-lightbulb-2' />
        {formatMessage(messages.threeYearHistory)}
      </div>
    );
  };

  render() {
    const {
      totalEmploymentsCount,
      accordionProps: { isLocked },
      intl: { formatMessage },
    } = this.props;

    return (
      <div>
        {totalEmploymentsCount
          ? this.renderHasEmployments()
          : this.renderInstructions()}
        {!isLocked && (
          <Button
            url={this.newEmploymentUrl()}
            theme='applyNew'
            icon='sl-custom-id-5'
            onClick={this.onAddEmployment}
          >
            {formatMessage(messages.addEmployment)}
          </Button>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const primaryApplicantId = ownProps.primaryApplicant.id;

  const props = {
    primaryApplicantsEmployments: employmentSelectors.clientsEmployments(state)(
      primaryApplicantId,
    ),
    applicationId: applicationSelectors.getApplicationId(state),
  };
  if (ownProps.primaryApplicantsPartner) {
    const partnerId = ownProps.primaryApplicantsPartner.id;
    props.primaryApplicantsPartnersEmployments = employmentSelectors.clientsEmployments(
      state,
    )(partnerId);
  }
  props.totalEmploymentsCount = employmentSelectors.primaryContactEmployments(
    state,
  ).length;

  return props;
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      confirmEmployments: employmentActions.confirmEmployments,
    },
    dispatch,
  );

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    applyOwnPropsChecker,
  )(
    applySection({
      iconName: 'sl-custom-id-5',
      furtherDecoration,
      confirmEntities,
    })(EmploymentsApply),
  ),
);
