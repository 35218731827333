import { createSelector } from 'reselect';
import * as addressSelectors from './addressSelectors';
import * as applicationSelectors from './applicationSelectors';
import * as assetSelectors from './assetSelectors';
import * as clientSelectors from './clientSelectors';
import * as coapplicantSelectors from './coapplicantSelectors';
import * as employmentSelectors from './employmentSelectors';
import * as expenseSelectors from './expenseSelectors';
import * as incomeSelectors from './incomeSelectors';
import * as liabilitySelectors from './liabilitySelectors';
import * as propertySelectors from './propertySelectors';

export const quickLmi = (state) => state.other.quickLmi;
export const listingInfo = (state) => {
  const { listingInfo: info } = state.other;
  return {
    ...info,
    address: info.address || '',
  };
};
export const maximumLmi = createSelector(quickLmi, (lmi) => lmi.maximum);
export const minimumLmi = createSelector(quickLmi, (lmi) => lmi.minimum);

export const hasOldInfo = createSelector(
  applicationSelectors.hasOldInformation,
  assetSelectors.hasOldInformation,
  liabilitySelectors.hasOldInformation,
  expenseSelectors.hasOldInformation,
  incomeSelectors.hasOldInformation,
  addressSelectors.hasOldInformation,
  employmentSelectors.hasOldInformation,
  clientSelectors.hasPrimaryApplicantOldInformation,
  clientSelectors.hasFamilyOldInformation,
  coapplicantSelectors.hasCoApplicantOldInformation,
  addressSelectors.hasCurrentAddressOldInformation,
  propertySelectors.hasToPurchaseOldInformation,
  propertySelectors.hasOtherPropertiesOldInformation,
  (
    applicationHasOldInfo,
    assetHasOldInfo,
    liabilityHasOldInfo,
    expenseHasOldInfo,
    incomeHasOldInfo,
    addressHasOldInfo,
    employmentHasOldInfo,
    clientPrimaryHasOldInfo,
    clientFamilyHasOldInfo,
    coapplicantHasOldInfo,
    addressCurrentHasOldInfo,
    propertyHasOldInfo,
    propertyOthersHasOldInfo,
  ) =>
    applicationHasOldInfo ||
    assetHasOldInfo ||
    liabilityHasOldInfo ||
    expenseHasOldInfo ||
    incomeHasOldInfo ||
    addressHasOldInfo ||
    employmentHasOldInfo ||
    clientPrimaryHasOldInfo ||
    clientFamilyHasOldInfo ||
    coapplicantHasOldInfo ||
    addressCurrentHasOldInfo ||
    propertyHasOldInfo ||
    propertyOthersHasOldInfo,
);
