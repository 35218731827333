import * as contactActionTypes from 'actions/contactActionTypes';
import * as individualContactActionTypes from 'actions/individualContactActionTypes';
import { actionTypes as loanApplicantActionTypes } from 'actions/loanApplicantActions';
import update from 'immutability-helper';

import { handleActions } from 'redux-actions';

import {
  setWorkingValue,
  clearWorking,
  removeEntity,
  loadEntityIntoWorking,
  setEntity,
  setNewEntity,
  mergeEntity,
  setNewOrMergeEntity,
  setError,
  setNewEntities,
} from 'lib/reducerHelper';

const actionTypes = { ...contactActionTypes, ...individualContactActionTypes };
const newContact = {
  errors: {},
  dob: {},
  mobile: {},
  officePhone: {},
  officeAddress: {},
};

export const initialState = {
  errors: {},
  entities: [],
  working: {
    new: newContact,
  },
};

const contactReducer = handleActions(
  {
    [actionTypes.CLEAR_WORKING_CONTACT](state, action) {
      return clearWorking(state, action, newContact);
    },
    [actionTypes.LOAD_CONTACT](state, action) {
      return loadEntityIntoWorking(state, action, newContact);
    },
    [actionTypes.REMOVE_CONTACT](state, action) {
      return removeEntity(state, action);
    },
    [actionTypes.SET_NEW_CONTACT](state, action) {
      return setNewEntity(state, action);
    },
    [actionTypes.SET_NEW_CONTACTS](state, action) {
      return setNewEntities(state, action);
    },
    [actionTypes.SET_CONTACT](state, action) {
      return setEntity(state, action);
    },
    [actionTypes.MERGE_CONTACT](state, action) {
      return mergeEntity(state, action);
    },
    [actionTypes.SET_NEW_OR_MERGE_CONTACT](state, action) {
      return setNewOrMergeEntity(state, action);
    },

    [actionTypes.SET_CONTACT_IS_COAPPLICANT](state, action) {
      return setWorkingValue(state, action.payload, 'isCoapplicant');
    },
    [actionTypes.SET_CONTACT_TYPE](state, action) {
      return setWorkingValue(state, action.payload, 'type');
    },
    [actionTypes.SET_CONTACT_ROLE](state, action) {
      return setWorkingValue(state, action.payload, 'role');
    },
    // These contact actions represent setting the data on a new Contact when the type is "Person"
    // I (Jaryd) believe that in reality the Contact is quite blank when of this type and all this
    // information is set onto a Client record within that Contact
    [actionTypes.SET_CONTACT_TITLE](state, action) {
      return setWorkingValue(state, action.payload, 'title');
    },
    [actionTypes.SET_CONTACT_FIRST_NAME](state, action) {
      return setWorkingValue(state, action.payload, 'firstName');
    },
    [actionTypes.SET_CONTACT_MIDDLE_NAME](state, action) {
      return setWorkingValue(state, action.payload, 'middleName');
    },
    [actionTypes.SET_CONTACT_LAST_NAME](state, action) {
      return setWorkingValue(state, action.payload, 'lastName');
    },
    [actionTypes.SET_CONTACT_PREFERRED_NAME](state, action) {
      return setWorkingValue(state, action.payload, 'preferredName');
    },
    [actionTypes.SET_CONTACT_PREVIOUS_NAME](state, action) {
      return setWorkingValue(state, action.payload, 'previousName');
    },
    [actionTypes.SET_CONTACT_MOTHERS_MAIDEN_NAME](state, action) {
      return setWorkingValue(state, action.payload, 'mothersMaidenName');
    },
    [actionTypes.SET_CONTACT_IS_CITIZEN_AND_RESIDENT](state, action) {
      return setWorkingValue(state, action.payload, 'isCitizenAndResident');
    },
    [actionTypes.SET_CONTACT_MOBILE](state, action) {
      return setWorkingValue(state, action.payload, 'mobile');
    },
    [actionTypes.SET_CONTACT_EMAIL](state, action) {
      return setWorkingValue(state, action.payload, 'email');
    },
    [actionTypes.SET_CONTACT_DOB_YEAR](state, action) {
      return update(state, {
        working: {
          [action.payload.id]: {
            dob: { year: { $set: action.payload.value } },
          },
        },
      });
    },
    [actionTypes.SET_CONTACT_DOB_MONTH](state, action) {
      return update(state, {
        working: {
          [action.payload.id]: {
            dob: { month: { $set: action.payload.value } },
          },
        },
      });
    },
    [actionTypes.SET_CONTACT_DOB_DAY](state, action) {
      return update(state, {
        working: {
          [action.payload.id]: { dob: { day: { $set: action.payload.value } } },
        },
      });
    },
    [actionTypes.SET_CONTACT_AGE](state, action) {
      return setWorkingValue(state, action.payload, 'age');
    },
    [actionTypes.SET_CONTACT_GENDER](state, action) {
      return setWorkingValue(state, action.payload, 'gender');
    },
    [actionTypes.SET_CONTACT_CITIZENSHIP_ID](state, action) {
      return setWorkingValue(state, action.payload, 'citizenshipId');
    },
    [actionTypes.SET_CONTACT_COUNTRY_OF_RESIDENCY_ID](state, action) {
      return setWorkingValue(state, action.payload, 'countryOfResidencyId');
    },
    [actionTypes.SET_CONTACT_IS_RESIDENT](state, action) {
      return setWorkingValue(state, action.payload, 'isResident');
    },
    [actionTypes.SET_CONTACT_RESIDENCY_ID](state, action) {
      return setWorkingValue(state, action.payload, 'residencyId');
    },
    [actionTypes.SET_CONTACT_MARITAL_STATUS_ID](state, action) {
      return setWorkingValue(state, action.payload, 'maritalStatusId');
    },

    // These contact actions represent setting the data on a new Contact when the type is "Company"
    // I (Daisy) copied it from Jaryd's code and please check his belief above
    [actionTypes.SET_CONTACT_BUSINESS_NAME](state, action) {
      return setWorkingValue(state, action.payload, 'businessName');
    },
    [actionTypes.SET_CONTACT_FIRM](state, action) {
      return setWorkingValue(state, action.payload, 'firm');
    },
    [actionTypes.SET_CONTACT_DESCRIPTION](state, action) {
      return setWorkingValue(state, action.payload, 'description');
    },
    [actionTypes.SET_CONTACT_NZBN](state, action) {
      return setWorkingValue(state, action.payload, 'nzbn');
    },
    [actionTypes.SET_CONTACT_TFN](state, action) {
      return setWorkingValue(state, action.payload, 'tfn');
    },
    [actionTypes.SET_CONTACT_OFFICE_PHONE](state, action) {
      return setWorkingValue(state, action.payload, 'officePhone');
    },
    [actionTypes.SET_CONTACT_ACN](state, action) {
      return setWorkingValue(state, action.payload, 'acn');
    },
    [actionTypes.SET_CONTACT_OFFICE_EMAIL](state, action) {
      return setWorkingValue(state, action.payload, 'officeEmail');
    },

    [actionTypes.SET_CONTACT_ERROR](state, action) {
      return setError(state, action);
    },

    [loanApplicantActionTypes.SET_PRIMARY_APPLICANT](
      state,
      { payload: applicantId },
    ) {
      const payload = state.entities.map((e) => ({
        ...e,
        isPrimaryContact: e.clientId === applicantId,
      }));
      return setNewEntities(state, { payload });
    },
  },
  initialState,
);

export default contactReducer;
