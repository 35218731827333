import { handleActions } from 'redux-actions';
import * as actionTypes from 'actions/advisorActionTypes';
import update from 'immutability-helper';
import { NO_ERROR } from 'constants/validators';

export const initialState = {
  errors: {},
  subscriptions: {},
};

const advisorReducer = handleActions(
  {
    [actionTypes.SET_ADVISOR_INFO](state, action) {
      return { ...state, ...action.payload };
    },
    [actionTypes.SET_ADVISOR_ERROR](state, action) {
      const { id, text, blocking } = action.payload;

      return update(state, {
        errors: {
          [id]: {
            $set: text === NO_ERROR ? undefined : { text, blocking },
          },
        },
      });
    },
  },
  initialState,
);

export default advisorReducer;
