import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { goBack } from '@loan_market/react-router-redux-multi';

import GoalSetterForm from 'containers/goal/GoalSetterForm/GoalSetterForm';

import { CURRENT_BANKS, QUESTION_IDS } from 'shared/constants/goalLoanAppInfo';

import goalLoanAppInfoActions from 'actions/goal/goalLoanAppInfoActions';
import UIActions from 'actions/UIActions';
import applicationActions from 'actions/loanApplicationActions';

import * as applicationSelectors from 'selectors/applicationSelectors';
import * as goalLoanApplicationSelectors from 'selectors/goalLoanApplicationSelectors';
import * as goalLoanAppInfoSelectors from 'selectors/goalLoanAppInfoSelectors';
import * as clientSelectors from 'selectors/clientSelectors';
import { fromCurrentToPreferredLenders } from 'lib/pathHelper';
import { getQuestionId } from 'lib/goalSetterFormHelper';
import { areArraysEqual } from 'lib/utils/dataUtils';

// eslint-disable-next-line sonarjs/cognitive-complexity
export function GoalPreferencesCurrentBank(props) {
  const {
    questions,
    answers,
    saveLoanAppInfoAnswers,
    active,
    familyId,
    goToPathWithAnimation,
    back,
    trackEvent,
    updateLoanApplication,
    metadata,
  } = props;
  const initState = {};
  const CURRENT_BANK = getQuestionId(QUESTION_IDS.CURRENT_BANK);
  const OTHER_CURRENT_BANK = getQuestionId(QUESTION_IDS.OTHER_CURRENT_BANK);
  if (
    answers[OTHER_CURRENT_BANK] &&
    ((answers[CURRENT_BANK] &&
      !answers[CURRENT_BANK].includes(OTHER_CURRENT_BANK)) ||
      !answers[CURRENT_BANK])
  ) {
    initState[CURRENT_BANK] = answers[CURRENT_BANK]
      ? [...answers[CURRENT_BANK], OTHER_CURRENT_BANK]
      : [OTHER_CURRENT_BANK];
  }

  const [savedAnswers, updateSavedAnswers] = useState(initState);

  const setAnswer = (questionId, value) =>
    updateSavedAnswers({ ...savedAnswers, [questionId]: value });

  const onSave = (isBack) => {
    if (!metadata?.saved || !metadata?.saved['current-bank']) {
      updateLoanApplication({
        id: active,
        metadata: {
          ...metadata,
          saved: { ...metadata.saved, 'current-bank': true },
        },
      });
    }

    const length = Object.keys(savedAnswers).length;
    if (
      (length === 1 &&
        !areArraysEqual(answers[CURRENT_BANK], savedAnswers[CURRENT_BANK])) ||
      length > 1
    ) {
      const customAnswers = {};
      if (!savedAnswers[CURRENT_BANK].includes(OTHER_CURRENT_BANK)) {
        customAnswers[OTHER_CURRENT_BANK] = '';
      }
      saveLoanAppInfoAnswers({
        section: CURRENT_BANKS,
        answers: { ...savedAnswers, ...customAnswers },
        loanAppId: active,
        familyId,
      });
    }
    if (!isBack) {
      goToPathWithAnimation(fromCurrentToPreferredLenders(active));
    }
  };

  const formProps = {
    formName: CURRENT_BANKS,
    action: setAnswer,
    questions,
    answers: { ...(answers || {}), ...savedAnswers },
    displayName: undefined,
    submitButtonProps: {
      text: 'Save and Next',
      action: onSave,
      theme: 'buttonNext',
      disabled:
        savedAnswers[CURRENT_BANK] && savedAnswers[CURRENT_BANK].length === 0,
    },
    back,
    trackEvent,
  };

  return <GoalSetterForm {...formProps} />;
}

GoalPreferencesCurrentBank.propTypes = {
  match: PropTypes.object,
  goTo: PropTypes.func,
  questions: PropTypes.object,
  answers: PropTypes.object,
  saveLoanAppInfoAnswers: PropTypes.func,
  active: PropTypes.number,
  familyId: PropTypes.number,
  goToPathWithAnimation: PropTypes.func,
  back: PropTypes.func,
  trackEvent: PropTypes.func,
  updateLoanApplication: PropTypes.func,
  metadata: PropTypes.object,
};

const mapStateToProps = (state) => {
  const family = clientSelectors.primaryFamily(state);
  return {
    questions: goalLoanAppInfoSelectors.questionsForSection(state)(
      CURRENT_BANKS,
    ),
    answers: goalLoanAppInfoSelectors.answersForSection(state)(
      family.contactId,
      CURRENT_BANKS,
    ),
    active: goalLoanApplicationSelectors.loanApplicationId(state),
    familyId: family.contactId,
    metadata: applicationSelectors.workingApplicationMetadata(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveLoanAppInfoAnswers: goalLoanAppInfoActions.saveLoanAppInfoAnswers,
      goToPathWithAnimation: UIActions.goToPathWithAnimation,
      back: goBack,
      trackEvent: UIActions.trackEvent,
      updateLoanApplication: applicationActions.updateLoanApplication,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GoalPreferencesCurrentBank);
