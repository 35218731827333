import { DIRECT_LIABILITIES_API_PATH } from 'shared/constants/apiPaths';

import {
  axiosWithAuth as directAxiosWithAuth,
  dataTransform as directDataTransform,
  returnJSON as directReturnJSON,
} from './mycrmBaseApi';
import { liabilityBuilderForMyCRM } from 'shared/lib/builders/mycrm/liability';
import { liabilityBuilderForMilli } from 'shared/lib/builders/milli/liability';

// Begin port of serverless liabilitiesApi
//
const putLiabilityDirect = (liabilityId, data) => {
  return directAxiosWithAuth
    .put(`${DIRECT_LIABILITIES_API_PATH}/${liabilityId}`, data)
    .then(directReturnJSON);
};

const deleteLiabilityDirect = (liabilityId) => {
  return directAxiosWithAuth
    .delete(`${DIRECT_LIABILITIES_API_PATH}/${liabilityId}`)
    .then(directReturnJSON);
};

//
// End of port of serverless liabilitiesApi

// port of serverless handler: liabilities/liabilities.put
export function putLiability(liability) {
  return putLiabilityDirect(liability.id, liabilityBuilderForMyCRM(liability))
    .then(liabilityBuilderForMilli)
    .then(directDataTransform((data) => data))
    .then(directReturnJSON);
}

// port of serverless handler: liabilities/liabilities.del
export function delLiability(liabilityId) {
  return deleteLiabilityDirect(liabilityId).then(directReturnJSON);
}
