import { createSelector } from 'reselect';
import * as applicationSelectors from 'selectors/applicationSelectors';
import { getCoapplicants as getClientCoapplicants } from 'selectors/clientSelectors';
import { companyContacts } from 'selectors/contactSelectors';

export const getAllCoapplicants = createSelector(
  applicationSelectors.getApplicants,
  getClientCoapplicants,
  companyContacts,
  (applicants, clientCoapplicants, contactCoapplicants) => {
    return clientCoapplicants.concat(contactCoapplicants).map((client) => {
      const applicant = applicants.find((a) => a.clientId === client.id) || {};
      return { ...client, isGuarantor: !!applicant.isGuarantor };
    });
  },
);

export const hasCoapplicants = createSelector(
  applicationSelectors.workingApplicationMetadata,
  getAllCoapplicants,
  (selectedMetadata, selectedCoapplicants) => {
    if (selectedCoapplicants && selectedCoapplicants.length > 0) {
      return true;
    } else if (selectedMetadata) {
      return selectedMetadata.hasCoapplicants;
    }
    return undefined;
  },
);

export const hasCoApplicantOldInformation = createSelector(
  getAllCoapplicants,
  (coApplicants) => coApplicants.some((i) => i?.isOldData),
);
