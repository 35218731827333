import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button/Button';
import styles from './ReviewSectionsModal.css';
import { PROFILE_SLUG_TITLE_MAP } from 'constants/profileSections';
import locale from 'config/locale';

export const ReviewSectionsModal = (props) => (
  <div>
    <h1 className={styles.heading}>You haven’t completed every section.</h1>
    <p>
      Complete the following section(s) to provide a more accurate view of your
      finances:
    </p>
    <ul className={styles.SectionList}>
      {props.unreviewedSections.map((section) => (
        <li key={section}>{PROFILE_SLUG_TITLE_MAP[section]}</li>
      ))}
    </ul>
    <div className={styles.buttonGroup}>
      <Button theme='linkButtonTheme' onClick={props.onReviewSectionsClick}>
        Complete section(s)
      </Button>
      <Button
        theme='buttonNext'
        className={styles.blockBtn}
        onClick={props.onSendToBrokerClick}
      >
        Send to {locale.isAU ? 'broker' : 'adviser'}
      </Button>
    </div>
  </div>
);

ReviewSectionsModal.propTypes = {
  onSendToBrokerClick: PropTypes.func,
  onReviewSectionsClick: PropTypes.func,
  unreviewedSections: PropTypes.arrayOf(PropTypes.string),
};
