// calculates the average monthly repayment for both variable and fixed products
export const calculateAverageMonthlyRepayment = (
  term,
  fixedTerm,
  initial,
  ongoing,
) => {
  if (term === 0) {
    return 0;
  }
  const fixed = fixedTerm > term ? term : fixedTerm;
  const initialAmount = initial * fixed;
  const ongoingAmount = ongoing * (term - fixed);
  return (initialAmount + ongoingAmount) / term;
};
