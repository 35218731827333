import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import _ from 'lodash';
import classNames from 'classnames/bind';
import locale from 'config/locale';

import { lenderReversedLogoUrl, lenderBannerUrl } from 'lib/utils/imageUtils';

import Accordion from 'components/Accordion/Accordion';
import Button from 'components/Button/Button';
import TextSummary from 'components/TextSummary/TextSummary';

import { formatCurrency } from 'lib/intlFormatters';
import {
  REPAYMENT_TYPE_PRINCIPAL_INTEREST,
  REPAYMENT_TYPE_PRINCIPAL_INTEREST_VALUE,
  REPAYMENT_TYPE_INTEREST_ONLY_VALUE,
} from 'shared/constants/repaymentTypes';

import InfoSection from 'components/LenderRecord/InfoSection';

import styles from './LenderRecord.css';

export const messages = defineMessages({
  lenderMaxBorrowHeading: {
    id: 'LenderRecord.lenderMaxBorrowHeading',
    defaultMessage: 'Maximum Borrowing Capacity',
  },
  totalBorrow: {
    id: 'LenderRecord.totalBorrow',
    defaultMessage: 'TOTAL BORROW',
  },
  initialLabel: {
    id: 'LenderRecord.initialLabel',
    defaultMessage: 'Initial',
  },
  assessmentLabel: {
    id: 'LenderRecord.assessmentLabel',
    defaultMessage: 'Assessment',
  },
  applyToConfirm: {
    id: 'LenderRecord.applyToConfirm',
    defaultMessage: 'APPLY TO CONFIRM',
  },
  yourLender: {
    id: 'LenderRecord.yourLender',
    defaultMessage: 'Your lender',
  },
  todaysTopDeal: {
    id: 'LenderRecord.todaysTopDeal',
    defaultMessage: 'Today’s Top Deal',
  },
  viewDetails: {
    id: 'LenderRecord.viewDetails',
    defaultMessage: 'View Details',
  },
  searchAll: {
    id: 'LenderRecord.searchAll',
    defaultMessage: 'Search all loans',
  },
});

export class LenderRecord extends PureComponent {
  static propTypes = {
    lender: PropTypes.object.isRequired,
    updateWorkingStructure: PropTypes.func,
    value: PropTypes.number.isRequired,
    maximumValue: PropTypes.number.isRequired,
    products: PropTypes.object,
    intl: intlShape.isRequired,
    requestFeaturedProducts: PropTypes.func,
    primaryAction: PropTypes.func,
    infoSectionProps: PropTypes.shape({
      heading: PropTypes.string.isRequired,
      headingInfoItems: PropTypes.arrayOf(PropTypes.string).isRequired,
      valueHeading: PropTypes.string.isRequired,
      valueSubtitle: PropTypes.string.isRequired,
    }).isRequired,
    legend: PropTypes.string.isRequired,
    productTable: PropTypes.func,
    productTableProps: PropTypes.object,
    history: PropTypes.object,
  };

  static defaultProps = {
    productTableProps: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      lenderImageErrorIds: [],
    };
  }

  hasNoProducts = () => {
    return (
      _.isEmpty(_.omitBy(this.props.products, _.isNull)) ||
      !this.props.productTable
    );
  };

  handleToggle = (toggle, isCollapsed) => {
    const { lender, products, requestFeaturedProducts } = this.props;

    if (isCollapsed && !products && requestFeaturedProducts) {
      requestFeaturedProducts({
        lenderId: lender.bank.lenderId,
        lenderCode: lender.lenderCode,
      });
    }
    toggle();
  };

  updateStructureAndGoToProductDetails = (product) => {
    const { updateWorkingStructure } = this.props;
    if (updateWorkingStructure) {
      const repaymentType =
        product.repayments.monthly.initial.type ===
        REPAYMENT_TYPE_PRINCIPAL_INTEREST
          ? REPAYMENT_TYPE_PRINCIPAL_INTEREST_VALUE
          : REPAYMENT_TYPE_INTEREST_ONLY_VALUE;

      updateWorkingStructure({ repaymentType });
    }
    this.props.history.push(`/product-detail/${product.id}`);
  };

  renderProductTable() {
    const {
      productTable: TableComponent,
      productTableProps,
      intl: { formatMessage },
      lender: { lenderName, bank },
      products,
    } = this.props;

    return (
      <TableComponent
        products={products}
        hasNoProducts={this.hasNoProducts()}
        lenderName={lenderName}
        handleSelection={this.updateStructureAndGoToProductDetails}
        buttonText={formatMessage(messages.viewDetails)}
        buttonStyleKey={bank.key}
        firstHeading={formatMessage(messages.todaysTopDeal)}
        {...productTableProps}
      />
    );
  }

  renderButton(bank) {
    return (
      <Button
        theme='productAction'
        onClick={this.props.primaryAction}
        className={classNames(styles.button, styles[`button${bank.key}`])}
      >
        {this.props.intl.formatMessage(messages.searchAll)}
      </Button>
    );
  }

  renderProductSection() {
    const {
      lender: { bank },
      productTable,
    } = this.props;
    const hasProducts = !this.hasNoProducts();

    return (
      <div className={styles.productsSection}>
        {hasProducts && this.renderButton(bank)}
        {productTable && this.renderProductTable()}
        <div
          className={classNames(styles.approvalBtnAndDescription, {
            [styles.approvalBtnAndDescriptionFull]: !productTable,
          })}
        >
          {hasProducts && this.renderButton(bank)}
          <TextSummary className={styles.lenderDescription} previewLength={350}>
            {bank.lenderDescription}
          </TextSummary>
        </div>
      </div>
    );
  }

  handleImageError = (id, isBanner) => () => {
    this.setState({
      lenderImageErrorIds: [
        ...this.state.lenderImageErrorIds,
        isBanner ? `b${id}` : id,
      ],
    });
  };

  renderContent = ({ contentRef, toggle, isCollapsed }) => {
    const {
      intl,
      lender,
      maximumValue,
      value,
      infoSectionProps,
      legend,
      intl: { formatMessage },
    } = this.props;
    const bankKey = lender.bank.key;
    const lenderId = lender.bank.lenderId;
    const reversedLogoUrl = lender.bank.reversedLogoUrl;
    const logoUrl =
      reversedLogoUrl ||
      lenderReversedLogoUrl(lenderId, locale.data.countryCode);
    const reversedBannerLogoUrl = lender.bank.lenderBannerUrl;
    const bannerUrl =
      reversedBannerLogoUrl ||
      lenderBannerUrl(lenderId, locale.data.countryCode);
    const hasLogo = !this.state.lenderImageErrorIds.includes(lenderId);
    const hasBanner = !this.state.lenderImageErrorIds.includes(`b${lenderId}`);
    const calculatedWidth = 40 + Math.round((value / maximumValue) * 60);

    const rootStyle = classNames(styles.root, {
      [styles.isOpen]: !isCollapsed,
    });
    const contentStyle = classNames(styles.content, styles[`box${bankKey}`]);
    const headerSectionStyle = classNames(
      styles.headerSection,
      styles[isCollapsed ? 'collapsed' : 'open'],
      styles[`lenderBackground${bankKey}`],
    );
    const headerRatioBarStyle = classNames(styles.headerRatioBar, {
      [styles.defaultHeaderRatioBar]: !hasLogo,
    });

    return (
      <div className={styles.container}>
        {lender.isCurrentLender && (
          <div className={styles.yourLender}>
            {formatMessage(messages.yourLender)}
          </div>
        )}
        <section className={rootStyle}>
          <div
            className={headerSectionStyle}
            onClick={() => this.handleToggle(toggle, isCollapsed)}
          >
            <div className={styles.ratioWrapper}>
              <div
                className={headerRatioBarStyle}
                style={{ width: isCollapsed ? `${calculatedWidth}%` : '100%' }}
              />
              {hasLogo ? (
                <div className={styles.headerLogo}>
                  <img
                    src={logoUrl}
                    onError={this.handleImageError(lenderId)}
                    data-location={
                      reversedLogoUrl
                        ? 'reversedLogoUrl'
                        : 'lenderReversedLogoUrl'
                    }
                  />
                </div>
              ) : (
                <h4 className={styles.headerLenderName}>
                  {lender.bank.lenderCode}
                </h4>
              )}
            </div>
            <div className={styles.valueAndClose}>
              <div className={styles.valueInfo}>
                <span className={styles.amount}>
                  {formatCurrency(intl)(value)}
                </span>
                <span className={styles.label}>{legend}</span>
              </div>
              <div className={styles.closeSection}>
                <span>Close</span>
                <i
                  className={
                    isCollapsed
                      ? 'mi-arrow-with-circle-down'
                      : 'mi-arrow-with-circle-up'
                  }
                />
              </div>
            </div>
          </div>
          <div className={styles.contentWrapper} ref={contentRef}>
            <div className={contentStyle}>
              <div className={styles.lenderImg}>
                {hasBanner && (
                  <img
                    src={bannerUrl}
                    alt={lender.bank.lenderName}
                    data-location={
                      reversedBannerLogoUrl
                        ? 'reversedBannerLogoUrl'
                        : 'bannerUrl'
                    }
                    onError={this.handleImageError(lenderId, true)}
                  />
                )}
              </div>
              <InfoSection value={value} intl={intl} {...infoSectionProps} />
              {this.renderProductSection()}
            </div>
          </div>
        </section>
      </div>
    );
  };

  render() {
    return <Accordion>{this.renderContent}</Accordion>;
  }
}

export default injectIntl(LenderRecord);
