let config;
try {
  config = window && window.LM_CONFIG;
} catch (error) {
  config = process.env;
}

// direct paths
export const FILE_INVITE_API_BASE = config.FILE_INVITE_API_BASE;
export const DIRECT_API_BASE = config.MYCRM_API_BASE;
export const DIRECT_PURCHASE_COST_API_BASE = config.PURCHASE_COST_API_BASE;
export const DIRECT_PURCHASE_COST_CLIENT_ID = config.PURCHASE_COST_CLIENT_ID;
export const DIRECT_PURCHASE_COST_API_QUERY = config.PURCHASE_COST_API_QUERY;
export const DIRECT_DATA_COLLECTION_API_PATH = `${config.MYCRM_API_BASE}/lm-data-collection`;
export const DIRECT_IDENTITY_VERIFICATION_API_PATH = `${config.MYCRM_API_BASE}/lm-identity-verifications`;
export const DIRECT_ACTION_TOKEN_API_PATH = `${config.MYCRM_API_BASE}/actiontoken`;
export const DIRECT_ADVISORS_API_PATH = `${config.MYCRM_API_BASE}/lm-advisers`;
export const DIRECT_ADVISER_ORG_PATH = `${config.MYCRM_API_BASE}/lm-adviser-org`;
export const DIRECT_ENTITIES_API_PATH = `${config.MYCRM_API_BASE}/lm-entities`;
export const DIRECT_ASSETS_API_PATH = `${config.MYCRM_API_BASE}/lm-assets`;
export const DIRECT_CLIENTS_API_PATH = `${config.MYCRM_API_BASE}/lm-clients`;
export const DIRECT_USER_ACCOUNT_API_PATH = `${config.MYCRM_API_BASE}/user-account`;
export const DIRECT_SELECT_OPTIONS_API_PATH = `${config.MYCRM_API_BASE}/SelectOptions`;
export const DIRECT_OPTIONS_API_PATH = `${config.MYCRM_API_BASE}/options`;
export const DIRECT_GOOGLE_MAPS_API_PATH = `${config.MYCRM_API_BASE}/lm-google-maps`;
export const DIRECT_LENDERS_API_PATH = `${config.MYCRM_API_BASE}/lm-lenders`;
export const DIRECT_LENDER_API_PATH = `${config.MYCRM_API_BASE}/lender`;
export const DIRECT_PRODUCTS_API_PATH = `${config.MYCRM_API_BASE}/lm-products`;
export const DIRECT_APPLICATIONS_API_PATH = `${config.MYCRM_API_BASE}/lm-applications`;
export const DIRECT_AGGREGATOR_API_PATH = `${config.MYCRM_API_BASE}/aggregator`;
export const DIRECT_FAMILIES_API_PATH = `${config.MYCRM_API_BASE}/lm-families`;
export const DIRECT_LOCALITIES_API_PATH = `${config.MYCRM_API_BASE}/lm-localities`;
export const DIRECT_CONTACTS_API_PATH = `${config.MYCRM_API_BASE}/contacts`;
export const DIRECT_CALCULATORS_API_PATH = `${config.MYCRM_API_BASE}/lm-calculators`;
export const DIRECT_LIABILITIES_API_PATH = `${config.MYCRM_API_BASE}/lm-liabilities`;
export const DIRECT_GOAL_SETTER_PATH = `${config.MYCRM_API_BASE}/goal-setter`;
export const DIRECT_PROPERTIES_API_PATH = `${config.MYCRM_API_BASE}/lm-properties`;
export const DIRECT_RAY_WHITE_API_PATH = `${config.MYCRM_API_BASE}/lm-ray-white`;
export const DIRECT_DOCUMENTS_API_PATH = `${config.MYCRM_API_BASE}/lm-documents`;
export const DIRECT_PAGE_API_PATH = `${config.MYCRM_API_BASE}/lm-pages`;
export const DIRECT_EXPENSES_API_PATH = `${config.MYCRM_API_BASE}/lm-expenses`;
export const DIRECT_EMPLOYMENTS_API_PATH = `${config.MYCRM_API_BASE}/lm-employments`;
export const DIRECT_EXPENSE_CATEGORY_TYPES_API_PATH = `${config.MYCRM_API_BASE}/lm-expenses`;
export const DIRECT_FUNDINGS_API_PATH = `${config.MYCRM_API_BASE}/lm-fundings`;
export const DIRECT_INCOMES_API_PATH = `${config.MYCRM_API_BASE}/lm-incomes`;
export const DIRECT_SERVICEABILITY_API_PATH = `${config.MYCRM_API_BASE}/lm-serviceability`;
export const DIRECT_GOAL_LOAN_APP_INFO_API_PATH = `${config.MYCRM_API_BASE}/lm-loan-scenario`;
export const DIRECT_FINANCIAL_GOAL_API_PATH = `${config.MYCRM_API_BASE}/financial-goal`;
export const DIRECT_LM_FINANCIAL_GOAL_API_PATH = `${config.MYCRM_API_BASE}/lm-financial-goal`;
export const DIRECT_LOAN_OBJECTIVES_API_PATH = `${config.MYCRM_API_BASE}/lm-loanapp-objectives`;
export const DIRECT_VERIFICATION_EXCHANGE_API_PATH = `${config.MYCRM_API_BASE}/lm-verification-exchange`;
