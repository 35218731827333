import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { intlShape, injectIntl, defineMessages } from 'react-intl';

import { residencePropertySummary } from 'lib/copyHelper';
import applyOwnPropsChecker from 'lib/applyOwnPropsChecker';
import { logEvent, EVENTS } from 'lib/amplitude';
import { PROPERTY_OWNED } from 'constants/options';
import { bindActionCreators } from 'redux';

import * as propertySelectors from 'selectors/propertySelectors';
import * as addressSelectors from 'selectors/addressSelectors';
import addressActions from 'actions/addressActions';

import applySection from 'hocs/applySection';
import { accordionPropTypes } from 'types/customPropTypes';
import ApplyItemContainer from 'components/ApplyItemContainer/ApplyItemContainer';
import EditableItem from 'components/EditableItem/EditableItem';
import Button from 'components/Button/Button';
import { RESIDENCE_SLUG } from 'constants/applyData';

const messages = defineMessages({
  title: {
    id: 'ResidenceApply.title',
    defaultMessage: 'Your Home',
  },
  titleDescription: {
    id: 'ResidenceApply.titleDescription',
    defaultMessage: 'Where do you live today?',
  },
  titleDescriptionCompleted: {
    id: 'ResidenceApply.titleDescriptionCompleted',
    defaultMessage:
      '{suburb, select, false {Where you live today} other {{suburb}}}',
  },
  headerDescription: {
    id: 'ResidenceApply.headerDescription',
    defaultMessage: 'Where do you live today?',
  },
  headerDescriptionCompleted: {
    id: 'ResidenceApply.headerDescriptionCompleted',
    defaultMessage:
      '{suburb, select, false {Where you live today} other {{suburb} is where you live today}}.',
  },
  popupTitle: {
    id: 'ResidenceApply.popupTitle',
    defaultMessage: 'Where you live today',
  },
  addResidenceProperty: {
    id: 'ResidenceApply.addResidenceProperty',
    defaultMessage: 'Add your home',
  },
  editableItemLabel: {
    id: 'ResidenceApply.editableItemLabel',
    defaultMessage: 'Where you live today',
  },
});

const furtherDecoration = (props) => {
  const {
    intl: { formatMessage },
    currentAddress,
    residenceEntity: residence,
    warningMessage,
  } = props;
  let postfix = '';
  let suburb = '';

  if (residence) {
    const propertyOwned = residence.owned === PROPERTY_OWNED;
    if (propertyOwned || currentAddress) {
      const addressSource = propertyOwned
        ? residence.address
        : currentAddress.address;
      suburb = addressSource.suburb || false;
      postfix = 'Completed';
    }
  }

  return {
    title: formatMessage(messages.title),
    headerDescription: formatMessage(messages[`headerDescription${postfix}`], {
      suburb,
    }),
    titleDescription: formatMessage(messages[`titleDescription${postfix}`], {
      suburb,
    }),
    warningMessage,
  };
};

const confirmEntities = (props) => {
  const { confirmCurrentHome, currentAddress, requestId } = props;
  confirmCurrentHome({
    id: requestId,
    currentAddress,
  });
};

class ResidenceApply extends Component {
  static displayName = 'ResidenceApply';
  static propTypes = {
    intl: intlShape.isRequired,
    accordionProps: PropTypes.shape(accordionPropTypes).isRequired,
    residenceEntity: PropTypes.object,
    currentAddress: PropTypes.object,
    urlPath: PropTypes.string.isRequired,
  };

  static forceVisibility = true;

  newResidencePropertyUrl = () =>
    `${this.props.urlPath}/residence/property/new`;

  editPropertyUrl = (id) => `${this.props.urlPath}/residence/property/${id}`;

  residenceSummary = (residence, currentAddress) => {
    const propertyOwned = residence.owned === PROPERTY_OWNED;
    if (!propertyOwned && !currentAddress) {
      return;
    }
    const addressSource = propertyOwned
      ? residence.address
      : currentAddress.address;
    const summary = residencePropertySummary(residence);

    return { rightLabel: addressSource.suburb, rightDescription: summary };
  };

  onAddResidenceProperty = () => {
    logEvent(EVENTS.ADD_PROPERTY, { section: RESIDENCE_SLUG });
  };

  renderInstructions = () => (
    <div key='residenceInstruction'>
      <p>
        Tell us about your current home. We want to understand where you live
        now and what your plans are.
      </p>
      <p>Let us know:</p>
      <ul>
        <li>If you own, rent, board, or live with parents</li>
        <li>What you intend to do with this property</li>
      </ul>
    </div>
  );

  render() {
    const {
      residenceEntity,
      intl: { formatMessage },
      accordionProps: { isLocked },
      currentAddress,
    } = this.props;
    const summary =
      residenceEntity && this.residenceSummary(residenceEntity, currentAddress);

    return (
      <div id='residenceApply'>
        {!residenceEntity ? (
          [
            this.renderInstructions(),
            !isLocked && (
              <Button
                url={this.newResidencePropertyUrl()}
                theme='applyNew'
                icon='sl-custom-house-2'
                key='residenceApply'
                onClick={this.onAddResidenceProperty}
              >
                {formatMessage(messages.addResidenceProperty)}
              </Button>
            ),
          ]
        ) : (
          <ApplyItemContainer>
            <EditableItem
              leftIcon='sl-custom-house-2'
              url={this.editPropertyUrl(residenceEntity.id)}
              leftLabel={formatMessage(messages.editableItemLabel)}
              {...summary}
            />
          </ApplyItemContainer>
        )}
      </div>
    );
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  onSubmit: () => {
    ownProps.onSubmit();
  },
});

const mapStateToProps = (state) => ({
  residenceEntity: propertySelectors.residenceEntity(state),
  currentAddress: addressSelectors.primaryCurrentAddress(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      confirmCurrentHome: addressActions.confirmCurrentHome,
    },
    dispatch,
  );

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
    applyOwnPropsChecker,
  )(
    applySection({
      iconName: 'sl-custom-house-2',
      furtherDecoration,
      confirmEntities,
    })(ResidenceApply),
  ),
);
