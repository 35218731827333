import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { push } from '@loan_market/react-router-redux-multi';

import ContentLayout from 'components/ContentLayout/ContentLayout';
import Button from 'components/Button/Button';
import Question from 'components/Question/Question';

import goalLoanApplicationActions from 'actions/goal/goalLoanApplicationActions';
import goalLoanAppInfoActions from 'actions/goal/goalLoanAppInfoActions';
import loanApplicationActions from 'actions/loanApplicationActions';

import * as applicationSelectors from 'selectors/applicationSelectors';
import * as goalLoanApplicationSelectors from 'selectors/goalLoanApplicationSelectors';
import * as goalLoanAppInfoSelectors from 'selectors/goalLoanAppInfoSelectors';
import * as clientSelectors from 'selectors/clientSelectors';

import {
  DISPLAY_OPTIONS,
  QUESTION_IDS,
} from 'shared/constants/goalLoanAppInfo';
import { SERVICES, TEAM } from 'shared/constants/goalDashboard';
import { renderQuestionContent, toSortedArray } from 'lib/goalSetterFormHelper';
import { toGoalTeam } from 'lib/pathHelper';
import { ERROR_MESSAGES, REQUIRED } from 'constants/validators';

import styles from '../GoalTeam/GoalTeam.css';

const {
  ADEQUATE_INSURANCE_FAMILY_YES_NO,
  ADEQUATE_INSURANCE_FAMILY_REASON,
  ADEQUATE_INSURANCE_HOME_YES_NO,
  ADEQUATE_INSURANCE_HOME_REASON,
} = QUESTION_IDS;

function GoalServices({
  questions,
  answers,
  updateHasVisited,
  active,
  saveLoanAppInfoAnswers,
  familyId,
  goTo,
  fetchQuestionsAndAnswers,
  metadata,
  updateLoanApplication,
}) {
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [savedAnswers, updateSavedAnswers] = useState({});
  const allAnswers = { ...answers, ...savedAnswers };

  useEffect(() => {
    updateHasVisited(TEAM);
    if (Object.keys(questions).length === 0) {
      fetchQuestionsAndAnswers({ familyId, loanAppId: active });
    }
  }, []);

  const setAnswer = (questionId, value) => {
    updateSavedAnswers({
      ...savedAnswers,
      [questionId]: value,
    });
  };

  const saveMetadata = () => {
    if (!metadata?.saved || !metadata?.saved[SERVICES]) {
      updateLoanApplication({
        id: active,
        metadata: {
          ...metadata,
          saved: { ...metadata.saved, [SERVICES]: true },
        },
      });
    }
  };

  const onSave = (isBack) => () => {
    setHasSubmitted(true);
    if (
      questions.every(
        (q) => q.validations.includes('required') && allAnswers[q.questionId],
      )
    ) {
      if (Object.keys(savedAnswers).length > 0) {
        saveLoanAppInfoAnswers({
          section: SERVICES,
          answers: savedAnswers,
          loanAppId: active,
          familyId,
        });
      }
      saveMetadata();
      if (!isBack) {
        goTo(toGoalTeam(active));
      }
    }
  };

  const showSubQ = (questionId) => {
    const allAnswers = { ...answers, ...savedAnswers };
    switch (questionId) {
      case ADEQUATE_INSURANCE_FAMILY_REASON:
        return allAnswers[ADEQUATE_INSURANCE_FAMILY_YES_NO] !== undefined;
      case ADEQUATE_INSURANCE_HOME_REASON:
        return allAnswers[ADEQUATE_INSURANCE_HOME_YES_NO] !== undefined;
      default:
        return true;
    }
  };

  const renderQuestion = (q) => {
    if (!showSubQ(q.questionId)) {
      return null;
    }
    const isRequired = q.validations.includes('required');
    const isSingleLineText =
      q.customDisplayTypeId === DISPLAY_OPTIONS.SINGLE_LINE_TEXT;
    return (
      <Question
        id={`${q.questionId}`}
        key={`${q.questionId}`}
        label={q.content}
        showLabel={!isSingleLineText}
        hideTopLine={isSingleLineText}
        error={isRequired ? ERROR_MESSAGES[REQUIRED] : undefined}
        showError={hasSubmitted && isRequired && !allAnswers[q.questionId]}
      >
        {renderQuestionContent(q, allAnswers, setAnswer)}
      </Question>
    );
  };

  return (
    <ContentLayout
      title={'Do you have adequate insurance cover?'}
      theme='noContentBg'
      saveAction={() => {}}
    >
      <div className={styles.root}>
        {questions.map((q) => (
          <>
            {renderQuestion(q)}
            {q.subQuestions.map(renderQuestion)}
          </>
        ))}
      </div>
      <Button
        onClick={onSave()}
        className='brandColor__button'
        theme='buttonNext'
      >
        Save and Next
        <i className='mi-arrow-with-circle-right' />
      </Button>
    </ContentLayout>
  );
}

GoalServices.propTypes = {
  active: PropTypes.number,
  goTo: PropTypes.func,
  updateHasVisited: PropTypes.func,
  questions: PropTypes.array,
  answers: PropTypes.object,
  saveLoanAppInfoAnswers: PropTypes.func,
  familyId: PropTypes.number,
  fetchQuestionsAndAnswers: PropTypes.func,
  updateLoanApplication: PropTypes.func,
  metadata: PropTypes.object,
};

const mapStateToProps = (state) => {
  const family = clientSelectors.primaryFamily(state);
  const sortedQuestions = toSortedArray(
    goalLoanAppInfoSelectors.questionsForSection(state)(SERVICES),
  );
  return {
    active: goalLoanApplicationSelectors.loanApplicationId(state),
    questions: sortedQuestions,
    answers: goalLoanAppInfoSelectors.answersForSection(state)(
      family.contactId,
      SERVICES,
    ),
    familyId: family.contactId,
    metadata: applicationSelectors.workingApplicationMetadata(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goTo: push,
      updateHasVisited: goalLoanApplicationActions.updateHasVisited,
      saveLoanAppInfoAnswers: goalLoanAppInfoActions.saveLoanAppInfoAnswers,
      fetchQuestionsAndAnswers:
        loanApplicationActions.requestGoalSetterQuestionsAndAnswers,
      updateLoanApplication: loanApplicationActions.updateLoanApplication,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(GoalServices);
