const COLORS = {
  red: '#d64627',
  mediumGrey: '#9b9b9b',
  darkGrey: '#7e939f',
  darkerGrey: '#4A4A4A',
};

const FONT_SIZES = {
  smallerFontSize: '0.625em',
};

exports.COLOR_LIST = COLORS;
exports.FONT_SIZES = FONT_SIZES;
