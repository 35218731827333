import { TRANSACTION_PURCHASING } from 'shared/constants/myCRMTypes/property';

export function propertyBuilderForMyCRM(property) {
  return {
    scenario_id: property.applicationId,
    property_id: property.id,
    property_type_id: property.propertyTypeId,
    value: property.value,
    future_value: property.futureValue,
    zoning_id: property.zoningId,
    status_id: property.statusId,
    suburb: property.suburb,
    state: property.state,
    postcode: property.postcode,
    country: property.country,
    primary_purpose_id: property.primaryPurposeId,
    is_preapproved: property.isPreapproved,
    transaction_id: property.transactionId || TRANSACTION_PURCHASING,
    client_ids: property.clientIds,
  };
}
