import axiosRetry, {
  isNetworkError,
  isIdempotentRequestError,
} from 'axios-retry';
import { isNetworkOffline } from 'lib/errorHelper';

const AXIOS_RETRY_COUNT = 3;

const retryCondition = (error) => {
  const { response, request } = error;
  const { status } = response || {};

  const isStatusForRetry = status > 500 && status < 505;

  const isGetRequestStatusforRetry =
    request.method === 'GET' && status >= 400 && status < 599;

  return (
    isNetworkOffline(error) ||
    isNetworkError(error) ||
    (isIdempotentRequestError(error) && isStatusForRetry) ||
    isGetRequestStatusforRetry
  );
};

const retryConfig = {
  retries: AXIOS_RETRY_COUNT,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition,
};

export { axiosRetry, retryConfig };
