import { assetBuilderForMilli, assetInfoBuilderForMilli } from './asset';
import { PROPERTY_OWNED } from 'shared/constants/options';
import { PRIMARY_PURPOSE_OWNER_OCCUPIED } from 'shared/constants/myCRMTypes/property';
import { mapAddressObjectFromPlaceDetail } from 'shared/lib/localityMapperHelper';

export function realEstateAssetBuilderForMilli(asset) {
  const address = asset.address || {};
  const builtAddress = {
    formattedAddress: address.formatted_address || asset.estate_address,
    countryCode: address.countryCodeInTwoLetter || address.CountryCode,
    state: address.stateShort_Name,
    streetNumber: address.street_number,
    streetName: address.route,
    suburb: address.locality,
  };
  return {
    ...assetBuilderForMilli(asset),
    propertyId: asset.property_id,
    generateRentalIncome: asset.rental_income,
    existingMortgages: asset.existing_mortgages,
    /* yes below is temporary solution until API is fixed */
    residence: asset.primary_purpose_id
      ? asset.primary_purpose_id === 1
      : asset.primary_purpose === 1,
    owned: PROPERTY_OWNED,
    zoningId: asset.zoning_id,
    propertyTypeId: asset.property_type_id,
    useAsSecurity: asset.security_bond,
    address: builtAddress,
  };
}

export function realEstateAssetInfoBuilderForMilli(asset, contactIds) {
  return {
    ...assetInfoBuilderForMilli(asset),
    generateRentalIncome: asset.IsRentalIncome,
    existingMortgages: asset.IsExistingMortgages,
    residence: asset.PrimaryPurposeId === PRIMARY_PURPOSE_OWNER_OCCUPIED.id,
    owned: PROPERTY_OWNED,
    zoningId: asset.ZoningId,
    propertyId: asset.PropertyId,
    propertyTypeId: asset.PropertyTypeId,
    useAsSecurity: asset.IsSecurity,
    address: mapAddressObjectFromPlaceDetail(asset.Address),
    contactIds,
  };
}
