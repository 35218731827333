import MoneybagSvg from 'assets/inbetweeners/moneybag.svg';
import CalculatorSvg from 'assets/inbetweeners/calculator.svg';
import Calculator3Svg from 'assets/inbetweeners/calculator_3.svg';
import CreditCardSvg from 'assets/inbetweeners/credit_card.svg';
import NotepadSvg from 'assets/inbetweeners/notepad.svg';
import CashAndCoinSvg from 'assets/inbetweeners/cash_and_coins.svg';
import BuildingSvg from 'assets/inbetweeners/building.svg';
import CashflowSvg from 'assets/inbetweeners/cash_flow.svg';
// import CoinsNotesSvg from 'assets/inbetweeners/sl-money.svg';
// import ScaleSvg from 'assets/inbetweeners/sl-scale.svg';
import CrossroadsSvg from 'assets/inbetweeners/crossroads.svg';
import HouseSvg from 'assets/inbetweeners/house.svg';
import MobilePhoneSvg from 'assets/inbetweeners/mobile_phone.svg';
import MobileTextSvg from 'assets/inbetweeners/mobile_text.svg';
import EmailTextSvg from 'assets/inbetweeners/email_sent.svg';
import UploadDocSvg from 'assets/inbetweeners/upload_doc.svg';
import PercentSvg from 'assets/inbetweeners/percent_4.svg';
import ChecklistSvg from 'assets/inbetweeners/check_list.svg';
import TransferSvg from 'assets/inbetweeners/transfer_3.svg';
import ClipboadAddSvg from 'assets/inbetweeners/clipboard_add.svg';
import BubbleConversationSvg from 'assets/inbetweeners/bubble_conversation_2.svg';
import EditSvg from 'assets/inbetweeners/edit_3.svg';
import ChatSvg from 'assets/inbetweeners/chat_1_1.svg';
import PlanePaperSvg from 'assets/inbetweeners/plane_paper_1.svg';
import WatchSvg from 'assets/inbetweeners/watch_2.svg';
import FilesSvg from 'assets/inbetweeners/files_1.svg';
import LockSvg from 'assets/inbetweeners/lock_2.svg';
import ReceiptSvg from 'assets/inbetweeners/receipt_4.svg';
import MagnifierSvg from 'assets/inbetweeners/magnifier.svg';
import InfoSvg from 'assets/inbetweeners/info.svg';
import BinocularSvg from 'assets/inbetweeners/binoculars.svg';
import PhoneSVG from 'assets/inbetweeners/phone.svg';
import CardSVG from 'assets/inbetweeners/card.svg';
import FlagSVG from 'assets/inbetweeners/flag.svg';
import Id5SVG from 'assets/inbetweeners/id_5.svg';
import ArrowSvg from 'assets/inbetweeners/arrow.svg';

export const SIMPLE_IMAGE_MESSAGE_DATA = {
  '/calculator': {
    id: 'Calculator',
    image: CalculatorSvg,
    heading: 'Calculating how much you’ll need to borrow.',
    checklist: ['Purchase costs', 'Stamp duty', 'Contributions'],
  },
  '/calculator-nz': {
    id: 'Calculator',
    image: CalculatorSvg,
    heading: 'Calculating how much you’ll need to borrow.',
    checklist: ['Purchase price', 'Other costs', 'Contributions'],
  },
  '/calculate-proceeds-of-sale': {
    id: 'CalculateProceedsOfSale',
    heading: 'Calculating proceeds of sale',
    image: CalculatorSvg,
    checklist: ['Outstanding mortgage', 'Agent fees', 'Other costs'],
  },
  '/getting-approved': {
    id: 'GettingApproved',
    image: NotepadSvg,
    heading: 'Getting approved for a home loan just got easier',
    checklist: ['Find the right loan', 'Apply online', 'Get pre-approved'],
  },
  '/rent-and-buy-new': {
    id: 'RentAndBuy',
    image: CashAndCoinSvg,
    heading: 'Let’s see what happens if you rent out your current home.',
  },
  '/first-how-much': {
    id: 'FirstHowMuch',
    image: MoneybagSvg,
    heading: 'Let’s work out how much you’ll need to borrow.',
    imageSize: 'tall',
  },
  '/first-home-grant': {
    id: 'FirstHomeGrant',
    image: HouseSvg,
    heading: 'Now we’ll look at the first home owner’s grant.',
  },
  '/more-detail': {
    id: 'MoreDetails',
    image: CrossroadsSvg,
    heading: 'Let’s look at things in more detail.',
    imageAnimationType: 'sync',
  },
  '/save-draft': {
    id: 'SavingDraft',
    image: UploadDocSvg,
    heading: 'Saving your profile.',
    checklist: ['Updating profile', 'Continue any time you like'],
  },
  '/investment-cashflow': {
    id: 'InvestmentCashflow',
    image: CalculatorSvg,
    heading: 'Calculating investment cashflow',
    checklist: [
      'Rental income',
      'Property management costs',
      'Interest payments',
    ],
  },
  '/refinancing-apply': {
    id: 'RefinancingApply',
    image: BuildingSvg,
    heading: 'Next, let’s talk about the property you’re refinancing.',
    imageAnimationType: 'sync',
  },
  '/prospective-property': {
    id: 'ProspectiveProperty',
    image: BuildingSvg,
    heading: 'Next, let’s talk about the property you’re looking to buy.',
    imageAnimationType: 'sync',
  },
  '/quick-look-at-cash-flow': {
    id: 'QuickLookAtCashFlow',
    image: CashflowSvg,
    heading: 'Now let’s take a quick look at cash flow.',
    imageAnimationType: 'sync',
    delay: 250,
  },
  '/do-you-have-credit-cards': {
    id: 'DoYouHaveCreditCards',
    image: CreditCardSvg,
    heading: 'Next let’s take a look at any credit cards or existing loans.',
  },
  '/create-your-profile': {
    id: 'CreateYourProfile',
    image: ChatSvg,
    heading: 'Create your profile, research online and test for pre-approval!',
  },
  '/create-your-profile-from-calculator': {
    id: 'CreateYourProfileFromCalculator',
    image: Id5SVG,
    heading: 'Create your profile & apply for pre-approval today!',
  },
  '/capture-mobile': {
    id: 'CaptureMobile',
    image: MobilePhoneSvg,
    heading: 'Next, secure your profile using your mobile number.',
    imageAnimationType: 'sync',
    alwaysShowImage: false,
    imageSize: 'tall',
  },
  '/best-number-to-call': {
    id: 'BestNumberToCall',
    image: MobilePhoneSvg,
    heading: `What's the best number to call you on?`,
    imageAnimationType: 'sync',
    alwaysShowImage: false,
    imageSize: 'tall',
  },
  '/sending-sms': {
    id: 'SendingSMS',
    image: MobileTextSvg,
    heading: 'We’ve sent you an SMS security code to validate your number.',
    imageAnimationType: 'sync',
    imageSize: 'large',
    noButton: true,
    alwaysShowImage: true,
    headerTimeMs: 1800,
  },
  '/sending-email-token': {
    id: 'SendingEmail',
    image: EmailTextSvg,
    heading: 'We’ve sent a security code to your email address',
    imageAnimationType: 'sync',
    alwaysShowImage: true,
    noButton: true,
    headerTimeMs: 1800,
  },
  '/applying-online': {
    id: 'ApplyingOnline',
    image: ChatSvg,
    heading: 'Your profile is now activated and secure.',
    alwaysShowImage: true,
  },
  '/select-a-loan': {
    id: 'SelectALoan',
    image: PercentSvg,
    heading: 'Let’s start by selecting a loan for pre-approval.',
  },
  '/lots-to-choose-from': {
    id: 'LotsToChooseFrom',
    image: ChecklistSvg,
    heading: 'There’s lots to choose from. Just select one for now.',
  },
  '/change-your-mind': {
    id: 'ChangeYourMind',
    image: TransferSvg,
    heading: 'You can change your mind at any time.',
    primaryButtonText: 'Let’s go',
  },
  '/loan-added': {
    id: 'LoanAdded',
    image: ClipboadAddSvg,
    heading: 'Nice. We’ve added that to your profile.',
  },
  '/expect-a-call': {
    id: 'ExpectACall',
    image: BubbleConversationSvg,
    heading: `We will be in touch once we have reviewed your details. Expect a call soon.`,
    alwaysShowImage: true,
  },
  '/complete-profile': {
    id: 'CompleteProfile',
    image: EditSvg,
    heading: 'Let’s complete your profile.',
    alwaysShowImage: true,
  },
  '/complete-as-much-as-you-can': {
    id: 'CompleteAsMuchAsYouCan',
    image: EditSvg,
    heading: 'Please complete as much detail as you can...',
    alwaysShowImage: true,
  },
  '/we-just-need-more-information': {
    id: 'NeedMoreInformation',
    image: EditSvg,
    heading: 'We just need a bit more information',
    alwaysShowImage: true,
  },
  '/:color?/send-for-review': {
    id: 'SendingYourProfile',
    image: PlanePaperSvg,
    heading: 'Sending your profile for review.',
    alwaysShowImage: true,
  },
  /* we might use these copies again when bank connect feature for all financial info is up? */
  // '/few-questions-to-go': {
  //   id: 'FewQuestionsToGo',
  //   image: ScaleSvg,
  //   heading: 'There are a few questions to go and then we’ll test your profile.',
  // },
  // '/well-ask-about': {
  //   id: 'WellAskAbout',
  //   image: CoinsNotesSvg,
  //   heading: 'We’ll ask about your finances. Income, expenses, etc.',
  // },
  // '/use-bank-connect': {
  //   id: 'UseBankConnect',
  //   image: WatchSvg,
  //   heading: 'Connect with your bank and complete your financial info in seconds.',
  //   alwaysShowImage: true,
  // },
  '/download-bank-statements': {
    id: 'DownloadBankStatements',
    image: FilesSvg,
    heading: 'Download all your bank statements in one go.',
    alwaysShowImage: true,
  },
  '/login-remains-private': {
    id: 'LoginRemainsPrivate',
    image: LockSvg,
    heading: 'Your login and account details remain private.',
    alwaysShowImage: true,
  },
  '/grab-bank-statements': {
    id: 'GrabBankStatements',
    image: WatchSvg,
    heading: 'Grab bank statements the easy way by connecting with your bank.',
    alwaysShowImage: true,
  },
  '/lets-connect-bank': {
    id: 'LetsConnectBank',
    image: ReceiptSvg,
    heading:
      'Let’s connect with your bank and look at your transactions for the last 6 months.',
    alwaysShowImage: true,
  },
  '/run-the-numbers': {
    id: 'RunTheNumbers',
    image: Calculator3Svg,
    heading:
      'We’ll run the numbers and give you an idea of what you really spend!',
    alwaysShowImage: true,
  },
  '/looked-at-transactions': {
    id: 'LookedAtTransactions',
    image: ChatSvg,
    heading: 'We’ve looked at your transactions and logged you out.',
    alwaysShowImage: true,
    primaryButtonText: 'Ok',
  },
  '/also-statements': {
    id: 'AlsoStatements',
    image: UploadDocSvg,
    heading:
      'We’ve also saved bank statements for these accounts in your profile.',
    alwaysShowImage: true,
    primaryButtonText: 'Got it',
  },
  '/complete-profile-pre-approval': {
    id: 'CompleteYourProfileForPreApproval',
    image: CardSVG,
    heading: 'Complete your profile, apply for pre-approval today.',
  },
  '/easy-ten-minutes': {
    id: 'EasyTenMinutes',
    image: ChatSvg,
    heading: 'It’s easy and will take about 10 mins.',
  },
  '/customer-care-will-answer-questions': {
    id: 'CustomerCareAnswerQuestions',
    image: PhoneSVG,
    heading: 'One of our experts will call you to answer any questions.',
  },
  '/customer-care-expect-a-call': {
    id: 'CustomerCareExpectACall',
    image: PhoneSVG,
    heading: 'Thanks! We’ll answer your question ASAP. Expect a call soon.',
  },
  '/in-case-you-need-help': {
    id: 'InCaseYouNeedHelp',
    image: InfoSvg,
    heading: 'But in case you need extra help, we have someone ready.',
  },
  '/we-understand-research': {
    id: 'WeUnderstandResearch',
    image: MagnifierSvg,
    heading: 'We understand you are just researching.',
  },
  '/continue-your-research': {
    id: 'ContinueYourResearch',
    image: BinocularSvg,
    heading: 'In the meantime, continue your research online.',
  },
  '/we-help-you-get-there': {
    id: 'WeHelpYouGetThere',
    image: FlagSVG,
    heading: 'Whatever your goals, we can help you get there.',
  },
  '/check-out-online-tools': {
    id: 'CheckOutOnlineTools',
    image: BinocularSvg,
    heading: 'In the meantime, check out our online tools.',
  },
  '/fast-pre-approval': {
    id: 'FastPreApproval',
    image: ArrowSvg,
    heading:
      'Complete your profile so we can find the right loan and get you pre-approved fast!',
  },
  '/compare-rates': {
    id: 'CompareRates',
    image: MagnifierSvg,
    heading: 'Compare rates, see your borrowing capacity and more.',
  },
  '/email-verification-success': {
    id: 'EmailVerificationSuccess',
    image: ChatSvg,
    heading: 'Thank you for verifying your email address!',
    primaryButtonText: 'Go to My Profile',
  },
  '/check-mobile-phone': {
    id: 'CheckMobilePhone',
    image: MobilePhoneSvg,
    heading: 'Check your mobile phone. We sent a security code.',
    primaryButtonText: 'Ok',
    alwaysShowImage: false,
    imageSize: 'tall',
  },
};

export const SIMPLE_HEADER_DATA = {
  '/thats-exciting': { message: 'That’s exciting!', timeToLiveMs: 1700 },
  '/password-saved': { message: 'Password saved!', timeToLiveMs: 1500 },
  '/got-it': { message: 'Got it.', timeToLiveMs: 1500 },
  '/ok': { message: 'Ok.', timeToLiveMs: 1250 },
  '/ok-emphasis': { message: 'Ok!', timeToLiveMs: 1250 },
  '/great': { message: 'Great.', timeToLiveMs: 1500 },
  '/great-emphasis': { message: 'Great!', timeToLiveMs: 1500 },
  '/alright': { message: 'Alright.', timeToLiveMs: 1500 },
  '/easy': { message: 'Easy!', timeToLiveMs: 1500 },
  '/thanks': { message: 'Thanks.', timeToLiveMs: 1500 },
  '/nice': { message: 'Nice!', timeToLiveMs: 1500 },
  '/grab-your-phone': { message: 'Check your phone.', timeToLiveMs: 1700 },
  '/grab-your-email': { message: 'Check your email.', timeToLiveMs: 1700 },
  '/enter-password': {
    message: 'Great! Let’s start by choosing a password.',
    timeToLiveMs: 1700,
  },
  '/almost-there': {
    message: 'Great. You’re almost there!',
    timeToLiveMs: 1700,
  },
  '/adding-loan': {
    message: 'Adding that loan to your profile.',
    timeToLiveMs: 1700,
  },
  '/kindly-login': {
    message: 'Kindly log in with your email and password.',
    timeToLiveMs: 2000,
  },
  '/welcome-back-from-shared': {
    message: 'Welcome back! Let’s log in to your profile.',
    timeToLiveMs: 2000,
  },
  '/welcome-from-compare': {
    message: 'Welcome to Loan Market. We’ll get you pre-approved in a giffy.',
    timeToLiveMs: 2200,
  },
  '/got-it-excited': { message: 'Got it!', timeToLiveMs: 1500 },
  '/password-saved-to-dashboard': {
    message: 'Password saved! Redirecting to your dashboard...',
    timeToLiveMs: 2000,
  },
};
