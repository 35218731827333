export function contactApplicationsBuilderForMilli(
  applications,
  clientsAppIds,
) {
  const validLoans = ['InProgress', 'Opportunity'];
  return applications
    ? applications
        .filter(
          (a) =>
            a.LoanStatus &&
            validLoans.includes(a.LoanStatus.LoanStatusCategory) &&
            clientsAppIds.includes(a.LoanScenarioID),
        )
        .map((a) => ({
          id: a.LoanScenarioID,
          clientName:
            a.Borrower && a.Borrower.length
              ? a.Borrower.map((borrower) => borrower.FirstName).join(', ')
              : '',
          purpose: a.LoanPurpose ? a.LoanPurpose.trim() : a.LoanPurpose,
          category: a.LendingCategoryName || a.Lenders_Category,
          isOpportunity: a.IsOpportunity,
        }))
    : [];
}
