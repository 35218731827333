import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { intlShape, injectIntl } from 'react-intl';

import propertyActions from 'actions/applyPropertyActions';
import addressActions from 'actions/addressActions';
import incomeActions from 'actions/incomeActions';
import liabilityActions from 'actions/liabilityActions';
import UIActions from 'actions/UIActions';
import { bindIndividualActions } from 'lib/actionHelpers';

import * as propertySelectors from 'selectors/propertySelectors';
import * as addressSelectors from 'selectors/addressSelectors';
import * as clientSelectors from 'selectors/clientSelectors';
import * as incomeSelectors from 'selectors/incomeSelectors';
import * as liabilitySelectors from 'selectors/liabilitySelectors';
import * as UISelectors from 'selectors/UISelectors';
import * as applicationSelectors from 'selectors/applicationSelectors';
import * as incomeTypeSelectors from 'selectors/incomeTypeSelectors';

import { PROPERTY_PURCHASING } from 'shared/constants/options';

import FormPopup, { formPopupProps } from 'components/PopupItem/FormPopup';
import PropertyForm from 'components/PopupForms/PropertyForm';

export class PropertyPopup extends FormPopup {
  static propTypes = {
    ...formPopupProps,
    intl: intlShape.isRequired,
    setPropertyIntlAddress: PropTypes.func.isRequired,
    setIntlAddress: PropTypes.func.isRequired,
  };

  onLoadNew() {
    const {
      match: {
        params: { section },
      },
      loadResidenceProperty,
      loadProspectiveProperty,
    } = this.props;
    if (section === 'residence') {
      loadResidenceProperty();
    } else if (section === 'to-purchase') {
      loadProspectiveProperty();
    }
  }

  title() {
    const { working } = this.props;
    const formStateTitle = super.title();
    if (formStateTitle) {
      return `${formStateTitle} property`;
    }
    return working.id ? 'Edit your property' : 'Add a property';
  }

  value = () => undefined;

  call = (b) => (a) => a(b);

  mapActionsWithId = (actions, id) => _.mapValues(actions, this.call(id));

  isLoaded() {
    const {
      id,
      match: {
        params: { section },
      },
      working,
      workingAddress,
      primaryApplicant,
      clientIdOwnershipOptions,
    } = this.props;
    if (!clientIdOwnershipOptions || clientIdOwnershipOptions.length === 0) {
      return false;
    }

    let loaded = !!primaryApplicant && !!working;
    if (id === 'new') {
      if (section === 'residence') {
        loaded = loaded && working.residence;
      } else if (section === 'to-purchase') {
        loaded = loaded && working.owned === PROPERTY_PURCHASING;
      }
    } else if (section === 'residence') {
      loaded = loaded && !!workingAddress;
    }
    return loaded;
  }

  setIncomeCategoryAndType = (value) => {
    const { incomeTypes, boundIncomeActions, workingIncome } = this.props;
    const { setIncomeCategory } = this.mapActionsWithId(
      boundIncomeActions,
      workingIncome.id || 'new',
    );
    setIncomeCategory({
      category: value,
      incomeTypes,
    });
  };

  renderForm() {
    const {
      id,
      isLocked,
      working,
      workingAddress,
      workingIncome,
      workingLiability,
      clientIdOwnershipOptions,
      boundPropertyActions,
      boundIncomeActions,
      boundLiabilityActions,
      boundAddressActions,
      setPropertyIntlAddress,
      setIntlAddress,
    } = this.props;

    return (
      <PropertyForm
        key={`propertyPopupForm-${this.state.refreshKey}`}
        isLocked={isLocked}
        working={working}
        workingAddress={workingAddress}
        workingIncome={workingIncome}
        workingLiability={workingLiability}
        clientIdOwnershipOptions={clientIdOwnershipOptions}
        {...this.mapActionsWithId(boundPropertyActions, id)}
        {...this.mapActionsWithId(
          boundIncomeActions,
          workingIncome.id || 'new',
        )}
        {...this.mapActionsWithId(
          boundLiabilityActions,
          workingLiability.id || 'new',
        )}
        {...this.mapActionsWithId(
          boundAddressActions,
          (workingAddress && workingAddress.id) || 'new',
        )}
        setIncomeCategory={this.setIncomeCategoryAndType}
        setPropertyIntlAddress={setPropertyIntlAddress}
        setIntlAddress={setIntlAddress}
        save={this.save}
        remove={this.remove}
        saveAndAddAnother={this.saveAndAddAnotherWithDefaults}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const paramId = ownProps.match.params.id;
  const id = parseInt(paramId, 10) || paramId || 'new';

  return {
    id,
    isLocked: applicationSelectors.getIsLocked(state),
    item: propertySelectors.entityById(state)(id),
    working: state.property.working[id],
    workingAddress: addressSelectors.workingPrimaryCurrentAddress(state),
    workingLiability: liabilitySelectors.workingLiabilityByAssetId(state)(id),
    workingIncome: incomeSelectors.workingIncomeByAssetOrPropertyId(state)(id),
    clientIdOwnershipOptions: clientSelectors.clientIdOptionsSharable(state),
    primaryApplicant: clientSelectors.primaryApplicant(state),
    requestIsProcessing: UISelectors.requestProcessing(state)(id),
    errors: UISelectors.requestErrors(state),
    incomeTypes: incomeTypeSelectors.incomeTypes(state),
    formPopUpStatus: UISelectors.formPopUpStatus(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators(
      {
        load: propertyActions.loadProperty,
        loadResidenceProperty: propertyActions.loadResidenceProperty,
        loadProspectiveProperty: propertyActions.loadProspectiveProperty,
        create: propertyActions.createProperty,
        update: propertyActions.updateProperty,
        delete: propertyActions.deleteProperty,
        clearWorking: propertyActions.clearWorkingProperty,
        clearErrors: UIActions.clearAsyncRequestErrors,
        resetFormPopupStatus: UIActions.resetFormPopupStatus,
        setPropertyIntlAddress: propertyActions.setPropertyIntlAddress,
        setIntlAddress: addressActions.setIntlAddress,
      },
      dispatch,
    ),
    boundPropertyActions: bindIndividualActions(
      {
        setApplyPropertyClientIds: propertyActions.setApplyPropertyClientIds,
        setApplyPropertyOwned: propertyActions.setApplyPropertyOwned,
        setApplyPropertyDescription:
          propertyActions.setApplyPropertyDescription,
        setApplyPropertyZoningId: propertyActions.setApplyPropertyZoningId,
        setApplyPropertyStatusId: propertyActions.setApplyPropertyStatusId,
        setApplyPropertyPropertyTypeId:
          propertyActions.setApplyPropertyPropertyTypeId,
        setApplyPropertyState: propertyActions.setApplyPropertyState,
        setApplyPropertyAddress: propertyActions.setApplyPropertyAddress,
        setApplyPropertyLocality: propertyActions.setApplyPropertyLocality,
        setApplyPropertyValue: propertyActions.setApplyPropertyValue,
        setApplyPropertyFutureValue:
          propertyActions.setApplyPropertyFutureValue,
        setApplyPropertyExistingMortgages:
          propertyActions.setApplyPropertyExistingMortgages,
        setApplyPropertyUseAsSecurity:
          propertyActions.setApplyPropertyUseAsSecurity,
        setApplyPropertyGenerateRentalIncome:
          propertyActions.setApplyPropertyGenerateRentalIncome,
        setApplyPropertyPrimaryPurposeId:
          propertyActions.setApplyPropertyPrimaryPurposeId,
        setApplyPropertyFoundProperty:
          propertyActions.setApplyPropertyFoundProperty,
        setApplyPropertyError: propertyActions.setApplyPropertyError,
      },
      dispatch,
    ),
    boundLiabilityActions: bindIndividualActions(
      {
        setLiabilityClientIds: liabilityActions.setLiabilityClientIds,
        setLiabilityCategory: liabilityActions.setLiabilityCategory,
        setLiabilityType: liabilityActions.setLiabilityType,
        setLiabilityCreditorId: liabilityActions.setLiabilityCreditorId,
        setLiabilityTotalLoanAmount:
          liabilityActions.setLiabilityTotalLoanAmount,
        setLiabilityOriginalLoanAmount:
          liabilityActions.setLiabilityOriginalLoanAmount,
        setLiabilityCurrentInterestRate:
          liabilityActions.setLiabilityCurrentInterestRate,
        setLiabilityRepaymentAmount:
          liabilityActions.setLiabilityRepaymentAmount,
        setLiabilityRepaymentFrequency:
          liabilityActions.setLiabilityRepaymentFrequency,
        setLiabilityIsInterestTaxDeductible:
          liabilityActions.setLiabilityIsInterestTaxDeductible,
        setLiabilityIsRefinance: liabilityActions.setLiabilityIsRefinance,
      },
      dispatch,
    ),
    boundIncomeActions: bindIndividualActions(
      {
        setIncomeClientIds: incomeActions.setIncomeClientIds,
        setIncomeType: incomeActions.setIncomeType,
        setIncomeValue: incomeActions.setIncomeValue,
        setIncomeFrequency: incomeActions.setIncomeFrequency,
        setIncomeCategory: incomeActions.setIncomeCategory,
      },
      dispatch,
    ),
    boundAddressActions: bindIndividualActions(
      {
        setAddressStartDateYear: addressActions.setAddressStartDateYear,
        setAddressStartDateMonth: addressActions.setAddressStartDateMonth,
        setAddressAddress: addressActions.setAddressAddress,
        setAddressTypeId: addressActions.setAddressTypeId,
        setAddressClientIds: addressActions.setAddressClientIds,
        setAddressOwnershipTypeId: addressActions.setAddressOwnershipTypeId,
      },
      dispatch,
    ),
  };
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(PropertyPopup),
);
