const APPLICATION_STATE = {
  SETTLED: 'Settled',
  NOT_PROCEEDED: 'Not Proceeded',
};

const STATUS = {
  NEW_APPLICATION: 'New Application',
  LODGED: 'Lodged',
  PRE_APPROVED: 'Pre-Approved',
  CONDITIONALLY_APPROVED: 'Conditionally Approved',
  UNCONDITIONALLY_APPROVED: 'Unconditionally Approved',
};

export function statusBuilderForMilli({
  category_id: categoryId,
  status_name: statusName,
  application_state_name: applicationStateName,
}) {
  if (applicationStateName === APPLICATION_STATE.SETTLED) {
    return 6;
  }
  if (
    categoryId === 1 ||
    applicationStateName === APPLICATION_STATE.NOT_PROCEEDED
  ) {
    return 1;
  } // The lead is in the lead pipeline
  switch (statusName) {
    case STATUS.NEW_APPLICATION:
      return 2; // lead is converted to application
    case STATUS.LODGED:
      return 3;
    case STATUS.PRE_APPROVED:
    case STATUS.CONDITIONALLY_APPROVED:
      return 4;
    case STATUS.UNCONDITIONALLY_APPROVED:
      return 5;
    default:
      throw new Error('MyCRM Status types don’t match Milli’s expected values');
  }
}
