export function lmiBuilderForMyCRM(lmi) {
  return {
    BrokerEventID: 0,
    Lenders: lmi.lender ? [lmi.lender] : [],
    IsSelfEmployed: false,
    NewLoanAmount: lmi.loanAmount,
    PredominantLoanPurpose: 'PurchaseOrConstruction',
    SecurityAmount: lmi.propertyValue,
    IsFirstHomeBuyer: lmi.isFirstHomeBuyer,
    StateName: lmi.state,
    LoanTerm: lmi.loanTerm,
    IsOwnerOccupied: lmi.ownerOccupied,
  };
}

function lmiBuilderForMilli(lmi) {
  return {
    value: lmi.LMIPremium,
    lenderId: lmi.LenderId,
    lenderName: lmi.LenderName,
    insurerName: lmi.InsurerName,
  };
}

export function convertToRange(lmiResults) {
  let total = 0;
  let count = 0;
  const range = lmiResults.reduce((p, c, i) => {
    if (!p.maximum || p.maximum.value < c.LMIPremium) {
      p.maximum = lmiBuilderForMilli(c);
    }
    if (!p.minimum || p.minimum.value > c.LMIPremium) {
      p.minimum = lmiBuilderForMilli(c);
    }
    if (c.LMIPremium) {
      total += c.LMIPremium;
      count++;
    }
    if (i === lmiResults.length - 1 && count > 0) {
      p.average = total / count;
    }
    return p;
  }, {});
  if (!range.average) {
    range.average = 0;
  }
  return range;
}
