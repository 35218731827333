export const SET_EMPLOYMENT_CLIENT_ID = 'setEmploymentClientId';
export const SET_EMPLOYMENT_TYPE_ID = 'setEmploymentTypeId';
export const SET_EMPLOYMENT_BASIS_ID = 'setEmploymentBasisId';
export const SET_EMPLOYMENT_STATUS_ID = 'setEmploymentStatusId';
export const SET_EMPLOYMENT_NATURE_ID = 'setEmploymentNatureId';
export const SET_EMPLOYMENT_ROLE = 'setEmploymentRole';
export const SET_EMPLOYMENT_NZBN = 'setEmploymentNZBN';
export const SET_EMPLOYMENT_DATE_STARTED_DAY = 'setEmploymentDateStartedDay';
export const SET_EMPLOYMENT_DATE_STARTED_MONTH =
  'setEmploymentDateStartedMonth';
export const SET_EMPLOYMENT_DATE_STARTED_YEAR = 'setEmploymentDateStartedYear';
export const SET_EMPLOYMENT_DATE_ENDED_DAY = 'setEmploymentDateEndedDay';
export const SET_EMPLOYMENT_DATE_ENDED_MONTH = 'setEmploymentDateEndedMonth';
export const SET_EMPLOYMENT_DATE_ENDED_YEAR = 'setEmploymentDateEndedYear';

export const SET_EMPLOYMENT_EMPLOYER_NAME = 'setEmploymentEmployerName';
export const SET_EMPLOYMENT_CONTACT_FIRST_NAME =
  'setEmploymentContactFirstName';
export const SET_EMPLOYMENT_CONTACT_LAST_NAME = 'setEmploymentContactLastName';
export const SET_EMPLOYMENT_EMPLOYER_ADDRESS = 'setEmploymentEmployerAddress';
export const SET_EMPLOYMENT_EMPLOYER_INTL_ADDRESS =
  'setEmploymentEmployerIntlAddress';
export const SET_EMPLOYMENT_EMPLOYER_PHONE = 'setEmploymentEmpoyerPhoneNumber';
export const SET_EMPLOYMENT_EMPLOYER_TYPE_ID = 'setEmploymentEmployerTypeId';
export const SET_EMPLOYMENT_IS_PROBATION = 'setEmploymentIsProbation';

export const SET_EMPLOYMENT_ERROR = 'setEmploymentError';
