import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';

import MobileInputIntl from 'components/MobileInputIntl/MobileInputIntl';
import locale from 'config/locale';
import { intlMobile } from 'shared/lib/utils';
import { featureFlags } from 'lib/rollout';

import clientActions from 'actions/clientActions';
import contactActions from 'actions/contactActions';
import addressActions from 'actions/addressActions';
import scenarioActions from 'actions/scenarioActions';

import * as clientSelectors from 'selectors/clientSelectors';

import {
  REQUIRED,
  MOBILE,
  MOBILE_INTERNATIONAL,
  EMAIL,
  DATE,
  IN_THE_PAST,
  NO_ERROR,
} from 'constants/validators';
import {
  GENDER_OPTIONS,
  GENDER_OPTIONS_WITH_UNDISCLOSED,
  YES_NO_OPTIONS,
  CLIENT_IS_DEPENDENT_OPTIONS,
  CONTACT_ROLE_OPTIONS,
  MARITAL_STATUS_OPTIONS,
  RESIDENCY_OPTIONS,
  COUNTRY_OPTIONS,
} from 'constants/options';

import {
  RESIDENCY_CITIZEN,
  RESIDENCY_RESIDENT,
} from 'shared/constants/myCRMTypes/clients';
import { formatMobile, unformatPhoneNumber } from 'lib/utils/numberUtils';
import { generateUniqueID } from 'lib/utils/stringUtils';
import { getMoreLessMessage } from 'lib/copyHelper';
import LocalStorageProxy from 'lib/localStorageProxy';
import { newClient } from 'reducers/clientReducer';

import manageQuestions, {
  manageQuestionsPropTypes,
} from 'hocs/manageQuestions';

import Form from 'components/Form/Form';
import Question from 'components/Question/Question';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import DateSelector from 'components/DateSelector/DateSelector';
import RadioButtonList from 'components/ButtonList/RadioButtonList';
import Selection from 'components/Selection/Selection';
import Accordion from 'components/Accordion/Accordion';
import AutocompleteAddress from 'components/AutocompleteInput/AutocompleteAddress';

import Questions from 'lib/Questions';

const messages = defineMessages({
  isDependent: {
    id: 'ClientForm.isDependent',
    defaultMessage: 'What type of family member?',
  },
  preferredName: {
    id: 'ClientForm.preferredName',
    defaultMessage: 'Preferred name',
  },
  previousName: {
    id: 'ClientForm.previousName',
    defaultMessage: 'Had a previous name? Tell us here:',
  },
  mothersMaidenName: {
    id: 'ClientForm.mothersMaidenName',
    defaultMessage: 'Mother’s maiden name',
  },
  preferredNameHint: {
    id: 'ClientForm.preferredNameHint',
    defaultMessage: 'Leave this blank, or enter a preferred name if you like',
  },
  previousNameHint: {
    id: 'ClientForm.previousNameHint',
    defaultMessage: 'Leave this blank, or enter a previous name if you like',
  },
  title: {
    id: 'ClientForm.title',
    defaultMessage: 'Title',
  },
  firstName: {
    id: 'ClientForm.firstName',
    defaultMessage: 'First name',
  },
  firstNameOptional: {
    id: 'ClientForm.firstNameOptional',
    defaultMessage: 'First name [optional]',
  },
  firstNameHint: {
    id: 'ClientForm.firstNameHint',
    defaultMessage: 'Enter { target } legal first name',
  },
  middleName: {
    id: 'ClientForm.middleName',
    defaultMessage: 'Middle name',
  },
  lastName: {
    id: 'ClientForm.lastName',
    defaultMessage: 'Last name',
  },
  lastNameHint: {
    id: 'ClientForm.lastNameHint',
    defaultMessage: 'Enter { target } legal last name',
  },
  homeAddress: {
    id: 'ClientForm.homeAddress',
    defaultMessage: 'Current home address',
  },
  defaultAddressText: {
    id: 'ClientForm.defaultAddressText',
    defaultMessage: 'Your current home address',
  },
  isAtPrimaryApplicantHomeAddress: {
    id: 'ClientForm.isAtPrimaryApplicantHomeAddress',
    defaultMessage: 'Does your partner also live at {address}?',
  },
  isSameAsHomeAddress: {
    id: 'ClientForm.isSameAsHomeAddress',
    defaultMessage: 'Is mailing address the same as home address?',
  },
  isPrimaryAtPartnerHomeAddress: {
    id: 'ClientForm.isPrimaryAtPartnerHomeAddress',
    defaultMessage: 'Do you also live at this address?',
  },
  mailAddress: {
    id: 'ClientForm.mailAddress',
    defaultMessage: 'Mailing address',
  },
  mobile: {
    id: 'ClientForm.mobile',
    defaultMessage: 'Mobile',
  },
  email: {
    id: 'ClientForm.email',
    defaultMessage: 'Email',
  },
  dob: {
    id: 'ClientForm.dob',
    defaultMessage: 'Date of birth',
  },
  dobOptional: {
    id: 'ClientForm.dobOptional',
    defaultMessage: 'Date of birth [optional]',
  },
  age: {
    id: 'ClientForm.age',
    defaultMessage: 'Age',
  },
  gender: {
    id: 'ClientForm.gender',
    defaultMessage: 'Gender',
  },
  isCitizenAndResident: {
    id: 'ClientForm.isCitizenAndResident',
    defaultMessage:
      '{ target } a citizen and permanent resident of { countryName }?',
  },
  citizenshipId: {
    id: 'ClientForm.citizenshipId',
    defaultMessage: 'What country { target } a citizen of?',
  },
  countryOfResidencyId: {
    id: 'ClientForm.countryOfResidencyId',
    defaultMessage: 'What country { target } a resident of?',
  },
  residencyId: {
    id: 'ClientForm.residencyId',
    defaultMessage: "What's { target } residency status?",
  },
  maritalStatusId: {
    id: 'ClientForm.maritalStatusId',
    defaultMessage: 'Marital status',
  },
  isCoapplicant: {
    id: 'ClientForm.isCoapplicant',
    defaultMessage: 'Will your partner be a co-applicant for this loan?',
  },
  role: {
    id: 'ClientForm.role',
    defaultMessage: 'What type of Co-Applicant?',
  },
  save: {
    id: 'ClientForm.save',
    defaultMessage: 'Save',
  },
  remove: {
    id: 'ClientForm.remove',
    defaultMessage: 'Remove',
  },
  ageLabel: {
    id: 'Age.years',
    defaultMessage: 'Years',
  },
  useToLogin: {
    id: 'ClientForm.useToLogin',
    defaultMessage: '[Use this to login to your profile]',
  },
});

export const propsTransformForQuestion = (props) => {
  if (!props.working) {
    return newClient;
  }
  const {
    working,
    workingCurrentAddress,
    workingMailAddress,
    primaryCurrentAddress,
    isSameAsHomeAddress,
    isAtPrimaryAddress,
    isPrimaryAtPartnerAddress,
  } = props;

  return {
    ...working,
    homeAddress:
      workingCurrentAddress && workingCurrentAddress.address.formattedAddress,
    mailAddress:
      workingMailAddress && workingMailAddress.address.formattedAddress,
    primaryCurrentAddress:
      primaryCurrentAddress && primaryCurrentAddress.address.formattedAddress,
    isSameAsHomeAddress,
    isAtPrimaryAddress,
    isPrimaryAtPartnerAddress,
    useContact: false,
  };
};

const residentOrCitizen = [RESIDENCY_RESIDENT.id, RESIDENCY_CITIZEN.id];

export const checkCitizenshipAndResidency = (client, localeCountryId) => {
  const isCitizenAndResident =
    client.citizenshipId &&
    parseInt(client.citizenshipId, 10) === localeCountryId &&
    client.isResident &&
    residentOrCitizen.includes(parseInt(client.residencyId, 10)) &&
    parseInt(client.countryOfResidencyId, 10) === locale.data.id;

  return !!isCitizenAndResident;
};

const checkIsResident = (countryOfResidencyId, residencyId) => {
  const isResident =
    parseInt(countryOfResidencyId, 10) === locale.data.id &&
    residentOrCitizen.includes(parseInt(residencyId, 10));

  return !!isResident;
};

const buildAddressQuestions = (client) => {
  const { primaryCurrentAddress, homeAddress } = client;
  const addressQuestions = [];
  if (client.isPartnerClient && primaryCurrentAddress) {
    addressQuestions.push('isAtPrimaryApplicantHomeAddress');
  }

  const showCurrentPartnerAddressQ =
    client.isPartnerClient &&
    (client.isAtPrimaryAddress === false || !primaryCurrentAddress);
  if (!client.isPartnerClient || showCurrentPartnerAddressQ) {
    addressQuestions.push(['homeAddress', REQUIRED]);
  }
  const showIsPrimaryAtPartnerAddressQ = !primaryCurrentAddress;
  if (
    client.isPartnerClient &&
    showIsPrimaryAtPartnerAddressQ &&
    !!homeAddress
  ) {
    addressQuestions.push('isPrimaryAtPartnerHomeAddress');
  }
  addressQuestions.push('isSameAsHomeAddress');
  if (client.isSameAsHomeAddress === false) {
    addressQuestions.push(['mailAddress', REQUIRED]);
  }
  return addressQuestions;
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const questionSet = (client) => {
  const questions = new Questions();
  if (!client.useContact && client.isDependent === undefined) {
    questions.addBranch(['isDependent', REQUIRED]);
  }

  if (client.isDependent) {
    questions.addBranch(
      'title',
      ['firstNameOptional', NO_ERROR],
      'middleName',
      'lastName',
      ['age', REQUIRED],
      ['dobOptional', NO_ERROR],
    );
    questions.add('saveButton');
  } else if (client.isDependent !== undefined || client.isCoapplicant) {
    questions.addBranch(
      'title',
      ['firstName', REQUIRED],
      'middleName',
      'lastName',
      ['email', REQUIRED, EMAIL],
      [
        'mobile',
        REQUIRED,
        featureFlags.internationalPhoneNumbers.isEnabled()
          ? MOBILE_INTERNATIONAL
          : MOBILE,
      ],
      ['dob', DATE, IN_THE_PAST],
      locale.isAU ? 'mothersMaidenName' : [],
    );
    const isPartner =
      client.isPartnerClient || (!client.id && !client.primaryApplicant);
    if (!client.useContact && isPartner) {
      questions.add(['isCoapplicant', REQUIRED]);

      if (client.isCoapplicant) {
        questions.add('role', REQUIRED);
      }
    }
    questions.add('moreLessButton', 'saveButton');
    questions.add('preferredName', 'previousName');
    if (client.useContact) {
      questions.add(['homeAddress', REQUIRED], ['isSameAsHomeAddress']);
      if (client.isSameAsHomeAddress === false) {
        questions.add(['mailAddress', REQUIRED]);
      }
    } else {
      questions.add(...buildAddressQuestions(client));
    }
    questions.add(['gender'], ['isCitizenAndResident', REQUIRED]);

    if (client.isCitizenAndResident === false) {
      questions.add(
        ['citizenshipId', REQUIRED],
        ['countryOfResidencyId', REQUIRED],
        ['residencyId', REQUIRED],
      );
    }
    questions.add(['maritalStatusId', REQUIRED]);
  }

  return questions.arrayOfQuestions();
};

export class DisconnectedClientForm extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    working: PropTypes.object.isRequired,
    isLocked: PropTypes.bool,
    isShared: PropTypes.bool,
    popup: PropTypes.bool,
    fieldsOnly: PropTypes.bool,
    questionKey: PropTypes.string.isRequired,
    save: PropTypes.func,
    remove: PropTypes.func,
    setPreferredName: PropTypes.func.isRequired,
    setPreviousName: PropTypes.func.isRequired,
    setMothersMaidenName: PropTypes.func.isRequired,
    setTitle: PropTypes.func.isRequired,
    setFirstName: PropTypes.func.isRequired,
    setMiddleName: PropTypes.func.isRequired,
    setLastName: PropTypes.func.isRequired,
    setMobile: PropTypes.func.isRequired,
    setEmail: PropTypes.func.isRequired,
    setDobYear: PropTypes.func.isRequired,
    setDobMonth: PropTypes.func.isRequired,
    setDobDay: PropTypes.func.isRequired,
    setAge: PropTypes.func.isRequired,
    setGender: PropTypes.func.isRequired,
    setMaritalStatusId: PropTypes.func.isRequired,
    setCitizenshipId: PropTypes.func.isRequired,
    setCountryOfResidencyId: PropTypes.func.isRequired,
    setIsResident: PropTypes.func.isRequired,
    setResidencyId: PropTypes.func.isRequired,
    setIsDependent: PropTypes.func,
    setIsCoapplicant: PropTypes.func,
    setRole: PropTypes.func,
    setError: PropTypes.func.isRequired,
    onLess: PropTypes.func,
    setIntlAddress: PropTypes.func.isRequired,
    loadClientAddresses: PropTypes.func.isRequired,
    titleOptions: PropTypes.arrayOf(PropTypes.object),
    onFocus: PropTypes.func,
    target: PropTypes.string,
    isNewForm: PropTypes.bool,
    ...manageQuestionsPropTypes,
  };

  static questionsToAsk = questionSet;

  static revealMethod = 'chunks';
  static revealOverBranch = true;

  static targetMessages = {
    primary: {
      question: 'are you',
      possesiveAdjective: 'your',
    },
    partner: {
      question: 'is your partner',
      possesiveAdjective: 'your partner’s',
    },
    dependent: {
      possesiveAdjective: 'your child’s',
    },
    coapplicant: {
      question: 'is the co-applicant',
      possesiveAdjective: 'your co-applicant’s',
    },
  };

  constructor(props) {
    super(props);
    this.targetMessage = DisconnectedClientForm.targetMessages[props.target];
  }

  componentWillUnmount() {
    this.props.setScenarioError({ id: 'emailUpdate', text: NO_ERROR });
  }

  componentDidMount() {
    const { working, setIsCitizenAndResident } = this.props;
    const isCitizenAndResident = working.citizenshipId
      ? checkCitizenshipAndResidency(working, locale.data.id)
      : undefined;
    setIsCitizenAndResident(isCitizenAndResident);
  }

  componentDidUpdate(prevProps) {
    const {
      working,
      clientCurrentAddress,
      target: currentTarget,
      loadClientAddresses,
      setIsCitizenAndResident,
    } = this.props;
    if (!prevProps.clientCurrentAddress && clientCurrentAddress) {
      loadClientAddresses(working.id);
    }
    if (prevProps.target !== currentTarget) {
      this.targetMessage = DisconnectedClientForm.targetMessages[currentTarget];
    }
    if (
      prevProps.working.isCitizenAndResident !== undefined &&
      working.isCitizenAndResident === undefined
    ) {
      setIsCitizenAndResident(prevProps.working.isCitizenAndResident);
    }
  }

  showMoreLessButton = () => {
    const { isShared } = this.props;
    return !(!!LocalStorageProxy.loginAsClientId || isShared);
  };

  onIsCitizenAndResidentClick = (isCitizenAndResident) => {
    const {
      setCitizenshipId,
      setCountryOfResidencyId,
      setIsResident,
      setResidencyId,
      setIsCitizenAndResident,
    } = this.props;
    const setCountry = isCitizenAndResident ? locale.data.id : undefined;

    setIsCitizenAndResident(isCitizenAndResident);
    setCitizenshipId(setCountry);
    setCountryOfResidencyId(setCountry);
    setIsResident(!!isCitizenAndResident);
    setResidencyId(isCitizenAndResident ? RESIDENCY_CITIZEN.id : undefined);
  };

  onIsCountryOfResidencyChange = (countryOfResidencyId) => {
    const {
      working: { residencyId },
      setCountryOfResidencyId,
      setIsResident,
    } = this.props;
    setCountryOfResidencyId(countryOfResidencyId);
    setIsResident(checkIsResident(countryOfResidencyId, residencyId));
  };

  onIsResidencyChange = (residencyId) => {
    const {
      working: { countryOfResidencyId },
      setResidencyId,
      setIsResident,
    } = this.props;
    setResidencyId(residencyId);
    setIsResident(checkIsResident(countryOfResidencyId, residencyId));
  };

  onIsAtPrimaryAddressClick = (value) => {
    const {
      working,
      workingCurrentAddress,
      isAtPrimaryAddress,
      setIsAtPrimaryAddress,
    } = this.props;
    if (isAtPrimaryAddress !== value) {
      setIsAtPrimaryAddress({
        value,
        clientId: working.id || 'new',
        currentAddressId: workingCurrentAddress.id,
      });
    }
  };

  onIsPrimaryAtPartnerHomeAddress = (value) => {
    const {
      working,
      workingCurrentAddress,
      isPrimaryAtPartnerAddress,
      setIsPrimaryAtPartnerAddress,
    } = this.props;
    if (isPrimaryAtPartnerAddress !== value) {
      setIsPrimaryAtPartnerAddress({
        value,
        clientId: working.id || 'new',
        currentAddressId: workingCurrentAddress.id,
      });
    }
  };

  onSameAsHomeAddressClick = (value) => {
    const {
      isSameAsHomeAddress,
      workingCurrentAddress,
      setMailAddressAddress,
    } = this.props;
    if (isSameAsHomeAddress !== value) {
      const newAddress = value
        ? workingCurrentAddress.address.formattedAddress
        : '';
      setMailAddressAddress(newAddress);
    }
  };

  onSubmit = () => {
    const { save, working } = this.props;
    save(working);
  };

  handleMoreLessClick = (toggle, isCollapsed) => (e) => {
    e.preventDefault();

    if (!isCollapsed && this.props.onLess) {
      this.props.onLess();
    }
    toggle();
  };

  generateQuestionKey = (id) => {
    return generateUniqueID(this.props.questionKey, id);
  };

  makeCommonQuestionProps = (key, questionClass, customLabel = false) => {
    const {
      intl: { formatMessage },
      questions,
    } = this.props;

    return {
      ...questions[key],
      id: questions[key] && this.generateQuestionKey(key),
      label: !customLabel && formatMessage(messages[key]),
      className: questionClass,
    };
  };

  renderFirstNameField(questionClass, optional = false) {
    const {
      working,
      intl: { formatMessage },
      setFirstName,
      isLocked,
    } = this.props;
    const firstNameKey = optional ? 'firstNameOptional' : 'firstName';
    return (
      <Question
        key={this.generateQuestionKey(firstNameKey)}
        {...this.makeCommonQuestionProps(firstNameKey, questionClass)}
        hint={formatMessage(messages.firstNameHint, {
          target: this.targetMessage.possesiveAdjective,
        })}
      >
        <Input
          action={setFirstName}
          value={working.firstName}
          disabled={isLocked}
        />
      </Question>
    );
  }

  renderAgeField(questionClass) {
    const { working, setAge, isLocked } = this.props;

    return (
      <Question
        key={this.generateQuestionKey('age')}
        {...this.makeCommonQuestionProps('age', questionClass)}
      >
        <Input action={setAge} value={working.age} disabled={isLocked} />
      </Question>
    );
  }

  renderDobField(questionClass, optional = false) {
    const {
      working,
      setDobDay,
      setDobMonth,
      setDobYear,
      isLocked,
    } = this.props;
    const dobKey = optional ? 'dobOptional' : 'dob';
    return (
      <Question
        key={this.generateQuestionKey(dobKey)}
        {...this.makeCommonQuestionProps(dobKey, questionClass)}
      >
        <DateSelector
          type='date'
          dayAction={setDobDay}
          day={working.dob.day}
          monthAction={setDobMonth}
          month={working.dob.month}
          yearAction={setDobYear}
          year={working.dob.year}
          maxDate={moment()}
          yearsOrder='descending'
          disabled={isLocked}
        />
      </Question>
    );
  }

  onSetEmail = (value) => {
    const {
      setScenarioError,
      setEmail,
      errors: { emailUpdate },
    } = this.props;
    if (emailUpdate && emailUpdate.text !== NO_ERROR) {
      setScenarioError({ id: 'emailUpdate', text: NO_ERROR });
    }
    setEmail(value);
  };

  renderSimpleFields() {
    const {
      working,
      popup,
      questions,
      intl: { formatMessage },
      setIsDependent,
      setMiddleName,
      setLastName,
      setMobile,
      setIsCoapplicant,
      setRole,
      isLocked,
      setMothersMaidenName,
      titleOptions,
      setTitle,
      errors,
      target,
      isNewForm,
    } = this.props;
    const lockEmail =
      working.savedEmail &&
      !isNewForm &&
      (target === 'coapplicant' || target === 'partner');

    const emailError = errors.emailUpdate && errors.emailUpdate.text;
    const questionClass = popup && 'fullWidthInput';
    const mobile = intlMobile(working.mobile);
    const onMobileChange = (num) => setMobile(unformatPhoneNumber(num));
    let fields;
    if (working.isDependent) {
      fields = [
        this.renderFirstNameField(questionClass, true),
        this.renderAgeField(questionClass),
        this.renderDobField(questionClass, true),
      ];
    } else {
      fields = [
        <Question
          key={this.generateQuestionKey('isDependent')}
          {...this.makeCommonQuestionProps('isDependent', questionClass)}
        >
          <RadioButtonList
            items={CLIENT_IS_DEPENDENT_OPTIONS}
            action={questions.isDependent ? setIsDependent : _.noop}
            value={working.isDependent}
            disabled={isLocked}
          />
        </Question>,
        <Question
          key={this.generateQuestionKey('title')}
          {...this.makeCommonQuestionProps('title', questionClass)}
        >
          <Selection
            action={setTitle}
            value={working.title}
            items={titleOptions}
            disabled={isLocked}
          />
        </Question>,
        this.renderFirstNameField(questionClass),
        <Question
          key={this.generateQuestionKey('middleName')}
          {...this.makeCommonQuestionProps('middleName', questionClass)}
        >
          <Input
            action={setMiddleName}
            value={working.middleName}
            disabled={isLocked}
          />
        </Question>,
        <Question
          key={this.generateQuestionKey('lastName')}
          {...this.makeCommonQuestionProps('lastName', questionClass)}
          hint={formatMessage(messages.lastNameHint, {
            target: this.targetMessage.possesiveAdjective,
          })}
        >
          <Input
            action={setLastName}
            value={working.lastName}
            disabled={isLocked}
          />
        </Question>,
        <Question
          key={this.generateQuestionKey('email')}
          {...this.makeCommonQuestionProps('email', questionClass)}
          notes={formatMessage(messages.useToLogin)}
          error={emailError}
          showError={!!emailError}
          tooltip={
            lockEmail ? 'Only your broker can change the email' : undefined
          }
        >
          <Input
            action={this.onSetEmail}
            value={working.email}
            type='email'
            disabled={isLocked || lockEmail}
          />
        </Question>,
        <Question
          key={this.generateQuestionKey('mobile')}
          {...this.makeCommonQuestionProps('mobile', questionClass)}
          notes={formatMessage(messages.useToLogin)}
        >
          {featureFlags.internationalPhoneNumbers.isEnabled() ? (
            <MobileInputIntl
              onChange={setMobile}
              value={mobile}
              countryCode={working.mobile.countryCode}
              dialCode={working.mobile.dialCode}
              disabled={isLocked}
              isMobile
            />
          ) : (
            <Input
              value={formatMobile(mobile)}
              maxLength={15}
              pattern='\\d*'
              action={onMobileChange}
              disabled={isLocked}
            />
          )}
        </Question>,
        this.renderDobField(questionClass),
        <Question
          key={this.generateQuestionKey('mothersMaidenName')}
          {...this.makeCommonQuestionProps('mothersMaidenName', questionClass)}
        >
          <Input
            action={setMothersMaidenName}
            value={working.mothersMaidenName}
            disabled={isLocked}
          />
        </Question>,
        <Question
          key={this.generateQuestionKey('isCoapplicant')}
          {...this.makeCommonQuestionProps('isCoapplicant', questionClass)}
          direction='row'
        >
          <RadioButtonList
            action={setIsCoapplicant}
            value={working.isCoapplicant}
            items={YES_NO_OPTIONS}
            disabled={isLocked}
          />
        </Question>,
        <Question
          key={this.generateQuestionKey('role')}
          {...this.makeCommonQuestionProps('role', questionClass)}
        >
          <Selection
            action={setRole}
            value={working.role}
            items={CONTACT_ROLE_OPTIONS(formatMessage)}
            disabled={isLocked}
          />
        </Question>,
      ];
    }

    return fields;
  }

  renderMoreFields = () => {
    const {
      working,
      workingCurrentAddress,
      workingMailAddress,
      primaryCurrentAddress,
      isSameAsHomeAddress,
      isAtPrimaryAddress,
      isPrimaryAtPartnerAddress,
      popup,
      intl: { formatMessage },
      setPreferredName,
      setPreviousName,
      setCurrentAddressAddress,
      setMailAddressAddress,
      setGender,
      setCitizenshipId,
      setMaritalStatusId,
      setError,
      isLocked,
      setIntlAddress,
    } = this.props;
    const questionClass = popup && 'fullWidthInput';
    const primaryCurrentAddressText =
      (primaryCurrentAddress &&
        primaryCurrentAddress.address.formattedAddress) ||
      formatMessage(messages.defaultAddressText);
    return [
      <Question
        key={this.generateQuestionKey('preferredName')}
        {...this.makeCommonQuestionProps('preferredName', questionClass)}
        hint={formatMessage(messages.preferredNameHint)}
        forceTopStyle
      >
        <Input
          action={setPreferredName}
          value={working.preferredName}
          disabled={isLocked}
        />
      </Question>,
      <Question
        key={this.generateQuestionKey('previousName')}
        {...this.makeCommonQuestionProps('previousName', questionClass)}
        hint={formatMessage(messages.previousNameHint)}
        forceTopStyle
      >
        <Input
          action={setPreviousName}
          value={working.previousName}
          disabled={isLocked}
        />
      </Question>,
      <Question
        key={this.generateQuestionKey('isAtPrimaryApplicantHomeAddress')}
        {...this.makeCommonQuestionProps(
          'isAtPrimaryApplicantHomeAddress',
          questionClass,
          true,
        )}
        label={formatMessage(messages.isAtPrimaryApplicantHomeAddress, {
          address: primaryCurrentAddressText,
        })}
        direction='row'
      >
        <RadioButtonList
          items={YES_NO_OPTIONS}
          action={this.onIsAtPrimaryAddressClick}
          value={isAtPrimaryAddress}
          disabled={isLocked}
        />
      </Question>,
      <Question
        key={this.generateQuestionKey('homeAddress')}
        {...this.makeCommonQuestionProps('homeAddress', questionClass)}
      >
        <AutocompleteAddress
          setError={setError}
          errorId='homeAddress'
          action={setCurrentAddressAddress}
          value={
            workingCurrentAddress &&
            workingCurrentAddress.address &&
            workingCurrentAddress.address.formattedAddress
          }
          intlValue={workingCurrentAddress && workingCurrentAddress.address}
          addressId={
            (workingCurrentAddress && workingCurrentAddress.id) || 'new'
          }
          intlOnChange={setIntlAddress}
          disabled={isLocked}
        />
      </Question>,
      <Question
        key={this.generateQuestionKey('isPrimaryAtPartnerHomeAddress')}
        {...this.makeCommonQuestionProps(
          'isPrimaryAtPartnerHomeAddress',
          questionClass,
          true,
        )}
        label={formatMessage(messages.isPrimaryAtPartnerHomeAddress, {
          address: primaryCurrentAddressText,
        })}
        direction='row'
      >
        <RadioButtonList
          items={YES_NO_OPTIONS}
          action={this.onIsPrimaryAtPartnerHomeAddress}
          value={isPrimaryAtPartnerAddress}
          disabled={isLocked}
        />
      </Question>,
      <Question
        key={this.generateQuestionKey('isSameAsHomeAddress')}
        {...this.makeCommonQuestionProps('isSameAsHomeAddress', questionClass)}
        direction='row'
      >
        <RadioButtonList
          items={YES_NO_OPTIONS}
          action={this.onSameAsHomeAddressClick}
          value={isSameAsHomeAddress}
          disabled={isLocked}
        />
      </Question>,
      <Question
        key={this.generateQuestionKey('mailAddress')}
        {...this.makeCommonQuestionProps('mailAddress', questionClass)}
      >
        <AutocompleteAddress
          setError={setError}
          errorId='mailAddress'
          action={setMailAddressAddress}
          value={
            workingMailAddress &&
            workingMailAddress.address &&
            workingMailAddress.address.formattedAddress
          }
          intlValue={workingMailAddress && workingMailAddress.address}
          addressId={(workingMailAddress && workingMailAddress.id) || 'new'}
          intlOnChange={setIntlAddress}
          disabled={isLocked}
        />
      </Question>,
      <Question
        key={this.generateQuestionKey('gender')}
        {...this.makeCommonQuestionProps('gender', questionClass)}
        direction='row'
      >
        {featureFlags.undisclosedGender.isEnabled() ? (
          <Selection
            action={setGender}
            value={working.gender}
            items={GENDER_OPTIONS_WITH_UNDISCLOSED}
            disabled={isLocked}
          />
        ) : (
          <RadioButtonList
            action={setGender}
            value={working.gender}
            items={GENDER_OPTIONS}
            disabled={isLocked}
          />
        )}
      </Question>,
      <Question
        key={this.generateQuestionKey('isCitizenAndResident')}
        {...this.makeCommonQuestionProps(
          'isCitizenAndResident',
          questionClass,
          true,
        )}
        label={formatMessage(messages.isCitizenAndResident, {
          target: _.capitalize(this.targetMessage.question),
          countryName: locale.data.name,
        })}
        direction='row'
      >
        <RadioButtonList
          items={YES_NO_OPTIONS}
          action={this.onIsCitizenAndResidentClick}
          value={working.isCitizenAndResident}
          disabled={isLocked}
        />
      </Question>,
      <Question
        key={this.generateQuestionKey('citizenshipId')}
        {...this.makeCommonQuestionProps('citizenshipId', questionClass, true)}
        label={formatMessage(messages.citizenshipId, {
          target: this.targetMessage.question,
        })}
        direction='row'
      >
        <Selection
          action={setCitizenshipId}
          value={
            parseInt(working.citizenshipId, 10) > 0 ? working.citizenshipId : ''
          }
          items={COUNTRY_OPTIONS}
          disabled={isLocked}
        />
      </Question>,
      <Question
        key={this.generateQuestionKey('countryOfResidencyId')}
        {...this.makeCommonQuestionProps(
          'countryOfResidencyId',
          questionClass,
          true,
        )}
        label={formatMessage(messages.countryOfResidencyId, {
          target: this.targetMessage.question,
        })}
        direction='row'
      >
        <Selection
          action={this.onIsCountryOfResidencyChange}
          value={
            parseInt(working.countryOfResidencyId, 10) > 0
              ? working.countryOfResidencyId
              : ''
          }
          items={COUNTRY_OPTIONS}
          disabled={isLocked}
        />
      </Question>,
      <Question
        key={this.generateQuestionKey('residencyId')}
        {...this.makeCommonQuestionProps('residencyId', questionClass, true)}
        label={formatMessage(messages.residencyId, {
          target: this.targetMessage.possesiveAdjective,
        })}
        direction='row'
      >
        <Selection
          action={this.onIsResidencyChange}
          value={
            parseInt(working.residencyId, 10) > 0 ? working.residencyId : ''
          }
          items={RESIDENCY_OPTIONS}
          disabled={isLocked}
        />
      </Question>,
      <Question
        key={this.generateQuestionKey('maritalStatusId')}
        {...this.makeCommonQuestionProps('maritalStatusId', questionClass)}
      >
        <Selection
          action={setMaritalStatusId}
          value={working.maritalStatusId}
          items={MARITAL_STATUS_OPTIONS}
          disabled={isLocked}
        />
      </Question>,
    ];
  };

  renderAccordionContent = ({ contentRef, toggle, isCollapsed }) => {
    const {
      working,
      fieldsOnly,
      questions,
      formCompleted,
      setCurrentQuestionTo,
      remove,
      intl: { formatMessage },
      isLocked,
      target,
      onFocus,
    } = this.props;

    const simpleFields = this.renderSimpleFields();
    const moreFields = !working.isDependent && (
      <div ref={contentRef}>{this.renderMoreFields()}</div>
    );
    const moreLessButton = this.showMoreLessButton() && (
      <Question {...questions.moreLessButton} className='fullWidthButton'>
        <Button
          theme='noBorder'
          className='alignLeft'
          icon={`sl-custom-${isCollapsed ? 'add' : 'minus'}-3`}
          onClick={this.handleMoreLessClick(toggle, isCollapsed)}
        >
          {formatMessage(getMoreLessMessage(isCollapsed, isLocked))}
        </Button>
      </Question>
    );

    if (fieldsOnly) {
      return (
        <div>
          {simpleFields}
          {moreFields}
          {moreLessButton}
        </div>
      );
    }

    return (
      <Form
        id='clientForm'
        formCompleted={formCompleted}
        onSubmit={this.onSubmit}
        onFocusLost={setCurrentQuestionTo(undefined)}
        onFocus={onFocus}
      >
        <fieldset disabled={isLocked}>
          {simpleFields}
          {moreFields}
          {moreLessButton}
          <Question {...questions.saveButton} className='fullWidthButton'>
            <Button
              theme='linkButton'
              hasErrorMessage={!formCompleted}
              onClick={this.onSubmit}
            >
              {formatMessage(messages.save)}
            </Button>
          </Question>
          {working.id && target !== 'partner' && (
            <Button
              className='brandColor__reverseButton'
              onClick={() => remove(working.id)}
            >
              {formatMessage(messages.remove)}
            </Button>
          )}
          <input className='hidden' type='submit' />
        </fieldset>
      </Form>
    );
  };

  render() {
    return (
      <Accordion hide expandByDefault={!this.showMoreLessButton()}>
        {this.renderAccordionContent}
      </Accordion>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { working, primaryApplicant } = ownProps;
  let target;
  if (working.primaryApplicant) {
    target = 'primary';
  } else if (
    primaryApplicant &&
    working.contactId === primaryApplicant.contactId
  ) {
    target = working.isDependent ? 'dependent' : 'partner';
  } else {
    target = 'coapplicant';
  }
  const isNewForm = !working.contactId;
  return {
    target,
    errors: state.scenario.errors,
    titleOptions: clientSelectors.titleOptions(state),
    isNewForm,
  };
};

const mapDispatchToPropsContact = (dispatch, ownProps) => {
  const id = (ownProps.working && ownProps.working.id) || 'new';
  const currentAddressId =
    ownProps.workingCurrentAddress && ownProps.workingCurrentAddress.id;
  const mailAddressId =
    ownProps.workingMailAddress && ownProps.workingMailAddress.id;

  return bindActionCreators(
    {
      setPreferredName: contactActions.setContactPreferredName(id),
      setPreviousName: contactActions.setContactPreviousName(id),
      setMothersMaidenName: contactActions.setContactMothersMaidenName(id),
      setIsCitizenAndResident: contactActions.setContactIsCitizenAndResident(
        id,
      ),
      setTitle: contactActions.setContactTitle(id),
      setFirstName: contactActions.setContactFirstName(id),
      setMiddleName: contactActions.setContactMiddleName(id),
      setLastName: contactActions.setContactLastName(id),
      setMobile: contactActions.setContactMobile(id),
      setEmail: contactActions.setContactEmail(id),
      setDobYear: contactActions.setContactDobYear(id),
      setDobMonth: contactActions.setContactDobMonth(id),
      setDobDay: contactActions.setContactDobDay(id),
      setAge: contactActions.setContactAge(id),
      setGender: contactActions.setContactGender(id),
      setCitizenshipId: contactActions.setContactCitizenshipId(id),
      setCountryOfResidencyId: contactActions.setContactCountryOfResidencyId(
        id,
      ),
      setIsResident: contactActions.setContactIsResident(id),
      setResidencyId: contactActions.setContactResidencyId(id),
      setMaritalStatusId: contactActions.setContactMaritalStatusId(id),
      setRole: contactActions.setContactRole(id),
      setError: contactActions.setContactError(id),
      setCurrentAddressAddress: addressActions.setAddressAddress(
        currentAddressId,
      ),
      setMailAddressAddress: addressActions.setAddressAddress(mailAddressId),
      setIntlAddress: addressActions.setIntlAddress,
      setScenarioError: scenarioActions.setError,
    },
    dispatch,
  );
};

const mapDispatchToPropsClient = (dispatch, ownProps) => {
  const id = (ownProps.working && ownProps.working.id) || 'new';
  const currentAddressId =
    ownProps.workingCurrentAddress && ownProps.workingCurrentAddress.id;
  const mailAddressId =
    ownProps.workingMailAddress && ownProps.workingMailAddress.id;

  return bindActionCreators(
    {
      setIsDependent: clientActions.setClientIsDependent(id),
      setPreferredName: clientActions.setClientPreferredName(id),
      setPreviousName: clientActions.setClientPreviousName(id),
      setMothersMaidenName: clientActions.setClientMothersMaidenName(id),
      setIsCitizenAndResident: clientActions.setClientIsCitizenAndResident(id),
      setTitle: clientActions.setClientTitle(id),
      setFirstName: clientActions.setClientFirstName(id),
      setMiddleName: clientActions.setClientMiddleName(id),
      setLastName: clientActions.setClientLastName(id),
      setMobile: clientActions.setClientMobile(id),
      setEmail: clientActions.setClientEmail(id),
      setDobYear: clientActions.setClientDobYear(id),
      setDobMonth: clientActions.setClientDobMonth(id),
      setDobDay: clientActions.setClientDobDay(id),
      setAge: clientActions.setClientAge(id),
      setGender: clientActions.setClientGender(id),
      setCitizenshipId: clientActions.setClientCitizenshipId(id),
      setCountryOfResidencyId: clientActions.setClientCountryOfResidencyId(id),
      setIsResident: clientActions.setClientIsResident(id),
      setResidencyId: clientActions.setClientResidencyId(id),
      setMaritalStatusId: clientActions.setClientMaritalStatusId(id),
      setIsCoapplicant: clientActions.setClientIsCoapplicant(id),
      setRole: clientActions.setClientRole(id),
      setError: clientActions.setClientError(id),
      setCurrentAddressAddress: addressActions.setAddressAddress(
        currentAddressId,
      ),
      setMailAddressAddress: addressActions.setAddressAddress(mailAddressId),
      setIsAtPrimaryAddress: addressActions.setIsAtPrimaryAddress,
      setIntlAddress: addressActions.setIntlAddress,
      setIsPrimaryAtPartnerAddress: addressActions.setIsPrimaryAtPartnerAddress,
      loadClientAddresses: clientActions.loadClientAddresses,
      setScenarioError: scenarioActions.setError,
    },
    dispatch,
  );
};

const ComposableContactForm = injectIntl(
  connect(mapStateToProps, mapDispatchToPropsContact)(DisconnectedClientForm),
);
ComposableContactForm.displayName = 'ComposableContactForm';

const ComposableClientForm = injectIntl(
  connect(mapStateToProps, mapDispatchToPropsClient)(DisconnectedClientForm),
);
ComposableClientForm.displayName = 'ComposableClientForm';

export default injectIntl(
  manageQuestions(
    connect(mapStateToProps, mapDispatchToPropsClient)(DisconnectedClientForm),
    propsTransformForQuestion,
  ),
);

export { ComposableContactForm, ComposableClientForm };
