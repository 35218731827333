import {
  EXPENSE_CATEGORY_HOUSEHOLD,
  EXPENSE_CATEGORY_INVESTMENT,
} from './expenseCategoryTypesV1';

export const EXPENSE_CATEGORY_SECONDARY_HOUSEHOLD = { id: 13 };

export const EXPENSE_CATEGORY_NAMES = {
  [EXPENSE_CATEGORY_HOUSEHOLD.id]: 'The main house you live in',
  [EXPENSE_CATEGORY_INVESTMENT.id]: 'The house you rent out',
  [EXPENSE_CATEGORY_SECONDARY_HOUSEHOLD.id]: 'The other house you live in',
};
