import { createSelector } from 'reselect';
import * as applicationSelectors from 'selectors/applicationSelectors';

export const advisor = (state) => state.advisor;

export const subscriptions = createSelector(advisor, (a) => a.subscriptions);

export const hasBankConnectSubscription = createSelector(
  subscriptions,
  applicationSelectors.isBrokerCustomerCare,
  (s, isCustomerCare) => s.bankConnectPlus || !!isCustomerCare,
);

export const brokerId = createSelector(advisor, (a) => a.familyId);
