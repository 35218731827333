import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';

import delay from 'hocs/delay';

import { getLenderStyle } from 'lib/utils/imageUtils';

import styles from './AnimatedLogoGrid.css';

const quickRatio = 0.03;
const slowRatio = 0.08;

// Burst animation sequence of quick ('q') and slow ('s')
const burstStyle = [
  'q',
  'q',
  's',
  'q',
  'q',
  'q',
  's',
  's',
  'q',
  'q',
  'q',
  's',
  's',
  'q',
  'q',
  's',
  'q',
  'q',
  'q',
  'q',
  'q',
  's',
  's',
  'q',
  'q',
];

const getBurstTiming = (index, lendersLength, duration) => {
  const ratio =
    burstStyle[index % lendersLength] === 'q' ? quickRatio : slowRatio;

  return ratio * duration * (burstStyle.length / lendersLength);
};

class AnimatedLogoGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLenders: [],
      showLogos: {},
    };
  }

  componentDidMount() {
    setTimeout(this.loadLogos, 500);
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearInterval(this.timeout);
    }
  }

  loadLogos = () => {
    const { lenders, duration, burst } = this.props;
    const { currentLenders } = this.state;

    if (lenders.length > 0) {
      currentLenders.push(lenders[currentLenders.length]);
      this.setState({ currentLenders });
    }

    if (currentLenders.length !== lenders.length) {
      const timing =
        burst && duration > 0
          ? getBurstTiming(currentLenders.length, lenders.length, duration)
          : duration / lenders.length;

      this.timeout = setTimeout(this.loadLogos, timing);
    } else {
      this.timeout = null;
    }
  };

  onLogoLoad = (lender) =>
    this.setState((prevState) => ({
      showLogos: { ...prevState.showLogos, [lender]: true },
    }));

  renderLogo = (lender) => (
    <li
      key={lender.key}
      className={this.props.showAll ? styles.showAllItem : styles.item}
      style={getLenderStyle(lender.key, this.state.showLogos)}
    >
      <img
        src={lender.logoUrl}
        alt={lender.key}
        data-image={lender.logoUrl}
        onLoad={() => this.onLogoLoad(lender.key)}
      />
    </li>
  );

  render() {
    const { currentLenders } = this.state;

    return (
      <CSSTransitionGroup
        component='ol'
        transitionName='pop'
        transitionEnterTimeout={300}
        transitionLeaveTimeout={0}
        className={styles.root}
      >
        {currentLenders.map(this.renderLogo)}
      </CSSTransitionGroup>
    );
  }
}

AnimatedLogoGrid.propTypes = {
  lenders: PropTypes.arrayOf(PropTypes.string).isRequired,
  duration: PropTypes.number.isRequired,
  burst: PropTypes.bool,
  showAll: PropTypes.bool,
};

AnimatedLogoGrid.defaultProps = {
  burst: false,
};

export default delay(AnimatedLogoGrid);
