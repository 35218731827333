export const CLEAR_WORKING_CLIENT = 'clearWorkingClient';
export const LOAD_CLIENT = 'loadClient';
export const LOAD_DEPENDENTS_INTO_WORKING = 'loadDependentsIntoWorking';
export const LOAD_CLIENT_ADDRESSES = 'loadClientAddresses';
export const LOAD_NEW_FAMILY_MEMBER = 'loadNewFamilyMember';
export const LOAD_NEW_DEPENDENT = 'loadNewDependent';
export const SET_CLIENT = 'setClient';
export const REMOVE_CLIENT = 'removeClient';
export const SET_NEW_CLIENT = 'setNewClient';
export const SET_NEW_CLIENTS = 'setNewClients';
export const SET_NEW_OR_MERGE_CLIENT = 'setNewOrMergeClient';

export const SET_TITLE_OPTIONS = 'setTitleOptions';
export const SET_CLIENT_ERROR = 'setClientError';

export const CREATE_CLIENT = 'createClient';
export const READ_CLIENT = 'readClient';
export const UPDATE_CLIENT = 'updateClient';
export const DELETE_CLIENT = 'deleteClient';
export const UPDATE_CLIENT_LOGIN = 'updateClientLogin';

export const CONFIRM_CLIENT = 'confirmClient';
export const CONFIRM_CLIENTS = 'confirmClients';
export const CONFIRM_CO_APPLICANTS = 'confirmCoapplicants';
