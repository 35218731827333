export const CLEAR_WORKING_EMPLOYMENT = 'clearWorkingEmployment';
export const LOAD_EMPLOYMENT = 'loadEmployment';
export const REMOVE_EMPLOYMENT = 'removeEmployment';
export const SET_EMPLOYMENT = 'setEmployment';
export const SET_NEW_EMPLOYMENT = 'setNewEmployment';
export const SET_NEW_EMPLOYMENTS = 'setNewEmployments';
export const SET_EMPLOYMENT_ERROR = 'setEmploymentError';
export const INSERT_NEW_EMPLOYMENTS = 'insertNewEmployments';
export const SET_NZBN_LOADING = 'setNzbnLoading';

export const CREATE_EMPLOYMENT = 'createEmployment';
export const UPDATE_EMPLOYMENT = 'updateEmployment';
export const DELETE_EMPLOYMENT = 'deleteEmployment';

export const CONFIRM_EMPLOYMENTS = 'confirmEmployments';
