import { PROPERTY_PURCHASING } from 'shared/constants/options';
import { serialiseLocality } from 'shared/lib/localityMapperHelper';
import { historyBuilderForMilli } from './history';

export function propertyBuilderForMilli(property) {
  let locality;
  if (property.country === 'New Zealand') {
    locality = property.suburb;
  } else if (property.suburb) {
    locality = serialiseLocality(property);
  }

  return {
    id: property.property_id,
    propertyTypeId: property.property_type_id,
    value: property.value,
    futureValue: property.future_value,
    zoningId: property.zoning_id,
    locality,
    suburb: property.suburb,
    state: property.state,
    postcode: property.postcode,
    country: property.country,
    primaryPurposeId: property.primary_purpose_id,
    owned: PROPERTY_PURCHASING,
    statusId: property.status_id,
    isPreapproved: property.is_preapproved,
    transactionId: property.transaction_id,
    clientIds: property.client_ids,
    ...historyBuilderForMilli(property),
  };
}
