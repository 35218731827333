import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { intlShape, injectIntl } from 'react-intl';
import _ from 'lodash';
import moment from 'moment';

import locale from 'config/locale';
import expenseActions from 'actions/expenseActions';
import UIActions from 'actions/UIActions';
import { logEvent, EVENTS } from 'lib/amplitude';

import * as expenseSelectors from 'selectors/expenseSelectors';
import * as incomeSelectors from 'selectors/incomeSelectors';
import * as contactSelectors from 'selectors/contactSelectors';
import * as UISelectors from 'selectors/UISelectors';
import * as applicationSelectors from 'selectors/applicationSelectors';
import * as clientSelectors from 'selectors/clientSelectors';
import * as expenseCategoryTypeSelectors from 'selectors/expenseCategoryTypeSelectors';

import FormPopup, { formPopupProps } from 'components/PopupItem/FormPopup';
import Popup from 'components/PopupItem/Popup';
import ExpenseForm from 'components/PopupForms/ExpenseForm';

import { MONTHLY, WEEKLY, YEARLY } from 'shared/constants/options';
import { messages as optionMessages } from 'constants/options';
import {
  INDEXATION,
  DISCLIAMERS,
  FOOTNOTE_MAPPING,
} from 'constants/absHouseholdExpenditure';
import {
  getValueForAverageHousehold,
  getValueForHouseholdShape,
  getValueForIncomeQuintile,
} from 'lib/absHouseholdExpenditureHelper';
import { formatCurrencyPm, formatCurrency } from 'lib/intlFormatters';

import { arrayToPrettyStringList } from 'lib/utils/stringUtils';
import { convertFrequency } from 'shared/lib/numbrero';
import { EXPENSES_SLUG } from 'constants/applyData';

export const renderExpenseHeading = (icon, heading) => (
  <h1 className={Popup.styles.expenseHeading}>
    <i className={icon} />
    {heading}
  </h1>
);

const getExpenseTypeId = (location, item) => {
  const { typeId } = (location && location.query) || {};
  return parseInt(typeId, 10) || (item && item.typeId);
};

const getUncategorised = (location, item) => {
  const { uncategorised } = (location && location.query) || {};
  return uncategorised === 'true' || item.uncategorised;
};

const getCategoryName = (typeInfo, uncategorised, category) => {
  const { name, isOther } = typeInfo;
  if (!isOther) {
    return name;
  }
  return `${category.name} - ${uncategorised ? 'Uncategorised' : 'Other'}`;
};

export class ExpensePopup extends FormPopup {
  static propTypes = {
    ...formPopupProps,
    intl: intlShape.isRequired,
    setExpenseTypeId: PropTypes.func.isRequired,
    setExpenseDescription: PropTypes.func.isRequired,
    setExpenseValue: PropTypes.func.isRequired,
    recurringExpenseItems: PropTypes.func.isRequired,
    setExpenseClientIds: PropTypes.func.isRequired,
    clientIdOwnershipOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
    collectionsWithAccounts: PropTypes.arrayOf(PropTypes.object).isRequired,
    typeInfo: PropTypes.object,
    getCategoryById: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      showAbsDisclaimer: false,
      showRecurringItemsDisclamer: false,
      showFullTransactions: false,
    };
    const {
      item,
      recurringExpenseItems,
      recurringExpenseItemsMetadata,
      typeInfo,
      getCategoryById,
      history: { location },
    } = props;
    this.typeId = getExpenseTypeId(location, item);
    this.uncategorised = getUncategorised(location, item);
    this.categoryName = getCategoryName(
      typeInfo,
      this.uncategorised,
      getCategoryById(typeInfo.categoryId),
    );
    this.recurringExpenseItems = recurringExpenseItems(this.typeId);
    this.recurringExpenseItemsMetadata = recurringExpenseItemsMetadata(
      this.typeId,
    );
    this.typeInfo = typeInfo;
  }

  onLoadNew() {
    const { setExpenseTypeId } = this.props;
    setExpenseTypeId(this.typeId);
  }

  isLoaded() {
    const { working } = this.props;
    return working && working.typeId;
  }

  title() {
    const { name } = this.typeInfo;
    const formStateTitle = super.title();
    if (formStateTitle) {
      return `${formStateTitle} ${this.isLoaded() ? name : 'Expense'}`;
    }
    return this.categoryName;
  }

  value = () =>
    convertFrequency(this.props.item.value, this.props.item.frequency, MONTHLY);

  formatValue = (v) => formatCurrencyPm(this.props.intl)(v);

  toggleAbsDisclaimer = () => {
    this.setState({
      showAbsDisclaimer: !this.state.showAbsDisclaimer,
    });
  };

  toggleRecurringItemsDisclamer = () => {
    this.setState({
      showRecurringItemsDisclamer: !this.state.showRecurringItemsDisclamer,
    });
  };

  toggleTransactions = () => {
    this.setState({
      showFullTransactions: !this.state.showFullTransactions,
    });
  };

  logSaveEvent() {
    logEvent(EVENTS.SAVE_FINANCIALS, { section: EXPENSES_SLUG });
  }

  logSaveAndAddEvent() {
    logEvent(EVENTS.SAVE_AND_ADD_FINANCIALS, { section: EXPENSES_SLUG });
  }

  logRemoveEvent() {
    logEvent(EVENTS.REMOVE_FINANCIALS, { section: EXPENSES_SLUG });
  }

  renderABSDisclaimer = (absData, defaultDescription) => {
    const { name } = this.typeInfo;
    return !this.state.showAbsDisclaimer ? (
      <a key='ExpensePopupDisclaimerButton' onClick={this.toggleAbsDisclaimer}>
        <i className='mi-arrow-filled-down' /> How we calculate what others
        spend.
      </a>
    ) : (
      <div key='ExpensePopupDisclaimer'>
        <p key='ExpensePopupDefaultDescription'>{defaultDescription}</p>
        <p key='ExpensePopupABSDescription'>
          Our typical ranges are ABS averages. They give you an indication of
          what other households typically spend. These are provided as a guide
          only. It’s important your budget reflects what you’ll actually be
          spending in this category.
        </p>
        <h1>How we calculated these estimates:</h1>
        <p>
          Our expenditure averages are drawn from the Australian Bureau of
          Statistics Household Expenditure Survey 2009-10 (HES). This data is
          based on a detailed analylsis of 9,774 Australian households
          expenditure. The HES provides averages for a number of household
          characteristics. For our estimates we have used All Australian
          Households, Family Composition, and Gross Income Quartile where data
          is available. To account for inflation since the HES was conducted, we
          have multiplied the 2009-2010 figures by {INDEXATION}, using the ABS
          Consumer Price Index Inflation Calculator.
        </p>
        {!Array.isArray(absData) ? (
          <p>
            For {name} we use the HES expenditure classification {absData.name}.
          </p>
        ) : (
          <p>
            For {name} we use the total of the HES expenditure classifications:{' '}
            {arrayToPrettyStringList(absData.map((c) => c.name))}.
          </p>
        )}
        <a onClick={this.toggleAbsDisclaimer}>
          <i className='mi-arrow-filled-up' /> Hide details
        </a>
      </div>
    );
  };

  renderABSTable(averageHousehold, averageForIncome, averageForShape) {
    const { intl, householdShape } = this.props;
    const { icon } = this.typeInfo;
    const range = [
      averageHousehold.value,
      averageForShape && averageForShape.value,
      averageForIncome.value,
    ];
    const max = _.max(range);
    const min = _.min(range);
    return (
      <div key='ExpensePopupABSContainer'>
        {renderExpenseHeading(
          icon,
          `Others spend ${formatCurrency(intl)(min)}–${formatCurrencyPm(intl)(
            max,
          )}`,
        )}
        <table key='ExpensePopupABSTable'>
          <tbody>
            <tr>
              <td>
                All households{FOOTNOTE_MAPPING[averageHousehold.disclaimer]}
              </td>
              <td>{formatCurrencyPm(intl)(averageHousehold.value)}</td>
            </tr>
            {averageForShape && (
              <tr>
                <td>
                  {optionMessages.householdShape[householdShape]}
                  {FOOTNOTE_MAPPING[averageForShape.disclaimer]}
                </td>
                <td>{formatCurrencyPm(intl)(averageForShape.value)}</td>
              </tr>
            )}
            <tr>
              <td>
                Households on similar income
                {FOOTNOTE_MAPPING[averageForIncome.disclaimer]}
              </td>
              <td>{formatCurrencyPm(intl)(averageForIncome.value)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  renderAbsFootnotes = (...args) => [
    args.find((v) => v && v.disclaimer === '*') && (
      <p key='ExpensePopupDisclaimer*' className={Popup.styles.fineprint}>
        {DISCLIAMERS['*']}
      </p>
    ),
    args.find((v) => v && v.disclaimer === '**') && (
      <p key='ExpensePopupDisclaimer**' className={Popup.styles.fineprint}>
        {DISCLIAMERS['**']}
      </p>
    ),
  ];

  renderABSDescription = (absData, defaultDescription) => {
    const { householdShape, grossIncome } = this.props;
    const averageHousehold = getValueForAverageHousehold(absData);
    const averageForShape = getValueForHouseholdShape(absData, householdShape);
    const averageForIncome = getValueForIncomeQuintile(
      absData,
      convertFrequency(grossIncome, YEARLY, WEEKLY),
    );
    return [
      this.renderABSTable(averageHousehold, averageForIncome, averageForShape),
      this.renderABSDisclaimer(absData, defaultDescription),
      this.renderAbsFootnotes(
        averageHousehold,
        averageForIncome,
        averageForShape,
      ),
    ];
  };

  renderBestGuessDescription = (guessData, defaultDescription) => {
    const { intl } = this.props;
    const { icon } = this.typeInfo;

    return [
      renderExpenseHeading(
        icon,
        `Others spend ${formatCurrency(intl)(
          guessData.min,
        )} - ${formatCurrencyPm(intl)(guessData.max)}`,
      ),
      <p key='defaultDesc'>{defaultDescription}</p>,
      guessData.disclaimer && <p key='disclaimer'>{guessData.disclaimer}</p>,
    ];
  };

  renderRecurringExpenseItem = (item) => {
    const { intl } = this.props;
    return (
      <div className={Popup.styles.recurringItem} key={item.id}>
        <div>
          <h3>{item.description}</h3>
          <p>{`${item.numberOfTransactions} Transactions in 6 months`}</p>
          <p>
            {`Av. ${formatCurrency(intl, { showDecimal: true })(
              item.total / item.numberOfTransactions,
            )} per transaction`}
          </p>
        </div>
        <div>
          <span>{formatCurrencyPm(intl)(item.monthlyAverage)}</span>
        </div>
      </div>
    );
  };

  renderRecurringItemsDisclaimer = () => {
    const { collectionsWithAccounts } = this.props;
    const { showRecurringItemsDisclamer } = this.state;

    return (
      <div>
        {showRecurringItemsDisclamer ? (
          <div>
            <p>
              These estimates are based on common reccurring transactions
              related to {this.categoryName} in 6 months accross your connected
              accounts.
            </p>
            <ul>
              {collectionsWithAccounts.map((c) => {
                return (
                  <li key={c.id}>
                    {c.institutionName} (
                    {c.accounts.map((a) => a.accountNumber).join(', ')}) -
                    Updated {moment(c.createdDate).fromNow()}
                  </li>
                );
              })}
            </ul>
            <a onClick={this.toggleRecurringItemsDisclamer}>
              <i className='mi-arrow-filled-up' /> Hide details
            </a>
          </div>
        ) : (
          <a
            key='ExpensePopupDisclaimerButton'
            onClick={this.toggleRecurringItemsDisclamer}
          >
            <i className='mi-arrow-filled-down' /> How we calculate what you
            spend.
          </a>
        )}
      </div>
    );
  };

  renderRecurringExpenseItems(items) {
    const { intl } = this.props;
    const { icon } = this.typeInfo;
    const { showFullTransactions } = this.state;

    const shouldToggleTransactions = items.length > 3;
    const firstItems = shouldToggleTransactions ? items.slice(0, 3) : items;
    const restItems = shouldToggleTransactions && items.slice(3, items.length);

    return (
      <div className={Popup.styles.recurringItemWrapper}>
        {renderExpenseHeading(
          icon,
          `You spend ${this.recurringExpenseItemsMetadata.expenseRange(intl)}`,
        )}
        {this.renderRecurringItemsDisclaimer()}
        {firstItems.map(this.renderRecurringExpenseItem)}
        {!showFullTransactions && restItems && (
          <a onClick={this.toggleTransactions}>
            ⋯ More Recurring Transactions ({restItems.length})
          </a>
        )}
        {showFullTransactions &&
          restItems &&
          restItems.map(this.renderRecurringExpenseItem)}
        {showFullTransactions && restItems && (
          <a onClick={this.toggleTransactions}>Show less</a>
        )}
      </div>
    );
  }

  renderDescription(style) {
    const { description, absData, bestGuess, isOther } = this.typeInfo;

    const showABSData = locale.isAU && !!absData;
    const showBestGuess = locale.isAU && !!bestGuess;
    let content;

    if (showABSData) {
      content = this.renderABSDescription(absData, description);
    } else if (showBestGuess) {
      content = this.renderBestGuessDescription(bestGuess, description);
    } else if (description) {
      content = <p>{description}</p>;
    }

    if (this.uncategorised) {
      /* ovrwrite default 'other' desciption */
      content = (
        <p>We were not able to automatically categorise these transactions.</p>
      );
    }

    const showRecurringItem = isOther
      ? this.uncategorised
      : this.recurringExpenseItemsMetadata.itemsExist;

    return (
      <div className={style}>
        {showRecurringItem &&
          this.renderRecurringExpenseItems(this.recurringExpenseItems)}
        {content}
      </div>
    );
  }

  renderForm() {
    const {
      isLocked,
      working,
      setExpenseValue,
      setExpenseDescription,
      setExpenseFrequency,
      setExpenseClientIds,
      clientIdOwnershipOptions,
    } = this.props;
    return (
      <ExpenseForm
        isLocked={isLocked}
        working={working}
        setName={setExpenseDescription}
        setFrequency={setExpenseFrequency}
        setExpenseClientIds={setExpenseClientIds}
        action={setExpenseValue}
        clientIdOwnershipOptions={clientIdOwnershipOptions}
        save={this.save}
        remove={this.remove}
        typeInfo={this.typeInfo}
        uncategorised={this.uncategorised}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const paramId = ownProps.match.params.id;
  const id = parseInt(paramId, 10) || paramId || 'new';
  const item = expenseSelectors.entity(state)(id);

  return {
    id,
    item,
    working: expenseSelectors.working(state)(id),
    grossIncome: incomeSelectors.totalAnnualIncome(state),
    householdShape: contactSelectors.householdShape(state),
    requestIsProcessing: UISelectors.requestProcessing(state)(id),
    errors: UISelectors.requestErrors(state),
    isLocked: applicationSelectors.getIsLocked(state),
    collectionsWithAccounts: [],
    recurringExpenseItems: () => {
      return [];
    },
    recurringExpenseItemsMetadata: () => {
      return [];
    },
    typeInfo: expenseCategoryTypeSelectors.typeInfo(state)(
      getExpenseTypeId(ownProps.history.location, item),
    ),
    getCategoryById: expenseCategoryTypeSelectors.categoryById(state),
    clientIdOwnershipOptions: clientSelectors.clientIdOwnershipOptions(state),
    formPopUpStatus: UISelectors.formPopUpStatus(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const paramId = ownProps.match.params.id;
  const id = parseInt(paramId, 10) || paramId || 'new';
  return bindActionCreators(
    {
      load: expenseActions.loadExpense,
      create: expenseActions.createExpense,
      update: expenseActions.updateExpense,
      delete: expenseActions.deleteExpense,
      clearWorking: expenseActions.clearWorkingExpense,
      clearErrors: UIActions.clearAsyncRequestErrors,
      resetFormPopupStatus: UIActions.resetFormPopupStatus,
      setExpenseValue: expenseActions.setExpenseValue(id),
      setExpenseTypeId: expenseActions.setExpenseTypeId(id),
      setExpenseDescription: expenseActions.setExpenseDescription(id),
      setExpenseFrequency: expenseActions.setExpenseFrequency(id),
      setExpenseClientIds: expenseActions.setExpenseClientIds(id),
    },
    dispatch,
  );
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(ExpensePopup),
);
