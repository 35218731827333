import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';
import locale from 'config/locale';
import MobileInputIntl from 'components/MobileInputIntl/MobileInputIntl';
import { intlMobile } from 'shared/lib/utils';
import { featureFlags } from 'lib/rollout';

import employmentActions from 'actions/employmentActions';

import {
  REQUIRED,
  REQUIRED_ADDRESS,
  DATE,
  MONTH_YEAR,
  GREATER_THAN_EMPLOYMENT_START_MONTH_YEAR,
  PHONE_NUMBER,
  PHONE_NUMBER_INTERNATIONAL,
  INVALID_NZBN,
  NO_ERROR,
} from 'constants/validators';
import {
  EMPLOYMENT_TYPE_PAYG,
  EMPLOYMENT_TYPE_SELF_EMPLOYED,
  EMPLOYMENT_STATUS_PREVIOUS_EMPLOYMENT,
  EMPLOYMENT_NATURE_REGISTERED,
} from 'shared/constants/myCRMTypes/employments';
import {
  YES_NO_OPTIONS,
  EMPLOYMENT_STATUS_OPTIONS,
  employmentTypeOptions,
  EMPLOYMENT_BASIS_OPTIONS,
  EMPLOYMENT_EMPLOYER_TYPE_OPTIONS,
  EMPLOYMENT_NATURE_OPTIONS,
} from 'constants/options';

import manageQuestions, {
  manageQuestionsPropTypes,
} from 'hocs/manageQuestions';

import Form from 'components/Form/Form';
import Question from 'components/Question/Question';
import AutocompleteAddress from 'components/AutocompleteInput/AutocompleteAddress';
import Input from 'components/Input/Input';
import Selection from 'components/Selection/Selection';
import Button from 'components/Button/Button';
import DateSelector from 'components/DateSelector/DateSelector';
import RadioButtonList from 'components/ButtonList/RadioButtonList';
import Accordion from 'components/Accordion/Accordion';
import Questions from 'lib/Questions';

export const messages = defineMessages({
  statusRadio: {
    id: 'EmploymentForm.statusRadio',
    defaultMessage: 'Is this a current or previous employment?',
  },
  statusSelect: {
    id: 'EmploymentForm.statusSelect',
    defaultMessage: 'Job status',
  },
  clientIdRadio: {
    id: 'EmploymentForm.clientIdRadio',
    defaultMessage: 'Who was employed at this job?',
  },
  clientIdSelect: {
    id: 'EmploymentForm.clientIdSelect',
    defaultMessage: 'Employee',
  },
  typeRadio: {
    id: 'EmploymentForm.typeRadio',
    defaultMessage: 'What was the nature of the employment?',
  },
  typeSelect: {
    id: 'EmploymentForm.typeSelect',
    defaultMessage: 'Job type',
  },
  basis: {
    id: 'EmploymentForm.basis',
    defaultMessage: 'Job basis',
  },
  nature: {
    id: 'EmploymentForm.natureId',
    defaultMessage: 'Employment Nature',
  },
  nzbn: {
    id: 'EmploymentForm.nzbn',
    defaultMessage: 'NZBN',
  },
  role: {
    id: 'EmploymentForm.role',
    defaultMessage: 'Job title',
  },
  employerName: {
    id: 'EmploymentForm.employerName',
    defaultMessage: 'Employer name',
  },
  contactFirstName: {
    id: 'EmploymentForm.contactFirstName',
    defaultMessage: 'Employer contact first name',
  },
  contactLastName: {
    id: 'EmploymentForm.contactLastName',
    defaultMessage: 'Employer contact last name',
  },
  employerAddress: {
    id: 'EmploymentForm.employerAddress',
    defaultMessage: 'Employer address',
  },
  employerPhone: {
    id: 'EmploymentForm.employerPhone',
    defaultMessage: 'Employer phone number',
  },
  employerType: {
    id: 'EmploymentForm.employerType',
    defaultMessage: 'Employer type',
  },
  dateStarted: {
    id: 'EmploymentForm.dateStarted',
    defaultMessage: 'Date started',
  },
  dateEnded: {
    id: 'EmploymentForm.dateEnded',
    defaultMessage: 'Date ended',
  },
  isProbationCurrent: {
    id: 'EmploymentForm.isProbationCurrent',
    defaultMessage: 'Are you on probation?',
  },
  isProbationCurrentNZ: {
    id: 'EmploymentForm.isProbationCurrentNZ',
    defaultMessage: 'Are you currently in a trial period with this employer?',
  },
  isProbationPrevious: {
    id: 'EmploymentForm.isProbationPrevious',
    defaultMessage: 'Were you on probation when you left?',
  },
  isProbationPreviousNZ: {
    id: 'EmploymentForm.isProbationPreviousNZ',
    defaultMessage:
      'Were you still in a trial period with this employer when you left?',
  },
  save: {
    id: 'EmploymentForm.save',
    defaultMessage: 'Save',
  },
  saveAndAddAnother: {
    id: 'EmploymentForm.saveAndAddAnother',
    defaultMessage: 'Save and add another',
  },
  remove: {
    id: 'EmploymentForm.remove',
    defaultMessage: 'Remove',
  },
});

const EMPLOYED_TYPES = [
  EMPLOYMENT_TYPE_PAYG.id,
  EMPLOYMENT_TYPE_SELF_EMPLOYED.id,
];

const propsTransformForQuestion = (props) => ({
  ...props.working,
  clientIdOptionsForNonsharable: props.clientIdOptionsForNonsharable,
  employerAddress:
    props.working.employerAddress &&
    props.working.employerAddress.formattedAddress,
});

const questionSet = (props) => {
  const questions = new Questions();
  const { typeId, natureId, statusId, clientIdOptionsForNonsharable } = props;
  const dateQuestions = [['dateStarted', DATE, MONTH_YEAR], ['isProbation']];
  if (statusId === EMPLOYMENT_STATUS_PREVIOUS_EMPLOYMENT.id) {
    dateQuestions.push([
      'dateEnded',
      DATE,
      MONTH_YEAR,
      GREATER_THAN_EMPLOYMENT_START_MONTH_YEAR,
    ]);
  }

  questions.addBranch(['statusId', REQUIRED]);
  if (clientIdOptionsForNonsharable.length > 1) {
    questions.addBranch(['clientId', REQUIRED]);
  }
  questions.addBranch(['typeId', REQUIRED]);
  if (EMPLOYED_TYPES.includes(typeId)) {
    const showNZBN = locale.isNZ;
    const showNatureField =
      typeId === EMPLOYMENT_TYPE_SELF_EMPLOYED.id && showNZBN;
    const showNZBNField =
      natureId === EMPLOYMENT_NATURE_REGISTERED.id && showNatureField;
    if (showNatureField) {
      questions.addBranch(['natureId', REQUIRED]);
    }
    const moreDetails = [
      ['role', REQUIRED],
      ['employerName', REQUIRED],
      ['contactFirstName'],
      ['contactLastName'],
      ['employerAddress', REQUIRED_ADDRESS],
      [
        'employerPhone',
        featureFlags.internationalPhoneNumbers.isEnabled()
          ? PHONE_NUMBER_INTERNATIONAL
          : PHONE_NUMBER,
      ],
      ['employerTypeId', locale.isNZ ? NO_ERROR : REQUIRED],
    ];
    moreDetails.unshift(['basisId', REQUIRED]);
    if (showNZBNField) {
      moreDetails.unshift(['nzbn', INVALID_NZBN]);
    }
    questions.addBranch(...moreDetails, ...dateQuestions);
    questions.add('saveButton', 'saveAndAddAnotherButton');
  } else {
    questions.addBranch(...dateQuestions);
    questions.add('saveButton', 'saveAndAddAnotherButton');
  }
  return questions.arrayOfQuestions();
};

export class DisconnectedEmploymentForm extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    isLocked: PropTypes.bool.isRequired,
    working: PropTypes.object.isRequired,
    save: PropTypes.func.isRequired,
    saveAndAddAnother: PropTypes.func.isRequired,
    setEmploymentTypeId: PropTypes.func.isRequired,
    setEmploymentBasisId: PropTypes.func.isRequired,
    setEmploymentNatureId: PropTypes.func.isRequired,
    setEmploymentNZBN: PropTypes.func.isRequired,
    setEmploymentStatusId: PropTypes.func.isRequired,
    setEmploymentRole: PropTypes.func.isRequired,
    setEmploymentDateStartedDay: PropTypes.func.isRequired,
    setEmploymentDateStartedMonth: PropTypes.func.isRequired,
    setEmploymentDateStartedYear: PropTypes.func.isRequired,
    setEmploymentDateEndedDay: PropTypes.func.isRequired,
    setEmploymentDateEndedMonth: PropTypes.func.isRequired,
    setEmploymentDateEndedYear: PropTypes.func.isRequired,
    setEmploymentEmployerName: PropTypes.func.isRequired,
    setEmploymentEmployerTypeId: PropTypes.func.isRequired,
    setEmploymentEmployerAddress: PropTypes.func.isRequired,
    setEmploymentEmployerIntlAddress: PropTypes.func.isRequired,
    setEmploymentIsProbation: PropTypes.func.isRequired,
    setEmploymentError: PropTypes.func.isRequired,
    ...manageQuestionsPropTypes,
  };

  static questionsToAsk = questionSet;
  static revealMethod = 'chunks';
  static revealOverBranch = false;

  constructor(props) {
    super(props);
    this.employmentTypeOptions = employmentTypeOptions();
  }

  componentDidMount() {
    const {
      working,
      setEmploymentClientId,
      clientIdOptionsForNonsharable,
    } = this.props;
    if (!working.id && clientIdOptionsForNonsharable.length === 1) {
      setEmploymentClientId(clientIdOptionsForNonsharable[0].value);
    }
  }

  onSubmit = () => {
    const { working, save } = this.props;
    save(working);
  };

  onEmploymentTypeIdChange = (value) => {
    const {
      setEmploymentTypeId,
      setEmploymentEmpoyerPhoneNumber,
      working,
    } = this.props;
    if (value && !EMPLOYED_TYPES.includes(value)) {
      const emptyValue = featureFlags.internationalPhoneNumbers.isEnabled()
        ? { ...working.employerPhone, number: '' }
        : '';
      setEmploymentEmpoyerPhoneNumber(emptyValue);
    }
    setEmploymentTypeId(value);
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  renderSimpleFields() {
    const {
      working,
      clientIdOptionsForNonsharable,
      questions,
      intl,
      isLocked,
      setEmploymentBasisId,
      setEmploymentClientId,
      setEmploymentStatusId,
      setEmploymentNatureId,
      setEmploymentNZBN,
      setEmploymentDateStartedDay,
      setEmploymentDateStartedMonth,
      setEmploymentDateStartedYear,
      setEmploymentDateEndedDay,
      setEmploymentDateEndedMonth,
      setEmploymentDateEndedYear,
      setEmploymentEmployerAddress,
      setEmploymentEmployerIntlAddress,
      setEmploymentEmpoyerPhoneNumber,
      setEmploymentEmployerTypeId,
      setEmploymentRole,
      setEmploymentEmployerName,
      setEmploymentContactFirstName,
      setEmploymentContactLastName,
      setEmploymentIsProbation,
      setEmploymentError,
    } = this.props;

    const currentDate = moment();
    const minimumDate = moment().set({ year: 1950, month: 1, day: 1 });
    const isPreviousJob =
      working.statusId === EMPLOYMENT_STATUS_PREVIOUS_EMPLOYMENT.id;
    const isProbationQ = isPreviousJob
      ? 'isProbationPrevious'
      : 'isProbationCurrent';
    const isProbationQNZ = isPreviousJob
      ? 'isProbationPreviousNZ'
      : 'isProbationCurrentNZ';
    return [
      <Question
        {...questions.statusId}
        label={intl.formatMessage(
          working.statusId ? messages.statusSelect : messages.statusRadio,
        )}
        className='fullWidthInput'
        key='questions.statusId'
      >
        {working.statusId ? (
          <Selection
            action={setEmploymentStatusId}
            value={working.statusId}
            items={EMPLOYMENT_STATUS_OPTIONS}
          />
        ) : (
          <RadioButtonList
            action={setEmploymentStatusId}
            value={working.statusId}
            items={EMPLOYMENT_STATUS_OPTIONS}
          />
        )}
      </Question>,
      <Question
        {...questions.clientId}
        label={intl.formatMessage(
          working.clientId ? messages.clientIdSelect : messages.clientIdRadio,
        )}
        className='fullWidthInput'
        key='questions.clientId'
      >
        {working.clientId ? (
          <Selection
            action={setEmploymentClientId}
            value={working.clientId}
            items={clientIdOptionsForNonsharable}
          />
        ) : (
          <RadioButtonList
            action={setEmploymentClientId}
            value={working.clientId}
            items={clientIdOptionsForNonsharable}
          />
        )}
      </Question>,
      <Question
        {...questions.typeId}
        label={intl.formatMessage(
          working.typeId ? messages.typeSelect : messages.typeRadio,
        )}
        className='fullWidthInput'
        key='questions.typeId'
      >
        {working.typeId ? (
          <Selection
            action={this.onEmploymentTypeIdChange}
            value={working.typeId}
            items={this.employmentTypeOptions}
          />
        ) : (
          <RadioButtonList
            action={this.onEmploymentTypeIdChange}
            value={working.typeId}
            items={this.employmentTypeOptions}
          />
        )}
      </Question>,
      <Question
        {...questions.natureId}
        label={intl.formatMessage(messages.nature)}
        className='fullWidthInput'
        key='questions.natureId'
      >
        {working.natureId ? (
          <Selection
            action={setEmploymentNatureId}
            value={working.natureId}
            items={EMPLOYMENT_NATURE_OPTIONS}
          />
        ) : (
          <RadioButtonList
            action={setEmploymentNatureId}
            value={working.natureId}
            items={EMPLOYMENT_NATURE_OPTIONS}
          />
        )}
      </Question>,
      <Question
        {...questions.nzbn}
        label={intl.formatMessage(messages.nzbn)}
        className='fullWidthInput'
        key='question.nzbn'
      >
        <Input value={working.nzbn} maxLength={13} action={setEmploymentNZBN} />
      </Question>,
      <Question
        {...questions.basisId}
        label={intl.formatMessage(messages.basis)}
        className='fullWidthInput'
        key='questions.basisId'
      >
        {working.basisId ? (
          <Selection
            action={setEmploymentBasisId}
            value={working.basisId}
            items={EMPLOYMENT_BASIS_OPTIONS}
          />
        ) : (
          <RadioButtonList
            action={setEmploymentBasisId}
            value={working.basisId}
            items={EMPLOYMENT_BASIS_OPTIONS}
          />
        )}
      </Question>,
      <Question
        {...questions.role}
        label={intl.formatMessage(messages.role)}
        className='fullWidthInput'
        key='question.role'
      >
        <Input action={setEmploymentRole} value={working.role} />
      </Question>,
      <Question
        {...questions.employerName}
        label={intl.formatMessage(messages.employerName)}
        className='fullWidthInput'
        key='questions.employerName'
      >
        <Input
          action={setEmploymentEmployerName}
          value={working.employerName}
        />
      </Question>,
      <Question
        {...questions.contactFirstName}
        label={intl.formatMessage(messages.contactFirstName)}
        className='fullWidthInput'
        key='questions.contactFirstName'
      >
        <Input
          action={setEmploymentContactFirstName}
          value={working.contactFirstName}
        />
      </Question>,
      <Question
        {...questions.contactLastName}
        label={intl.formatMessage(messages.contactLastName)}
        className='fullWidthInput'
        key='questions.contactLastName'
      >
        <Input
          action={setEmploymentContactLastName}
          value={working.contactLastName}
        />
      </Question>,
      <Question
        key='question.employerAddress'
        {...questions.employerAddress}
        label={intl.formatMessage(messages.employerAddress)}
        className='fullWidthInput'
      >
        <AutocompleteAddress
          errorId='employerAddress'
          addressId={working.id || 'new'}
          setError={setEmploymentError}
          action={setEmploymentEmployerAddress}
          value={working.employerAddress.formattedAddress}
          intlValue={working.employerAddress}
          intlOnChange={setEmploymentEmployerIntlAddress}
          disabled={isLocked}
        />
      </Question>,
      <Question
        {...questions.employerPhone}
        label={intl.formatMessage(messages.employerPhone)}
        className='fullWidthInput'
        key='question.employerPhone'
      >
        {featureFlags.internationalPhoneNumbers.isEnabled() ? (
          <MobileInputIntl
            onChange={setEmploymentEmpoyerPhoneNumber}
            value={intlMobile(working.employerPhone)}
            countryCode={
              working.employerPhone && working.employerPhone.countryCode
            }
            dialCode={working.employerPhone && working.employerPhone.dialCode}
            disabled={isLocked}
          />
        ) : (
          <Input
            value={intlMobile(working.employerPhone)}
            action={setEmploymentEmpoyerPhoneNumber}
            disabled={isLocked}
          />
        )}
      </Question>,
      ...(locale.isNZ
        ? []
        : [
            // eslint-disable-next-line react/jsx-indent
            <Question
              {...questions.employerTypeId}
              label={intl.formatMessage(messages.employerType)}
              className='fullWidthInput'
              key='question.employerTypeId'
            >
              <Selection
                action={setEmploymentEmployerTypeId}
                value={working.employerTypeId}
                items={EMPLOYMENT_EMPLOYER_TYPE_OPTIONS}
              />
            </Question>,
          ]),
      <Question
        {...questions.dateStarted}
        label={intl.formatMessage(messages.dateStarted)}
        className='fullWidthInput'
        key='question.dateStarted'
      >
        <DateSelector
          type='date'
          dayAction={setEmploymentDateStartedDay}
          day={working.dateStarted.day}
          monthAction={setEmploymentDateStartedMonth}
          month={working.dateStarted.month}
          yearAction={setEmploymentDateStartedYear}
          year={working.dateStarted.year}
          minDate={minimumDate}
          maxDate={moment.max(currentDate, moment(working.dateStarted))}
          yearsOrder='descending'
        />
      </Question>,
      <Question
        {...questions.isProbation}
        label={intl.formatMessage(
          messages[locale.isNZ ? isProbationQNZ : isProbationQ],
        )}
        className='fullWidthInput'
        direction='row'
        key='questions.isProbation'
      >
        <RadioButtonList
          action={setEmploymentIsProbation}
          value={working.isProbation}
          items={YES_NO_OPTIONS}
        />
      </Question>,
      <Question
        {...questions.dateEnded}
        label={intl.formatMessage(messages.dateEnded)}
        className='fullWidthInput'
        key='question.dateEnded'
      >
        <DateSelector
          type='date'
          dayAction={setEmploymentDateEndedDay}
          day={working.dateEnded.day}
          monthAction={setEmploymentDateEndedMonth}
          month={working.dateEnded.month}
          yearAction={setEmploymentDateEndedYear}
          year={working.dateEnded.year}
          maxDate={moment.max(currentDate, moment(working.dateEnded))}
          minDate={moment(working.dateStarted)}
        />
      </Question>,
    ];
  }

  renderAccordionContent = () => {
    const {
      isLocked,
      working,
      questions,
      save,
      formCompleted,
      saveAndAddAnother,
      intl,
      remove,
      NZBNLoading,
      setCurrentQuestionTo,
    } = this.props;

    const simpleFields = this.renderSimpleFields();

    return (
      <Form
        id='employmentForm'
        formCompleted={formCompleted}
        onSubmit={this.onSubmit}
        onFocusLost={setCurrentQuestionTo(undefined)}
      >
        <fieldset disabled={isLocked}>
          {simpleFields}
          <Question {...questions.saveButton} className='fullWidthButton'>
            <Button
              theme='linkButton'
              hasErrorMessage={!formCompleted || NZBNLoading}
              errorMessage={
                working.errors &&
                working.errors.nzbn &&
                working.errors.nzbn.text
              }
              onClick={() => save(working)}
            >
              {intl.formatMessage(messages.save)}
            </Button>
          </Question>
          <Question
            {...questions.saveAndAddAnotherButton}
            className='fullWidthButton'
          >
            {working.id ? (
              <Button
                className='brandColor__reverseButton'
                onClick={() => remove(working.id)}
              >
                {intl.formatMessage(messages.remove)}
              </Button>
            ) : (
              <Button
                theme='linkButton'
                hasErrorMessage={!formCompleted || NZBNLoading}
                errorMessage={
                  working.errors &&
                  working.errors.nzbn &&
                  working.errors.nzbn.text
                }
                onClick={() => saveAndAddAnother(working)}
              >
                {intl.formatMessage(messages.saveAndAddAnother)}
              </Button>
            )}
          </Question>
          <input className='hidden' type='submit' />
        </fieldset>
      </Form>
    );
  };

  render() {
    return <Accordion hide>{this.renderAccordionContent}</Accordion>;
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const id = ownProps.working.id || 'new';
  return bindActionCreators(
    {
      setEmploymentClientId: employmentActions.setEmploymentClientId(id),
      setEmploymentTypeId: employmentActions.setEmploymentTypeId(id),
      setEmploymentBasisId: employmentActions.setEmploymentBasisId(id),
      setEmploymentNatureId: employmentActions.setEmploymentNatureId(id),
      setEmploymentNZBN: employmentActions.setEmploymentNZBN(id),
      setEmploymentStatusId: employmentActions.setEmploymentStatusId(id),
      setEmploymentRole: employmentActions.setEmploymentRole(id),
      setEmploymentDateStartedDay: employmentActions.setEmploymentDateStartedDay(
        id,
      ),
      setEmploymentDateStartedMonth: employmentActions.setEmploymentDateStartedMonth(
        id,
      ),
      setEmploymentDateStartedYear: employmentActions.setEmploymentDateStartedYear(
        id,
      ),
      setEmploymentDateEndedDay: employmentActions.setEmploymentDateEndedDay(
        id,
      ),
      setEmploymentDateEndedMonth: employmentActions.setEmploymentDateEndedMonth(
        id,
      ),
      setEmploymentDateEndedYear: employmentActions.setEmploymentDateEndedYear(
        id,
      ),
      setEmploymentEmployerName: employmentActions.setEmploymentEmployerName(
        id,
      ),
      setEmploymentContactFirstName: employmentActions.setEmploymentContactFirstName(
        id,
      ),
      setEmploymentContactLastName: employmentActions.setEmploymentContactLastName(
        id,
      ),
      setEmploymentEmployerAddress: employmentActions.setEmploymentEmployerAddress(
        id,
      ),
      setEmploymentEmployerIntlAddress: employmentActions.setEmploymentEmployerIntlAddress(
        id,
      ),
      setEmploymentEmpoyerPhoneNumber: employmentActions.setEmploymentEmpoyerPhoneNumber(
        id,
      ),
      setEmploymentEmployerTypeId: employmentActions.setEmploymentEmployerTypeId(
        id,
      ),
      setEmploymentIsProbation: employmentActions.setEmploymentIsProbation(id),
      setEmploymentError: employmentActions.setEmploymentError(id),
    },
    dispatch,
  );
};

export default injectIntl(
  connect(
    null,
    mapDispatchToProps,
  )(manageQuestions(DisconnectedEmploymentForm, propsTransformForQuestion)),
);
