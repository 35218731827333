import { DIRECT_EXPENSES_API_PATH } from 'shared/constants/apiPaths';

import {
  axiosWithAuth as directAxiosWithAuth,
  dataTransform as directDataTransform,
  returnJSON as directReturnJSON,
} from './mycrmBaseApi';

import { expenseBuilderForMyCRM } from 'shared/lib/builders/mycrm/expense';
import { expenseBuilderForMilli } from 'shared/lib/builders/milli/expense';

// Begin port of serverless expensesApi
//
const putExpenseDirect = (expenseId, data) => {
  return directAxiosWithAuth
    .put(`${DIRECT_EXPENSES_API_PATH}/${expenseId}`, data)
    .then(directReturnJSON);
};

const deleteExpenseDirect = (expenseId) => {
  return directAxiosWithAuth
    .delete(`${DIRECT_EXPENSES_API_PATH}/${expenseId}`)
    .then(directReturnJSON);
};
//
// End of port of serverless expensesApi

// port of serverless handler: expenses/expenses.put
export function putExpense(expense, isLivingExpensesV2Enabled) {
  return putExpenseDirect(
    expense.id,
    expenseBuilderForMyCRM({
      ...expense,
      isLivingExpensesV2Enabled,
    }),
  )
    .then(expenseBuilderForMilli)
    .then(directDataTransform((data) => data))
    .then(directReturnJSON);
}

// port of serverless handler: expenses/expenses.del
export function delExpense(expenseId) {
  return deleteExpenseDirect(expenseId)
    .then((_) => ({ data: expenseId }))
    .then(directReturnJSON);
}
