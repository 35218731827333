export function fundingBuilderForMilli(funding, loanApplicationId) {
  return {
    name: funding.name,
    description: funding.notes || funding.Description,
    id: funding.funding_id,
    value: funding.value,
    loanApplicationId: loanApplicationId || funding.scenario_id,
    propertyId: funding.property_id,
    isRequired: !!funding.is_only_value_editable,
    type: funding.type,
  };
}

export function fundingDepositTypeForMilli(type) {
  return {
    name: type.Name,
    value: type.Name,
    label: type.Name,
    key: type.Name,
  };
}
