import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { intlShape, injectIntl } from 'react-intl';

import incomeActions from 'actions/incomeActions';
import employmentActions from 'actions/employmentActions';
import UIActions from 'actions/UIActions';

import * as incomeSelectors from 'selectors/incomeSelectors';
import * as clientSelectors from 'selectors/clientSelectors';
import * as UISelectors from 'selectors/UISelectors';
import * as applicationSelectors from 'selectors/applicationSelectors';
import * as employmentSelectors from 'selectors/employmentSelectors';
import * as incomeTypeSelectors from 'selectors/incomeTypeSelectors';

import FormPopup, { formPopupProps } from 'components/PopupItem/FormPopup';
import IncomeForm from 'components/PopupForms/IncomeForm';

import { logEvent, EVENTS } from 'lib/amplitude';
import { INCOMES_SLUG } from 'constants/applyData';

class IncomePopup extends FormPopup {
  static propTypes = {
    ...formPopupProps,
    intl: intlShape.isRequired,
    setIncomeClientIds: PropTypes.func.isRequired,
    setIncomeType: PropTypes.func.isRequired,
    setIncomeCategory: PropTypes.func.isRequired,
    setIncomeDescription: PropTypes.func.isRequired,
    setIncomeValue: PropTypes.func.isRequired,
    setIncomeFrequency: PropTypes.func.isRequired,
    setIncomeIsGross: PropTypes.func.isRequired,
    setIncomeError: PropTypes.func.isRequired,
    setEmploymentEmployerAddress: PropTypes.func.isRequired,
    setEmploymentEmployerIntlAddress: PropTypes.func.isRequired,
    setEmploymentBasisId: PropTypes.func.isRequired,
    NZBNLoading: PropTypes.bool.isRequired,
    incomeTypes: PropTypes.object,
    incomeTypeCategories: PropTypes.arrayOf(PropTypes.object),
    rentalIncomeCategory: PropTypes.object,
    categoryRequiresEmployment: PropTypes.func,
    categoryFieldLabel: PropTypes.func,
    categoryFieldDescription: PropTypes.func,
  };

  title() {
    const { working } = this.props;
    const formStateTitle = super.title();
    if (formStateTitle) {
      return `${formStateTitle} income`;
    }
    return working.id ? 'Edit your income' : 'Add an income';
  }

  value = () => undefined;

  logSaveEvent() {
    logEvent(EVENTS.SAVE_FINANCIALS, { section: INCOMES_SLUG });
  }

  logSaveAndAddEvent() {
    logEvent(EVENTS.SAVE_AND_ADD_FINANCIALS, { section: INCOMES_SLUG });
  }

  logRemoveEvent() {
    logEvent(EVENTS.REMOVE_FINANCIALS, { section: INCOMES_SLUG });
  }

  setIncomeCategoryAndType = (value) => {
    const { setIncomeCategory, incomeTypes } = this.props;
    setIncomeCategory({ category: value, incomeTypes });
  };

  renderForm() {
    const {
      isLocked,
      working,
      clientIdOwnershipOptions,
      clientIdOwnershipOptionsNonSharable,
      employmentOptions,
      setIncomeClientIds,
      setIncomeType,
      setIncomeDescription,
      setIncomeValue,
      setIncomeFrequency,
      setIncomeIsGross,
      setIncomeEmploymentId,
      setIncomeError,
      workingNewEmployment,
      setEmploymentEmployerName,
      setEmploymentNatureId,
      setEmploymentNZBN,
      setEmploymentTypeId,
      setEmploymentStatusId,
      setEmploymentBasisId,
      setEmploymentEmployerAddress,
      setEmploymentEmployerIntlAddress,
      NZBNLoading,
      incomeTypes,
      incomeTypeCategories,
      rentalIncomeCategory,
      categoryRequiresEmployment,
      categoryFieldLabel,
      categoryFieldDescription,
    } = this.props;

    return (
      <IncomeForm
        key={`incomePopupForm-${this.state.refreshKey}`}
        isLocked={isLocked}
        clientIdOwnershipOptions={clientIdOwnershipOptions}
        clientIdOwnershipOptionsNonSharable={
          // eslint-disable-next-line react/jsx-indent
          clientIdOwnershipOptionsNonSharable
        }
        employmentOptions={employmentOptions}
        working={working}
        workingNewEmployment={workingNewEmployment}
        save={this.save}
        remove={this.remove}
        saveAndAddAnother={this.saveAndAddAnother}
        setIncomeClientIds={setIncomeClientIds}
        setIncomeType={setIncomeType}
        setIncomeCategory={this.setIncomeCategoryAndType}
        setIncomeDescription={setIncomeDescription}
        setIncomeValue={setIncomeValue}
        setIncomeFrequency={setIncomeFrequency}
        setIncomeIsGross={setIncomeIsGross}
        setIncomeEmploymentId={setIncomeEmploymentId}
        setIncomeError={setIncomeError}
        setEmploymentEmployerName={setEmploymentEmployerName}
        setEmploymentTypeId={setEmploymentTypeId}
        setEmploymentBasisId={setEmploymentBasisId}
        setEmploymentNatureId={setEmploymentNatureId}
        setEmploymentNZBN={setEmploymentNZBN}
        setEmploymentStatusId={setEmploymentStatusId}
        setEmploymentEmployerAddress={setEmploymentEmployerAddress}
        setEmploymentEmployerIntlAddress={setEmploymentEmployerIntlAddress}
        NZBNLoading={NZBNLoading}
        incomeTypes={incomeTypes}
        incomeTypeCategories={incomeTypeCategories}
        rentalIncomeCategory={rentalIncomeCategory}
        categoryRequiresEmployment={categoryRequiresEmployment}
        categoryFieldLabel={categoryFieldLabel}
        categoryFieldDescription={categoryFieldDescription}
      />
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  const paramId = ownProps.match.params.id;
  const id = parseInt(paramId, 10) || paramId || 'new';
  return {
    id,
    item: incomeSelectors.entity(state)(id),
    working: incomeSelectors.working(state)(id),
    clientIdOwnershipOptions: clientSelectors.clientIdOwnershipOptions(state),
    clientIdOwnershipOptionsNonSharable: clientSelectors.clientIdOwnershipOptionsNonSharable(
      state,
    ),
    requestIsProcessing: UISelectors.requestProcessing(state)(id),
    employmentOptions: employmentSelectors.employmentOptions(state),
    workingNewEmployment: employmentSelectors.working(state)('new'),
    errors: UISelectors.requestErrors(state),
    isLocked: applicationSelectors.getIsLocked(state),
    NZBNLoading: employmentSelectors.NZBNLoading(state),
    incomeTypes: incomeTypeSelectors.incomeTypes(state),
    incomeTypeCategories: incomeTypeSelectors.incomeTypeCategories(state),
    categoryRequiresEmployment: incomeTypeSelectors.categoryRequiresEmployment(
      state,
    ),
    categoryFieldLabel: incomeTypeSelectors.categoryFieldLabel(state),
    categoryFieldDescription: incomeTypeSelectors.categoryFieldDescription(
      state,
    ),
    rentalIncomeCategory: incomeTypeSelectors.rentalIncomeCategory(state),
    formPopUpStatus: UISelectors.formPopUpStatus(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const paramId = ownProps.match.params.id;
  const id = parseInt(paramId, 10) || paramId || 'new';
  return bindActionCreators(
    {
      load: incomeActions.loadIncome,
      create: incomeActions.createIncome,
      update: incomeActions.updateIncome,
      delete: incomeActions.deleteIncome,
      clearWorking: incomeActions.clearWorkingIncome,
      clearErrors: UIActions.clearAsyncRequestErrors,
      resetFormPopupStatus: UIActions.resetFormPopupStatus,
      setIncomeClientIds: incomeActions.setIncomeClientIds(id),
      setIncomeType: incomeActions.setIncomeType(id),
      setIncomeCategory: incomeActions.setIncomeCategory(id),
      setIncomeDescription: incomeActions.setIncomeDescription(id),
      setIncomeValue: incomeActions.setIncomeValue(id),
      setIncomeFrequency: incomeActions.setIncomeFrequency(id),
      setIncomeIsGross: incomeActions.setIncomeIsGross(id),
      setIncomeEmploymentId: incomeActions.setIncomeEmploymentId(id),
      setIncomeError: incomeActions.setIncomeError(id),
      setEmploymentEmployerName: employmentActions.setEmploymentEmployerName(
        'new',
      ),
      setEmploymentEmployerAddress: employmentActions.setEmploymentEmployerAddress(
        'new',
      ),
      setEmploymentEmployerIntlAddress: employmentActions.setEmploymentEmployerIntlAddress(
        'new',
      ),
      setEmploymentStatusId: employmentActions.setEmploymentStatusId('new'),
      setEmploymentTypeId: employmentActions.setEmploymentTypeId('new'),
      setEmploymentBasisId: employmentActions.setEmploymentBasisId('new'),
      setEmploymentNatureId: employmentActions.setEmploymentNatureId('new'),
      setEmploymentNZBN: employmentActions.setEmploymentNZBN('new'),
    },
    dispatch,
  );
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(IncomePopup),
);
