import { takeEvery, all, put, call, select, fork } from 'redux-saga/effects';

import {
  REQUEST_ADVISOR_INFO,
  REQUEST_ADVISOR_PHOTO,
  REQUEST_ADVISOR_LOGIN_REDIRECT,
  REQUEST_ADVISER_FEATURES,
} from 'actions/advisorActionTypes';
import advisorActions from 'actions/advisorActions';
import scenarioActions from 'actions/scenarioActions';
import goalLoanApplicationActions from 'actions/goal/goalLoanApplicationActions';

import {
  getAdvisorInfo,
  getAdvisorProfilePhoto,
  getAdviserSubscriptions,
} from 'services/advisorsApi';

import LocalStorageProxy, { GOAL_EXEMPTED_PROPS } from 'lib/localStorageProxy';
import { monitorAsyncRequest } from 'lib/sagaHelpers.js';
import UIActions from 'actions/UIActions';
import { fetchUserData } from 'sagas/userSagas';
import { requestAggregatorByFamilyId } from 'sagas/aggregatorSagas';
import { requestGoalLoanApplicationData } from 'sagas/goal/goalLoanApplicationSagas';
import { getIdTokenClaims } from 'lib/okta';

import { toMyCRMLogin } from 'lib/pathHelper';

import * as advisorSelectors from 'selectors/advisorSelectors';
import * as UISelectors from 'selectors/UISelectors';

import { OKTA_GROUPS } from 'constants/okta-groups';
import { removeChatWidget } from 'lib/prepareStore';
import * as applicationSelectors from 'selectors/applicationSelectors';
import { APPLY_BASE_PATH } from 'shared/constants/paths';
import { push } from '@loan_market/react-router-redux-multi';

const HALF_BODY_PHOTO_TYPE = 2;

export function* requestAdvisorInfo({ payload }) {
  try {
    const advisor = yield call(getAdvisorInfo, payload);
    yield fork(requestAggregatorByFamilyId, payload.familyId);

    yield put(advisorActions.setAdvisorInfo(advisor));
    yield put(scenarioActions.setAllocatedBrokerFamilyId(advisor.familyId));
    LocalStorageProxy.brokerFamilyId = advisor.familyId;
    removeChatWidget();
    return advisor;
  } catch (error) {
    yield put(UIActions.setPageError({ status: 404, error }));
    throw error;
  }
}

export function* requestAdvisorPhoto({ payload }) {
  try {
    const { familyId } = payload;
    const { profilePhoto: halfBodyImage } = yield call(getAdvisorProfilePhoto, {
      familyId,
      type: HALF_BODY_PHOTO_TYPE,
    });
    yield put(advisorActions.setAdvisorInfo({ halfBodyImage }));
  } catch (error) {
    yield put(UIActions.setPageError({ status: 404, error }));
    throw error;
  }
}

export function* fetchClientFromBrokerLogin(brokerFamilyId) {
  try {
    yield call(fetchUserData, false, brokerFamilyId);
    const applicationId = yield select(applicationSelectors.getApplicationId);
    const applyPath = `${APPLY_BASE_PATH}/${applicationId}`;
    yield put(push(applyPath));
  } catch (error) {
    yield put(UIActions.setPageError({ status: 403, err: error }));
    LocalStorageProxy.clearAll();
  }
}

export function* fetchGoalLoanApplication(loanApplicationId) {
  try {
    const applicationIsRead = yield select(UISelectors.applicationIsRead);
    if (applicationIsRead) {
      return;
    }
    yield call(requestGoalLoanApplicationData, loanApplicationId);
    yield put(
      goalLoanApplicationActions.setActiveGoalLoanApp(
        Number(loanApplicationId),
      ),
    );
    yield put(
      UIActions.startAnimationSequence(['/checkout-appointment-companion']),
    );
    const adviser = yield select(advisorSelectors.advisor);
    yield put(advisorActions.requestAdviserFeatures(adviser.familyId));
    yield put(UIActions.setApplicationIsRead());
  } catch (error) {
    console.error(error); // eslint-disable-line no-console
    LocalStorageProxy.clearAllExcept(GOAL_EXEMPTED_PROPS);
    yield put(
      advisorActions.setAdvisorError({
        id: 'brokerLogin',
        text: 'You don’t have permission to view this loan application.',
        block: false,
      }),
    );
    throw error;
  }
}

export function* requestAdvisorLoginRedirect({ payload }) {
  const { isGoalSetter, loanApplicationId, oktaAuth } = payload;
  yield put(UIActions.startFullPageSpinner());
  try {
    const { email, familyId, groups } = yield getIdTokenClaims(oktaAuth);
    LocalStorageProxy.brokerLoginUsername = email;
    if (isGoalSetter) {
      yield call(fetchGoalLoanApplication, loanApplicationId);
    } else if (
      groups &&
      (groups.includes(OKTA_GROUPS.ADVISER) ||
        groups.includes(OKTA_GROUPS.CORPORATE))
    ) {
      // Broker Login
      yield call(fetchClientFromBrokerLogin, familyId);
    }
    yield put(UIActions.stopFullPageSpinner());
  } catch (error) {
    window.location.href = toMyCRMLogin(window.location.href);
  }
}

export function* requestAdviserFeatures({ payload: adviserId }) {
  try {
    const adviserFeatureSubs = yield call(getAdviserSubscriptions, adviserId);
    yield put(
      advisorActions.setAdvisorInfo({
        subscriptions: adviserFeatureSubs,
      }),
    );
  } catch (error) {
    console.error('Failed to load adviser features.', error);
    throw error;
  }
}

export default function* advisorSagas() {
  yield all([
    yield monitorAsyncRequest(
      takeEvery,
      REQUEST_ADVISOR_INFO,
      requestAdvisorInfo,
    ),
    yield monitorAsyncRequest(
      takeEvery,
      REQUEST_ADVISOR_PHOTO,
      requestAdvisorPhoto,
    ),
    yield monitorAsyncRequest(
      takeEvery,
      REQUEST_ADVISOR_LOGIN_REDIRECT,
      requestAdvisorLoginRedirect,
    ),
    yield monitorAsyncRequest(
      takeEvery,
      REQUEST_ADVISER_FEATURES,
      requestAdviserFeatures,
    ),
  ]);
}
