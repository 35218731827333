import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import _ from 'lodash';

import { POSITIVE_CHART_SPECTRUM } from 'constants/colours';
import {
  formatCurrency,
  formatPercentage,
  formatFrequency,
} from 'lib/intlFormatters';

import Report from 'components/Report/Report';
import SectionItem from 'components/SectionItem/SectionItem';
import Section from 'components/Section/Section';
import SectionGroup from 'components/SectionGroup/SectionGroup';
import DonutChart from 'components/DonutChart/DonutChart';

const ReportContent = ({
  data: reportData,
  intl,
  title,
  frequency,
  id,
  spectrum,
  simpleDonutSection,
  theme,
  children,
  isLocked,
  onSectionClick,
  rightIconSvg,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  // extract data only for generating donut
  const donutData = reportData
    .filter((s) => !s.displayOnly)
    .map((section, index) => {
      const useSectionItemTotal =
        Array.isArray(section.items) &&
        section.items.filter((v) => typeof v.value === 'number' && !v.ignore)
          .length > 0;
      return {
        isChartTotal: section.isChartTotal,
        colour:
          section.donutColour ||
          section.colour ||
          spectrum[index % spectrum.length],
        label: section.shortLabel || section.label,
        displayNegative: section.negative,
        data: useSectionItemTotal
          ? section.items.map((item) => item.value)
          : [section.value],
        total: useSectionItemTotal
          ? _.sumBy(section.items, 'value')
          : section.value,
      };
    });

  const basis = donutData.splice(
    donutData.findIndex((d) => d.isChartTotal),
    1,
  );
  let basisLabel = 'Total';
  let basisTotal;
  if (basis && basis.length > 0) {
    basisLabel = basis[0].label;
    basisTotal = basis[0].total;
  }

  const renderSections = (
    section,
    index,
    colourIndex = null,
    isGroup = false,
  ) => {
    if (section.hidden) {
      return null;
    }
    const colour =
      section.colour || spectrum[(colourIndex || index) % spectrum.length];

    if (section.isGroup) {
      let startIndex = index;
      const totalValue =
        _.sumBy(section.items, (item) => _.sumBy(item.items, 'value')) || 0;
      const formatedTotalvalue = formatCurrency(intl)(
        section.negative ? -totalValue : totalValue,
      );
      return (
        <SectionGroup
          key={`Group_${section.label}`}
          title={section.label}
          colour={colour}
          totalValue={formatedTotalvalue}
          valueInfoLabel={formatFrequency(intl, frequency)}
        >
          {section.items.map((childSection, childIndex) => {
            startIndex += 1;
            return renderSections(childSection, childIndex, startIndex, true);
          })}
        </SectionGroup>
      );
    }

    const sectionValue = section.value || _.sumBy(section.items, 'value') || 0;
    const value = formatCurrency(intl)(
      section.negative ? -sectionValue : sectionValue,
    );
    const noHeading = index === 0 && simpleDonutSection;
    let content = [];

    if (section.items) {
      content = content.concat(
        section.items.map((item, i) => {
          const name =
            typeof item.name === 'function' ? item.name(intl) : item.name;
          return (
            <SectionItem
              key={`${_.kebabCase(name)}-${i}`}
              valueInfoLabel={formatFrequency(intl, frequency)}
              {...item}
              formatValue={
                item.formatValue ? item.formatValue(intl) : formatCurrency(intl)
              }
              name={name}
              isGroup={isGroup}
              isAccordion={section.isAccordion}
              isOldData={item.isOldData && !isLocked}
              onClick={onSectionClick}
              rightIconSvg={rightIconSvg}
            />
          );
        }),
      );
    }
    return (
      <Section
        key={section.label}
        header={{
          value,
          name: section.label,
        }}
        isOpenByDefault={section.isOpenByDefault}
        {...section.sectionProps}
        colour={colour}
        icon={<i className='mi-arrow-with-circle-down' />}
        closedIcon={<i className='mi-arrow-with-circle-up' />}
        valueInfoLabel={formatFrequency(intl, frequency)}
        noHeading={noHeading}
        isAccordion={section.isAccordion}
      >
        {content}
      </Section>
    );
  };

  const report = reportData.map((section, index) =>
    renderSections(section, index),
  );
  return (
    <Report id={id} title={title} theme={theme}>
      <DonutChart
        id={basisLabel}
        key={`DonutChart-${id}`}
        data={donutData}
        label={basisLabel}
        value={basisTotal}
        percentageFormatter={formatPercentage(intl)}
        valueFormatter={formatCurrency(intl)}
        valueInfoLabel={formatFrequency(intl, frequency)}
        simple={simpleDonutSection}
      />
      {report}
      {children}
    </Report>
  );
};

ReportContent.propTypes = {
  intl: intlShape.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  spectrum: PropTypes.arrayOf(PropTypes.string).isRequired,
  id: PropTypes.string,
  title: PropTypes.string,
  frequency: PropTypes.number,
  simpleDonutSection: PropTypes.bool,
  theme: PropTypes.string,
  children: PropTypes.node,
  isLocked: PropTypes.bool,
  onSectionClick: PropTypes.func,
  rightIconSvg: PropTypes.element,
};

ReportContent.defaultProps = {
  spectrum: POSITIVE_CHART_SPECTRUM,
  simpleDonutSection: false,
};

export default injectIntl(ReportContent);
