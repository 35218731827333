import { EMPLOYMENT_STATUS_PREVIOUS_EMPLOYMENT } from 'shared/constants/myCRMTypes/employments';
import { unformatDate } from 'shared/lib/utils';
import { historyBuilderForMilli } from './history';

export function employmentBuilderForMilli(employment, contactId) {
  let dateEnded;
  if (
    employment.EmploymentStatusId !== EMPLOYMENT_STATUS_PREVIOUS_EMPLOYMENT.id
  ) {
    dateEnded = {};
  } else {
    dateEnded = unformatDate(employment.EndDateTime) || {};
  }
  return {
    id: employment.EmploymentId,
    clientId: employment.ClientId,
    typeId: employment.EmploymentTypeId,
    basisId: employment.EmploymentBasisId,
    basisName: employment.EmploymentBasisName,
    statusId: Number.isInteger(employment.EmploymentStatusId)
      ? employment.EmploymentStatusId
      : null,
    natureId: employment.EmploymentNatureID,
    entityId: employment.EntityId,
    isCurrent: !employment.DateEnded,
    nzbn: employment.NZBN,
    role: employment.EmploymentRole,
    employerName: employment.EmployerName,
    contactFirstName: employment.EmployerContactFirstName,
    contactLastName: employment.EmployerContactLastName,
    employerPhone: employment.EmployerNumber,
    employerTypeId: employment.EmployerTypeId,
    isProbation: employment.IsProbation,
    employerAddress: {
      formattedAddress: employment.Address,
      countryCode: employment.CompleteAddress.CountryCode,
    },
    dateStarted: employment.DateStarted
      ? unformatDate(employment.StartDateTime)
      : {},
    dateEnded,
    contactId: Number(contactId),
    addressId: employment.AddressId,
    completeAddress: employment.CompleteAddress,
    ...historyBuilderForMilli(employment),
  };
}
