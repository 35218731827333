export const PROFILE_SECTIONS = {
  GOAL: 'yourGoals',
  APPLICANT: 'yourDetails',
  FAMILY: 'yourHouseHold',
  COAPPLICANTS: 'coApplicants',
  RESIDENCE: 'yourHome',
  TO_PURCHASE: 'toPurchase',
  PROPERTIES: 'otherProperties',
  ASSETS: 'assets',
  LIABILITIES: 'liabilities',
  INCOMES: 'income',
  EXPENSES: 'expenses',
  ADDRESSES: 'addressHistory',
  EMPLOYMENTS: 'employmentHistory',
  FORESEEABLE_CHANGES: 'foreseeableChanges',
  CREDIT_HISTORY: 'creditHistory',
  LOAN_AMOUNT: 'loanAmount',
  DOCUMENTS: 'supportingDocuments',
  LENDERS: 'lenders',
  LOAN_FEATURES: 'loanFeatures',
  FUTURE_PLANS: 'futurePlans',
  SERVICES: 'services',
};

export const PROFILE_CATEGORIES = {
  GOALS: 'goals',
  APPLICANTS: 'applicants',
  PROPERTIES: 'properties',
  FINANCIAL_POSITION: 'financialPosition',
  HISTORY: 'history',
  LOAN_AMOUNT_APPLY: 'loanAmountApply',
  PREFERENCES: 'preferences',
  LOOKING_AHEAD: 'lookingAhead',
  SERVICES: 'services',
  SUPPORTING_INFORMATION: 'supportingInformation',
};

export const PROFILE_SECTION_CATEGORIES = {
  [PROFILE_CATEGORIES.GOALS]: [PROFILE_SECTIONS.GOAL],
  [PROFILE_CATEGORIES.APPLICANTS]: [
    PROFILE_SECTIONS.APPLICANT,
    PROFILE_SECTIONS.FAMILY,
    PROFILE_SECTIONS.COAPPLICANTS,
  ],
  [PROFILE_CATEGORIES.PROPERTIES]: [
    PROFILE_SECTIONS.RESIDENCE,
    PROFILE_SECTIONS.TO_PURCHASE,
    PROFILE_SECTIONS.PROPERTIES,
  ],
  [PROFILE_CATEGORIES.FINANCIAL_POSITION]: [
    PROFILE_SECTIONS.ASSETS,
    PROFILE_SECTIONS.LIABILITIES,
    PROFILE_SECTIONS.INCOMES,
    PROFILE_SECTIONS.EXPENSES,
  ],
  [PROFILE_CATEGORIES.HISTORY]: [
    PROFILE_SECTIONS.ADDRESSES,
    PROFILE_SECTIONS.EMPLOYMENTS,
    PROFILE_SECTIONS.FORESEEABLE_CHANGES,
    PROFILE_SECTIONS.CREDIT_HISTORY,
  ],
  [PROFILE_CATEGORIES.LOAN_AMOUNT_APPLY]: [PROFILE_SECTIONS.LOAN_AMOUNT],
  [PROFILE_CATEGORIES.SUPPORTING_INFORMATION]: [PROFILE_SECTIONS.DOCUMENTS],
  [PROFILE_CATEGORIES.PREFERENCES]: [
    PROFILE_SECTIONS.LENDERS,
    PROFILE_SECTIONS.LOAN_FEATURES,
  ],
  [PROFILE_CATEGORIES.LOOKING_AHEAD]: [PROFILE_SECTIONS.FUTURE_PLANS],
  [PROFILE_CATEGORIES.SERVICES]: [PROFILE_SECTIONS.SERVICES],
};

export const GOAL_SETTER_PROFILE_CATEGORIES = [
  PROFILE_CATEGORIES.PREFERENCES,
  PROFILE_CATEGORIES.LOOKING_AHEAD,
  PROFILE_CATEGORIES.SERVICES,
];

export const PROFILE_SLUG_TITLE_MAP = {
  goal: 'Your Goals',
  applicant: 'Your Details',
  family: 'Your Household',
  coapplicants: 'Co-Applicants',
  residence: 'Your Home',
  'to-purchase': 'To Purchase',
  properties: 'Other Properties',
  assets: 'Assets',
  liabilities: 'Liabilities',
  incomes: 'Income',
  expenses: 'Expenses',
  addresses: 'Address History',
  employments: 'Employment',
  'foreseeable-changes': 'Foreseeable Changes',
  'credit-history': 'Credit',
  'loan-amount': 'Loan Amount',
  documents: 'Supporting Documents',
  'current-bank': 'Lenders',
  'loan-options': 'Loan Features',
  services: 'Services',
  'long-term-goals': 'Future Plans',
};
