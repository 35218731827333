export const CLEAR_WORKING_APPLICATION = 'clearWorkingApplication';
export const LOAD_APPLICATION = 'loadApplication';
export const SET_LOAN_APPLICATION = 'setLoanApplication';
export const MERGE_LOAN_APPLICATION = 'mergeLoanApplication';

export const SET_LENDER = 'setLender';
export const SET_LOAN_PRODUCT = 'setLoanProduct';

export const SET_PRIMARY_GOALS = 'setPrimaryGoals';
export const SET_SPECIFIC_GOAL = 'setSpecificGoal';
export const SET_WHAT_YOU_WANT_TODO = 'setWhatYouWantTodo';
export const SET_HAS_IMPORTANT_DATES = 'setHasImportantDates';
export const SET_HAS_IMPORTANT_DATE_OF_TYPE = 'setHasImportantDateOfType';
export const SET_IMPORTANT_DATE_YEAR = 'setImportantDateYear';
export const SET_IMPORTANT_DATE_MONTH = 'setImportantDateMonth';
export const SET_IMPORTANT_DATE_DAY = 'setImportantDateDay';

export const SET_FORESEEABLE_CHANGE_DETAILS = 'setForeseeableChangeDetails';

export const SET_LIFE_INSURANCE = 'setLifeInsurance';
export const SET_HOME_INSURANCE = 'setHomeInsurance';

export const SET_BANKRUPT = 'setBankrupt';
export const SET_BEHIND_IN_PAYMENTS = 'setBehindInPayments';
export const SET_DIRECTOR = 'setDirector';
export const SET_CONDUCT = 'setConduct';

export const SET_BANKRUPT_DETAIL = 'setBankruptDetail';
export const SET_BEHIND_IN_PAYMENTS_DETAIL = 'setBehindInPaymentsDetail';
export const SET_DIRECTOR_DETAIL = 'setDirectorDetail';

export const SET_REVIEW_FINANCES = 'setReviewFinances';

export const SET_METADATA = 'setMetadata';
export const REMOVE_METADATA = 'removeMetadata';
export const SET_FORESEES_CHANGES_METADATA = 'setForeseesChangesMetadata';

export const CREATE_CONTACT_ON_APPLICATION = 'createContactOnApplication';
export const READ_CONTACT_ON_APPLICATION = 'readContactOnApplication';
export const UPDATE_CONTACT_ON_APPLICATION = 'updateContactOnApplication';
export const DELETE_CONTACT_ON_APPLICATION = 'deleteContactOnApplication';

export const SET_SECURITIES = 'setSecurities';
export const REQUEST_SECURITIES = 'requestSecurities';

export const REQUEST_GOAL_SETTER_QUESTIONS_AND_ANSWERS =
  'requestGoalSetterQuestionsAndAnswers';
export const READ_LOAN_APPLICATION = 'readLoanApplication';
export const UPDATE_LOAN_APPLICATION = 'updateLoanApplication';
export const SEND_LOAN_APPLICATION_FOR_REVIEW = 'sendLoanApplicationForReview';

export const REQUEST_LOAN_APPLICATION_STATUS = 'requestLoanApplicationStatus';
export const SET_LOAN_APPLICATION_STATUS = 'setLoanApplicationStatus';

export const SET_NEW_WORKING_APPLICATION = 'setNewWorkingApplication';
export const SET_NEW_WORKING_APPLICANT = 'setNewWorkingApplicant';

export const CONFIRM_LOAN_APPLICATION = 'confirmLoanApplication';
