/* eslint-disable prettier/prettier */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import classNames from 'classnames/bind';

import locale from 'config/locale';
import { arrayOfStringsObjectsNodes } from 'types/customPropTypes';
import * as privacyPolicySelectors from 'selectors/privacyPolicySelectors';

import styles from './ComplianceFooter.css';

const messages = defineMessages({
  standardDisclaimerText: {
    id: 'ComplianceFooter.standardDisclaimer.text',
    defaultMessage: `As with any financial scenario there are risks involved.
          This illustration provides an overview or summary only and it should not be considered a comprehensive analysis.
          You should before acting in reliance upon this illustration seek independent professional lending or taxation advice as appropriate specific to your objectives, financial circumstances or needs.
          Information included in the illustration has been sourced from third parties and has not been independently verified.
          Terms, conditions, fees and charges may apply. Normal lending criteria apply. Rates subject to change. Approved applicants only.`,
  },
  standardDisclaimerTextNZPart1: {
    id: 'ComplianceFooter.standardDisclaimerTextNZPart1.text',
    defaultMessage: `A Financial Adviser making a financial recommendation must have a reasonable basis for making that
     recommendation. This means that the Financial Adviser must conduct an appropriate investigation as to the financial
      goals, financial situation and particular needs of the person/s concerned, and any recommendations must take this
       into account. The information requested in this fact find is sought to enable a recommendation/s to be made on a
        reasonable basis, and will be used for that purpose. Although you are free to select products yourself,
         we strongly advise you to give full and complete information to enable us to provide you with a recommendation.`,
  },
  standardDisclaimerTextNZPart2: {
    id: 'ComplianceFooter.standardDisclaimerTextNZPart2.text',
    defaultMessage: `It is recognized that the information you have supplied in this document is personal and we undertake to keep it
     confidential.  The information will be retained so that we can provide ongoing service to you. You have a right
      under The Privacy Act 2020 to obtain access to and to request correction of any personal information held by us
       about you. To make this request, simply contact us.`,
  },
  copyrightText: {
    id: 'ComplianceFooter.copyright.text',
    defaultMessage: '© {copyright}',
  },
  privacyPolicyText: {
    id: 'ComplianceFooter.privacyPolicy.text',
    defaultMessage: 'Privacy policy',
  },
  termsAndConditionsText: {
    id: 'ComplianceFooter.termsAndConditions.text',
    defaultMessage: 'Terms and Conditions',
  },
});

const ComplianceFooter = ({
  footerDisclaimer,
  hideStandardDisclaimer,
  brand,
  intl,
  inverse,
  privacyPolicyLink,
  isOwnBrand,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  return (
    <footer className={classNames.bind(styles)('root', { inverse })}>
      <div className={styles.container}>
        <div className={styles.disclaimer}>
          {footerDisclaimer && (
            <div className={styles.customisedDisclaimer}>
              {footerDisclaimer.map((disclaimer, index) => (
                <div key={`footer-${index}`} dangerouslySetInnerHTML={{__html: disclaimer}} />
              ))}
            </div>
          )}
          {!hideStandardDisclaimer && (
            <div className={styles.standardDisclaimer}>
              {locale.isNZ ? (
                <div className={styles.NZDisclaimers}>
                  <span>
                    {intl.formatMessage(messages.standardDisclaimerTextNZPart1)}
                  </span>
                  <span>
                    {intl.formatMessage(messages.standardDisclaimerTextNZPart2)}
                  </span>
                </div>
              ) : (
                intl.formatMessage(messages.standardDisclaimerText)
              )}
            </div>
          )}
        </div>
        {!locale.isNZ && (
          <div className={styles.flexFooter}>
            <span className={styles.complianceItem}>
              <a
                href={privacyPolicyLink}
                target='_blank'
                rel='noopener noreferrer'
              >
                {intl.formatMessage(messages.privacyPolicyText)}
              </a>
            </span>
            <span className={styles.complianceItem}>
              <a
                href={`${
                  isOwnBrand
                    ? 'https://loanmarketgroup.com'
                    : locale.data.website
                }/terms-and-conditions`}
                target='_blank'
                rel='noopener noreferrer'
              >
                {intl.formatMessage(messages.termsAndConditionsText)}
              </a>
            </span>
          </div>
        )}
      </div>
    </footer>
  );
};

ComplianceFooter.propTypes = {
  brand: PropTypes.string,
  footerDisclaimer: arrayOfStringsObjectsNodes,
  hideStandardDisclaimer: PropTypes.bool,
  inverse: PropTypes.bool,
  intl: intlShape.isRequired,
  privacyPolicyLink: PropTypes.string,
  isOwnBrand: PropTypes.bool,
};

ComplianceFooter.defaultProps = {
  inverse: false,
};

const mapStateToProps = (state) => ({
  advisor: state.advisor,
  isOwnBrand: state.advisorOrg.isOwnBrand,
  privacyPolicyLink: privacyPolicySelectors.selectPrivacyPolicyLink(state)
});

export default connect(mapStateToProps)(injectIntl(ComplianceFooter));
