import { arrayOfTruthyKeys } from 'shared/lib/utils';
import {
  SCENARIO_PURPOSE_MAP,
  REFINANCE_PURPOSE_MAP,
} from 'shared/constants/myCrmMaps';

export function buildPurposeFromScenario(scenario) {
  return [SCENARIO_PURPOSE_MAP[scenario.name]]
    .concat(
      arrayOfTruthyKeys(scenario.refinanceReasons).map(
        (refinanceReason) => REFINANCE_PURPOSE_MAP[refinanceReason],
      ),
    )
    .filter((purpose) => purpose);
}
