import {
  DIRECT_FINANCIAL_GOAL_API_PATH,
  DIRECT_LM_FINANCIAL_GOAL_API_PATH,
} from 'shared/constants/apiPaths';

import {
  axiosWithAuth as directAxiosWithAuth,
  returnJSON as directReturnJSON,
} from '../mycrmBaseApi';

import { featureFlags } from '../../lib/rollout';
const financialGoalUpgradeClenchFlagName =
  'consumer.direct-to-mycrm-api.financial-goal';

export function getFinancialGoalTypesDirect(brokerId) {
  return directAxiosWithAuth
    .get(`${DIRECT_FINANCIAL_GOAL_API_PATH}/type`, {
      params: { brokerId },
    })
    .then(directReturnJSON);
}

export function getFinancialGoalTypesByFamilyDirect(familyId, brokerId) {
  const financialGoalUpgradeClench = featureFlags[
    financialGoalUpgradeClenchFlagName
  ].isEnabled();
  const FINANCIAL_GOAL_API_PATH = financialGoalUpgradeClench
    ? DIRECT_LM_FINANCIAL_GOAL_API_PATH
    : DIRECT_FINANCIAL_GOAL_API_PATH;
  return directAxiosWithAuth
    .get(`${FINANCIAL_GOAL_API_PATH}/${familyId}/type`, {
      params: { brokerId },
    })
    .then(directReturnJSON);
}

export function getFinancialGoalDisclaimerDirect() {
  return directAxiosWithAuth
    .get(`${DIRECT_FINANCIAL_GOAL_API_PATH}/disclaimer`)
    .then(directReturnJSON);
}
