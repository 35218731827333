import { MILLI_TO_MYCRM_FREQ_ID } from 'shared/lib/frequencyHelper';
import { historyBuilderForMyCRM } from './history';

export function expenseBuilderForMyCRM(expense) {
  return {
    ownership_percentage: 100 / expense.clientIds.length,
    client_ids: expense.clientIds,
    value: expense.value,
    type_id: expense.typeId,
    frequency_id: MILLI_TO_MYCRM_FREQ_ID[expense.frequency],
    description: expense.description || '',
    isLivingExpensesV2TurnedOn: expense.isLivingExpensesV2Enabled,
    ...historyBuilderForMyCRM(expense),
  };
}
