import {
  documentTypes,
  documentSubCategoryLookup,
} from 'shared/constants/myCRMTypes/documents';
import {
  DOCUMENT_CATEGORIES as DOCUMENT_CATEGORIES_AU,
  SUB_CATEGORY_OTHER_DOCUMENT,
  OTHER_INFO_TYPE_ID,
} from 'shared/constants/myCRMTypes/documentsAU';
import {
  DOCUMENT_CATEGORIES as DOCUMENT_CATEGORIES_NZ,
  SUB_CATEGORY_OTHER,
  NZ_OTHER_DOCUMENTS,
} from 'shared/constants/myCRMTypes/documentsNZ';
import { MYCRM_TO_MILLI_STATUS_TYPES } from 'shared/constants/myCRMTypes/status';

import { pageBuilderForMilli } from './page';

export function documentBuilderForMilli(document) {
  let typeId = document.document_type_id;
  let category;
  if (typeId) {
    let type = documentTypes(document.countryCode).find((t) => t.id === typeId);
    if (!type) {
      type =
        document.countryCode === 'AU'
          ? { subCategory: SUB_CATEGORY_OTHER_DOCUMENT.key }
          : { subCategory: SUB_CATEGORY_OTHER.key };
      typeId =
        document.countryCode === 'AU' ? OTHER_INFO_TYPE_ID : NZ_OTHER_DOCUMENTS;
    }
    const subCategory = documentSubCategoryLookup(document.countryCode)[
      type.subCategory
    ];
    category = subCategory.category;
  }

  return {
    id: document.id || document.family_doc_id,
    type: typeId,
    category,
    status: MYCRM_TO_MILLI_STATUS_TYPES[document.status],
    name: document.name,
    size: document.size,
    pages: document.pages
      ? document.pages
          .map(pageBuilderForMilli)
          .filter((p) => p && p.id !== undefined)
      : [],
  };
}

export const documentCategoriesMapper = (data, countryCode) => {
  const valueKey = countryCode === 'NZ' ? 'Value' : 'DocumentTypeID';
  const nameKey = countryCode === 'NZ' ? 'Name' : 'DocumentTypeName';
  const categoryKey = countryCode === 'NZ' ? 'GroupName' : 'CategoryName';
  const categories =
    countryCode === 'NZ' ? DOCUMENT_CATEGORIES_NZ : DOCUMENT_CATEGORIES_AU;
  return data.reduce((map, obj) => {
    const category = obj[categoryKey];
    const newObj = {
      key: Number(obj[valueKey]),
      value: Number(obj[valueKey]),
      label: obj[nameKey],
      name: obj[nameKey],
    };
    const arr = map[category];
    if (!categories.includes(category)) {
      map.Other = [...(map.Other || []), newObj];
    } else if (arr) {
      arr.push(newObj);
    } else {
      map[category] = [newObj];
    }
    return map;
  }, {});
};
