import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

import { getInbetweenerDebounceTime } from 'lib/utils/browserUtils';

import { getLenderFilterBycountryCode } from 'shared/lib/lenderHelper';
import locale from 'config/locale';

import AnimationWrapper from 'components/AnimationWrapper/AnimationWrapper';
import AnimatedHeading from 'components/AnimatedHeading/AnimatedHeading';
import AnimatedLogoGrid from 'components/AnimatedLogoGrid/AnimatedLogoGrid';
import Button from 'components/Button/Button';

import headingStyles from '../AnimatedHeading/AnimatedHeading.css';

export default class GridInbetweener extends Component {
  static propTypes = {
    headings: PropTypes.arrayOf(PropTypes.string).isRequired,
    headingTime: PropTypes.number,
    logoDuration: PropTypes.number,
    initialDelay: PropTypes.number,
    next: PropTypes.func.isRequired,
    buttonText: PropTypes.string,
  };

  static defaultProps = {
    headingTime: 2000,
    logoDuration: 2750,
    initialDelay: 100,
  };

  constructor(props) {
    super(props);
    this.state = {
      showFinalHeading: false,
      showButton: false,
      showAll: false,
    };
    this._lenders = [];
  }

  componentDidMount() {
    this._lenders = getLenderFilterBycountryCode(locale.data.countryCode);
  }

  setShowButton = () => {
    this.setState({
      showButton: true,
    });
  };

  setShowAll = () => {
    this.setState({
      showAll: true,
    });
  };

  nextRoute = () => {
    this.props.next();
  };

  handleItemEnter = (index) => {
    const { headingTime, logoDuration, headings } = this.props;
    if (headings.length === 1) {
      this.setState({
        showFinalHeading: true,
      });
      setTimeout(() => this.setShowButton(), logoDuration * 0.6);
    } else if (index >= headings.length - 1) {
      this.setState({
        showFinalHeading: true,
        showButton: true,
      });
      setTimeout(() => this.setShowButton(), headingTime * 0.35);
    }
  };

  handleSkip = debounce(
    () => {
      const { showButton, showFinalHeading } = this.state;

      if (showButton) {
        this.nextRoute();
      } else if (showFinalHeading) {
        this.setShowButton();
      }
    },
    getInbetweenerDebounceTime(),
    { leading: true, trailing: false },
  );

  renderHeadings() {
    const { headings, initialDelay, headingTime } = this.props;
    const { showFinalHeading } = this.state;
    return (
      <AnimatedHeading
        delay={initialDelay}
        onElementEnter={this.handleItemEnter}
        skipAllowed={!showFinalHeading}
        className={headingStyles.gridInbetweener}
      >
        {headings.length === 1 ? (
          <h1>{headings[0]}</h1>
        ) : (
          headings.map((heading, i) => {
            const isLast = i === headings.length - 1;

            return (
              <h1
                data-duration={isLast ? undefined : headingTime}
                key={`${heading}-${i}`}
              >
                {heading}
              </h1>
            );
          })
        )}
      </AnimatedHeading>
    );
  }

  render() {
    const { initialDelay, logoDuration, buttonText } = this.props;
    const { showButton, showAll } = this.state;
    return (
      <AnimationWrapper
        key='findingLoan'
        id='findingLoan'
        onClick={this.handleSkip}
        onTouchStart={this.handleSkip}
      >
        {this.renderHeadings()}
        <AnimatedLogoGrid
          delay={showButton ? 0 : initialDelay + 10}
          duration={showButton ? 0 : logoDuration}
          lenders={this._lenders}
          showAll={showAll}
        />
        <Button
          visibility={showButton && !showAll}
          newTheme='blockAnchor'
          onClick={this.setShowAll}
        >
          Show All
        </Button>
        <Button
          id='inbetweenerNextButton'
          visibility={showButton}
          theme='transparent'
          className='brandColor__font--hover'
          onClick={this.nextRoute}
          animationClassName='centerAlignBlock'
          transition
        >
          {buttonText || 'Next'}
        </Button>
      </AnimationWrapper>
    );
  }
}
