/* eslint-disable sonarjs/no-duplicate-string */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import applyOwnPropsChecker from 'lib/applyOwnPropsChecker';
import { logEvent, EVENTS } from 'lib/amplitude';
import * as propertySelectors from 'selectors/propertySelectors';
import { bindActionCreators } from 'redux';
import propertyActions from 'actions/applyPropertyActions';

import { propertiesHint } from 'lib/hintHelper';

import applySection from 'hocs/applySection';
import { accordionPropTypes } from 'types/customPropTypes';
import ApplyItemContainer from 'components/ApplyItemContainer/ApplyItemContainer';
import EditableItem from 'components/EditableItem/EditableItem';
import Button from 'components/Button/Button';
import ApplyAdditionalQuestion from 'components/ApplyAdditionalQuestion/ApplyAdditionalQuestion';
import { PROPERTIES_SLUG } from 'constants/applyData';

const messages = defineMessages({
  title: {
    id: 'PropertiesApply.title',
    defaultMessage: 'Other Properties',
  },
  titleDescription: {
    id: 'PropertiesApply.titleDescription',
    defaultMessage: 'Do you own any other properties?',
  },
  titleDescriptionCompleted: {
    id: 'PropertiesApply.titleDescriptionCompleted',
    defaultMessage:
      '{numberOfEntities, plural, =0 {No other properties} other {{suburbs, select, false {{numberOfEntities, plural, one {# other property} other {# other properties}}} other {{suburbs}}}}}',
  },
  headerDescription: {
    id: 'PropertiesApply.headerDescription',
    defaultMessage: 'Do you own any other properties?',
  },
  headerDescriptionCompleted: {
    id: 'PropertiesApply.headerDescriptionCompleted',
    defaultMessage:
      '{ownOtherProperties, select, false {Do you own any other properties?} other {You’ve told us about {numberOfEntities, plural, one {# other property} other {# other properties}} you own.}}',
  },
  addProperty: {
    id: 'PropertiesApply.addProperty',
    defaultMessage: 'Add a property',
  },
  ownOtherProperties: {
    id: 'PropertiesApply.ownOtherProperties',
    defaultMessage: 'Do you own any other properties?',
  },
});

export const furtherDecoration = (props) => {
  const {
    intl: { formatMessage },
    ownOtherProperties,
    otherEntities,
    isCompleted,
    warningMessage,
  } = props;

  const postfix = isCompleted ? 'Completed' : '';
  const numberOfEntities = otherEntities.length;
  const suburbs = otherEntities
    .filter((p) => p.address.suburb)
    .map((p) => p.address.suburb);

  return {
    title: formatMessage(messages.title),
    headerDescription: formatMessage(messages[`headerDescription${postfix}`], {
      numberOfEntities,
      ownOtherProperties,
    }),
    titleDescription: formatMessage(messages[`titleDescription${postfix}`], {
      numberOfEntities,
      suburbs: suburbs.length > 0 ? suburbs.join(', ') : false,
    }),
    isCompleted,
    warningMessage,
  };
};

const nextButtonProps = ({ isCompleted, ownOtherProperties }) => ({
  disabled: !isCompleted && _.isNil(ownOtherProperties),
});

const confirmEntities = (props) => {
  const { confirmProperties, otherEntities, requestId } = props;
  confirmProperties({
    id: requestId,
    properties: otherEntities,
  });
};

class PropertiesApply extends Component {
  static displayName = 'PropertiesApply';
  static propTypes = {
    intl: intlShape.isRequired,
    accordionProps: PropTypes.shape(accordionPropTypes).isRequired,
    setMetadata: PropTypes.func.isRequired,
    otherEntities: PropTypes.arrayOf(PropTypes.object).isRequired,
    ownOtherProperties: PropTypes.bool,
    urlPath: PropTypes.string.isRequired,
  };

  static forceVisibility = true;

  onOwnOtherPropertiesClick = (value) => {
    this.props.setMetadata({ ownOtherProperties: value });
  };

  newPropertyUrl = () => `${this.props.urlPath}/properties/property/new`;
  editPropertyUrl = (id) => `${this.props.urlPath}/properties/property/${id}`;

  onAddProperty = () => {
    logEvent(EVENTS.ADD_PROPERTY, { section: PROPERTIES_SLUG });
  };

  renderHasProperties() {
    const { otherEntities } = this.props;

    return (
      <div>
        <ApplyItemContainer>
          {otherEntities.map((entity, i) => (
            <EditableItem
              key={i}
              icon='sl-custom-building-2'
              url={this.editPropertyUrl(entity.id)}
              leftLabel='Investment'
              rightLabel={entity.address.suburb}
              rightDescription={entity.address.state}
            />
          ))}
        </ApplyItemContainer>
      </div>
    );
  }

  renderMaybeNoProperties() {
    const {
      accordionProps: { isLocked },
      intl: { formatMessage },
      ownOtherProperties,
    } = this.props;

    return (
      <ApplyAdditionalQuestion
        id='hasProperties'
        label={formatMessage(messages.ownOtherProperties)}
        hint={propertiesHint}
        action={this.onOwnOtherPropertiesClick}
        value={ownOtherProperties}
        disabled={isLocked}
      />
    );
  }

  render() {
    const {
      otherEntities,
      ownOtherProperties,
      accordionProps: { isLocked },
      intl: { formatMessage },
    } = this.props;
    const hasEntities = otherEntities.length > 0;
    return (
      <div id='propertiesApply'>
        {hasEntities
          ? this.renderHasProperties()
          : this.renderMaybeNoProperties()}
        {(ownOtherProperties || hasEntities) && !isLocked && (
          <div>
            <Button
              url={this.newPropertyUrl()}
              theme='applyNew'
              icon='sl-custom-building-2'
              onClick={this.onAddProperty}
            >
              {formatMessage(messages.addProperty)}
            </Button>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  otherEntities: propertySelectors.nonResidenceOwnEntities(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      confirmProperties: propertyActions.confirmProperties,
    },
    dispatch,
  );

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    applyOwnPropsChecker,
  )(
    applySection({
      iconName: 'sl-custom-building-2',
      furtherDecoration,
      nextButtonProps,
      confirmEntities,
    })(PropertiesApply),
  ),
);
