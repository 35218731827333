import * as actionTypes from 'actions/loanApplicationActionTypes';
import update from 'immutability-helper';
import { mergeEntity, setNewEntity } from 'lib/reducerHelper';
import _ from 'lodash';
import { handleActions } from 'redux-actions';
import { GUARANTOR } from 'constants/options';

export const newApplication = {
  errors: {},
  settlementDate: {},
  primaryGoals: {},
  metadata: {},
  securities: [],
  applicants: [],
};

export const initialState = {
  errors: {},
  working: newApplication,
  entities: [],
};

export const setWorkingValue = (state, payload, key) =>
  update(state, {
    working: {
      [key]: { $set: payload },
    },
  });

const loanApplicationReducer = handleActions(
  {
    [actionTypes.CLEAR_WORKING_APPLICATION](state) {
      return { ...state, working: newApplication };
    },
    [actionTypes.LOAD_APPLICATION](state, action) {
      return update(state, {
        working: {
          $set: update(newApplication, {
            $merge: { ...state.entities.find((e) => e.id === action.payload) },
          }),
        },
      });
    },
    [actionTypes.SET_NEW_WORKING_APPLICATION](state, action) {
      const newState = setNewEntity(state, action);
      return update(newState, {
        working: {
          $set: update(newApplication, { $merge: { ...action.payload } }),
        },
      });
    },
    [actionTypes.MERGE_LOAN_APPLICATION](state, action) {
      return mergeEntity(state, action);
    },
    [actionTypes.SET_LOAN_APPLICATION](state, action) {
      return update(state, {
        entities: {
          $splice: [
            [
              state.entities.findIndex((e) => e.id === action.payload.id),
              1,
              action.payload,
            ],
          ],
        },
      });
    },
    [actionTypes.CREATE_CONTACT_ON_APPLICATION](state, action) {
      const index = state.entities.findIndex(
        (e) => e.id === action.payload.loanApplicationId,
      );
      return update(state, {
        entities: {
          [index]: {
            contacts: {
              $push: [
                { id: action.payload.contactId, role: action.payload.role },
              ],
            },
            applicants: {
              $push: [action.payload],
            },
          },
        },
      });
    },
    [actionTypes.UPDATE_CONTACT_ON_APPLICATION](state, action) {
      const applicationIndex = state.entities.findIndex(
        (e) => e.id === action.payload.loanApplicationId,
      );
      const contactIndex = state.entities[applicationIndex].contacts.findIndex(
        (e) => e.id === action.payload.contactId,
      );
      return update(state, {
        entities: {
          [applicationIndex]: {
            contacts: {
              [contactIndex]: {
                $merge: { role: action.payload.role },
              },
            },
          },
        },
      });
    },

    [actionTypes.DELETE_CONTACT_ON_APPLICATION](state, action) {
      const applicationIndex = state.entities.findIndex(
        (e) => e.id === action.payload.loanApplicationId,
      );
      const contactIndex = state.entities[applicationIndex].contacts.findIndex(
        (e) => e.id === action.payload.contactId,
      );
      return update(state, {
        entities: {
          [applicationIndex]: {
            contacts: {
              $splice: [[contactIndex, 1]],
            },
          },
        },
      });
    },
    [actionTypes.SET_LENDER](state, action) {
      return setWorkingValue(state, action.payload, 'lender');
    },
    [actionTypes.SET_PRIMARY_GOALS](state, action) {
      return setWorkingValue(state, action.payload, 'primaryGoals');
    },
    [actionTypes.SET_SPECIFIC_GOAL](state, action) {
      return setWorkingValue(state, action.payload, 'specificGoal');
    },
    [actionTypes.SET_WHAT_YOU_WANT_TODO](state, action) {
      return setWorkingValue(state, action.payload, 'whatYouWantTodo');
    },
    [actionTypes.SET_HAS_IMPORTANT_DATES](state, action) {
      return setWorkingValue(state, action.payload, 'hasImportantDates');
    },
    [actionTypes.SET_HAS_IMPORTANT_DATE_OF_TYPE](state, action) {
      const { type, value } = action.payload;
      return setWorkingValue(state, value, `has${_.upperFirst(type)}Date`);
    },
    [actionTypes.SET_IMPORTANT_DATE_YEAR](state, action) {
      const { type, value } = action.payload;
      return update(state, {
        working: { [`${type}Date`]: { year: { $set: value } } },
      });
    },
    [actionTypes.SET_IMPORTANT_DATE_MONTH](state, action) {
      const { type, value } = action.payload;
      return update(state, {
        working: { [`${type}Date`]: { month: { $set: value } } },
      });
    },
    [actionTypes.SET_IMPORTANT_DATE_DAY](state, action) {
      const { type, value } = action.payload;
      return update(state, {
        working: { [`${type}Date`]: { date: { $set: value } } },
      });
    },
    [actionTypes.SET_FORESEEABLE_CHANGE_DETAILS](state, action) {
      const { clientId, value } = action.payload;
      const existingIndex = state.working.foreseeableChanges.findIndex(
        (c) => c.clientId === clientId,
      );

      if (existingIndex !== -1) {
        return update(state, {
          working: {
            foreseeableChanges: {
              [existingIndex]: {
                details: { $set: value },
              },
            },
          },
        });
      }
      return update(state, {
        working: {
          foreseeableChanges: {
            $push: [{ clientId, details: value }],
          },
        },
      });
    },
    [actionTypes.SET_LIFE_INSURANCE](state, action) {
      return setWorkingValue(state, action.payload, 'lifeInsurance');
    },
    [actionTypes.SET_HOME_INSURANCE](state, action) {
      return setWorkingValue(state, action.payload, 'homeInsurance');
    },
    [actionTypes.SET_BEHIND_IN_PAYMENTS](state, action) {
      return setWorkingValue(state, action.payload, 'behindInPayments');
    },
    [actionTypes.SET_DIRECTOR](state, action) {
      return setWorkingValue(state, action.payload, 'director');
    },
    [actionTypes.SET_CONDUCT](state, action) {
      return setWorkingValue(state, action.payload, 'conduct');
    },
    [actionTypes.SET_BANKRUPT](state, action) {
      return setWorkingValue(state, action.payload, 'bankrupt');
    },
    [actionTypes.SET_BEHIND_IN_PAYMENTS_DETAIL](state, action) {
      return setWorkingValue(state, action.payload, 'behindInPaymentsDetail');
    },
    [actionTypes.SET_DIRECTOR_DETAIL](state, action) {
      return setWorkingValue(state, action.payload, 'directorDetail');
    },
    [actionTypes.SET_BANKRUPT_DETAIL](state, action) {
      return setWorkingValue(state, action.payload, 'bankruptDetail');
    },
    [actionTypes.SET_REVIEW_FINANCES](state, action) {
      return setWorkingValue(state, action.payload, 'reviewFinances');
    },
    [actionTypes.SET_LOAN_PRODUCT](state, action) {
      return setWorkingValue(state, action.payload, 'loanProduct');
    },
    [actionTypes.SET_METADATA](state, action) {
      return update(state, {
        working: { metadata: { $merge: action.payload } },
      });
    },
    [actionTypes.REMOVE_METADATA](state, action) {
      return update(state, {
        working: { metadata: { $unset: [action.payload] } },
      });
    },
    [actionTypes.SET_FORESEES_CHANGES_METADATA](state, action) {
      if (state.working.metadata.foreseesChanges) {
        return update(state, {
          working: {
            metadata: { foreseesChanges: { $merge: action.payload } },
          },
        });
      }
      return update(state, {
        working: { metadata: { foreseesChanges: { $set: action.payload } } },
      });
    },
    [actionTypes.SET_SECURITIES](state, action) {
      return setWorkingValue(state, action.payload, 'securities');
    },
    [actionTypes.SET_LOAN_APPLICATION_STATUS](state, action) {
      return setWorkingValue(state, action.payload, 'applicationStatus');
    },
    [actionTypes.SET_NEW_WORKING_APPLICANT](state, action) {
      const { id, contactId, role, brokerId } = action.payload;
      const value = [
        ...state.working.applicants,
        {
          clientId: id,
          contactId: contactId,
          brokerId: brokerId,
          isGuarantor: role === GUARANTOR,
          isBrokerCustomerCare: false,
          hasCreditCheckRetried: false,
          hasSignedPrivacyPolicy: false,
          hasSeenContext: false,
        },
      ];
      return setWorkingValue(state, value, 'applicants');
    },
  },
  initialState,
);

export default loanApplicationReducer;
