import _findKey from 'lodash/findKey';
import _startCase from 'lodash/startCase';
import _flatten from 'lodash/flatten';
import { GENDER_MAP } from 'shared/constants/myCrmMaps';

import { getAgeAndAgeUnitFromDob } from 'shared/lib/clientHelper';
import { ROLE_CHILD } from 'shared/constants/myCRMTypes/clients';
import {
  FORK_FAST_APPROVAL,
  FORK_DIY_RESEARCH,
} from 'shared/constants/loanScenarios';
import { dobBuilderForMilli } from './dob';
import { historyBuilderForMilli } from './history';

const CLIENT_ROLE_GUARANTOR = 'guarantor';
const CLIENT_ROLE_BORROWER = 'borrower';

export function clientBuilderForMilli(client) {
  const genderString = _findKey(GENDER_MAP, (val) => val === client.gender);
  const displayName = _startCase(client.name.display || client.name.first);
  const dob = dobBuilderForMilli(client.dob);
  const { age, ageUnit } = getAgeAndAgeUnitFromDob(dob);
  return {
    id: client.id,
    preferredName: client.name.display,
    displayName,
    displayFullName: `${displayName} ${_startCase(client.name.last)}`,
    title: client.name.title,
    firstName: client.name.first,
    middleName: client.name.middle,
    lastName: client.name.last,
    previousName: client.name.previous,
    mothersMaidenName: client.mothers_maiden_name,
    maritalStatusId: client.marital_status_id,
    residencyId: client.residency_id,
    citizenshipId: client.citizenship_id,
    countryOfResidencyId: client.country_of_residency_id,
    dob,
    age,
    ageUnit,
    mobile: client.mobile,
    email: client.email,
    isDependent: client.role_id === ROLE_CHILD,
    isResident: client.is_resident,
    contactId: client.family_id,
    gender: genderString,
    isDeceased: client.is_deceased,
    type: 'person',
    role: client.is_guarantor ? CLIENT_ROLE_GUARANTOR : CLIENT_ROLE_BORROWER,
    isCoapplicant: !!client.co_applicant /* convert null to false */,
    forkChoice:
      client.fork_choice === 1 ? FORK_FAST_APPROVAL : FORK_DIY_RESEARCH,
    ...historyBuilderForMilli(client),
  };
}

export function clientApplicationsBuilderForMilli(
  applications,
  primaryClientIds,
) {
  const allApplications = _flatten(applications);
  const distinctAppsIds = [
    ...new Set(allApplications.map((application) => application.id)),
  ];
  const distinctApps = distinctAppsIds.map((distinctAppId) => {
    return allApplications.find((app) => app.id === distinctAppId);
  });
  const appsToShow = distinctApps.filter(
    (distinctApp) =>
      !!distinctApp.applicants.filter((applicant) =>
        primaryClientIds.includes(applicant.client_id),
      ).length,
  );
  return appsToShow.map((app) => app.id);
}

export function clientTitleBuilder(list) {
  return list.map((l) => ({ name: l.Name, value: l.Value }));
}
