import { DIRECT_PRODUCTS_API_PATH } from 'shared/constants/apiPaths';

import {
  axiosWithAuth as directAxiosWithAuth,
  dataTransform as directDataTransform,
  returnJSON as directReturnJSON,
} from './mycrmBaseApi';
import locale from '../config/locale';
import { createParamsFromRequest } from './productsApi';
import { getLendersListApi } from './lendersApi';
import {
  createLowestRateProductRequest,
  mapLowestRateProductResponse,
} from '../../shared/lib/productHelper';

// port of serverless handler: products/products.lowestRateProduct
export function getLowestRateProduct(params) {
  if (!params) {
    return Promise.reject(
      new Error('params bust be provided to get lowest rate product.'),
    );
  }
  return createParamsFromRequest(params).then((query) => {
    if (!query) {
      return Promise.reject(new Error('Query parameters are missing.'));
    }
    return getLendersListApi().then((allLenders) => {
      return createLowestRateProductRequest(query, allLenders).then(
        (lowestRateProductRequest) => {
          return directAxiosWithAuth
            .get(`${DIRECT_PRODUCTS_API_PATH}/${locale.countryCode}`, {
              params: lowestRateProductRequest,
            })
            .then((productList) =>
              productList && productList.data && productList.data.length
                ? productList.data[0]
                : undefined,
            )
            .then(directDataTransform(mapLowestRateProductResponse))
            .then(directReturnJSON);
        },
      );
    });
  });
}

// port of handler: products/products.lowestRate
export function getLowestRate(params) {
  return getLowestRateProduct(params).then((product) => product.interestRate);
}
