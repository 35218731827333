import locale from 'config/locale';

import { placesAutoComplete } from './placesApi';
import { returnJSON, throwJSON } from './baseApi';

export const searchAddressApi = (query, countryCode, withPlaceId) => {
  return placesAutoComplete(query, countryCode || locale.data.countryCode).then(
    (predictions) => {
      if (!predictions || predictions.length === 0) {
        return Promise.reject(
          new Error('Sorry, we can’t find that address. Please try again.'),
        );
      } else {
        if (withPlaceId) {
          return {
            data: predictions.map((p) => ({
              text: p.description,
              placeId: p.place_id,
            })),
          };
        } else {
          return { data: predictions.map((p) => p.description) };
        }
      }
    },
  );
};

export const searchAddress = (query, countryCode) =>
  searchAddressApi(query, countryCode, false).then(returnJSON, throwJSON);

export const searchAddressWithPlaceId = (query, countryCode) =>
  searchAddressApi(query, countryCode, true).then(returnJSON, throwJSON);
