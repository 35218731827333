import en from 'react-intl/locale-data/en';

import {
  COUNTRY_AUSTRALIA,
  COUNTRY_NEW_ZEALAND,
} from 'shared/constants/myCRMTypes/countries';

const localeMetadata = [
  {
    ...COUNTRY_AUSTRALIA,
    localeData: en,
    localeCode: 'en-AU',
    englishName: 'Australia',
    currency: 'aud',
    phoneNumber: '135626',
    phoneNumberFormatted: '13 56 26',
    companyNumber: 'ABN 89 105 230 019',
    licenceNumber: 'Australian Credit Licence 390222',
    licenceNumberShort: 'ACL 390222',
    website: 'https://www.loanmarket.com.au',
  },
  {
    ...COUNTRY_NEW_ZEALAND,
    localeData: en,
    localeCode: 'en-NZ',
    englishName: 'New Zealand',
    currency: 'nzd',
    // phoneNumber: '0508878788',
    // phoneNumberFormatted: '0508 87 87 88',
    companyNumber: 'Company Number 4313118',
    website: 'https://www.loanmarket.co.nz',
  },
];

const locale = {
  _localeData: null,
  _isAU: true,
  _isNZ: null,

  get countryCode() {
    return this._localeData.countryCode;
  },

  get countryId() {
    return this._localeData.id;
  },

  set countryCode(value) {
    const newData = localeMetadata.find((l) => l.countryCode === value);
    this._localeData = newData;
    this._isAU = newData.localeCode === 'en-AU';
    this._isNZ = newData.localeCode === 'en-NZ';
  },

  get data() {
    if (!this._localeData) {
      this.countryCode = 'AU';
    }
    return this._localeData;
  },

  get isAU() {
    return this._isAU;
  },

  get isNZ() {
    return this._isNZ;
  },
};

export default locale;
