import { MYCRM_TO_MILLI_FREQ_ID } from 'shared/lib/frequencyHelper';

export function structureBuilderForMilli(structure) {
  return {
    id: structure.id,
    loanTerm: structure.loan_term,
    loanAmount: structure.loan_amount, // TODO figure out why we do .ceil on loanamount. hi lending tribe
    productId: structure.product_id,
    lenderId: structure.lender_id,
    repaymentType: structure.interest_only_term
      ? 'interest'
      : 'principal_interest',
    repaymentFrequency: MYCRM_TO_MILLI_FREQ_ID[structure.payment_frequency_id],
    interestOnlyTerm: structure.interest_only_term,
    clientIds: structure.client_ids,
    name: structure.name,
    isLocked: !!structure.is_locked,
  };
}
