const incomeTypeBuilder = (incomeType) => ({
  key: incomeType.Value,
  label: incomeType.Name,
  name: incomeType.Name,
  value: incomeType.Value,
});

const incomeCategoryBuilder = (incomeType) => ({
  key: incomeType.GroupId,
  label: (incomeType.GroupName || '').replace('/', ' / '),
  name: (incomeType.GroupName || '').replace('/', ' / '),
  value: incomeType.GroupId,
  order: incomeType.Order,
  icon: incomeType.Icon,
  requiresEmployment: incomeType.RequiresEmployment,
  fieldLabel: incomeType.Label,
  fieldDescription: incomeType.Description,
});

const getUniqueCategories = (value, index, self) => {
  return self.indexOf(value) === index && value !== '1';
};

const compareOrder = (a, b) => {
  const order = b.order > a.order ? -1 : 0;
  return a.order > b.order ? 1 : order;
};

export const incomeTypesBuilderForMilli = (data, countryCode = 'AU') => {
  const incomeTypes =
    countryCode === 'AU' ? data.filter((type) => type.CountryId === 0) : data;
  const incomeGroupIds = incomeTypes
    .map((type) => type.GroupId)
    .filter(getUniqueCategories);
  const incomeCategories = incomeGroupIds
    .map((groupId) => {
      const category = incomeTypes.find((type) => type.GroupId === groupId);
      return incomeCategoryBuilder(category);
    })
    .sort(compareOrder);
  const incomeTypesByCategory = incomeTypes.reduce((map, obj) => {
    const incomeType = incomeTypeBuilder(obj);
    if (map[obj.GroupId]) {
      map[obj.GroupId].push(incomeType);
    } else {
      map[obj.GroupId] = [incomeType];
    }
    return map;
  }, {});
  return {
    categories: incomeCategories,
    ...incomeTypesByCategory,
  };
};
