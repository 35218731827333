export function advisorBuilderForMilli(advisorInfo) {
  const advisorOrg = advisorInfo.organization || {};
  return {
    name: `${advisorInfo.name.first} ${advisorInfo.name.last}`,
    clientId: advisorInfo.client_id,
    preferredName: advisorInfo.preferred_name,
    lastName: advisorInfo.name.last,
    mobile: advisorInfo.mobile,
    businessPhone: advisorInfo.business_phone,
    email: advisorInfo.email,
    uuid: advisorInfo.uuid,
    familyId: advisorInfo.family_id,
    imgUrl: advisorInfo.imgUrl,
    fax: advisorInfo.fax,
    abn: advisorInfo.abn,
    acn: advisorInfo.acn,
    displayAddress: advisorInfo.display_address,
    advisorOrgId: advisorOrg.id || null,
    advisorOrgName: advisorOrg.business_name || advisorOrg.trading_name || null,
    actualAdviserOrgName: advisorOrg.adviser_org_name,
    brandingCategory: advisorOrg.branding_category || null,
    advisorOrgSlug: advisorOrg.slug,
    displayTitle: advisorInfo.display_title,
    halfBodyImgUrl: advisorInfo.half_body_photo_url,
    role: advisorInfo.role,
    isAssistant: advisorInfo.is_assistant,
    corporateTeam: advisorInfo.corporate_team,
    businessUnitId: advisorInfo.business_unit_id,
    privacyPolicyConsent: advisorInfo.PrivacyPolicyConsent,
    privacyPolicyLink: advisorInfo.PrivacyPolicyLink,
    reportingStateName: advisorInfo.ReportingStateName,
  };
}
