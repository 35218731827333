import { DIRECT_ADVISER_ORG_PATH } from 'shared/constants/apiPaths';

import {
  axiosWithAuth as directAxiosWithAuth,
  dataTransform as directDataTransform,
  returnJSON as directReturnJSON,
} from './mycrmBaseApi';
import { advisorOrgBuilderForMilli } from 'shared/lib/builders/milli/advisorOrg';

// Begin port of serverless advisorOrgsApi
//
export const getAdvisorOrgBySlug = (
  slug,
  adviserOrgId,
  showPrincipleAdviser = false,
) => {
  return directAxiosWithAuth
    .get(`${DIRECT_ADVISER_ORG_PATH}/${slug}`, {
      params: { adviserOrgId, showPrincipleAdviser },
    })
    .then(directReturnJSON);
};
//
// End port of serverless advisorOrgsApi

// port of serverless handler: advisorOrgs/advisorOrgs.getAdvisorOrgInfo
export const getAdvisorOrgInfo = ({
  id: advisorOrgId,
  slug,
  shouldFetchPrincipleAdvisor,
}) => {
  return getAdvisorOrgBySlug(slug, advisorOrgId, shouldFetchPrincipleAdvisor)
    .then(directDataTransform(advisorOrgBuilderForMilli))
    .then(directReturnJSON);
};
