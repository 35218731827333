import { ContextPageType } from 'constants/contextTypes';

export const getContextPageType = (
  isSmartDataEnabled,
  hasOldData,
  hasAlreadySeenContext,
) => {
  if (isSmartDataEnabled) {
    if (hasOldData) {
      return ContextPageType.SMART_DATA_OLD_DATA;
    }
    if (!hasAlreadySeenContext) {
      return ContextPageType.SMART_DATA_NEW_CLIENT;
    }
  }
  if (hasOldData) {
    return ContextPageType.NO_SMART_DATA_OLD_DATA;
  }
  if (!hasAlreadySeenContext) {
    return ContextPageType.NO_SMART_DATA_NEW_CLIENT;
  }
};

export const nzContextContentMapper = (pageType, content) => {
  if (pageType === ContextPageType.SMART_DATA_NEW_CLIENT) {
    content.para2.context = `Fill in the remaining sections in as much detail as you can, then send it to your adviser to review`;
  }

  if (pageType === ContextPageType.NO_SMART_DATA_NEW_CLIENT) {
    content.para2.heading = `Send to adviser`;
    content.para2.context = `Once you’ve completed your profile, send it to your adviser so that they can review your information & progress with your loan`;
  }

  if (pageType === ContextPageType.NO_SMART_DATA_OLD_DATA) {
    content.para2.context = `Fill in the rest of your profile in as much detail as you can, then send it to your adviser for review`;
  }

  if (pageType === ContextPageType.SMART_DATA_OLD_DATA) {
    content.para2.context = `Fill in the rest of your profile in as much detail as you can, then send it to your adviser for review`;
  }

  return content;
};
