import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import Input from 'components/Input/Input';
import CurrencyInput from 'components/CurrencyInput/CurrencyInput';
import { formatDecimalNumber } from 'lib/utils/numberUtils';

import styles from './InputWithSpan.css';

class InputWithSpan extends Component {
  renderInputComponent() {
    const {
      id,
      action,
      value,
      inputComponent = 'Input',
      onKeyDown,
      onBlur,
      onFocus,
      forceFocus,
      spanValue,
      dispatchActionOnBlur,
      customType,
      type,
      removeFormatter = 'false',
    } = this.props;
    const components = {
      Input,
      CurrencyInput,
    };
    const inputMode =
      spanValue === '%' || customType === 'number' ? 'decimal' : undefined;
    const formatter =
      !removeFormatter && (spanValue === '%' || customType === 'number')
        ? { format: formatDecimalNumber, unformat: formatDecimalNumber }
        : undefined;

    return React.createElement(components[inputComponent], {
      id,
      type: type || 'text',
      action,
      value,
      onKeyDown,
      onBlur,
      onFocus,
      forceFocus,
      dispatchActionOnBlur,
      className: styles.input,
      inputMode,
      formatter,
    });
  }

  render() {
    const { spanValue, className } = this.props;
    return (
      <div className={classNames(styles.root, className)}>
        {this.renderInputComponent()}
        <span className={styles.span}>{spanValue}</span>
      </div>
    );
  }
}

InputWithSpan.displayName = 'InputWithSpan';
InputWithSpan.propTypes = {
  id: PropTypes.string,
  action: PropTypes.func.isRequired,
  dispatchActionOnBlur: PropTypes.bool,
  onKeyDown: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  forceFocus: PropTypes.bool,
  inputComponent: PropTypes.string,
  className: PropTypes.string,
  spanValue: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  customType: PropTypes.string,
  type: PropTypes.string,
  removeFormatter: PropTypes.bool,
};

export default InputWithSpan;
