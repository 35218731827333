import _isInteger from 'lodash/isInteger';

export function dobBuilderForMilli(dob) {
  if (!dob || !_isInteger(dob.month) || !dob.day || !dob.year) {
    return undefined;
  }

  return {
    day: dob.day,
    month: dob.month - 1,
    year: dob.year,
  };
}
