import { isEmptyObject, intlMobile } from 'shared/lib/utils';

function toMyCRMDate(date) {
  return {
    date: date.day || 0,
    Day: date.day || 0,
    Month: date.month + 1,
    Year: date.year,
  };
}

export function employmentBuilderForMyCRM(employment, addressId) {
  return {
    AddressId: addressId,
    EmploymentId: employment.id,
    ClientId: employment.clientId,
    EmploymentTypeId: employment.typeId,
    EmploymentBasisId: employment.basisId,
    EmploymentBasisName: employment.basisName,
    EmploymentStatusId: employment.statusId,
    EmploymentRollId: 0,
    EmploymentRole: employment.role,
    EmployerName: employment.employerName,
    EmployerContactFirstName: employment.contactFirstName,
    EmployerContactLastName: employment.contactLastName,
    EmployerNumber: intlMobile(employment.employerPhone),
    EmployerTypeId: employment.employerTypeId,
    IsProbation: employment.isProbation,
    Address: employment.employerAddress
      ? employment.employerAddress.formattedAddress
      : null,
    DateStarted: toMyCRMDate(employment.dateStarted),
    DateEnded: isEmptyObject(employment.dateEnded)
      ? null
      : toMyCRMDate(employment.dateEnded),
    IsCurrent: employment.isCurrent,
    EmploymentNatureID: employment.natureId,
    EntityId: employment.entityId,
    NZBN: employment.nzbn,
    newEndPoint: 'employment',
  };
}
