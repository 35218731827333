import { DIRECT_FUNDINGS_API_PATH } from 'shared/constants/apiPaths';
import {
  axiosWithAuth as directAxiosWithAuth,
  dataTransform as directDataTransform,
  returnJSON as directReturnJSON,
} from './mycrmBaseApi';
import { fundingBuilderForMyCRM } from 'shared/lib/builders/mycrm/funding';
import { getDepositTypesDirect } from './optionsApi';
import { fundingDepositTypeForMilli } from 'shared/lib/builders/milli/funding';

// Begin port of serverless fundingsApi
//
export const putFundingDirect = (fundingId, data) => {
  return directAxiosWithAuth
    .put(`${DIRECT_FUNDINGS_API_PATH}/${fundingId}`, data)
    .then(directReturnJSON);
};

export const deleteFundingDirect = (fundingId) => {
  return directAxiosWithAuth
    .delete(`${DIRECT_FUNDINGS_API_PATH}/${fundingId}`)
    .then(directReturnJSON);
};
//
// End port of serverless fundingsApi

// port of serverless handler: fundings/fundings.getTypes
export function getDepositTypes() {
  return getDepositTypesDirect()
    .then(directDataTransform((data) => data))
    .then(directDataTransform((data) => data.map(fundingDepositTypeForMilli)))
    .then(directReturnJSON);
}

// port of serverless handler: fundings/fundings.put
export function putFunding(funding) {
  return putFundingDirect(funding.id, fundingBuilderForMyCRM(funding))
    .then(directDataTransform((data) => data))
    .then(directReturnJSON);
}

// port of serverless handler: fundings/fundings.del
export function delFunding(fundingId) {
  return deleteFundingDirect(fundingId).then(directReturnJSON);
}
