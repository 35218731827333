import _ from 'lodash';
import * as request from 'axios';
import { parseCurrencyValues } from 'lib/utils/numberUtils';
import {
  DIRECT_CALCULATORS_API_PATH,
  DIRECT_RAY_WHITE_API_PATH,
  DIRECT_PURCHASE_COST_API_BASE,
  DIRECT_PURCHASE_COST_CLIENT_ID,
  DIRECT_PURCHASE_COST_API_QUERY,
} from 'shared/constants/apiPaths';
import axios from './baseApi';
import {
  axiosWithAuth as directAxiosWithAuth,
  dataTransform as directDataTransform,
  returnJSON as directReturnJSON,
} from './mycrmBaseApi';
import { convertToRange, lmiBuilderForMyCRM } from 'shared/lib/lmiHelper';
import { listingMapperForMilli } from 'shared/lib/builders/rayWhiteBuilder';
import {
  STATUS_ESTABLISHED,
  STATUS_NEW_BUILDING,
  STATUS_VACANT_LAND,
  STATUS_TO_BE_BUILT,
} from 'shared/constants/myCRMTypes/property';

// Port (mostly a copy) of serverless handler: others/purchaseCost.get
const UNSUPPORTED_STATES = ['ZEALAND'];
export const getPurchaseCostDirect = (clientParams) => {
  const instance = axios.create({
    baseURL: DIRECT_PURCHASE_COST_API_BASE,
  });
  const params = {
    ...clientParams,
    clientId: DIRECT_PURCHASE_COST_CLIENT_ID,
  };

  const state = clientParams.state;
  if (!state || UNSUPPORTED_STATES.includes(state.toUpperCase())) {
    return Promise.resolve({
      grant: '0.00',
      mortgageRegFee: '0.00',
      otherConcession: '0.00',
      stampDutyPayable: '0.00',
      totalConcession: '0.00',
      totalFee: '0.00',
      transferFee: '0.00',
    });
  }

  return instance
    .get(DIRECT_PURCHASE_COST_API_QUERY, { params })
    .then((response) => {
      if (!response.data.totalFee) {
        // checking if data is in right form and throw manually since the api gives us 200 even when error occurs :(
        return Promise.reject(new Error(response.data));
      }

      return _.pick(response.data, [
        'grant',
        'mortgageRegFee',
        'otherConcession',
        'stampDutyPayable',
        'totalConcession',
        'totalFee',
        'transferFee',
      ]);
    });
};

export function getPurchaseCost(params) {
  return getPurchaseCostDirectV2(params).then((purchaseCostData) => {
    const additionalData = _.pick(params, [
      'totalIncome',
      'movingCost',
      'conveyancerCost',
      'renovationCost',
    ]);
    return parseCurrencyValues({ ...purchaseCostData, ...additionalData });
  });
}

export function getPurchaseCostDirectV2(clientParams) {
  const params = {
    ...clientParams,
    clientId: DIRECT_PURCHASE_COST_CLIENT_ID,
    propertyDesc: mapPropertyType(clientParams.propertyDesc),
  };

  const state = clientParams.state;
  if (!state || UNSUPPORTED_STATES.includes(state.toUpperCase())) {
    return Promise.resolve({
      grant: '0.00',
      mortgageRegFee: '0.00',
      otherConcession: '0.00',
      stampDutyPayable: '0.00',
      totalConcession: '0.00',
      totalFee: '0.00',
      transferFee: '0.00',
    });
  }

  return directAxiosWithAuth
    .post(`${DIRECT_CALCULATORS_API_PATH}/government-fees-and-grants`, params)
    .then((response) => {
      return _.pick(response.data, [
        'grant',
        'mortgageRegFee',
        'otherConcession',
        'stampDutyPayable',
        'totalConcession',
        'totalFee',
        'transferFee',
      ]);
    });
}

export function getQuickLMI(data) {
  return directAxiosWithAuth
    .post(
      `${DIRECT_CALCULATORS_API_PATH}/0000/quick-lmi`,
      lmiBuilderForMyCRM(data),
    )
    .then(directDataTransform(convertToRange))
    .then(directReturnJSON);
}

export function getListingInfo(listingId) {
  return directAxiosWithAuth
    .get(`${DIRECT_RAY_WHITE_API_PATH}/listings/${listingId}`)
    .then(directDataTransform((data) => data))
    .then(({ data }) => {
      if (!data || !data.data || data.data.length === 0) {
        return Promise.reject(new Error(`No listings ${listingId}`));
      }
      return listingMapperForMilli(data.data[0].value);
    })
    .then(directReturnJSON);
}

export function getCountryFromIPAddress() {
  return request
    .get('https://geolocation-db.com/json/')
    .then((response) => response.data && response.data.country_code)
    .catch((error) => error);
}

export const mapPropertyType = (propertyType) => {
  switch (propertyType) {
    case STATUS_NEW_BUILDING.name:
      return 'New Building';
    case STATUS_TO_BE_BUILT.name:
      return 'To be Built';
    case STATUS_ESTABLISHED.name:
      return 'Established';
    case STATUS_VACANT_LAND.name:
      return 'Vacant Land';
    default:
      return 'New Building';
  }
};
