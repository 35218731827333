/* eslint-disable prettier/prettier */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import { bindActionCreators } from 'redux';

import applyOwnPropsChecker from 'lib/applyOwnPropsChecker';
import { formatPeriod } from 'lib/utils/stringUtils';
import { findOption } from 'lib/optionHelpers';
import { logEvent, EVENTS } from 'lib/amplitude';
import { ADDRESS_TYPE_OPTIONS } from 'constants/options';

import * as addressSelectors from 'selectors/addressSelectors';
import * as clientSelectors from 'selectors/clientSelectors';
import * as applicationSelectors from 'selectors/applicationSelectors';

import addressActions from 'actions/addressActions';

import applySection from 'hocs/applySection';
import { accordionPropTypes } from 'types/customPropTypes';
import ApplyItemContainer from 'components/ApplyItemContainer/ApplyItemContainer';
import EditableItem from 'components/EditableItem/EditableItem';
import Button from 'components/Button/Button';

import styles from './AddressApply.css';
import { ADDRESSES_SLUG } from 'constants/applyData';

const messages = defineMessages({
  title: {
    id: 'AddressApply.title',
    defaultMessage: 'Address History',
  },
  titleDescription: {
    id: 'AddressApply.titleDescription',
    defaultMessage: 'Tell us about your address history',
  },
  titleDescriptionCompleted: {
    id: 'AddressApply.titleDescriptionCompleted',
    defaultMessage:
      '{numberOfAddresses} {numberOfAddresses, plural, one {address} other {addresses}}',
  },
  headerDescription: {
    id: 'AddressApply.headerDescription',
    defaultMessage: 'Where have you lived for the past three years?',
  },
  headerDescriptionCompleted: {
    id: 'AddressApply.headerDescriptionCompleted',
    defaultMessage: 'Your address history for the past three years.',
  },
  instructionsMain: {
    id: 'AddressApply.instructionsMain',
    defaultMessage:
      'Lenders tend to view applications with a stable address history favourably.',
  },
  letUsKnow: {
    id: 'AddressApply.letUsKnow',
    defaultMessage: 'Let us know',
  },
  currentCorrect: {
    id: 'AddressApply.currentCorrect',
    defaultMessage: 'If your current address details are correct',
  },
  whereLived: {
    id: 'AddressApply.whereLived',
    defaultMessage: 'Where you have lived for the past 3 years',
  },
  addAddress: {
    id: 'AddressApply.addAddress',
    defaultMessage: 'Add an address',
  },
  almostThere: {
    id: 'AddressApply.almostThere',
    defaultMessage: `Lenders require 3 years of address history`,
  },
  threeYearHistory: {
    id: 'AddressApply.threeYearHistory',
    defaultMessage: 'Most lenders require a 3 year history.',
  },
});

const furtherDecoration = (props) => {
  const {
    intl: { formatMessage },
    isCompleted,
    addresses,
    hasExpandedAddresses,
    hasOldInformation,
    warningMessage,
  } = props;

  const postfix = isCompleted ? 'Completed' : '';
  const showLengthWarningMessage =
    !isCompleted && !!addresses.length && hasExpandedAddresses;

  let warningMessageFormatted = '';
  if (showLengthWarningMessage) {
    warningMessageFormatted = formatMessage(messages.almostThere);
  } else if (hasOldInformation) {
    warningMessageFormatted = warningMessage;
  }

  return {
    title: formatMessage(messages.title),
    headerDescription: formatMessage(messages[`headerDescription${postfix}`]),
    titleDescription: formatMessage(messages[`titleDescription${postfix}`], {
      numberOfAddresses: addresses.length,
    }),
    warningMessage: warningMessageFormatted,
  };
};

const confirmEntities = (props) => {
  const { confirmAddresses, addresses, applicationId } = props;
  confirmAddresses({
    id: applicationId,
    addresses,
  });
};

class AddressApply extends Component {
  static displayName = 'AddressApply';
  static propTypes = {
    intl: intlShape.isRequired,
    accordionProps: PropTypes.shape(accordionPropTypes).isRequired,
    addresses: PropTypes.arrayOf(PropTypes.object).isRequired,
    primaryApplicantsAddresses: PropTypes.arrayOf(PropTypes.object).isRequired,
    primaryApplicantsPartnersAddresses: PropTypes.arrayOf(PropTypes.object),
    sharedAddresses: PropTypes.arrayOf(PropTypes.object),
    // eslint-disable-next-line react/no-unused-prop-types
    clientIdOwnershipOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
    hasExpandedAddresses: PropTypes.bool,
    isOpen: PropTypes.bool.isRequired,
    setMetadata: PropTypes.func.isRequired,
    isCompleted: PropTypes.bool.isRequired,
    urlPath: PropTypes.string.isRequired,
  };

  static defaultProps = {
    primaryApplicantsPartnersAddresses: [],
    sharedAddresses: [],
  };

  static forceVisibility = true;

  componentDidUpdate(prevProps) {
    const { addresses, isOpen, setMetadata } = this.props;
    if (!prevProps.isOpen && isOpen) {
      setMetadata({ hasExpandedAddresses: !!addresses.length });
    }
  }

  newAddressAddressUrl = () => `${this.props.urlPath}/addresses/address/new`;
  editAddressUrl = (id) => `${this.props.urlPath}/addresses/address/${id}`;

  // eslint-disable-next-line sonarjs/cognitive-complexity
  editableAddressItem = (address) => {
    const addressDetails = address.address;

    addressDetails.streetAddress = addressDetails.streetAddress ?? '';
    addressDetails.streetNumber = addressDetails.streetNumber ?? '';
    addressDetails.streetType = addressDetails.streetType ?? '';
    addressDetails.streetName = addressDetails.streetName ?? '';
    addressDetails.level = addressDetails.level ?? '';
    addressDetails.buildingName = addressDetails.buildingName ?? '';
    addressDetails.subpremise = addressDetails.subpremise ?? '';
    addressDetails.suburb = addressDetails.suburb ?? '';
    addressDetails.state = addressDetails.state ?? '';

    const { formatDate } = this.props.intl;
    const hasSuburbAndState = addressDetails.suburb && addressDetails.state;
    const level = addressDetails.level
      ? `${
          addressDetails.streetAddress.includes(`Level ${addressDetails.level}`)
            ? ''
            : `Level ${addressDetails.level}, `
        }`
      : '';
    const buildingName = addressDetails.buildingName
      ? `${
          addressDetails.streetAddress.includes(addressDetails.buildingName)
            ? ''
            : `${addressDetails.buildingName}, `
        }`
      : '';
    const subpremise = addressDetails.subpremise
      ? `${
          addressDetails.streetAddress.includes(
            `Unit ${addressDetails.subpremise}`,
          )
            ? ''
            : `Unit ${addressDetails.subpremise}, `
        }`
      : '';
    const streetNumber = addressDetails.streetNumber
      ? `${addressDetails.streetNumber} `
      : '';
    const streetName = addressDetails.streetName
      ? `${addressDetails.streetName} `
      : '';
    const streetType = addressDetails.streetType
      ? `${addressDetails.streetType} `
      : '';
    const streetAddress = addressDetails.streetAddress
      ? `${addressDetails.streetAddress} `
      : '';
    const extensionDetails = `${level}${buildingName}${subpremise}`.trim();
    const streetDetails = `${streetNumber}${streetName}${streetType}`.trim();
    const rightLabel = `${
      extensionDetails.endsWith(',') && !(streetAddress || streetDetails)
        ? extensionDetails.slice(0, -1)
        : `${extensionDetails} `
    }${
      streetAddress ||
      (streetDetails.endsWith(',') ? streetDetails.slice(0, -1) : streetDetails)
    }`
      .trim()
      .replace(`${addressDetails.subpremise}/`, '')
      .replace(`,,`, `, `)
      .replace(/\s\s+/g, ' ');

    return (
      <EditableItem
        key={`address-${address.id}`}
        url={this.editAddressUrl(address.id)}
        leftIcon='sl-custom-mail-box-3'
        leftLabel={findOption(ADDRESS_TYPE_OPTIONS, address.typeId).displayName}
        leftDescription={formatPeriod(
          formatDate,
          address.startDate,
          address.endDate,
        )}
        rightLabel={rightLabel}
        rightDescription={`${address.address.suburb || ''}${
          hasSuburbAndState ? ', ' : ''
        }${address.address.state || ''}`}
      />
    );
  };

  onAddAddress = () => {
    logEvent(EVENTS.ADD_HISTORY, { section: ADDRESSES_SLUG });
  };

  renderHasAddresses() {
    const {
      primaryApplicantsAddresses,
      primaryApplicantsPartnersAddresses,
      sharedAddresses,
      isCompleted,
      hasExpandedAddresses,
    } = this.props;
    const showHint = !isCompleted && !hasExpandedAddresses;

    return (
      <div id='addressesApply'>
        {primaryApplicantsAddresses.length > 0 && [
          <h4 key='your-title'>You</h4>,
          <ApplyItemContainer
            key='applyItemContainer'
            showHint={
              showHint &&
              // eslint-disable-next-line prettier/prettier
              primaryApplicantsPartnersAddresses.length +
                sharedAddresses.length ===
                0
            }
          >
            {primaryApplicantsAddresses.map(this.editableAddressItem)}
          </ApplyItemContainer>,
        ]}
        {primaryApplicantsPartnersAddresses.length > 0 && [
          <h4 key='partner-title'>Your partner</h4>,
          <ApplyItemContainer
            key='applyItemContainer'
            showHint={showHint && sharedAddresses.length === 0}
          >
            {primaryApplicantsPartnersAddresses.map(this.editableAddressItem)}
          </ApplyItemContainer>,
        ]}

        {sharedAddresses.length > 0 && [
          <h4 key='you-and-parter-title'>You and your partner</h4>,
          <ApplyItemContainer key='applyItemContainer' showHint={showHint}>
            {sharedAddresses.map(this.editableAddressItem)}
          </ApplyItemContainer>,
        ]}
        {showHint && this.renderHint()}
      </div>
    );
  }

  renderInstructions() {
    const {
      intl: { formatMessage },
    } = this.props;

    return (
      <div key='addressInstruction'>
        <p>{formatMessage(messages.instructionsMain)}</p>
        <p>{formatMessage(messages.letUsKnow)}</p>
        <ul>
          <li>{formatMessage(messages.currentCorrect)}</li>
          <li>{formatMessage(messages.whereLived)}</li>
        </ul>
      </div>
    );
  }

  renderHint = () => {
    const {
      intl: { formatMessage },
    } = this.props;
    return (
      <div className={styles.addressHint}>
        <i className='sl-custom-lightbulb-2' />
        {formatMessage(messages.threeYearHistory)}
      </div>
    );
  };

  render() {
    const {
      addresses,
      intl: { formatMessage },
      accordionProps: { isLocked },
    } = this.props;

    return (
      <div id='addressApply'>
        {addresses.length
          ? this.renderHasAddresses()
          : this.renderInstructions()}
        {!isLocked && (
          <Button
            url={this.newAddressAddressUrl()}
            theme='applyNew'
            icon='sl-custom-mail-box-3'
            key='addressApply'
            onClick={this.onAddAddress}
          >
            {formatMessage(messages.addAddress)}
          </Button>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const primaryApplicantId = ownProps.primaryApplicant.id;

  const props = {
    addresses: addressSelectors.primaryContactAddressHistories(state),
    primaryApplicantsAddresses: addressSelectors.clientsAddresses(state)([
      primaryApplicantId,
    ]),
    clientIdOwnershipOptions: clientSelectors.clientIdOwnershipOptions(state),
    applicationId: applicationSelectors.getApplicationId(state),
  };
  if (ownProps.primaryApplicantsPartner) {
    const partnerId = ownProps.primaryApplicantsPartner.id;
    const clientIds = [primaryApplicantId, partnerId];
    props.primaryApplicantsPartnersAddresses = addressSelectors.clientsAddresses(
      state,
    )([partnerId]);
    props.sharedAddresses = addressSelectors.clientsAddresses(state)(clientIds);
  }
  return props;
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      confirmAddresses: addressActions.confirmAddresses,
    },
    dispatch,
  );

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    applyOwnPropsChecker,
  )(
    applySection({
      iconName: 'sl-custom-mail-box-3',
      furtherDecoration,
      confirmEntities,
    })(AddressApply),
  ),
);
