import { createSelector } from 'reselect';

export const aggregator = (state) => state.aggregator;

export const selectAggregator = createSelector(
  aggregator,
  (aggregatorState) => aggregatorState.aggregator || {},
);

export const brandFeatures = createSelector(
  selectAggregator,
  (aggregator) => aggregator.brandFeatures || {},
);

export const isNonLMG = createSelector(
  brandFeatures,
  (features) => features.HasOtherRedirectUrls,
);
