import {
  documentTypesPerSubCategoryKey,
  DOCUMENT_UPLOADED_STATUS_ID,
} from 'shared/constants/myCRMTypes/documents';
import { OTHER_INFO_TYPE_ID } from 'shared/constants/myCRMTypes/documentsAU';
import { NZ_OTHER_DOCUMENTS } from 'shared/constants/myCRMTypes/documentsNZ';

import { pageBuilderForMyCrm } from './page';

function docTypeBuilderForMyCRM(document) {
  const isNZ = document.countryCode === 'NZ';
  if (!document.type) {
    if (document.subCategory) {
      return documentTypesPerSubCategoryKey(document.countryCode)[
        document.subCategory
      ][0].id;
    }
    return isNZ ? NZ_OTHER_DOCUMENTS : OTHER_INFO_TYPE_ID;
  }
  return document.type;
}

export function newDocumentBuilderForMyCRM(document) {
  const typeId = docTypeBuilderForMyCRM(document);
  return {
    family_id: Number(document.contactId),
    document_type_id: Number(typeId),
    document_key: `MyCRM/${document.key}`,
    name: document.name,
    size: document.size,
    content_type: document.contentType,
    status: DOCUMENT_UPLOADED_STATUS_ID,
  };
}

export function documentBuilderForMyCRM(document) {
  const typeId = docTypeBuilderForMyCRM(document);
  return {
    id: document.id,
    document_type_id: typeId,
    application_id: document.applicationId,
    client_id: document.clientId, // bank connect for now
    name: document.name,
    size: document.size,
    pages: document.pages ? document.pages.map(pageBuilderForMyCrm) : undefined,
    file_content: document.pdfData,
    content_type: document.pdfData ? 'application/pdf' : document.contentType,
  };
}
