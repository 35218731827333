import { routerReducer } from '@loan_market/react-router-redux-multi';
import { combineReducers } from 'redux';
import { ReduxMirror } from '@loanmarket/react-redux-mirror';

import { INITIAL_SCENARIO_STATE } from 'shared/constants/defaults';
import scenarioReducer from './scenarioReducer';
import productsReducer from './productsReducer';
import UIReducer, { initialState as UIInitialState } from './UIReducer';
import loanApplicationReducer, {
  initialState as applicationInitialState,
} from './loanApplicationReducer';
import otherReducer, {
  initialState as otherInitialState,
} from './otherReducer';
import assetReducer, {
  initialState as assetInitialState,
} from './assetReducer';
import liabilityReducer, {
  initialState as liabilityInitialState,
} from './liabilityReducer';
import expenseReducer, {
  initialState as expenseInitialState,
} from './expenseReducer';
import incomeReducer, {
  initialState as incomeInitialState,
} from './incomeReducer';
import contactReducer, {
  initialState as contactInitialState,
} from './contactReducer';
import employmentReducer, {
  initialState as employmentInitialState,
} from './employmentReducer';
import clientReducer, {
  initialState as clientInitialState,
} from './clientReducer';
import propertyReducer, {
  initialState as propertyInitialState,
} from './propertyReducer';
import fundingReducer, {
  initialState as fundingInitialState,
} from './fundingReducer';
import structureReducer, {
  initialState as structureInitialState,
} from './structureReducer';
import addressReducer, {
  initialState as addressInitialState,
} from './addressReducer';
import documentReducer, {
  initialState as documentInitialState,
} from './documentReducer';
import householdReducer, {
  initialState as householdCollectionInitialState,
} from './householdReducer';
import advisorReducer, {
  initialState as advisorInitialState,
} from './advisorReducer';
import advisorOrgReducer, {
  initialState as advisorOrgInitialState,
} from './advisorOrgReducer';
import goalLoanAppInfoReducer, {
  initialState as goalLoanAppInfoInitialState,
} from './goal/goalLoanAppInfoReducer';
import goalLoanApplicationReducer, {
  initialState as goalLoanApplicationInitialState,
} from './goal/goalLoanApplicationReducer';
import expenseCategoryTypeReducer, {
  initialState as expenseCategoryTypeInitialState,
} from './expenseCategoryTypeReducer';
import lenderReducer, {
  initialState as lenderInitialState,
} from './lenderReducer';
import incomeTypeReducer, {
  initialState as incomeTypeInitialState,
} from './incomeTypeReducer';
import depositTypeReducer, {
  initialState as depositTypeInitialState,
} from './depositTypeReducer';
import aggregatorReducer, {
  initialState as aggregatorInitialState,
} from './aggregatorReducer';
import privacyPolicyReducer, {
  initialState as privacyPolicyInitialState,
} from './privacyPolicyReducer';
import dataCollectionReducer, {
  initialState as dataCollectionInitialState,
} from './dataCollectionReducer';

const reduxMirror = new ReduxMirror({
  scenario: scenarioReducer,
});

const appReducer = combineReducers({
  ...reduxMirror.reducers(),
  routing: routerReducer,
  UISettings: UIReducer,
  products: productsReducer,
  application: loanApplicationReducer,
  asset: assetReducer,
  liability: liabilityReducer,
  expense: expenseReducer,
  income: incomeReducer,
  client: clientReducer,
  contact: contactReducer,
  employment: employmentReducer,
  other: otherReducer,
  property: propertyReducer,
  funding: fundingReducer,
  structure: structureReducer,
  address: addressReducer,
  document: documentReducer,
  household: householdReducer,
  advisor: advisorReducer,
  advisorOrg: advisorOrgReducer,
  goalLoanAppInfo: goalLoanAppInfoReducer,
  goalApplication: goalLoanApplicationReducer,
  expenseCategoryType: expenseCategoryTypeReducer,
  lender: lenderReducer,
  incomeType: incomeTypeReducer,
  depositType: depositTypeReducer,
  aggregator: aggregatorReducer,
  privacyPolicy: privacyPolicyReducer,
  dataCollection: dataCollectionReducer,
});

export const defaultState = {
  routing: {},
  scenario: INITIAL_SCENARIO_STATE,
  UISettings: UIInitialState,
  application: applicationInitialState,
  property: propertyInitialState,
  asset: assetInitialState,
  liability: liabilityInitialState,
  expense: expenseInitialState,
  income: incomeInitialState,
  employment: employmentInitialState,
  client: clientInitialState,
  contact: contactInitialState,
  advisor: advisorInitialState,
  advisorOrg: advisorOrgInitialState,
  funding: fundingInitialState,
  structure: structureInitialState,
  address: addressInitialState,
  document: documentInitialState,
  other: otherInitialState,
  household: householdCollectionInitialState,
  goalLoanAppInfo: goalLoanAppInfoInitialState,
  goalApplication: goalLoanApplicationInitialState,
  expenseCategoryType: expenseCategoryTypeInitialState,
  lender: lenderInitialState,
  incomeType: incomeTypeInitialState,
  depositType: depositTypeInitialState,
  aggregator: aggregatorInitialState,
  privacyPolicy: privacyPolicyInitialState,
  dataCollection: dataCollectionInitialState,
};

const rootReducer = (state, action) => {
  if (action.payload === 'LOGOUT') {
    state = defaultState;
  }

  return appReducer(state, action);
};

export default rootReducer;
