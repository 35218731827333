/* eslint-disable react/jsx-handler-names */
/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { noop } from 'lodash';
import hoistNonReactStatic from 'hoist-non-react-statics';

import AccordionSection from 'components/AccordionSection/AccordionSection';
import { accordionPropTypes } from 'types/customPropTypes';
import Button from 'components/Button/Button';

import commonMessages from 'constants/commonMessages';
import * as SpinnerNames from 'constants/spinnerNames';
import { logEvent, EVENTS } from 'lib/amplitude';

const applySection = ({
  iconName,
  furtherDecoration,
  nextButtonProps = noop,
  confirmEntities,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => (WrappedComponent) => {
  class ApplySection extends Component {
    static displayName = `ApplySection(${
      WrappedComponent.displayName || WrappedComponent.name
    })`;

    static propTypes = {
      intl: intlShape.isRequired,
      accordionProps: PropTypes.shape(accordionPropTypes),
      slug: PropTypes.string.isRequired,
      openAccordion: PropTypes.string,
      open: PropTypes.func.isRequired,
      close: PropTypes.func.isRequired,
      startAnimationSequence: PropTypes.func.isRequired,
      isCompleted: PropTypes.bool,
      isExact: PropTypes.bool,
      requestId: PropTypes.number,
      requests: PropTypes.arrayOf(PropTypes.object),
      requestErrors: PropTypes.arrayOf(PropTypes.object),
      pushSpinner: PropTypes.func,
      popSpinner: PropTypes.func,
      setPageError: PropTypes.func,
      clearAsyncRequestErrors: PropTypes.func,
      onSubmit: PropTypes.func,
      isOpen: PropTypes.bool.isRequired,
      hasOldInformation: PropTypes.bool,
      confirmEntities: PropTypes.func,
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
      const { onSubmit, requests, requestId, isOpen } = this.props;
      if (!onSubmit || this.hasControlledNextSequence() || !isOpen) {
        return;
      }

      const requestIsProcessing = requests.find(
        (r) => r.id === requestId && !r.error,
      );
      const requestWillBeProcessing = nextProps.requests.find(
        (r) => r.id === requestId && !r.error,
      );

      if (requestIsProcessing && !requestWillBeProcessing) {
        this.setProcessed(nextProps.requestErrors);
      }
    }

    setProcessed(requestErrors) {
      const {
        popSpinner,
        close,
        setPageError,
        clearAsyncRequestErrors,
      } = this.props;
      popSpinner(SpinnerNames.APPLY_SECTION);
      if (requestErrors.length === 0) {
        close();
      } else {
        setPageError({ status: 500, err: requestErrors });
        clearAsyncRequestErrors();
      }
    }

    saveAndNext = (e) => {
      const {
        accordionProps: { isLocked, nextSequence },
        startAnimationSequence,
        pushSpinner,
        close,
        onSubmit,
        hasOldInformation,
        slug,
      } = this.props;
      const hasControlledNextSequence = this.hasControlledNextSequence();
      e.preventDefault();
      if (onSubmit && !isLocked) {
        if (!hasControlledNextSequence) {
          pushSpinner(SpinnerNames.APPLY_SECTION);
        }
        if (hasOldInformation) {
          confirmEntities(this.props);
        } else {
          onSubmit();
        }
      } else if (!hasControlledNextSequence) {
        close();
      }
      logEvent(EVENTS.SAVE_AND_CLOSE, { section: slug });
      if (hasControlledNextSequence) {
        startAnimationSequence(nextSequence);
      }
    };

    UNSAFE_componentWillUpdate(nextProps) {
      this.forceScroll = nextProps.isExact && !this.props.isExact;
    }

    hasControlledNextSequence = () =>
      !!(
        this.props.accordionProps.nextSequence &&
        this.props.accordionProps.nextSequence.length > 0
      );

    render() {
      const {
        open,
        close,
        slug,
        openAccordion,
        accordionProps,
        isCompleted,
        isSaved,
        intl: { formatMessage },
        accordionProps: { isLocked },
      } = this.props;
      const decoration = furtherDecoration(this.props);
      let actionText;
      let icon = 'mi-arrow-with-circle-right';
      if (isLocked) {
        actionText = 'closeAndNext';
      } else if (
        decoration.warningMessage &&
        decoration.warningMessage.includes('confirm')
      ) {
        actionText = 'confirm';
        icon = 'sl-custom-hand-like-2';
      } else {
        actionText = this.hasControlledNextSequence()
          ? 'saveAndNext'
          : 'saveAndClose';
      }

      const isCompletedState = decoration.isCompleted ?? isCompleted;

      return (
        <AccordionSection
          id={`accordionSection--${WrappedComponent.displayName}`}
          {...accordionProps}
          openSelf={open(slug)}
          close={close}
          slug={slug}
          iconName={iconName}
          openAccordion={openAccordion}
          forceScroll={this.forceScroll}
          isFreezed={this.hasControlledNextSequence()}
          {...decoration}
          isCompleted={isCompletedState && isSaved}
        >
          <WrappedComponent saveAndNext={this.saveAndNext} {...this.props} />
          <div>
            <Button
              theme='applyButtonPrimary'
              id='applySection__next'
              onClick={this.saveAndNext}
              icon={icon}
              {...nextButtonProps(this.props)}
            >
              {formatMessage(commonMessages[actionText])}
            </Button>
          </div>
        </AccordionSection>
      );
    }
  }

  // Copy any static data over (e.g. managedQuestions)
  hoistNonReactStatic(ApplySection, WrappedComponent);

  return ApplySection;
};

export default applySection;
