/* eslint-disable sonarjs/no-duplicate-string */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import { searchAddress } from 'services/addressApi';
import { REQUIRED, INVALID_NZBN, REQUIRED_ADDRESS } from 'constants/validators';
import {
  MYCRM_FREQUENCY_OPTIONS,
  INCOME_FREQUENCY_OPTIONS,
  EMPLOYMENT_STATUS_OPTIONS,
  EMPLOYMENT_NATURE_OPTIONS,
  EMPLOYMENT_BASIS_OPTIONS,
  INCOME_OPTIONS,
  employmentTypeOptions,
} from 'constants/options';
import {
  EMPLOYMENT_TYPE_SELF_EMPLOYED,
  EMPLOYMENT_NATURE_REGISTERED,
} from 'shared/constants/myCRMTypes/employments';
import { WEEKLY } from 'shared/constants/options';
import {
  INCOME_SALARY_CATEGORY,
  INCOME_RENTAL,
} from 'shared/constants/myCRMTypes/incomes';
import locale from 'config/locale';

import manageQuestions, {
  manageQuestionsPropTypes,
} from 'hocs/manageQuestions';
import Questions from 'lib/Questions';

import Form from 'components/Form/Form';
import Question from 'components/Question/Question';
import Input from 'components/Input/Input';
import CurrencyInputWithSelection from 'components/CurrencyInputWithSelection/CurrencyInputWithSelection';
import Selection from 'components/Selection/Selection';
import Button from 'components/Button/Button';
import RadioButtonList from 'components/ButtonList/RadioButtonList';
import AutocompleteAddress from 'components/AutocompleteInput/AutocompleteAddress';
import { messages as employmentMessages } from 'components/PopupForms/EmploymentForm';

const messages = defineMessages({
  ownership: {
    id: 'IncomeForm.ownership',
    defaultMessage: 'Ownership',
  },
  categoryRadio: {
    id: 'IncomeForm.categoryRadio',
    defaultMessage: 'Income category',
  },
  categorySelect: {
    id: 'IncomeForm.categorySelect',
    defaultMessage: 'Income category',
  },
  frequency: {
    id: 'IncomeForm.frequency',
    defaultMessage: 'Frequency',
  },
  value: {
    id: 'IncomeForm.value',
    defaultMessage: 'Gross Income (pre-tax) & frequency',
  },
  valueNZ: {
    id: 'IncomeForm.valueNZ',
    defaultMessage: 'Pre-Tax Income & frequency',
  },
  valueAndGross: {
    id: 'IncomeForm.valueAndGross',
    defaultMessage: 'Income',
  },
  employmentId: {
    id: 'IncomeForm.employmentId',
    defaultMessage: 'Which employer is it related to?',
  },
  save: {
    id: 'IncomeForm.save',
    defaultMessage: 'Save',
  },
  saveAndAddAnother: {
    id: 'IncomeForm.saveAndAddAnother',
    defaultMessage: 'Save and add another',
  },
  remove: {
    id: 'IncomeForm.remove',
    defaultMessage: 'Remove',
  },
  confirm: {
    id: 'IncomeForm.confirm',
    defaultMessage: 'Confirm',
  },
});

// eslint-disable-next-line sonarjs/cognitive-complexity
const questionSet = (props) => {
  const questions = new Questions();
  const {
    category,
    clientIdOwnershipOptions,
    employmentOptions,
    employmentId,
    natureId,
    employmentTypeId,
    incomeTypes,
    categoryRequiresEmployment,
  } = props;

  questions.addBranch(['category', REQUIRED]);
  if (incomeTypes[category] && incomeTypes[category].length > 0) {
    questions.addBranch(['type', REQUIRED]);
  }

  if (category) {
    if (clientIdOwnershipOptions.length > 1) {
      questions.addBranch(['clientIds', REQUIRED]);
    }
    questions.addBranch(['frequency', REQUIRED], ['value', REQUIRED]);
    if (locale.isNZ) {
      questions.add(['isGross', REQUIRED]);
    }

    if (categoryRequiresEmployment(category)) {
      if (employmentOptions.length > 1) {
        questions.add('employmentId');
      }
      if (employmentOptions.length === 1 || employmentId === 'new') {
        const employerQuestions = [
          ['employerName', REQUIRED],
          ['employerAddress', REQUIRED_ADDRESS],
        ];
        const employmentQuestions = [
          ['employmentStatusId', REQUIRED],
          ['employmentTypeId', REQUIRED],
        ];
        const showNZBN = locale.isNZ;
        const showNatureField =
          employmentTypeId === EMPLOYMENT_TYPE_SELF_EMPLOYED.id && showNZBN;
        const showNZBNField =
          natureId === EMPLOYMENT_NATURE_REGISTERED.id && showNatureField;
        if (showNatureField) {
          employmentQuestions.push(['natureId', REQUIRED]);
        }
        if (showNZBNField) {
          employmentQuestions.push(['nzbn', INVALID_NZBN]);
        }
        employmentQuestions.push(['basisId', REQUIRED]);
        questions.addBranch(...employerQuestions);
        questions.addBranch(...employmentQuestions);
      }
    } else {
      questions.add('description');
    }

    questions.add('saveButton', 'saveAndAddAnotherButton');
  }

  return questions.arrayOfQuestions();
};

export class DisconnectedIncomeForm extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    working: PropTypes.object.isRequired,
    clientIdOwnershipOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
    save: PropTypes.func.isRequired,
    saveAndAddAnother: PropTypes.func.isRequired,
    remove: PropTypes.func,
    setIncomeClientIds: PropTypes.func,
    setIncomeType: PropTypes.func.isRequired,
    setIncomeCategory: PropTypes.func.isRequired,
    setIncomeDescription: PropTypes.func.isRequired,
    setIncomeValue: PropTypes.func.isRequired,
    setIncomeIsGross: PropTypes.func.isRequired,
    setIncomeFrequency: PropTypes.func.isRequired,
    setIncomeError: PropTypes.func.isRequired,
    incomeTypes: PropTypes.object,
    rentalIncomeCategory: PropTypes.object,
    incomeTypeCategories: PropTypes.arrayOf(PropTypes.object),
    categoryRequiresEmployment: PropTypes.func,
    categoryFieldLabel: PropTypes.func,
    categoryFieldDescription: PropTypes.func,
    ...manageQuestionsPropTypes,
  };

  static questionsToAsk = questionSet;

  static revealMethod = 'chunks';
  static revealOverBranch = false;

  constructor(props) {
    super(props);
    this.employmentTypeOptions = employmentTypeOptions();
  }

  componentDidMount() {
    const {
      working,
      setIncomeClientIds,
      clientIdOwnershipOptions,
      setIncomeIsGross,
    } = this.props;
    if (!working.id && clientIdOwnershipOptions.length === 1) {
      setIncomeClientIds(clientIdOwnershipOptions[0].value);
    }
    if (!(locale.isNZ && INCOME_SALARY_CATEGORY === working.category)) {
      setIncomeIsGross('true');
    } // TODO: do not follow this, update currency input with selection to accept boolean
  }

  onSubmit = () => {
    const { save, working } = this.props;
    save(working);
  };

  renderIncomeType = () => {
    const {
      working: { category, type },
      questions,
      setIncomeType,
      incomeTypes,
      categoryFieldLabel,
    } = this.props;
    // This additional logic has to be repeated here because of the unhandled intl.
    if (category && incomeTypes[category] && incomeTypes[category].length > 1) {
      return (
        <Question
          {...questions.type}
          label={categoryFieldLabel(category)}
          className='fullWidthInput'
        >
          {type ? (
            <Selection
              action={setIncomeType}
              value={type}
              items={incomeTypes[category]}
            />
          ) : (
            <RadioButtonList
              action={setIncomeType}
              value={type}
              items={incomeTypes[category]}
            />
          )}
        </Question>
      );
    }
    return null;
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  render() {
    const {
      working,
      questions,
      formCompleted,
      isLocked,
      setCurrentQuestionTo,
      saveAndAddAnother,
      intl,
      setIncomeCategory,
      setIncomeFrequency,
      setIncomeEmploymentId,
      setEmploymentBasisId,
      setIncomeDescription,
      setIncomeValue,
      setIncomeIsGross,
      setIncomeClientIds,
      remove,
      clientIdOwnershipOptions,
      clientIdOwnershipOptionsNonSharable,
      employmentOptions,
      setEmploymentEmployerIntlAddress,
      workingNewEmployment,
      setEmploymentEmployerName,
      setEmploymentTypeId,
      setEmploymentEmployerAddress,
      setEmploymentStatusId,
      setEmploymentNatureId,
      setEmploymentNZBN,
      NZBNLoading,
      setIncomeError,
      incomeTypeCategories,
      categoryRequiresEmployment,
      categoryFieldDescription,
      rentalIncomeCategory,
    } = this.props;
    const {
      type,
      category,
      clientIds,
      value,
      frequency,
      isGross,
      description,
      employmentId,
      isOldData,
    } = working;

    const isRental = type === parseInt(INCOME_RENTAL, 10);
    const clientIdsOptions = categoryRequiresEmployment(category)
      ? clientIdOwnershipOptionsNonSharable
      : clientIdOwnershipOptions;

    return (
      <Form
        id='incomeForm'
        formCompleted={formCompleted}
        onSubmit={this.onSubmit}
        onFocusLost={setCurrentQuestionTo(undefined)}
      >
        <fieldset disabled={isLocked}>
          {!category ? (
            <Question
              {...questions.category}
              label={intl.formatMessage(messages.categoryRadio)}
              className='fullWidthInput'
            >
              <RadioButtonList
                action={setIncomeCategory}
                value={undefined}
                items={incomeTypeCategories}
              />
            </Question>
          ) : (
            <Question
              {...questions.category}
              label={intl.formatMessage(messages.categorySelect)}
              className='fullWidthInput'
            >
              <Selection
                action={setIncomeCategory}
                value={category}
                selectionDefault={isRental ? type : undefined}
                items={isRental ? [rentalIncomeCategory] : incomeTypeCategories}
                disabled={isRental}
              />
            </Question>
          )}

          {this.renderIncomeType()}

          <Question
            {...questions.clientIds}
            label={intl.formatMessage(messages.ownership)}
            className='fullWidthInput'
          >
            {!clientIds ? (
              <RadioButtonList
                action={setIncomeClientIds}
                value={undefined}
                items={clientIdsOptions}
              />
            ) : (
              <Selection
                action={setIncomeClientIds}
                value={clientIds && clientIds.join()}
                items={clientIdsOptions}
                disabled={isRental}
              />
            )}
          </Question>
          {locale.isNZ && category === INCOME_SALARY_CATEGORY ? (
            <>
              <Question
                {...questions.frequency}
                label={intl.formatMessage(messages.frequency)}
                className='fullWidthInput'
              >
                <Selection
                  action={setIncomeFrequency}
                  value={frequency}
                  items={INCOME_FREQUENCY_OPTIONS(intl.formatMessage)}
                />
              </Question>
              <Question
                {...questions.value}
                label={intl.formatMessage(messages.valueAndGross)}
                className='fullWidthInput'
              >
                <CurrencyInputWithSelection
                  action={setIncomeValue}
                  value={value}
                  selectionDefault
                  selectionValue={isGross}
                  selectionAction={setIncomeIsGross}
                  selectionItems={INCOME_OPTIONS}
                />
              </Question>
            </>
          ) : (
            <Question
              {...questions.value}
              label={intl.formatMessage(
                locale.isNZ ? messages.valueNZ : messages.value,
              )}
              className='fullWidthInput'
            >
              <CurrencyInputWithSelection
                action={setIncomeValue}
                value={value}
                selectionDefault={WEEKLY}
                selectionValue={frequency}
                selectionAction={setIncomeFrequency}
                selectionItems={MYCRM_FREQUENCY_OPTIONS(intl.formatMessage)}
              />
            </Question>
          )}
          <Question
            {...questions.employmentId}
            label={intl.formatMessage(messages.employmentId)}
            className='fullWidthInput'
          >
            <Selection
              action={setIncomeEmploymentId}
              value={employmentId}
              items={employmentOptions}
            />
          </Question>
          <Question
            {...questions.description}
            label={type && category && categoryFieldDescription(category)}
            className='fullWidthInput'
          >
            <Input
              action={setIncomeDescription}
              value={description}
              disabled={isRental}
            />
          </Question>
          <Question
            {...questions.employerName}
            label={intl.formatMessage(employmentMessages.employerName)}
            className='fullWidthInput'
          >
            <Input
              action={setEmploymentEmployerName}
              value={workingNewEmployment.employerName}
            />
          </Question>
          <Question
            key='question.employerAddress'
            {...questions.employerAddress}
            label={intl.formatMessage(employmentMessages.employerAddress)}
            className='fullWidthInput'
          >
            <AutocompleteAddress
              errorId='employerAddress'
              addressId={workingNewEmployment.id || 'new'}
              setError={setIncomeError}
              action={setEmploymentEmployerAddress}
              value={workingNewEmployment.employerAddress.formattedAddress}
              intlValue={workingNewEmployment.employerAddress}
              intlOnChange={setEmploymentEmployerIntlAddress}
              serviceFn={searchAddress}
              disabled={isLocked}
            />
          </Question>
          <Question
            {...questions.employmentStatusId}
            label={intl.formatMessage(employmentMessages.statusSelect)}
            className='fullWidthInput'
          >
            <Selection
              action={setEmploymentStatusId}
              value={workingNewEmployment.statusId}
              items={EMPLOYMENT_STATUS_OPTIONS}
            />
          </Question>
          <Question
            {...questions.employmentTypeId}
            label={intl.formatMessage(employmentMessages.typeSelect)}
            className='fullWidthInput'
          >
            <Selection
              action={setEmploymentTypeId}
              value={workingNewEmployment.typeId}
              items={this.employmentTypeOptions}
            />
          </Question>
          <Question
            {...questions.natureId}
            label={intl.formatMessage(employmentMessages.nature)}
            className='fullWidthInput'
          >
            <Selection
              action={setEmploymentNatureId}
              value={workingNewEmployment.natureId}
              items={EMPLOYMENT_NATURE_OPTIONS}
            />
          </Question>
          <Question
            {...questions.nzbn}
            label={intl.formatMessage(employmentMessages.nzbn)}
            className='fullWidthInput'
          >
            <Input
              action={setEmploymentNZBN}
              value={workingNewEmployment.nzbn}
              maxLength={13}
            />
          </Question>
          <Question
            {...questions.basisId}
            label={intl.formatMessage(employmentMessages.basis)}
            className='fullWidthInput'
            key='questions.basisId'
          >
            <Selection
              action={setEmploymentBasisId}
              value={workingNewEmployment.basisId}
              items={EMPLOYMENT_BASIS_OPTIONS}
            />
          </Question>
          <Question {...questions.saveButton} className='fullWidthButton'>
            <Button
              theme='linkButton'
              hasErrorMessage={!formCompleted || NZBNLoading}
              onClick={this.onSubmit}
            >
              {intl.formatMessage(isOldData ? messages.confirm : messages.save)}
            </Button>
          </Question>
          {working.id ? (
            <Button
              className='brandColor__reverseButton'
              onClick={() => remove(working.id)}
            >
              {intl.formatMessage(messages.remove)}
            </Button>
          ) : (
            <Question
              {...questions.saveAndAddAnotherButton}
              className='fullWidthButton'
            >
              <Button
                theme='linkButton'
                hasErrorMessage={!formCompleted || NZBNLoading}
                onClick={() => saveAndAddAnother(working)}
              >
                {intl.formatMessage(messages.saveAndAddAnother)}
              </Button>
            </Question>
          )}
          <input className='hidden' type='submit' />
        </fieldset>
      </Form>
    );
  }
}

export default injectIntl(
  manageQuestions(DisconnectedIncomeForm, (props) => ({
    ...props.working,
    employerName: props.workingNewEmployment.employerName,
    employmentTypeId: props.workingNewEmployment.typeId,
    basisId: props.workingNewEmployment.basisId,
    natureId: props.workingNewEmployment.natureId,
    nzbn: props.workingNewEmployment.nzbn,
    employerAddress:
      props.workingNewEmployment.employerAddress &&
      props.workingNewEmployment.employerAddress.formattedAddress,
    employmentStatusId: props.workingNewEmployment.statusId,
    clientIdOwnershipOptions: props.clientIdOwnershipOptions,
    employmentOptions: props.employmentOptions,
    incomeTypes: props.incomeTypes,
    categoryRequiresEmployment: props.categoryRequiresEmployment,
    NZBNLoading: props.NZBNLoading,
  })),
);
