import { DIRECT_EXPENSE_CATEGORY_TYPES_API_PATH } from 'shared/constants/apiPaths';

import {
  axiosWithAuth as directAxiosWithAuth,
  returnJSON as directReturnJSON,
} from './mycrmBaseApi';

import { expenseCategoryTypeBuilderForMilli } from 'shared/lib/builders/milli/contactExpenseTypeBuilder';

// Begin port of serverless expensesApi
//
const getExpenseCategoryTypesDirect = (countryId) => {
  return directAxiosWithAuth
    .get(`${DIRECT_EXPENSE_CATEGORY_TYPES_API_PATH}/category-types`, {
      params: { countryId },
    })
    .then(directReturnJSON);
};

//
// End of port of serverless liabilitiesApi

// port of serverless handler: expenseCategoryTypes/expenseCategoryTypes.get
export const getExpenseCategoryTypes = (version, countryId) => {
  return getExpenseCategoryTypesDirect(countryId)
    .then(expenseCategoryTypeBuilderForMilli)
    .then(directReturnJSON);
};
