import {
  DIRECT_CONTACTS_API_PATH,
  DIRECT_FAMILIES_API_PATH,
} from 'shared/constants/apiPaths';

import {
  axiosWithAuth as directAxiosWithAuth,
  dataTransform as directDataTransform,
  returnJSON as directReturnJSON,
} from './mycrmBaseApi';

import { incomeBuilderForMilli } from 'shared/lib/builders/milli/income';
import {
  expenseSanitizerForMilli,
  expenseBuilderForMilli,
} from 'shared/lib/builders/milli/expense';
import { clientBuilderForMilli } from 'shared/lib/builders/milli/client';
import { liabilityBuilderForMilli } from 'shared/lib/builders/milli/liability';
import { assetBuilderForMilli } from 'shared/lib/builders/milli/asset';
import { realEstateAssetBuilderForMilli } from 'shared/lib/builders/milli/realEstateAsset';
import { addressBuilderForMilli } from 'shared/lib/builders/milli/address';
import { employmentBuilderForMilli } from 'shared/lib/builders/milli/employment';
import { incomeBuilderForMyCRM } from 'shared/lib/builders/mycrm/income';
import { clientBuilderForMyCRM } from 'shared/lib/builders/mycrm/client';
import { expenseBuilderForMyCRM } from 'shared/lib/builders/mycrm/expense';
import { addressBuilderForMyCRM } from 'shared/lib/builders/mycrm/address';
import { liabilityBuilderForMyCRM } from 'shared/lib/builders/mycrm/liability';
import { assetBuilderForMyCRM } from 'shared/lib/builders/mycrm/asset';
import { realEstateAssetBuilderForMyCRM } from 'shared/lib/builders/mycrm/realEstateAsset';
import { employmentBuilderForMyCRM } from 'shared/lib/builders/mycrm/employment';
import { buildAddressForMyCRM } from './placesApi';

// Begin port of serverless contactsApi
//
export const postClientToFamilyDirect = (contactId, data) => {
  return directAxiosWithAuth
    .post(`${DIRECT_FAMILIES_API_PATH}/${contactId}/clients`, data)
    .then(directReturnJSON);
};

const getContactIncomesDirect = (contactId) => {
  return directAxiosWithAuth
    .get(`${DIRECT_FAMILIES_API_PATH}/${contactId}/incomes`)
    .then(directReturnJSON);
};

export const postIncomeForContactDirect = (contactId, data) => {
  return directAxiosWithAuth
    .post(`${DIRECT_FAMILIES_API_PATH}/${contactId}/incomes`, data)
    .then(directReturnJSON);
};

const putIncomeForContactDirect = (contactId, data) => {
  return directAxiosWithAuth
    .put(`${DIRECT_FAMILIES_API_PATH}/${contactId}/incomes/${data.id}`, data)
    .then(directReturnJSON);
};

const deleteIncomeForContactDirect = (contactId, incomeId) => {
  return directAxiosWithAuth
    .delete(`${DIRECT_FAMILIES_API_PATH}/${contactId}/incomes/${incomeId}`)
    .then(directReturnJSON);
};

const getContactExpensesDirect = (contactId, isLivingExpensesV2TurnedOn) => {
  return directAxiosWithAuth
    .get(`${DIRECT_FAMILIES_API_PATH}/${contactId}/expenses`, {
      params: { isLivingExpensesV2TurnedOn },
    })
    .then(directReturnJSON);
};

const postExpenseForContactDirect = (contactId, data) => {
  return directAxiosWithAuth
    .post(`${DIRECT_FAMILIES_API_PATH}/${contactId}/expenses`, data)
    .then(directReturnJSON);
};

export const getContactAssetsDirect = (contactId) => {
  return directAxiosWithAuth
    .get(`${DIRECT_FAMILIES_API_PATH}/${contactId}/assets`)
    .then(directReturnJSON);
};

const getContactLiabilitiesDirect = (contactId, loanAppId) => {
  return directAxiosWithAuth
    .get(
      `${DIRECT_FAMILIES_API_PATH}/${contactId}/liabilities?loanApplicationId=${loanAppId}`,
    )
    .then(directReturnJSON);
};

const postLiabilityForContactDirect = (contactId, data) => {
  return directAxiosWithAuth
    .post(`${DIRECT_FAMILIES_API_PATH}/${contactId}/liabilities`, data)
    .then(directReturnJSON);
};

const postAssetForContactDirect = (contactId, data) => {
  return directAxiosWithAuth
    .post(`${DIRECT_FAMILIES_API_PATH}/${contactId}/assets`, data)
    .then(directReturnJSON);
};

const postRealEstateAssetForContactDirect = (contactId, data) => {
  return directAxiosWithAuth
    .post(`${DIRECT_FAMILIES_API_PATH}/${contactId}/real-estate-assets`, data)
    .then(directReturnJSON);
};

const getContactClientsDirect = (contactId, loanAppId) => {
  return directAxiosWithAuth
    .get(`${DIRECT_FAMILIES_API_PATH}/${contactId}/clients`, {
      params: { loanAppId },
    })
    .then(directReturnJSON);
};

const getContactAddressesDirect = (contactId) => {
  return directAxiosWithAuth
    .get(`${DIRECT_FAMILIES_API_PATH}/${contactId}/addresses`)
    .then(directReturnJSON);
};

const getContactEmploymentsDirect = (contactId) => {
  return directAxiosWithAuth
    .get(
      `${DIRECT_CONTACTS_API_PATH}/ClientEntityEmploymentGet?familyId=${contactId}`,
    )
    .then(directReturnJSON);
};

const postContactEmploymentDirect = (data) => {
  return directAxiosWithAuth
    .post(`${DIRECT_CONTACTS_API_PATH}/employment`, data)
    .then(directReturnJSON);
};

const getContactAddressDirect = (addressId) => {
  return directAxiosWithAuth
    .get(`/AddressDetailsGet?addressId=${addressId}`)
    .then(directReturnJSON);
};

export const postAddressForContactDirect = (contactId, data) => {
  return directAxiosWithAuth
    .post(`${DIRECT_FAMILIES_API_PATH}/${contactId}/addresses`, data)
    .then(directReturnJSON);
};

const updateAddressForContactDirect = (contactId, data) => {
  return directAxiosWithAuth
    .put(`${DIRECT_FAMILIES_API_PATH}/${contactId}/addresses`, data)
    .then(directReturnJSON);
};

const deleteAddressForContactDirect = (contactId, addressDetailsId) => {
  return directAxiosWithAuth
    .delete(
      `${DIRECT_FAMILIES_API_PATH}/${contactId}/addresses/${addressDetailsId}`,
    )
    .then(directReturnJSON);
};

export const getAdvisorEmailSignatureImageDirect = (familyId, type) => {
  return directAxiosWithAuth
    .get(
      `${DIRECT_CONTACTS_API_PATH}/${familyId}/email-signature-image?type=${type}`,
    )
    .then(directReturnJSON);
};

export const getContactGoalsDirect = (familyId) => {
  return directAxiosWithAuth
    .get(`${DIRECT_CONTACTS_API_PATH}/${familyId}/goal`)
    .then(directReturnJSON);
};

export const postContactGoalsDirect = (familyId, data) => {
  return directAxiosWithAuth
    .post(`${DIRECT_CONTACTS_API_PATH}/${familyId}/goal`, data)
    .then(directReturnJSON);
};

export const getContactLoanListDirect = (familyId) => {
  return directAxiosWithAuth
    .get(
      `${DIRECT_CONTACTS_API_PATH}/LoanListGet?familyID=${familyId}&includeOpportunity=true`,
    )
    .then(directReturnJSON);
};

export const getAdvisorSubscriptionsDirect = (familyId) => {
  return directAxiosWithAuth
    .get(`${DIRECT_CONTACTS_API_PATH}/${familyId}/SubscriptionInfo`)
    .then(directReturnJSON);
};

export const getContactLendersDirect = (adviserId) => {
  return directAxiosWithAuth
    .get(
      `${DIRECT_CONTACTS_API_PATH}/LenderListGet?milliAdviserId=${adviserId}`,
    )
    .then(directReturnJSON);
};
//
// End of port of serverless contactsApi

// port of serverless handler: contacts/contacts.getExpenses
export function getContactExpenses(contactId, isLivingExpensesV2Enabled) {
  return getContactExpensesDirect(
    contactId,
    isLivingExpensesV2Enabled === 'true',
  )
    .then((expenses) =>
      expenses.filter(expenseSanitizerForMilli).map(expenseBuilderForMilli),
    )
    .then(directReturnJSON);
}

// port of serverless handler: contacts/contacts.postExpenses
export function postExpenseForContact(
  contactId,
  data,
  isLivingExpensesV2Enabled,
) {
  return postExpenseForContactDirect(
    contactId,
    expenseBuilderForMyCRM({ ...data, isLivingExpensesV2Enabled }),
  )
    .then(expenseBuilderForMilli)
    .then(directReturnJSON);
}

// port of serverless handler: contacts/contacts.postClient
export function postClientToFamily(client) {
  return postClientToFamilyDirect(
    client.contactId,
    clientBuilderForMyCRM(client),
  )
    .then(clientBuilderForMilli)
    .then(directReturnJSON);
}

// port of serverless handler: contacts/contacts.getIncomes
export function getContactIncomes(contactId) {
  return getContactIncomesDirect(contactId)
    .then((data) => data.map(incomeBuilderForMilli))
    .then(directReturnJSON);
}

// port of serverless handler: contacts/contacts.postIncomes
export function postIncomeForContact(contactId, data) {
  return postIncomeForContactDirect(contactId, incomeBuilderForMyCRM(data))
    .then(incomeBuilderForMilli)
    .then(directReturnJSON);
}

// port of serverless handler: contacts/contacts.putIncome
export function putIncomeForContact(contactId, data) {
  return putIncomeForContactDirect(contactId, incomeBuilderForMyCRM(data))
    .then(incomeBuilderForMilli)
    .then(directReturnJSON);
}

// port of serverless handler: contacts/contacts.deleteIncome
export function deleteIncomeForContact(contactId, incomeId) {
  return deleteIncomeForContactDirect(contactId, incomeId).then(
    directReturnJSON,
  );
}

// port of serverless handler: contacts/contacts.getAssets
export function getContactAssets(contactId) {
  return getContactAssetsDirect(contactId)
    .then((data) => _.partition(data, (d) => d.type_id === 1))
    .then(([realEstateAssets, assets]) => ({
      realEstateAssets: realEstateAssets.map(realEstateAssetBuilderForMilli),
      assets: assets.map(assetBuilderForMilli),
    }))
    .then(directReturnJSON);
}

// port of serverless handler: contacts/contacts.getLiabilities
export function getContactLiabilities(contactId, loanAppId) {
  return getContactLiabilitiesDirect(contactId, loanAppId)
    .then((data) => data.map(liabilityBuilderForMilli))
    .then(directReturnJSON);
}

// port of serverless handler: contacts/contacts.postLiabilities
export function postLiabilityForContact(contactId, data) {
  return postLiabilityForContactDirect(
    contactId,
    liabilityBuilderForMyCRM(data),
  )
    .then(liabilityBuilderForMilli)
    .then(directReturnJSON);
}

// port of serverless handler: contacts/contacts.postAssets
export function postAssetForContact(contactId, data) {
  return postAssetForContactDirect(contactId, assetBuilderForMyCRM(data))
    .then(assetBuilderForMilli)
    .then(directReturnJSON);
}

// port of serverless handler: contacts/contacts.postRealEstateAssets
export function postRealEstateAssetForContact(contactId, data) {
  return buildAddressForMyCRM(data.address, Boolean(data.address))
    .then((addressId) => {
      return Promise.all([
        postRealEstateAssetForContactDirect(
          contactId,
          realEstateAssetBuilderForMyCRM({ ...data, addressId }),
        ),
        getContactAddressDirect(addressId),
      ]);
    })
    .then(([newAsset, address]) =>
      realEstateAssetBuilderForMilli({
        ...newAsset,
        address,
        client_ids: data.clientIds,
      }),
    )
    .then(directReturnJSON);
}

// port of serverless handler: contacts/contacts.getClients
export function getContactClients(contactId, loanAppId) {
  return getContactClientsDirect(contactId, loanAppId)
    .then((data) => data.map(clientBuilderForMilli))
    .then(directDataTransform((data) => data))
    .then(directReturnJSON);
}

// port of serverless handler: contacts/contacts.getAddresses
export function getContactAddress(contactId) {
  return getContactAddressesDirect(contactId)
    .then((data) => data.map(addressBuilderForMilli))
    .then(directReturnJSON);
}

// port of serverless handler: contacts/contacts.getEmployments
export function getContactEmployments(contactId) {
  return getContactEmploymentsDirect(contactId)
    .then((data) => data.map((e) => employmentBuilderForMilli(e, contactId)))
    .then(directReturnJSON);
}

// port of serverless handler: contacts/contacts.postEmployment
export function postContactEmployment(employment) {
  const { contactId, addressId, completeAddress, employerAddress } = employment;
  const formattedAddress = employerAddress.formattedAddress;
  const useIncomingAddressId =
    !formattedAddress ||
    (completeAddress && completeAddress.formattedAddress === formattedAddress);
  const addressIdPromise = useIncomingAddressId
    ? Promise.resolve(addressId)
    : buildAddressForMyCRM(
        employerAddress,
        Boolean(employerAddress.isCustomAddress),
      );
  return addressIdPromise
    .then((newAddressId) =>
      postContactEmploymentDirect(
        employmentBuilderForMyCRM(employment, newAddressId),
      ),
    )
    .then(({ Data: employmentId }) =>
      getContactEmploymentsDirect(contactId).then((employmentsData) => {
        const employments = employmentsData.map((e) =>
          employmentBuilderForMilli(e, contactId),
        );
        return employments.find((e) => e.id === employmentId);
      }),
    )
    .then(directReturnJSON);
}

// port of serverless handler: contacts/contacts.postAddresses
export function postAddressForContact(contactId, data) {
  return buildAddressForMyCRM(data.address, Boolean(data.address))
    .then((addressId) => {
      return Promise.all([
        postAddressForContactDirect(
          contactId,
          addressBuilderForMyCRM({ ...data, addressId }),
        ),
        getContactAddressDirect(addressId),
      ]);
    })
    .then(([postAddressForContactResp, placeDetails]) =>
      addressBuilderForMilli({
        ...postAddressForContactResp,
        ...placeDetails,
      }),
    )
    .then(directReturnJSON);
}

// port of serverless handler: contacts/contacts.putAddresses
export function putAddressForContact(contactId, data) {
  return buildAddressForMyCRM(data.address, Boolean(data.address))
    .then((addressId) => {
      return Promise.all([
        updateAddressForContactDirect(
          contactId,
          addressBuilderForMyCRM({ ...data, addressId }),
        ),
        getContactAddressDirect(addressId),
      ]);
    })
    .then(([newAddress, completeDetails]) =>
      addressBuilderForMilli({
        ...newAddress,
        completeDetails,
      }),
    )
    .then(directReturnJSON);
}

// port of serverless handler: contacts/contacts.deleteAddresses
export function delAddressForContact(contactId, addressId) {
  return deleteAddressForContactDirect(contactId, addressId).then(
    directReturnJSON,
  );
}
