import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './FormPopup.css';

import Popup, { popupProps } from './Popup';

export const formPopupProps = {
  ...popupProps,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  location: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  working: PropTypes.object,
  create: PropTypes.func,
  update: PropTypes.func.isRequired,
  delete: PropTypes.func.isRequired,
  clearWorking: PropTypes.func.isRequired,
  load: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  isLocked: PropTypes.bool,
  formPopUpStatus: PropTypes.object,
};

class FormPopup extends Popup {
  static propTypes = formPopupProps;

  static defaultProps = {
    ...Popup.defaultProps,
    item: {},
  };

  constructor(props) {
    super(props);
    this.processType = null;
  }

  componentDidMount() {
    super.componentDidMount();
    const { id, item } = this.props;
    if (id === 'new' || item.id !== undefined) {
      this.loadWorking(this.props.id);
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    super.UNSAFE_componentWillReceiveProps(nextProps);
    const { clearWorking, item, load } = this.props;
    if (item.id !== nextProps.item.id) {
      if (item.id) {
        clearWorking(item.id);
      }
      if (nextProps.item.id) {
        load(nextProps.item.id);
      }
    }
  }

  loadWorking(id) {
    const { load } = this.props;
    if (id !== 'new') {
      load(id);
    } else if (this.onLoadNew) {
      this.onLoadNew();
    }
  }

  title() {
    const { processing, processed } = this.state;
    const { working } = this.props;
    if (processing) {
      return this.processType ? this.processType.present : 'Processing';
    }
    if (processed) {
      return this.processType ? this.processType.past : 'Processed';
    }
    if (!working) {
      return 'Loading';
    }
    return null;
  }

  createOrUpdate() {
    const { working, update, create } = this.props;
    if (working.id !== undefined) {
      this.processType = {
        present: 'Updating',
        past: 'Updated',
      };
      update(working);
    } else {
      this.processType = {
        present: 'Creating',
        past: 'Created',
      };
      create(working);
    }
  }

  refresh = () => {
    const { working, clearWorking } = this.props;
    const { refreshKey } = this.state.refreshKey;
    clearWorking(working.id);
    this.setState({
      refreshKey: refreshKey + 1,
      processing: false,
      processed: false,
    });
  };

  save = () => {
    this.createOrUpdate();
    this.onProcessed = this.closePopup;
    if (this.logSaveEvent) {
      this.logSaveEvent();
    }
  };

  saveAndAddAnother = () => {
    this.createOrUpdate();
    this.onProcessed = this.refresh;
    if (this.logSaveAndAddEvent) {
      this.logSaveAndAddEvent();
    }
  };

  isLoaded() {
    return !!this.props.working;
  }

  remove = (id) => {
    this.processType = {
      present: 'Removing',
      past: 'Removed',
    };
    this.props.delete(id);
    this.onProcessed = this.closePopup;
    if (this.logRemoveEvent) {
      this.logRemoveEvent();
    }
  };

  onClose = () => {
    const { id, clearWorking } = this.props;
    clearWorking(id);
  };

  renderBody() {
    const { isLocked } = this.props;
    const rootStyle = classNames(styles.root, {
      [styles.isLocked]: isLocked,
    });

    return (
      <div className={rootStyle}>
        {this.isLoaded() && this.renderForm && this.renderForm()}
      </div>
    );
  }
}

export default FormPopup;
