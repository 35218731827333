import {
  DIRECT_GOAL_LOAN_APP_INFO_API_PATH,
  DIRECT_LOAN_OBJECTIVES_API_PATH,
} from 'shared/constants/apiPaths';
import {
  axiosWithAuth as directAxiosWithAuth,
  returnJSON as directReturnJSON,
} from '../mycrmBaseApi';
import {
  CURRENT_BANKS,
  FUTURE_LONG_TERM_GOAL_SUMMARY,
  HORIZON,
  LOAN_OPTIONS,
  LONG_TERM_GOALS,
  LONG_TERM_GOALS_CONSOLIDATED,
  NATURE_OF_CHANGE,
  PREFERENCES,
  PREFERRED_BANKS,
  QUESTION_IDS,
  QUESTION_TYPES,
  RETIREMENT,
  SERVICES,
  TEAM,
} from '../../../shared/constants/goalLoanAppInfo';
import { getLoanAppQuestions, getObjectiveOptionsDirect } from '../optionsApi';
import {
  adverseChangesBuilderForMilli,
  answerBuilderForMyCRM,
  answersBuilderForMilli,
  contactGoalBuilderForMyCRM,
  contactGoalToQsMapper,
  loanAppQsBuilderForMilli,
  longTermGoalConsolidatedBuilderForMilli,
  longTermGoalsBuilderForMilli,
  teamAnswerBuilderForMyCRM,
  teamAnswersBuilderForMilli,
} from 'shared/lib/builders/myCrmGoalLoanAppBuilder';
import { postContactGoalsDirect } from '../contactApi';
import {
  getFinancialGoalDisclaimerDirect,
  getFinancialGoalTypesByFamilyDirect,
  getFinancialGoalTypesDirect,
} from './financialGoalApi';
import { getLoanApplicantsDirect } from '../loanApplicationApi';

// Begin port of serverless loanScenarioApi
//
export const getLoanObjectivesDirect = (loanAppId) => {
  return directAxiosWithAuth
    .get(
      `${DIRECT_GOAL_LOAN_APP_INFO_API_PATH}/LoanObjectivesGet?loanApplicationId=${loanAppId}`,
    )
    .then(directReturnJSON);
};

export const getPossibleAdverseChangesDirect = (loanAppId, familyId) => {
  return directAxiosWithAuth
    .get(
      `${DIRECT_GOAL_LOAN_APP_INFO_API_PATH}/${loanAppId}/possible-adverse-changes?familyId=${familyId}&loanApplicationId=${loanAppId}&questionId=0`,
    )
    .then(directReturnJSON);
};

export const getPossibleAdverseChangesNatureDirect = (loanAppId, familyId) => {
  return directAxiosWithAuth
    .get(
      `${DIRECT_GOAL_LOAN_APP_INFO_API_PATH}/${loanAppId}/possible-adverse-changes/nature-of-change?familyId=${familyId}&loanApplicationId=${loanAppId}&questionId=0`,
    )
    .then(directReturnJSON);
};

export const deletePossibleAdverseChangesNatureDirect = (
  loanAppId,
  familyId,
  natureOfChangeId,
) => {
  return directAxiosWithAuth
    .delete(
      `${DIRECT_GOAL_LOAN_APP_INFO_API_PATH}/${loanAppId}/possible-adverse-changes/possible-anticipated?familyId=${familyId}&natureOfChangeId=${natureOfChangeId}`,
    )
    .then(directReturnJSON);
};

export const postPossibleAdverseChangesNatureDirect = (data) => {
  return directAxiosWithAuth
    .post(
      `${DIRECT_GOAL_LOAN_APP_INFO_API_PATH}/${data.loanApplicationId}/possible-adverse-changes/nature-of-change`,
      data,
    )
    .then(directReturnJSON);
};

export const postPossibleAdverseChangesDirect = (data) => {
  return directAxiosWithAuth
    .post(
      `${DIRECT_GOAL_LOAN_APP_INFO_API_PATH}/${data.loanApplicationId}/possible-adverse-changes`,
      data,
    )
    .then(directReturnJSON);
};

export const getAssetsInfoDirect = (loanAppId) => {
  return directAxiosWithAuth
    .get(
      `${DIRECT_GOAL_LOAN_APP_INFO_API_PATH}/AssetInfoGet?loanScenarioId=${loanAppId}`,
    )
    .then(directReturnJSON);
};

export const getLoanInsuranceDirect = (loanAppId) => {
  return directAxiosWithAuth
    .get(
      `${DIRECT_GOAL_LOAN_APP_INFO_API_PATH}/LoanInsuranceGet?loanApplicationId=${loanAppId}`,
    )
    .then(directReturnJSON);
};

export const setLoanInsuranceDirect = (data) => {
  return directAxiosWithAuth
    .post(`${DIRECT_GOAL_LOAN_APP_INFO_API_PATH}/LoanInsuranceSet`, data)
    .then(directReturnJSON);
};
//
// End port of serverless loanScenarioApi

// Begin port of serverless loanObjectivesApi
//
export const setLoanObjectivesDirect = (data) => {
  return directAxiosWithAuth
    .post(`${DIRECT_LOAN_OBJECTIVES_API_PATH}/answer`, data)
    .then(directReturnJSON);
};
//
// End port of serverless loanObjectivesApi

// Begin port of serverless handlers: goalLoanAppInfo/goalLoanAppInfo.*
export const getPromise = (filters, section, promise) => {
  if (filters.length === 0 || !section) {
    return promise;
  }
  if (filters.includes(section)) {
    return promise;
  }
  switch (section) {
    case LONG_TERM_GOALS:
      return Promise.resolve({ Data: [], isEmpty: true });
    case RETIREMENT:
    case PREFERENCES:
    case TEAM:
    case SERVICES:
      return Promise.resolve([]);
    default:
      return promise;
  }
};

export const getAnswersPromise = (filters, section, promise) => {
  if (filters.length === 0 || !section) {
    return promise;
  }
  if (filters.includes(section)) {
    return promise;
  }
  switch (section) {
    case RETIREMENT:
    case LONG_TERM_GOALS:
      return Promise.resolve({ Data: [], isEmpty: true });
    case PREFERENCES:
    case TEAM:
    case SERVICES:
      return Promise.resolve([]);
    default:
      return promise;
  }
};

export const getAllQuestions = (
  familyId,
  isNZ,
  brokerId,
  sections,
  featureNames,
) => {
  const features = featureNames ?? [];
  const filters = sections ?? [];
  const insuranceQType = isNZ
    ? QUESTION_TYPES.INSURANCE_AND_REFERRALS
    : QUESTION_TYPES.INSURANCE;

  const goalsPromise = getFinancialGoalTypesByFamilyDirect(familyId, brokerId);
  const goalsDisclaimerPromise = getFinancialGoalDisclaimerDirect().catch(
    () => ({}),
  );
  const changesPromise = getLoanAppQuestions(
    QUESTION_TYPES.POSSIBLE_ADVERSE_CHANGES,
  );
  const optionsPromise = getObjectiveOptionsDirect();
  const preferencesPromise = getLoanAppQuestions(QUESTION_TYPES.OBJECTIVES);
  const insurancePromise = getLoanAppQuestions(insuranceQType);

  return Promise.all([
    getPromise(filters, LONG_TERM_GOALS, goalsPromise),
    getPromise(filters, LONG_TERM_GOALS, goalsDisclaimerPromise),
    getPromise(filters, RETIREMENT, changesPromise),
    getPromise(filters, null, optionsPromise),
    getPromise(filters, PREFERENCES, preferencesPromise),
    getPromise(filters, TEAM, insurancePromise),
  ]).then(
    ([
      goalsData,
      disclaimerData,
      changesData,
      options,
      preferencesData,
      insuranceData,
    ]) => {
      const goalsDataPromise =
        goalsData.Data && goalsData.Data.length === 0 && !goalsData.isEmpty
          ? getFinancialGoalTypesDirect(brokerId).then((financialGoals) => {
              const promises = financialGoals.map((goal) =>
                postContactGoalsDirect(
                  familyId,
                  contactGoalBuilderForMyCRM({
                    familyId,
                    questionId: 0,
                    answer: '',
                    goalTypeId: goal.typeId,
                    goalType: goal.type,
                  }),
                ),
              );
              return Promise.all(promises).then(() => {
                return getFinancialGoalTypesByFamilyDirect(familyId);
              });
            })
          : Promise.resolve(goalsData);
      return goalsDataPromise.then((goalsData) => {
        const mappedGoalsData = contactGoalToQsMapper(
          goalsData,
          disclaimerData,
        );
        const longTermGoals = loanAppQsBuilderForMilli(
          mappedGoalsData,
          [],
          LONG_TERM_GOALS,
          isNZ,
          features,
        );
        const retirement = loanAppQsBuilderForMilli(
          changesData,
          options,
          RETIREMENT,
          isNZ,
          features,
        );
        const horizon = loanAppQsBuilderForMilli(
          changesData,
          options,
          HORIZON,
          isNZ,
        );
        const longTermGoalConsolidated = loanAppQsBuilderForMilli(
          changesData,
          options,
          LONG_TERM_GOALS_CONSOLIDATED,
          isNZ,
        );
        const currentBanks = loanAppQsBuilderForMilli(
          preferencesData,
          options,
          CURRENT_BANKS,
          isNZ,
          features,
        );
        const preferredBanks = loanAppQsBuilderForMilli(
          preferencesData,
          options,
          PREFERRED_BANKS,
          isNZ,
          features,
        );
        const loanOptions = loanAppQsBuilderForMilli(
          preferencesData,
          options,
          LOAN_OPTIONS,
          isNZ,
          features,
        );
        const team = loanAppQsBuilderForMilli(
          insuranceData,
          options,
          TEAM,
          isNZ,
          features,
        );
        const services = loanAppQsBuilderForMilli(
          preferencesData,
          options,
          SERVICES,
          isNZ,
          features,
        );
        return {
          [LONG_TERM_GOALS]: longTermGoals,
          [RETIREMENT]: retirement,
          [HORIZON]: horizon,
          [CURRENT_BANKS]: currentBanks,
          [PREFERRED_BANKS]: preferredBanks,
          [LOAN_OPTIONS]: loanOptions,
          [TEAM]: team,
          [SERVICES]: services,
          [LONG_TERM_GOALS_CONSOLIDATED]: longTermGoalConsolidated,
        };
      });
    },
  );
};

export const getAllAnswers = (loanAppId, filters) => {
  return getLoanApplicantsDirect(loanAppId).then((loanApplicants) => {
    const { InvolvedPartyPerson: families } = loanApplicants;
    if (!families || Object.keys(families).length === 0) {
      return Promise.reject(new Error('No families were loaded'));
    }
    return Promise.all([
      getAnswersPromise(
        filters,
        PREFERENCES,
        getLoanObjectivesDirect(loanAppId),
      ),
      getAnswersPromise(filters, TEAM, getLoanInsuranceDirect(loanAppId)),
    ]).then(([preferencesData, insuranceData]) => {
      const preferences = answersBuilderForMilli(preferencesData);
      const insurance = teamAnswersBuilderForMilli(insuranceData);
      const promises = Object.keys(families).map(async (familyId) => {
        return Promise.all([
          getAnswersPromise(
            filters,
            RETIREMENT,
            getPossibleAdverseChangesNatureDirect(loanAppId, familyId),
          ),
          getAnswersPromise(
            filters,
            RETIREMENT,
            getPossibleAdverseChangesDirect(loanAppId, familyId),
          ),
          getAnswersPromise(
            filters,
            LONG_TERM_GOALS,
            getFinancialGoalTypesByFamilyDirect(familyId),
          ),
        ]).then(([natureOfChangeData, changesData, goalsData]) => {
          const retirement = adverseChangesBuilderForMilli(changesData, {});
          const horizon = adverseChangesBuilderForMilli(
            changesData,
            natureOfChangeData,
          );
          const longTermGoals = longTermGoalsBuilderForMilli(goalsData);
          const longTermGoalConsolidated = longTermGoalConsolidatedBuilderForMilli(
            changesData,
            QUESTION_IDS[FUTURE_LONG_TERM_GOAL_SUMMARY],
          );

          return {
            familyId,
            answers: {
              [LONG_TERM_GOALS]: longTermGoals,
              [RETIREMENT]: retirement,
              [HORIZON]: horizon,
              [CURRENT_BANKS]: preferences,
              [PREFERRED_BANKS]: preferences,
              [LOAN_OPTIONS]: preferences,
              [TEAM]: insurance,
              [SERVICES]: preferences,
              [LONG_TERM_GOALS_CONSOLIDATED]: longTermGoalConsolidated,
            },
          };
        });
      });

      return Promise.all(promises).then((answers) => {
        return answers.reduce((map, a) => {
          map[a.familyId] = a.answers;
          return map;
        }, {});
      });
    });
  });
};

export const postAnswers = (section, body) => {
  const { loanAppId, familyId, clientId, answers } = body;
  let promises;
  switch (section) {
    case NATURE_OF_CHANGE:
      if (Object.keys(answers).length < 3 && !!answers.natureOfChangeId) {
        return deletePossibleAdverseChangesNatureDirect(
          loanAppId,
          familyId,
          answers.natureOfChangeId,
        );
      } else if (answers.natureOfChange) {
        return postPossibleAdverseChangesNatureDirect({
          loanApplicationId: loanAppId,
          loanScenarioId: loanAppId,
          familyId,
          clientId,
          ...answers,
        });
      }
      break;
    case HORIZON:
    case RETIREMENT:
      promises = Object.keys(answers).map((key) =>
        postPossibleAdverseChangesDirect({
          loanApplicationId: loanAppId,
          familyId,
          clientId,
          questionId: key,
          answer: answers[key],
        }),
      );
      return Promise.all(promises);
    case LONG_TERM_GOALS_CONSOLIDATED:
      promises = Object.keys(answers).map((key) =>
        postPossibleAdverseChangesDirect({
          loanApplicationId: loanAppId,
          familyId,
          clientId,
          questionId: key,
          answer: answers[key].value,
        }),
      );
      return Promise.all(promises);
    case LONG_TERM_GOALS:
      promises = Object.keys(answers).map((key) =>
        postContactGoalsDirect(
          familyId,
          contactGoalBuilderForMyCRM({
            familyId,
            questionId: key,
            answer: answers[key].value,
            goalTypeId: answers[key].goalTypeId,
          }),
        ),
      );
      return Promise.all(promises);
    case CURRENT_BANKS:
    case PREFERRED_BANKS:
    case LOAN_OPTIONS:
    case SERVICES:
      promises = Object.keys(answers)
        .filter((key) => key !== QUESTION_IDS.HAS_PREFERRED_LENDERS)
        .map((key) =>
          setLoanObjectivesDirect(
            answerBuilderForMyCRM({
              loanScenarioId: loanAppId,
              familyId,
              questionId: key,
              value: answers[key],
            }),
          ),
        );
      return Promise.all(promises);
    case TEAM: {
      const teamAnswers = { ...answers };
      promises = Object.keys(teamAnswers).map((key) =>
        setLoanInsuranceDirect(
          teamAnswerBuilderForMyCRM({
            loanScenarioId: loanAppId,
            familyId,
            questionId: key,
            value: teamAnswers[key],
          }),
        ),
      );
      return Promise.all(promises);
    }
    default:
      return Promise.reject(new Error('Invalid Section'));
  }
};
// End port of serverless handlers: goalLoanAppInfo/goalLoanAppInfo.*

export function getAllLoanAppInfoQuestions(
  familyId,
  isNZ,
  brokerId,
  sections = [],
  featureNames = [],
) {
  return getAllQuestions(familyId, isNZ, brokerId, sections, featureNames).then(
    directReturnJSON,
  );
}

export function getAllLoanAppInfoAnswers(loanAppId, sections = []) {
  return getAllAnswers(loanAppId, sections).then(directReturnJSON);
}

export function postLoanAppInfoAnswers(section, data) {
  return postAnswers(section || {}, data).then(directReturnJSON);
}
