import locale from 'config/locale';
import { DIRECT_GOOGLE_MAPS_API_PATH } from 'shared/constants/apiPaths';

import {
  axiosWithAuth as directAxiosWithAuth,
  returnJSON as directReturnJSON,
} from './mycrmBaseApi';
import {
  mapAddressComponentsFromPlaceDetail,
  mapPlaceDetailFromMyCRMPlaceDetail,
} from '../../shared/lib/localityMapperHelper';
import { getAddressStreetTypes } from './optionsApi';

export function placesAutoComplete(input, countryCode) {
  return directAxiosWithAuth
    .get(`${DIRECT_GOOGLE_MAPS_API_PATH}/places-auto-complete`, {
      params: {
        input: input,
        commasSeparatedCountryCodes: countryCode || locale.data.countryCode,
      },
    })
    .then(directReturnJSON)
    .then(function (autoCompleteResponse) {
      return {
        data: autoCompleteResponse.predictions || [],
      };
    })
    .then(directReturnJSON);
}

const unknownPlaceDetails = { address_components: [] };
export function getPlaceDetail(placeId) {
  return directAxiosWithAuth
    .get(`${DIRECT_GOOGLE_MAPS_API_PATH}/place`, {
      params: {
        placeId: placeId,
      },
    })
    .then(function (placeResponse) {
      return {
        data: placeResponse.data.Result || {},
      };
    })
    .then(directReturnJSON)
    .catch((error) => {
      if (error.json.status === 'NOT_FOUND') {
        return unknownPlaceDetails;
      }
    });
}

export function getPlaceDetailsByPreciseAddress(address, placeId) {
  const promise = placeId
    ? Promise.resolve([{ place_id: placeId }])
    : placesAutoComplete(address, locale.countryCode);
  return promise
    .then((places) => getPlaceDetail(places[0].place_id))
    .catch(() => unknownPlaceDetails);
}

export function addressDetailsSet(googleGeocodingAddress) {
  return directAxiosWithAuth
    .post(`/AddressDetailsSet`, googleGeocodingAddress)
    .then(directReturnJSON);
}

export function addressDetailsGet(addressId) {
  return directAxiosWithAuth
    .get(`/AddressDetailsGet`, {
      params: {
        addressId: addressId,
      },
    })
    .then(directReturnJSON);
}

export function buildAddressForMyCRM(address, isCustomAddress) {
  const { formattedAddress, placeId } = address;
  if (typeof formattedAddress !== 'string') {
    return Promise.resolve({
      formatted_address: null,
      state: null,
      suburb: null,
    });
  }
  if (isCustomAddress === undefined) {
    isCustomAddress = Boolean(address);
  } else {
    isCustomAddress = Boolean(isCustomAddress);
  }
  return getAddressStreetTypes().then((streetTypes) => {
    return getPlaceDetailsByPreciseAddress(formattedAddress, placeId)
      .then((myCrmPlaceDetails) =>
        mapAddressComponentsFromPlaceDetail(
          mapPlaceDetailFromMyCRMPlaceDetail(myCrmPlaceDetails),
          streetTypes,
        ),
      )
      .then((addressComponents) => ({
        formatted_address: formattedAddress,
        ...addressComponents,
      }))
      .then((placeDetails) => {
        return addressDetailsSet({
          ...placeDetails,
          isCustomAddress,
        });
      });
  });
}
