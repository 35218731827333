import { takeEvery, all, put, select, call } from 'redux-saga/effects';
import * as contactSelectors from 'selectors/contactSelectors';

import {
  CREATE_EXPENSE,
  UPDATE_EXPENSE,
  DELETE_EXPENSE,
  CONFIRM_EXPENSES,
} from 'actions/expenseActionTypes';
import expenseActions from 'actions/expenseActions';

import { putExpense, delExpense } from 'services/expenseApi';

import { postExpenseForContact } from 'services/contactApi';

import { monitorAsyncRequest } from 'lib/sagaHelpers.js';
import { featureFlags } from 'lib/rollout';
import { ASYNC_REQUEST_TYPE } from 'constants/options';

export function* createExpense({ payload }) {
  const primaryContact = yield select(contactSelectors.primaryContact);
  const expense = yield postExpenseForContact(
    primaryContact.contactId,
    payload,
    featureFlags.livingExpenseV2.isEnabled(),
  );
  yield put(expenseActions.setNewExpense(expense));
}

export function* updateExpense({ payload }) {
  const expense = yield putExpense(
    payload,
    featureFlags.livingExpenseV2.isEnabled(),
  );
  yield put(expenseActions.setExpense({ ...payload, ...expense }));
}

export function* confirmExpenses({ payload: { expenses } }) {
  yield all(expenses.map((payload) => call(updateExpense, { payload })));
}

export function* deleteExpense({ payload }) {
  const expenseId = yield delExpense(payload);
  yield put(expenseActions.removeExpense(expenseId));
}

export default function* expenseSagas() {
  yield all([
    yield monitorAsyncRequest(
      takeEvery,
      CREATE_EXPENSE,
      createExpense,
      ASYNC_REQUEST_TYPE.FORM_POP_UP_REQUEST,
    ),
    yield monitorAsyncRequest(
      takeEvery,
      UPDATE_EXPENSE,
      updateExpense,
      ASYNC_REQUEST_TYPE.FORM_POP_UP_REQUEST,
    ),
    yield monitorAsyncRequest(
      takeEvery,
      DELETE_EXPENSE,
      deleteExpense,
      ASYNC_REQUEST_TYPE.FORM_POP_UP_REQUEST,
    ),
    yield monitorAsyncRequest(takeEvery, CONFIRM_EXPENSES, confirmExpenses),
  ]);
}
