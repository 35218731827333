import { DIRECT_ACTION_TOKEN_API_PATH } from 'shared/constants/apiPaths';
import {
  axiosWithAuth as directAxiosWithAuth,
  returnJSON as directReturnJSON,
} from './mycrmBaseApi';

const TOKEN_STATUS = {
  Valid: 0,
  Invalid: 1,
  Expired: 2,
  AlreadyExecuted: 3,
};

export const getTokenStatus = (token) => {
  return directAxiosWithAuth
    .get(`${DIRECT_ACTION_TOKEN_API_PATH}/getstatus`, {
      params: { tokenText: token },
    })
    .then(directReturnJSON)
    .then(({ Status }) => {
      switch (Status) {
        case TOKEN_STATUS.Valid: {
          return Promise.resolve({
            isValidToken: true,
            isAlreadyExecuted: false,
          });
        }
        case TOKEN_STATUS.Invalid:
        case TOKEN_STATUS.Expired:
        case TOKEN_STATUS.AlreadyExecuted: {
          return Promise.resolve({
            isValidToken: false,
            isAlreadyExecuted: Status === TOKEN_STATUS.AlreadyExecuted,
          });
        }
        default: {
          return Promise.reject(new Error('Something went wrong'));
        }
      }
    });
};

export const executeToken = (token) => {
  return directAxiosWithAuth
    .post(`${DIRECT_ACTION_TOKEN_API_PATH}/executetoken`, {
      TokenText: token,
    })
    .then(directReturnJSON)
    .then(() => {
      return Promise.resolve({ success: true });
    });
};
