/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable sonarjs/cognitive-complexity */

import {
  SET_PAGE_ERROR,
  SET_ASYNC_REQUEST_ERROR,
  SET_PAGE_GHOST_ERROR,
} from 'actions/UIActionTypes';
import { TRACK_CURRENT_USER } from 'actions/scenarioActionTypes';
import LocalStorageProxy from 'lib/localStorageProxy';

function trackErrors() {
  return (store) => (next) => (action) => {
    const { payload, type } = action;
    /* 
    comment by removing sentry, 
    Will Implement it again in honeycomb
    const appId = LocalStorageProxy.loginAsLoanApplicationId
      ? `[AppId: ${LocalStorageProxy.loginAsLoanApplicationId}] `
      : '';
    const loginAsClientId = LocalStorageProxy.loginAsClientId
      ? `[ClientId: ${LocalStorageProxy.loginAsClientId}] `
      : '';
    const prefix = `${appId}${loginAsClientId}`;
    */
    switch (type) {
      case TRACK_CURRENT_USER:
        // eslint-disable-next-line no-case-declarations
        const { isClient, familyId, clientId, email, mobile } = payload;
        // eslint-disable-next-line no-case-declarations
        const params = {
          familyId,
          clientId,
          email,
          mobile,
          type: isClient ? 'client' : 'adviser',
        };

        LocalStorageProxy.sentry = JSON.stringify(params);
        break;
      case SET_PAGE_ERROR:
      case SET_PAGE_GHOST_ERROR:
        if (payload?.error || payload?.err) {
          // eslint-disable-next-line no-console
          console.error(payload.error ?? payload.err);
          /* 
            comment by removing sentry, 
            Will Implement it again in honeycomb
            console.warn('SET_PAGE_GHOST_ERROR===trackErrors', payload.status);
            console.warn(
              'SET_PAGE_GHOST_ERROR===trackErrors',
              `${prefix}${JSON.stringify(payload.err)}`,
            );
          */
        }
        break;
      case SET_ASYNC_REQUEST_ERROR:
        if (payload && payload.error) {
          /* 
            comment by removing sentry, 
            Will Implement it again in honeycomb
            console.warn(
              'SET_ASYNC_REQUEST_ERROR===trackErrors',
              payload.id,
              payload.type,
              `${prefix}${JSON.stringify(payload.error)}`,
            );
          */
        }
        break;
      default:
        break;
    }
    return next(action);
  };
}

export default trackErrors;
