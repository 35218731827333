import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { bindActionCreators } from 'redux';
import SVGInline from 'react-svg-inline';

import { hideBodyOverflow, absolutePopup } from 'lib/utils/browserUtils';
import { hasUpdatedEmailOrMobile, getFormattedMobile } from 'lib/clientHelper';

import * as SpinnerNames from 'constants/spinnerNames';
import * as clientSelectors from 'selectors/clientSelectors';
import * as UISelectors from 'selectors/UISelectors';
import clientActions from 'actions/clientActions';
import UIActions from 'actions/UIActions';

import Button from 'components/Button/Button';
import UpdatedEmailMobile from 'assets/icons/updated-email-mobile.svg';
import UpdatedEmail from 'assets/icons/updated-email.svg';
import UpdatedMobile from 'assets/icons/updated-mobile.svg';
import PopupStatus from '../PopupItem/PopupStatus.js';
import styles from '../PopupItem/PopupItem.css';

const isAbsolutePopup = absolutePopup();

class UpdateLoginPopup extends Component {
  static propTypes = {
    parentPath: PropTypes.string,
    closeOnOverlay: PropTypes.bool,
    updateClient: PropTypes.func,
    popSpinner: PropTypes.func,
    requestIsProcessing: PropTypes.bool,
    working: PropTypes.object,
    client: PropTypes.object,
    errors: PropTypes.arrayOf(PropTypes.object),
    clientId: PropTypes.string,
    history: PropTypes.object,
  };

  static styles = styles;

  constructor() {
    super();
    hideBodyOverflow(true);
    this.state = {
      processing: false,
      processed: false,
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown);
    setTimeout(
      this.popupHeader &&
        this.popupHeader.scrollIntoView.bind(this.popupHeader),
      100,
    ); /* avoid Illegal invocation call */
    this.props.popSpinner(SpinnerNames.APPLY_SECTION);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
    hideBodyOverflow(false);
  }

  componentDidUpdate(prevProps) {
    const { requestIsProcessing, clientId } = this.props;
    if (prevProps.requestIsProcessing && !requestIsProcessing) {
      this.setProcessed();
      if (clientId) {
        const closePopup = this.closePopup;
        setTimeout(() => closePopup(), 1000);
      }
    }
  }

  setProcessed = () => {
    this.setState({
      processing: false,
      processed: true,
    });
  };

  onKeyDown = (e) => {
    if (['Esc', 'Escape'].includes(e.key)) {
      this.closePopup();
    }
  };

  onClickOverlay = ({ target, currentTarget }) => {
    if (!this.props.closeOnOverlay) {
      return;
    }

    if (target === currentTarget) {
      this.closePopup();
    }
  };

  closePopup = () => {
    const { parentPath } = this.props;
    Promise.resolve(typeof this.onClose === 'function' && this.onClose()).then(
      () => {
        if (parentPath) {
          this.props.history.push(parentPath);
        } else {
          this.props.history.goBack();
        }
      },
    );
  };

  renderUpdated = (oldValue, newValue) => (
    <div className={styles.updated}>
      <div>
        <span>Old</span>
        <div>{oldValue}</div>
      </div>
      <div>
        <span>New</span>
        <div className={styles.updatedValue}>{newValue}</div>
      </div>
    </div>
  );

  getPopupIconAndLabel = (emailUpdated, mobileUpdated) => {
    if (emailUpdated && mobileUpdated) {
      return {
        label: 'login details',
        icon: UpdatedEmailMobile,
      };
    } else if (emailUpdated) {
      return {
        label: 'email',
        icon: UpdatedEmail,
      };
    }
    return {
      label: 'mobile number',
      icon: UpdatedMobile,
      iconStyle: 'mobileIcon',
    };
  };

  onSave = () => {
    const { updateClient, working } = this.props;
    this.setState({
      processing: true,
      processed: false,
    });
    updateClient(working);
  };

  renderBody = () => {
    const { working, client, clientId } = this.props;
    const { emailUpdated, mobileUpdated } = hasUpdatedEmailOrMobile(
      working,
      client,
    );

    const { icon, iconStyle, label } = this.getPopupIconAndLabel(
      emailUpdated,
      mobileUpdated,
    );

    const pronoun = label === 'login details' ? 'these' : 'this';
    return (
      <>
        <div className={styles.updateLogin}>
          <SVGInline
            className={classNames(styles.icon, styles[iconStyle])}
            svg={icon}
          />
          <h2>
            Looks like you&apos;ve updated {clientId ? pronoun : 'your'} {label}
          </h2>
          {emailUpdated && this.renderUpdated(client.email, working.email)}
          {mobileUpdated &&
            this.renderUpdated(
              getFormattedMobile(client.mobile),
              getFormattedMobile(working.mobile),
            )}
          <div className={styles.updateDescription}>
            {clientId
              ? 'These are the details to access this profile. We’ll confirm them next time logging in.'
              : `These are the details you’ll use to access your profile. We’ll confirm them next time you log in.`}
          </div>
        </div>
        <Button
          theme='linkButton'
          hasErrorMessage={false}
          onClick={this.onSave}
        >
          Save
        </Button>
        <Button className='brandColor__reverseButton' onClick={this.closePopup}>
          Cancel
        </Button>
      </>
    );
  };

  render() {
    const { working, client, errors, requestIsProcessing } = this.props;
    const { processing, processed } = this.state;
    const errored = errors.length > 0;
    const updating = processed || processing || errored;
    if (!working || !client) {
      return null;
    }

    const overlayStyle = classNames(styles.overlay, {
      [styles.absolute]: isAbsolutePopup,
    });

    let status = 'processing';
    if (errored) {
      status = 'errored';
    } else if (processing) {
      status = 'processing';
    } else if (processed) {
      status = 'processed';
    }

    return (
      <div className={overlayStyle} onClick={this.onClickOverlay}>
        <div className={styles.root}>
          <div
            className={styles.header}
            ref={(c) => {
              this.popupHeader = c;
            }}
          >
            <span className={styles.name}>Update login details</span>
            <i
              className={`sl-custom-cross-1 ${styles.closeIcon}`}
              onClick={this.closePopup}
            />
          </div>
          <div className={styles.popupBody}>
            {!requestIsProcessing && !updating && this.renderBody()}
            {(updating || requestIsProcessing) && (
              <PopupStatus status={status} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const primaryApplicant = clientSelectors.primaryApplicant(state);
  const clientId = ownProps.match.params.id;
  const requestId = clientId ? parseInt(clientId, 10) : primaryApplicant.id;
  return {
    requestId,
    working: clientSelectors.working(state)(requestId),
    client: clientSelectors.client(state)(requestId),
    requestIsProcessing: UISelectors.requestProcessing(state)(requestId),
    errors: UISelectors.requestErrors(state),
    clientId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      updateClient: clientActions.updateClient,
      pushSpinner: UIActions.pushSpinner,
      popSpinner: UIActions.popSpinner,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateLoginPopup);
