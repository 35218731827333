import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import { bindActionCreators } from 'redux';

import { makeFormalDisplayName } from 'lib/utils/formUtils';
import { dataOfMatchingValueByKey } from 'lib/utils/dataUtils';
import applyOwnPropsChecker from 'lib/applyOwnPropsChecker';

import * as coapplicantSelectors from 'selectors/coapplicantSelectors';
import clientActions from 'actions/clientActions';

import applySection from 'hocs/applySection';
import { accordionPropTypes } from 'types/customPropTypes';
import {
  CONTACT_TYPE_OPTIONS,
  CONTACT_ROLE_OPTIONS,
  PERSON,
  PERSON_CONTACT_TYPES,
} from 'constants/options';
import { coApplicationsHint } from 'lib/hintHelper';
import Button from 'components/Button/Button';
import EditableItem from 'components/EditableItem/EditableItem';
import ApplyItemContainer from 'components/ApplyItemContainer/ApplyItemContainer';
import ApplyAdditionalQuestion from 'components/ApplyAdditionalQuestion/ApplyAdditionalQuestion';

const messages = defineMessages({
  title: {
    id: 'CoapplicantsApply.title',
    defaultMessage: 'Co-Applicants',
  },
  titleDescription: {
    id: 'CoapplicantsApply.titleDescription',
    defaultMessage: 'Tell us if you have any co-applicants',
  },
  headerDescription: {
    id: 'CoapplicantsApply.headerDescription',
    defaultMessage: 'Is there anybody else applying with you?',
  },
  titleDescriptionWithCoapplicant: {
    id: 'CoapplicantsApply.titleDescriptionWithCoapplicant',
    defaultMessage:
      '{coapplicantCount} {coapplicantCount, plural, =1 {Co-Applicant} other {Co-Applicants}}',
  },
  titleDescriptionNoCoapplicant: {
    id: 'CoapplicantsApply.titleDescriptionNoCoapplicant',
    defaultMessage: 'No Co-Applicants',
  },
  headerDescriptionWithCoapplicant: {
    id: 'CoapplicantsApply.headerDescriptionWithCoapplicant',
    defaultMessage:
      'You have {coapplicantCount} {coapplicantCount, plural, =1 {Co-Applicant} other {Co-Applicants}}',
  },
  headerDescriptionNoCoapplicant: {
    id: 'CoapplicantsApply.headerDescriptionNoCoapplicant',
    defaultMessage: 'You have no Co-Applicants.',
  },
  add: {
    id: 'CoapplicantsApply.add',
    defaultMessage: 'Add a co-applicant',
  },
  edit: {
    id: 'CoapplicantsApply.edit',
    defaultMessage: 'Edit your co-applicants details',
  },
  coapplicants: {
    id: 'CoapplicantsApply.coapplicants',
    defaultMessage: 'Co-Applicants ({ coapplicantsCount })',
  },
  coapplicantNumber: {
    id: 'CoapplicantsApply.coapplicantNumber',
    defaultMessage: 'Co-Applicant #{ index }',
  },
  othersOnApplication: {
    id: 'CoapplicantsApply.othersOnApplication',
    defaultMessage:
      'Will there be other people or related parties on the application?',
  },
  firstCoapplicant: {
    id: 'CoapplicantsApply.firstCoapplicant',
    defaultMessage: 'Add your first co-applicant',
  },
  anotherCoapplicant: {
    id: 'CoapplicantsApply.anotherCoapplicant',
    defaultMessage: 'Add another co-applicant',
  },
  addCoapplicantDescription: {
    id: 'CoapplicantsApply.addCoapplicantDescription',
    defaultMessage:
      'Add the detail of all co-applicants, guarantors and trust or other business entities involved in the transaction here.',
  },
  noParties: {
    id: 'CoapplicantsApply.noParties',
    defaultMessage: 'No parties added.',
  },
});

const furtherDecoration = (props) => {
  const {
    intl: { formatMessage },
    hasCoapplicants,
    coapplicants: entities,
    warningMessage,
  } = props;
  const coapplicantCount = entities.length;
  let postfix = '';

  if (coapplicantCount) {
    postfix = 'WithCoapplicant';
  } else if (hasCoapplicants === false) {
    postfix = 'NoCoapplicant';
  }
  return {
    title: formatMessage(messages.title),
    titleDescription: formatMessage(messages[`titleDescription${postfix}`], {
      coapplicantCount,
    }),
    headerDescription: formatMessage(messages[`headerDescription${postfix}`], {
      coapplicantCount,
    }),
    warningMessage,
  };
};

const confirmEntities = (props) => {
  const { requestId, coapplicants, confirmCoapplicants } = props;
  confirmCoapplicants({
    id: requestId,
    coapplicants,
  });
};

const nextButtonProps = ({ isCompleted, hasCoapplicants }) => ({
  disabled: !isCompleted && _.isNil(hasCoapplicants),
});

const getName = (fullContact) => {
  return fullContact.type === PERSON
    ? makeFormalDisplayName(fullContact)
    : fullContact.businessName;
};

class CoapplicantsApply extends Component {
  static displayName = 'CoapplicantsApply';
  static propTypes = {
    intl: intlShape.isRequired,
    accordionProps: PropTypes.shape(accordionPropTypes).isRequired,
    coapplicants: PropTypes.arrayOf(PropTypes.object),
    hasCoapplicants: PropTypes.bool,
    setMetadata: PropTypes.func.isRequired,
    urlPath: PropTypes.string.isRequired,
  };

  onHasCoApplicantsClick = (value) => {
    this.props.setMetadata({ hasCoapplicants: value });
  };

  coapplicantsCopy = (coapplicants) =>
    coapplicants.map(
      (coapp) =>
        `${coapp.firstName} ${coapp.middleName || ''} ${
          coapp.lastName
        } (${dataOfMatchingValueByKey(
          CONTACT_ROLE_OPTIONS(this.props.intl.formatMessage),
          coapp.role,
          'label',
        )})`,
    );

  companiesCopy = (companies) =>
    companies.map(
      (company) =>
        `${company.businessName} (${dataOfMatchingValueByKey(
          CONTACT_ROLE_OPTIONS(this.props.intl.formatMessage),
          company.role,
          'label',
        )})`,
    );

  editContactUrl = (id, isClient) => {
    const path = isClient ? 'client' : 'coapplicant';
    return `${this.props.urlPath}/coapplicants/${path}/${id}`;
  };

  renderHasCoApplicants() {
    const {
      intl: { formatMessage },
      coapplicants,
    } = this.props;

    return (
      <ApplyItemContainer>
        {coapplicants.map((fullContact, i) => {
          const contactType = dataOfMatchingValueByKey(
            CONTACT_TYPE_OPTIONS(formatMessage),
            fullContact.type,
            'label',
          );
          const contactRole = PERSON_CONTACT_TYPES.includes(fullContact.type)
            ? ` - ${fullContact.isGuarantor ? 'Guarantor' : 'Borrower'}`
            : '';
          const leftLabel = fullContact.isPartnerClient
            ? 'Partner'
            : formatMessage(messages.coapplicantNumber, {
                index: i + 1,
              });
          return (
            <EditableItem
              key={`coapplicant-${fullContact.id}`}
              leftIcon='sl-custom-user-add-1'
              url={this.editContactUrl(fullContact.id, fullContact.isClient)}
              leftLabel={leftLabel}
              rightLabel={getName(fullContact)}
              rightDescription={`${contactType}${contactRole}`}
            />
          );
        })}
      </ApplyItemContainer>
    );
  }

  renderMaybeNoCoApplicants() {
    const {
      intl: { formatMessage },
      hasCoapplicants,
      accordionProps: { isLocked },
    } = this.props;

    return (
      <ApplyAdditionalQuestion
        id='coapplicants'
        label={formatMessage(messages.othersOnApplication)}
        hint={coApplicationsHint}
        action={this.onHasCoApplicantsClick}
        value={hasCoapplicants}
        disabled={isLocked}
      />
    );
  }

  render() {
    const {
      coapplicants,
      hasCoapplicants,
      accordionProps: { isLocked },
      intl: { formatMessage },
    } = this.props;

    const hasEntities = coapplicants.length > 0;
    const newContactUrl = `${this.props.urlPath}/coapplicants/coapplicant/new`;
    return (
      <div id='coapplicantsApply'>
        {hasEntities
          ? this.renderHasCoApplicants()
          : this.renderMaybeNoCoApplicants()}
        {(hasEntities || hasCoapplicants) && !isLocked && (
          <Button
            url={newContactUrl}
            theme='applyNew'
            icon='sl-custom-user-add-1'
          >
            {formatMessage(messages.anotherCoapplicant)}
          </Button>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    coapplicants: coapplicantSelectors.getAllCoapplicants(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      confirmCoapplicants: clientActions.confirmCoapplicants,
    },
    dispatch,
  );
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    applyOwnPropsChecker,
  )(
    applySection({
      iconName: 'sl-custom-user-add-1',
      furtherDecoration,
      nextButtonProps,
      confirmEntities,
    })(CoapplicantsApply),
  ),
);
