import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { formatMobile } from 'lib/utils/numberUtils';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import AnimationWrapper from 'components/AnimationWrapper/AnimationWrapper';
import AnimatedHeading from 'components/AnimatedHeading/AnimatedHeading';
import Button from 'components/Button/Button';
import locale from 'config/locale';

import classNames from 'classnames/bind';

import styles from './IntroduceBroker.css';

const applyPhoneTrackingId = 'apply-broker-phone-tracking';

class IntroduceBroker extends Component {
  static propTypes = {
    advisor: PropTypes.object,
    next: PropTypes.func,
    primaryButtonAction: PropTypes.func,
  };

  static defaultProps = {
    advisor: {},
  };

  constructor(props) {
    super(props);

    this.state = {
      showIntroduceBroker: false,
    };
  }

  renderPhoneSection = (unformattedPhone, formattedPhone) => (
    <a
      href={`tel:${unformattedPhone}`}
      id={applyPhoneTrackingId}
      className='brandColor__link'
    >
      <i className='mi-phone' />
      <span id={applyPhoneTrackingId}>{formattedPhone}</span>
    </a>
  );

  nextRoute = () => {
    const { next } = this.props;

    next && next();
  };

  componentDidMount() {
    this._showIntroduceBrokerTimeout = setTimeout(() => {
      this.setState({ showIntroduceBroker: true });
    }, 1000);
  }

  componentWillUnmount() {
    if (this._showIntroduceBrokerTimeout) {
      clearTimeout(this._showIntroduceBrokerTimeout);
    }
  }

  renderBrokerDetails = (advisor, image) => {
    const { showIntroduceBroker } = this.state;
    const { mobile } = advisor;
    return (
      <CSSTransitionGroup
        transitionName='slowlyFadeInAndOut'
        transitionEnterTimeout={1000}
        transitionLeaveTimeout={0}
        className={styles.introduceBrokerContainer}
      >
        {showIntroduceBroker && (
          <div id='IntroduceBroker'>
            <div className={styles.introduceBroker}>
              <div className={styles.advisorDetails}>
                <h1>{advisor.name}</h1>
                <span>
                  {advisor.displayTitle ||
                    (locale.data.countryCode === 'NZ'
                      ? 'Mortgage adviser'
                      : 'Mortgage broker')}
                </span>
                {advisor.brandingCategory === 'Loan Market' ? (
                  <span>Loan Market</span>
                ) : (
                  <span>
                    {advisor.actualAdviserOrgName ||
                      advisor.advisorOrgName ||
                      ''}
                  </span>
                )}
                <span>{advisor.displayAddress}</span>
                <span className={styles.telNumber}>
                  {this.renderPhoneSection(mobile, formatMobile(mobile))}
                </span>
              </div>
              <div className={styles.headerImg}>
                <img src={image} />
              </div>
            </div>
            <div className={styles.buttonContainer}>
              <Button
                id='inbetweenerNextButton'
                className='brandColor__button Button__base'
                animationClassName='centerAlignBlock'
                onClick={this.props.primaryButtonAction || this.nextRoute}
                transition
              >
                Next
              </Button>
            </div>
          </div>
        )}
      </CSSTransitionGroup>
    );
  };

  render() {
    const { advisor } = this.props;
    const image = advisor.imgUrl;
    const heading = `If you need a hand, ${advisor.preferredName} is here to help answer any questions you might have!`;

    return (
      <>
        <AnimationWrapper
          key='IntroduceBrokerWrapper'
          id='IntroduceBrokerWrapper'
        >
          <div className={styles.mainContainer}>
            <AnimatedHeading
              delay={10}
              skipAllowed={false}
              currentElementStyle={styles.header}
              className={classNames({
                [styles.animatedHeader]: true,
                alignLeft: false,
              })}
            >
              <h1>{heading}</h1>
            </AnimatedHeading>
          </div>
        </AnimationWrapper>
        {this.renderBrokerDetails(advisor, image)}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  advisor: state.advisor,
});

export default connect(mapStateToProps)(IntroduceBroker);
