import { DIRECT_PAGE_API_PATH } from 'shared/constants/apiPaths';

import {
  axiosWithAuth as directAxiosWithAuth,
  dataTransform as directDataTransform,
  returnJSON as directReturnJSON,
} from './mycrmBaseApi';

import { pageBuilderForMilli } from 'shared/lib/builders/milli/page';

// port of serverless handler: documents/documents.getPage
export function getPage(pageId) {
  return directAxiosWithAuth
    .get(`${DIRECT_PAGE_API_PATH}/${pageId}`)
    .then(directDataTransform(pageBuilderForMilli))
    .then(directReturnJSON);
}
