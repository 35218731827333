import { intlMobile } from 'shared/lib/utils';
import { GENDER_MAP } from 'shared/constants/myCrmMaps';
import { FORK_FAST_APPROVAL } from 'shared/constants/loanScenarios';
import { ROLE_ADULT, ROLE_CHILD } from 'shared/constants/myCRMTypes/clients';

import { dobBuilderForMyCRM } from './dob';

export function clientBuilderForMyCRM(client) {
  let roleId;
  if (client.isDependent) {
    roleId = ROLE_CHILD;
  } else {
    roleId = ROLE_ADULT;
  }

  const result = {
    name: {
      display: client.preferredName,
      first: client.firstName || client.preferredName,
      middle: client.middleName,
      last: client.lastName,
      previous: client.previousName,
      title: client.title,
    },
    dob: dobBuilderForMyCRM(client.dob),
    mothers_maiden_name: client.mothersMaidenName,
    marital_status_id: client.maritalStatusId,
    residency_id: client.residencyId,
    citizenship_id: client.citizenshipId,
    is_resident: client.isResident,
    country_of_residency_id: client.countryOfResidencyId,
    mobile: intlMobile(client.mobile),
    email: client.email,
    active: true,
    role_id: roleId,
    co_applicant: client.isDependent ? true : client.isCoapplicant,
    age: client.age,
    gender: GENDER_MAP[client.gender],
    scenario_id: client.loanApplicationId,
    is_primary: client.isPrimary,
    is_guarantor: client.role === 'guarantor',
    fork_choice: client.forkChoice === FORK_FAST_APPROVAL ? 1 : 2,
  };
  if (client.brokerId) {
    result.allocated_broker_family_id = client.brokerId;
  }
  return result;
}
