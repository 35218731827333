import { takeEvery, all, put, call, fork } from 'redux-saga/effects';
import { REQUEST_ADVISOR_ORG_INFO } from 'actions/advisorOrgActionTypes';
import advisorOrgActions from 'actions/advisorOrgActions';
import {
  setupBrandColorStylesheet,
  setupBrandFaviconForMilli,
  setupBYOBDefaults,
} from 'lib/brandColorHelpers';
import UIActions from 'actions/UIActions';
import scenarioActions from 'actions/scenarioActions';
import locale from 'config/locale';

import { getAdvisorOrgInfo } from 'services/advisorOrgsApi';
import { requestAggregator } from 'sagas/aggregatorSagas';

import { monitorAsyncRequest } from 'lib/sagaHelpers.js';
import { isApplyNow } from 'lib/envHelper';
import lenders from 'shared/lib/lenderHelper';
import { getLendersList } from 'lib/lenders';
import {
  COUNTRY_NEW_ZEALAND,
  COUNTRY_AUSTRALIA,
} from 'shared/constants/myCRMTypes/countries';

export function* requestAdvisorOrgInfo({ payload }) {
  yield put(UIActions.startFullPageSpinner());
  try {
    const advisorOrg = yield call(getAdvisorOrgInfo, payload);
    if (advisorOrg.countryCode) {
      locale.countryCode = advisorOrg.countryCode;
      if (locale.isNZ) {
        lenders.data = {
          _all: yield getLendersList(COUNTRY_NEW_ZEALAND.id),
        };
      } else {
        lenders.data = {
          _all: yield getLendersList(COUNTRY_AUSTRALIA.id),
        };
      }
    }
    if (advisorOrg.principleAdvisorFamilyId) {
      yield put(
        scenarioActions.setAllocatedBrokerFamilyId(
          advisorOrg.principleAdvisorFamilyId,
        ),
      );
    }
    if (advisorOrg.aggregatorId) {
      yield fork(requestAggregator, { payload: advisorOrg.aggregatorId });
    }
    yield put(advisorOrgActions.setAdvisorOrgInfo(advisorOrg));
    if (advisorOrg.isOwnBrand) {
      if (advisorOrg.brandColor) {
        yield call(setupBrandColorStylesheet, advisorOrg.brandColor);
        yield call(setupBrandFaviconForMilli, advisorOrg.brandColor);
      } else {
        yield call(setupBYOBDefaults);
      }
    }
    if (!payload.shouldContinueSpinner) {
      yield put(UIActions.stopFullPageSpinner());
    }
  } catch (error) {
    if (isApplyNow()) {
      yield call(setupBYOBDefaults);
    }
    yield put(UIActions.stopFullPageSpinner());
    yield put(UIActions.setPageError({ status: 404, error }));
    throw error;
  }
}

export default function* advisorOrgSagas() {
  yield all([
    yield monitorAsyncRequest(
      takeEvery,
      REQUEST_ADVISOR_ORG_INFO,
      requestAdvisorOrgInfo,
    ),
  ]);
}
