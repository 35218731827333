import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push, goBack } from '@loan_market/react-router-redux-multi';
import PropTypes from 'prop-types';

import { useModal } from 'contexts/ModalContext';

import GoalSetterForm from 'containers/goal/GoalSetterForm/GoalSetterForm';
import { getSection } from 'lib/goalFutureHelper';
import { getQuestionId } from 'lib/goalSetterFormHelper';
import { featureFlags } from 'lib/rollout';

import UIActions from 'actions/UIActions';
import goalLoanAppInfoActions from 'actions/goal/goalLoanAppInfoActions';

import locale from 'config/locale';
import { HORIZON } from 'shared/constants/paths';
import {
  NATURE_OF_CHANGE,
  CUSTOM_OPTION_LABELS,
  QUESTION_IDS,
} from 'shared/constants/goalLoanAppInfo';

import * as goalLoanApplicationSelectors from 'selectors/goalLoanApplicationSelectors';
import * as goalLoanAppInfoSelectors from 'selectors/goalLoanAppInfoSelectors';
import * as clientSelectors from 'selectors/clientSelectors';

import NatureOfChangeModal from './NatureOfChangeModal';

export function GoalFutureHorizon(props) {
  const {
    questions,
    answers,
    client,
    saveLoanAppInfoAnswers,
    applicationId,
    goTo,
    nextClient,
    back,
    trackEvent,
  } = props;
  const familyId = client.contactId;
  const ANTICIPATED_CHANGES = getQuestionId(QUESTION_IDS.ANTICIPATED_CHANGES);
  const [savedAnswers, updateSavedAnswers] = useState({});
  const modal = useModal();
  const submitButtonText =
    featureFlags['complianceQuestions.reduceDuplication'].isEnabled() ||
    locale.isNZ
      ? 'Save and Next'
      : 'Save and Close';

  const setAnswer = (questionId, value) =>
    updateSavedAnswers({ ...savedAnswers, [questionId]: value });

  const onSave = (isBack) => {
    if (Object.keys(savedAnswers).length > 0) {
      saveLoanAppInfoAnswers({
        section: HORIZON,
        clientId: client.id,
        answers: savedAnswers,
        loanAppId: applicationId,
        familyId,
      });
    }
    if (!isBack) {
      goTo(
        getSection({
          section: HORIZON,
          client,
          nextClient,
          active: applicationId,
        }).nextPath,
      );
    }
  };

  const onModalUpdate = (modalAnswers) => {
    saveLoanAppInfoAnswers({
      section: NATURE_OF_CHANGE,
      clientId: client.id,
      answers: modalAnswers,
      loanAppId: applicationId,
      familyId,
    });
    modal.hideModal();
  };

  const onSectionClick = (q, item) => {
    const customId = `${NATURE_OF_CHANGE}-${item.key}`;
    let natureOfChangeQs = q.subQuestions;
    if (item.key !== CUSTOM_OPTION_LABELS[ANTICIPATED_CHANGES].Other) {
      natureOfChangeQs = natureOfChangeQs.filter(
        (o) => o.questionId !== 'other',
      );
    }
    const modalContentProps = {
      displayName: client.displayName,
      updateAnswers: onModalUpdate,
      natureOfChangeQs,
      answers: {
        ...(answers[client.id] || {})[customId],
        ...savedAnswers[customId],
      },
      isSelected: item.isSelected,
      optionId: item.key,
    };
    modal.showModal({
      title: item.title,
      content: <NatureOfChangeModal {...modalContentProps} />,
      closeOnOverlay: false,
      theme: 'halfWidth',
    });
  };

  const formProps = {
    formName: HORIZON,
    action: setAnswer,
    questions,
    answers: { ...(answers[client.id] || {}), ...savedAnswers },
    displayName: client.displayName,
    onSectionClick,
    submitButtonProps: {
      text: submitButtonText,
      action: onSave,
      theme: 'buttonNext',
    },
    back,
    trackEvent,
  };

  return <GoalSetterForm {...formProps} />;
}

GoalFutureHorizon.propTypes = {
  match: PropTypes.object,
  client: PropTypes.object,
  nextClient: PropTypes.object,
  goTo: PropTypes.func,
  questions: PropTypes.object,
  answers: PropTypes.object,
  applicationId: PropTypes.number,
  saveLoanAppInfoAnswers: PropTypes.func,
  back: PropTypes.func,
  trackEvent: PropTypes.func,
};

const mapStateToProps = (state, props) => {
  const { clientId, familyId } = props.match.params;
  const cId = Number(clientId);
  const fId = Number(familyId);
  return {
    applicationId: goalLoanApplicationSelectors.loanApplicationId(state),
    client: clientSelectors.client(state)(cId) || {},
    nextClient: clientSelectors.nextClient(state)(fId, cId),
    questions: goalLoanAppInfoSelectors.questionsForSection(state)(HORIZON),
    answers: goalLoanAppInfoSelectors.answersForSection(state)(
      familyId,
      HORIZON,
    ),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goTo: push,
      saveLoanAppInfoAnswers: goalLoanAppInfoActions.saveLoanAppInfoAnswers,
      back: goBack,
      trackEvent: UIActions.trackEvent,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(GoalFutureHorizon);
