import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button/Button';
import SVGInline from 'react-svg-inline';
import SpinningWheel from 'assets/icons/spinning-wheel.svg';

import styles from './ButtonWithSpinner.css';
import classNames from 'classnames';

const ButtonWithSpinner = ({
  id,
  showSpinner,
  onClick,
  label,
  labelWhileSpinning,
  className,
  disabled = false,
  theme = 'buttonNext',
}) =>
  !showSpinner ? (
    <Button
      id={id}
      onClick={onClick}
      theme={theme}
      className={className}
      disabled={disabled}
    >
      {label}
    </Button>
  ) : (
    <div className={classNames(styles.showSpinner, className)}>
      <div className={classNames(styles.spinner, 'SpinnerIcon')}>
        <SVGInline svg={SpinningWheel} />
      </div>
      <span className={classNames(styles.label, 'labelWhileSpinning')}>
        {labelWhileSpinning}
      </span>
    </div>
  );

ButtonWithSpinner.propTypes = {
  id: PropTypes.string,
  showSpinner: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string,
  labelWhileSpinning: PropTypes.string,
};

export default ButtonWithSpinner;
