import { createSelector } from 'reselect';
import _ from 'lodash';
import moment from 'moment';

import {
  workingApplication,
  workingApplicationMetadata,
  getCreditHistoryQuestionsLeft,
  getForeseeableChangeQuestionsLeft,
} from 'selectors/applicationSelectors';
import {
  primaryApplicant,
  primaryHasFamilyEntities,
} from 'selectors/clientSelectors';
import { householdShape } from 'selectors/contactSelectors';
import { getAllCoapplicants } from 'selectors/coapplicantSelectors';
import {
  residenceEntity,
  prospectiveProperties,
  nonResidenceOwnEntities,
} from 'selectors/propertySelectors';
import { totalAssets } from 'selectors/assetSelectors';
import { liabilities as liabilitiesList } from 'selectors/liabilitySelectors';
import { totalIncomesMonthly } from 'selectors/incomeSelectors';
import {
  lendersSectionText,
  preferencesSectionText,
  futurePlansCompleted,
  servicesCompleted,
} from 'selectors/goalLoanApplicationSelectors';
import {
  totalMonthlyExpenses,
  anyExpensePending,
} from 'selectors/expenseSelectors';
import {
  getHasMainCategories,
  fileInvite,
  isFileInviteComplete,
} from 'selectors/documentSelectors';
import * as addressSelectors from 'selectors/addressSelectors';
import { clientsEmployments } from 'selectors/employmentSelectors';
import { SINGLE_HOUSEHOLD } from 'shared/constants/options';
import { ADDRESS_TYPE_CURRENT } from 'shared/constants/myCRMTypes/address';
import { EMPLOYMENT_STATUS_PRIMARY_EMPLOYMENT } from 'shared/constants/myCRMTypes/employments';
import { featureFlags } from 'lib/rollout';

const THREE_YEARS_IN_MONTHS = 36;

const goalCompleted = createSelector(
  workingApplication,
  (a) =>
    a &&
    a.primaryGoals &&
    _.values(a.primaryGoals).filter((val) => val).length > 0,
);

const familyCompleted = createSelector(
  workingApplicationMetadata,
  primaryHasFamilyEntities,
  householdShape,
  (metadata, hasEntities, contactHouseholdShape) =>
    metadata.hasHousehold === false ||
    contactHouseholdShape === SINGLE_HOUSEHOLD ||
    hasEntities,
);

const residenceCompleted = createSelector(
  residenceEntity,
  (residence) => !!residence,
);

const toPurchaseCompleted = createSelector(
  workingApplicationMetadata,
  prospectiveProperties,
  (metadata, properties) =>
    metadata.lookingToBuyProperty === false || properties.length > 0,
);

const propertiesCompleted = createSelector(
  workingApplicationMetadata,
  nonResidenceOwnEntities,
  (metadata, otherEntities) =>
    metadata.ownOtherProperties === false || !!otherEntities.length,
);

const assetsCompleted = createSelector(
  workingApplicationMetadata,
  totalAssets,
  (metadata, total) => total > 0 || metadata.hasAssets === false,
);

const liabilitiesCompleted = createSelector(
  workingApplicationMetadata,
  liabilitiesList,
  (metadata, list) => !!list.length || metadata.hasLiabilities === false,
);

const incomesCompleted = createSelector(
  workingApplicationMetadata,
  totalIncomesMonthly,
  (metadata, total) => total > 0 || metadata.hasIncomes === false,
);

const expensesCompleted = createSelector(
  workingApplicationMetadata,
  totalMonthlyExpenses,
  anyExpensePending,
  (metadata, total, anyPending) =>
    metadata.hasExpandedExpenses === true && !!total && !anyPending,
);

const applicantCompleted = createSelector(primaryApplicant, (client) => {
  return !!(
    client &&
    client.firstName &&
    client.email &&
    client.mobile &&
    !_.isEmpty(client.dob)
  );
});

const coapplicantCompleted = createSelector(
  workingApplicationMetadata,
  getAllCoapplicants,
  (metadata, entities) =>
    entities.length > 0 || metadata.hasCoapplicants === false,
);

const creditHistoryCompleted = createSelector(
  getCreditHistoryQuestionsLeft,
  (questionsLeft) => !questionsLeft,
);

const documentsCompleted = createSelector(
  getHasMainCategories,
  fileInvite,
  isFileInviteComplete,
  (hasMainCategories, fileInviteDetails, fileInviteComplete) =>
    fileInviteDetails.inviteUrl
      ? fileInviteComplete && hasMainCategories
      : hasMainCategories,
);

const lendersCompleted = createSelector(lendersSectionText, (text) => !!text);

const loanOptionsCompleted = createSelector(
  preferencesSectionText,
  (text) => !!text,
);

export const hasCurrentAddress = (entities) =>
  entities.some((e) => e.typeId === ADDRESS_TYPE_CURRENT.id);
export const hasCurrentEmployment = (entities) =>
  entities.some((e) => e.statusId === EMPLOYMENT_STATUS_PRIMARY_EMPLOYMENT.id);
export const isWithinThreeYears = (entities, startDate, endDate) => {
  const sorted = entities.sort((a, b) =>
    moment(a[startDate]).diff(moment(b[startDate])),
  );
  const totalHistoryInMonths = sorted.reduce((accum, addr) => {
    const start = moment({ ...addr[startDate], date: 1 });
    const end = moment({ ...addr[endDate], date: 1 });
    const diff = end.diff(start, 'months');
    return accum + diff;
  }, 0);
  return totalHistoryInMonths >= THREE_YEARS_IN_MONTHS;
};

const addressCompleted = createSelector(
  addressSelectors.primaryContactAddressHistories,
  (a) => hasCurrentAddress(a) && isWithinThreeYears(a, 'startDate', 'endDate'),
);

const employmentCompleted = createSelector(
  clientsEmployments,
  primaryApplicant,
  (employmentsById, client) =>
    client &&
    hasCurrentEmployment(employmentsById(client.id)) &&
    isWithinThreeYears(employmentsById(client.id), 'dateStarted', 'dateEnded'),
);

const foreseeableChangesCompleted = createSelector(
  getForeseeableChangeQuestionsLeft,
  (questionsLeft) =>
    featureFlags.disableMilliForeseeableChanges.isEnabled() || !questionsLeft,
);

const completionSelectors = [
  goalCompleted,
  familyCompleted,
  residenceCompleted,
  toPurchaseCompleted,
  propertiesCompleted,
  assetsCompleted,
  liabilitiesCompleted,
  incomesCompleted,
  expensesCompleted,
  applicantCompleted,
  coapplicantCompleted,
  documentsCompleted,
  addressCompleted,
  employmentCompleted,
  foreseeableChangesCompleted,
  creditHistoryCompleted,
];

const completionSelectorsNoDocuments = [
  goalCompleted,
  familyCompleted,
  residenceCompleted,
  toPurchaseCompleted,
  propertiesCompleted,
  assetsCompleted,
  liabilitiesCompleted,
  incomesCompleted,
  expensesCompleted,
  applicantCompleted,
  coapplicantCompleted,
  addressCompleted,
  employmentCompleted,
  foreseeableChangesCompleted,
  creditHistoryCompleted,
];

export const completedSections = createSelector(
  completionSelectors,
  (
    goal,
    family,
    residence,
    toPurchase,
    properties,
    assets,
    liabilities,
    incomes,
    expenses,
    applicant,
    coapplicants,
    documents,
    addresses,
    employments,
    foreseeableChanges,
    creditHistory,
  ) => ({
    goal,
    family,
    residence,
    'to-purchase': toPurchase,
    properties,
    assets,
    liabilities,
    incomes,
    expenses,
    applicant,
    coapplicants,
    'loan-amount': true,
    documents,
    addresses,
    employments,
    'foreseeable-changes': foreseeableChanges,
    'credit-history': creditHistory,
  }),
);

export const noDocumentsCompleted = createSelector(
  completionSelectorsNoDocuments,
  (...args) => args,
);

export const percentageCompleted = createSelector(
  lendersCompleted,
  loanOptionsCompleted,
  futurePlansCompleted,
  servicesCompleted,
  noDocumentsCompleted,
  (lenders, loanOptions, futurePlans, services, args) => {
    let newArgs = [...args];
    if (featureFlags.goalSetterFactFindMerge.isEnabled()) {
      newArgs = [
        ...newArgs,
        lenders,
        loanOptions,
        ...Object.values(futurePlans),
        services,
      ];
    }
    return newArgs.filter((a) => !!a).length / newArgs.length;
  },
);

export const completedEnoughToViewMaxBorrow = createSelector(
  goalCompleted,
  familyCompleted,
  residenceCompleted,
  toPurchaseCompleted,
  propertiesCompleted,
  assetsCompleted,
  liabilitiesCompleted,
  incomesCompleted,
  expensesCompleted,
  (...args) => args.filter((a) => !!a).length === args.length,
);

export const goalPercentageCompleted = createSelector(
  applicantCompleted,
  familyCompleted,
  coapplicantCompleted,
  residenceCompleted,
  toPurchaseCompleted,
  propertiesCompleted,
  assetsCompleted,
  liabilitiesCompleted,
  incomesCompleted,
  expensesCompleted,
  addressCompleted,
  employmentCompleted,
  creditHistoryCompleted,
  (...args) => args.filter((a) => !!a).length / args.length,
);

export const savedSections = createSelector(
  workingApplicationMetadata,
  (metadata) => metadata.saved || {},
);
