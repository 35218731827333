import _reduce from 'lodash/reduce';
import _camelCase from 'lodash/camelCase';

export function featureFlagsAndSubsBuilderForMilli(entities, keys) {
  return _reduce(
    entities,
    (obj, entity) => {
      obj[_camelCase(entity[keys.name])] = entity[keys.enabled];
      return obj;
    },
    {},
  );
}
