import {
  DIRECT_CLIENTS_API_PATH,
  DIRECT_SELECT_OPTIONS_API_PATH,
  DIRECT_USER_ACCOUNT_API_PATH,
} from 'shared/constants/apiPaths';

import {
  axiosWithAuth as directAxiosWithAuth,
  dataTransform as directDataTransform,
  returnJSON as directReturnJSON,
} from './mycrmBaseApi';
import {
  clientBuilderForMilli,
  clientTitleBuilder,
} from '../../shared/lib/builders/milli/client';
import { clientBuilderForMyCRM } from '../../shared/lib/builders/mycrm/client';
import { assetBuilderForMilli } from '../../shared/lib/builders/milli/asset';
import { assetBuilderForMyCRM } from '../../shared/lib/builders/mycrm/asset';
import { expenseBuilderForMilli } from '../../shared/lib/builders/milli/expense';
import { expenseBuilderForMyCRM } from '../../shared/lib/builders/mycrm/expense';
import { statusBuilderForMilli } from '../../shared/lib/builders/milli/status';
import { liabilityBuilderForMyCRM } from '../../shared/lib/builders/mycrm/liability';
import { liabilityBuilderForMilli } from '../../shared/lib/builders/milli/liability';
import { addressDetailsGet, buildAddressForMyCRM } from './placesApi';
import { realEstateAssetBuilderForMyCRM } from '../../shared/lib/builders/mycrm/realEstateAsset';
import { realEstateAssetBuilderForMilli } from '../../shared/lib/builders/milli/realEstateAsset';
import { PermissionDeniedError } from './authenticate';

// Begin port of serverless clientsApi, authenticate
//
export const advisorLoginAsAuthDirect = (clientId) => {
  return directAxiosWithAuth
    .get(`${DIRECT_CLIENTS_API_PATH}/${clientId}/check-access`)
    .then(() => ({ env_metadata: { clientId: clientId } }));
};

export const confirmClientLoginWithClientIdHeaderDirect = (clientId) => {
  return directAxiosWithAuth
    .get(`${DIRECT_CLIENTS_API_PATH}/${clientId}`)
    .then(
      directDataTransform((responseData) => ({
        env_metadata: {
          familyId: responseData.family_id,
          clientId: responseData.id,
        },
      })),
    )
    .then(directReturnJSON);
};

export const getClientsMatchingAuthEmailDirect = (
  brokerFamilyId,
  advisorOrgSlug,
) => {
  const searchParams = {};
  if (brokerFamilyId > 0) {
    searchParams.brokerFamilyId = brokerFamilyId;
  }

  if (advisorOrgSlug) {
    searchParams.adviserOrgSlug = advisorOrgSlug;
  }

  return directAxiosWithAuth
    .get(`${DIRECT_CLIENTS_API_PATH}`, {
      params: searchParams,
    })
    .then(directReturnJSON);
};

export const selectClientOfBrokerDirect = (brokerFamilyId, advisorOrgSlug) => {
  return getClientsMatchingAuthEmailDirect(brokerFamilyId, advisorOrgSlug)
    .then((clients) => {
      if (!clients) {
        throw new PermissionDeniedError('No client found');
      }

      const client = clients[0];
      return buildUser(authorizationHeader)({
        env_metadata: { clientId: client.id, familyId: client.family_id },
      });
    })
    .catch((error) => {
      throw new PermissionDeniedError('Unable to access this client: ' + error);
    });
};

export const getClientDirect = (clientId) => {
  return directAxiosWithAuth
    .get(`${DIRECT_CLIENTS_API_PATH}/${clientId}`)
    .then(directReturnJSON);
};

export const getLoanApplicationsForClientDirect = (clientId) => {
  return directAxiosWithAuth
    .get(`${DIRECT_CLIENTS_API_PATH}/${clientId}/scenarios`)
    .then((result) => {
      if (!result.data.length) {
        return Promise.reject(new Error('There is no application'));
      } else {
        return Promise.resolve(directReturnJSON(result));
      }
    });
};

export const getLeadStatusForClientDirect = (clientId) => {
  return directAxiosWithAuth
    .get(`${DIRECT_CLIENTS_API_PATH}/${clientId}/leads-status`)
    .then(directReturnJSON);
};

export const postClientDirect = (data) => {
  return directAxiosWithAuth
    .post(`${DIRECT_CLIENTS_API_PATH}`, data)
    .then(directReturnJSON);
};

export const putClientDirect = (clientId, data) => {
  return directAxiosWithAuth
    .put(`${DIRECT_CLIENTS_API_PATH}/${clientId}`, data)
    .then(directReturnJSON);
};

export const putClientMobileDirect = (clientId, data) => {
  return directAxiosWithAuth
    .put(`${DIRECT_CLIENTS_API_PATH}/register/${clientId}/mobile`, data)
    .then(directReturnJSON);
};

export const setEmailVerifiedDirect = (clientId) => {
  return directAxiosWithAuth
    .post(`${DIRECT_CLIENTS_API_PATH}/verify-user-email`, { clientId })
    .then(directReturnJSON)
    .then((data) => (data.Data ? data.Data : data));
};

export const patchClientDirect = (clientId, data) => {
  return getClientDirect(clientId)
    .then(directDataTransform(clientBuilderForMilli))
    .then(directReturnJSON)
    .then((clientData) => {
      const patchedData = {
        ...clientData,
        ...data,
      };
      if (_.isEmpty(patchedData.dob)) {
        delete patchedData.dob;
      }
      return putClient(clientId, patchedData);
    });
};

export const postLoanApplicationForClientDirect = (clientId, data) => {
  return directAxiosWithAuth
    .post(`${DIRECT_CLIENTS_API_PATH}/${clientId}/scenarios`, data)
    .then(directReturnJSON);
};

export const postAssetForClientDirect = (clientId, data) => {
  return directAxiosWithAuth
    .post(`${DIRECT_CLIENTS_API_PATH}/${clientId}/assets`, data)
    .then(directReturnJSON);
};

export const postLiabilityForClientDirect = (clientId, data) => {
  return directAxiosWithAuth
    .post(`${DIRECT_CLIENTS_API_PATH}/${clientId}/liabilities`, data)
    .then(directReturnJSON);
};

export const postExpenseForClientDirect = (clientId, data) => {
  return directAxiosWithAuth
    .post(`${DIRECT_CLIENTS_API_PATH}/${clientId}/expenses`, data)
    .then(directReturnJSON);
};

export const postRealEstateForClientDirect = (clientId, data) => {
  return directAxiosWithAuth
    .post(`${DIRECT_CLIENTS_API_PATH}/${clientId}/real-estate-assets`, data)
    .then(directReturnJSON);
};

export const getEmploymentsForClientDirect = (clientId) => {
  return directAxiosWithAuth
    .get(`${DIRECT_CLIENTS_API_PATH}/${clientId}/employments`)
    .then(directReturnJSON);
};

export const postEmploymentForClientDirect = (clientId, data) => {
  return directAxiosWithAuth
    .post(`${DIRECT_CLIENTS_API_PATH}/${clientId}/employments`, data)
    .then(directReturnJSON);
};

export const postClientEventDirect = (clientId, data) => {
  return directAxiosWithAuth
    .post(`${DIRECT_CLIENTS_API_PATH}/${clientId}/notification-event`, data)
    .then(directReturnJSON);
};

export const postEmailVerificationTokenDirect = () => {
  return directAxiosWithAuth
    .post(`${DIRECT_CLIENTS_API_PATH}/register/email-verification-token`, {})
    .then(directReturnJSON);
};

export const postVerifyEmailDirect = (data) => {
  return directAxiosWithAuth
    .post(`${DIRECT_CLIENTS_API_PATH}/register/email-validation`, data)
    .then(directReturnJSON);
};

export const postPasswordRecoveryDirect = (data) => {
  return directAxiosWithAuth
    .post(`${DIRECT_USER_ACCOUNT_API_PATH}/password-recovery-adviserorg`, data)
    .then(directReturnJSON);
};

export const postOnlineFactFindDirect = (data) => {
  return directAxiosWithAuth
    .post(`${DIRECT_CLIENTS_API_PATH}/online-fact-find/resend`, data)
    .then(directReturnJSON);
};
//
// End of port of serverless clientsApi, authenticate

export const postClient = (client) => ({
  ...client,
  loanApplicationId: 1,
  id: Math.round(Math.random() * 1000),
});

export function getClient(clientId) {
  return directAxiosWithAuth
    .get(`${DIRECT_CLIENTS_API_PATH}/${clientId}`)
    .then(directDataTransform(clientBuilderForMilli))
    .then(directReturnJSON);
}

export function putClient(clientId, data) {
  return directAxiosWithAuth
    .put(`${DIRECT_CLIENTS_API_PATH}/${clientId}`, clientBuilderForMyCRM(data))
    .then(directDataTransform(clientBuilderForMilli))
    .then(directReturnJSON);
}

export function delClient(clientId) {
  return directAxiosWithAuth
    .delete(`${DIRECT_CLIENTS_API_PATH}/${clientId}`)
    .then(directReturnJSON);
}

// We should delete this, expense creates happen through contacts instead now
export function postExpenseForClient(expense) {
  return directAxiosWithAuth
    .post(
      `${DIRECT_CLIENTS_API_PATH}/${expense.clientId}/expenses`,
      expenseBuilderForMyCRM(expense),
    )
    .then(directDataTransform(expenseBuilderForMilli))
    .then(directReturnJSON);
}

export function getLoanApplicationStatus(clientId) {
  return directAxiosWithAuth
    .get(`${DIRECT_CLIENTS_API_PATH}/${clientId}/leads-status`)
    .then(function (resp) {
      const status =
        resp && resp.data && resp.data.status
          ? statusBuilderForMilli(resp.data.status)
          : 1;
      return {
        data: {
          status: status,
        },
      };
    })
    .then(directReturnJSON);
}

export function deleteAsCoapplicant(coapplicant, loanApplicationId) {
  return directAxiosWithAuth
    .put(
      `${DIRECT_CLIENTS_API_PATH}/${coapplicant.clientId}`,
      clientBuilderForMyCRM({
        ...coapplicant,
        isCoapplicant: false,
        loanApplicationId,
      }),
    )
    .then(directDataTransform(clientBuilderForMilli))
    .then(directReturnJSON);
}

export function postAssetForClient(asset) {
  return directAxiosWithAuth
    .post(
      `${DIRECT_CLIENTS_API_PATH}/${asset.clientId}/assets`,
      assetBuilderForMyCRM(asset),
    )
    .then(function (lmAsset) {
      return {
        data: {
          ...lmAsset.data,
          category_id: asset.category,
          client_ids: asset.clientIds,
        },
      };
    })
    .then(directDataTransform(assetBuilderForMilli))
    .then(directReturnJSON);
}

export function postRealEstateAssetForClient(asset) {
  return buildAddressForMyCRM(asset.address).then((addressId) => {
    return directAxiosWithAuth
      .post(
        `${DIRECT_CLIENTS_API_PATH}/${asset.clientId}/real-estate-assets`,
        realEstateAssetBuilderForMyCRM({ ...asset, addressId }),
      )
      .then(function (newAsset) {
        return addressDetailsGet(addressId).then(function (address) {
          return {
            data: {
              ...newAsset.data,
              address,
              client_ids: asset.clientIds,
            },
          };
        });
      })
      .then(directDataTransform(realEstateAssetBuilderForMilli))
      .then(directReturnJSON);
  });
}

export function postLiabilityForClient(liability) {
  return directAxiosWithAuth
    .post(
      `${DIRECT_CLIENTS_API_PATH}/${liability.clientId}/liabilities`,
      liabilityBuilderForMyCRM(liability),
    )
    .then(function (lmLiability) {
      return {
        data: {
          ...lmLiability.data,
          category_id: liability.category,
        },
      };
    })
    .then(directDataTransform(liabilityBuilderForMilli))
    .then(directReturnJSON);
}

export function getEmploymentsForClient(clientId) {
  // postEmploymentForClient is never used.  Creates happen via contacts.
  return Promise.reject(
    new Error('getEmploymentsForClient unexpectedly used.'),
  );
}

export function postEmploymentForClient(employment) {
  // postEmploymentForClient is never used.  Creates happen via contacts.
  return Promise.reject(
    new Error('postEmploymentForClient unexpectedly used.'),
  );
}

export function getTitleOptions() {
  return directAxiosWithAuth
    .get(`${DIRECT_SELECT_OPTIONS_API_PATH}/Title`)
    .then(directDataTransform(clientTitleBuilder))
    .then(directReturnJSON);
}
