import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { intlShape, injectIntl, defineMessages } from 'react-intl';

import manageQuestions, {
  manageQuestionsPropTypes,
} from 'hocs/manageQuestions';
import Form from 'components/Form/Form';
import Question from 'components/Question/Question';
import Selection from 'components/Selection/Selection';

import householdActions from 'actions/householdActions';
import * as clientSelectors from 'selectors/clientSelectors';
import * as contactSelectors from 'selectors/contactSelectors';
import Questions from 'lib/Questions';
import { REQUIRED } from 'constants/validators';

import { householdShapeOptions } from 'constants/options';

const messages = defineMessages({
  describeHousehold: {
    id: 'HouseholdForm.describeHousehold',
    defaultMessage: 'What best describes your current household?',
  },
});

class HouseholdForm extends PureComponent {
  static displayName = 'HouseholdForm';
  static propTypes = {
    intl: intlShape.isRequired,
    ...manageQuestionsPropTypes,
  };

  static questionsToAsk = (props) => {
    const questions = new Questions();
    questions.addBranch(['householdShape', REQUIRED]);

    return questions.arrayOfQuestions();
  };

  static forceVisibility = true;
  static forceActive = true;

  componentDidMount() {
    const { setFormCompleted, formCompleted, setHouseholdShape } = this.props;
    setFormCompleted(formCompleted);
    setHouseholdShape(this.household);
  }

  componentDidUpdate(prevProps) {
    const {
      setFormCompleted,
      householdShape,
      dependents,
      partner,
    } = this.props;
    const shapeChanged = householdShape !== prevProps.householdShape;
    const dependentsChanged =
      (dependents || []).length !== (prevProps.dependents || []).length;
    const partnerChanged =
      (!partner && !!prevProps.partner) || (!!partner && !prevProps.partner);
    if (shapeChanged || dependentsChanged || partnerChanged) {
      setFormCompleted(this.isHouseholdCompleted());
    }
  }

  isHouseholdCompleted = () => {
    if (!this.props.householdShape) {
      return false;
    }

    const { isCouple, isParent, dependents, partner } = this.props;
    const hasDependents = (dependents || []).length > 0;
    return (
      ((isCouple && !!partner) || !isCouple) &&
      ((isParent && hasDependents) || !isParent)
    );
  };

  onSelectHouseholdShape = (householdShape) => {
    const { setHouseholdShape } = this.props;
    setHouseholdShape(householdShape);
  };

  render() {
    const {
      intl: { formatMessage },
      onSubmit,
      questions,
      householdShape,
      isLocked,
      formCompleted,
      setCurrentQuestionTo,
      onFocus,
    } = this.props;

    return (
      <Form
        id='HouseholdForm'
        onSubmit={onSubmit}
        formCompleted={formCompleted}
        onFocusLost={setCurrentQuestionTo(undefined)}
        onFocus={onFocus}
      >
        <Question
          {...questions.householdShape}
          label={formatMessage(messages.describeHousehold)}
          forceFocus={false}
        >
          <Selection
            action={this.onSelectHouseholdShape}
            items={householdShapeOptions(formatMessage)}
            value={householdShape}
            disabled={isLocked}
          />
        </Question>
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    householdShape: contactSelectors.currentHouseholdShape(state),
    household: contactSelectors.householdShape(state),
    partner: clientSelectors.primaryApplicantsPartner(state),
    dependents: clientSelectors.primaryApplicantsDependents(state),
    isCouple: contactSelectors.isCouple(state),
    isParent: contactSelectors.isParent(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators(
      {
        setHouseholdShape: householdActions.setHouseholdShape,
        setFormCompleted: householdActions.setFormCompleted,
      },
      dispatch,
    ),
  };
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(manageQuestions(HouseholdForm)),
);
