import { stringToInt } from 'shared/lib/utils';
import { REPAYMENT_TYPE_INTEREST_ONLY_VALUE } from 'shared/constants/repaymentTypes';
import { MILLI_TO_MYCRM_FREQ_ID } from 'shared/lib/frequencyHelper';

export function structureBuilderForMyCRM(structure) {
  const {
    id,
    repaymentFrequency,
    repaymentType,
    lenderId,
    productId,
    loanAmount,
    loanTerm,
    clientIds,
    interestOnlyTerm = 1,
  } = structure;
  const result = {
    id,
    loan_term: stringToInt(loanTerm),
    loan_amount: Math.floor(loanAmount),
    product_id: productId,
    lender_id: lenderId,
    interest_only_term:
      repaymentType === REPAYMENT_TYPE_INTEREST_ONLY_VALUE
        ? stringToInt(interestOnlyTerm)
        : 0,
    payment_frequency_id: MILLI_TO_MYCRM_FREQ_ID[repaymentFrequency],
  };
  if (clientIds) {
    result.client_ids = clientIds;
  }
  return result;
}
