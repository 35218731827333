import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { intlShape, injectIntl } from 'react-intl';

import assetActions from 'actions/assetActions';
import UIActions from 'actions/UIActions';

import * as assetSelectors from 'selectors/assetSelectors';
import * as clientSelectors from 'selectors/clientSelectors';
import * as UISelectors from 'selectors/UISelectors';
import * as applicationSelectors from 'selectors/applicationSelectors';

import FormPopup, { formPopupProps } from 'components/PopupItem/FormPopup';
import AssetForm from 'components/PopupForms/AssetForm';
import { logEvent, EVENTS } from 'lib/amplitude';
import { ASSETS_SLUG } from 'constants/applyData';

class AssetPopup extends FormPopup {
  static propTypes = {
    ...formPopupProps,
    intl: intlShape.isRequired,
    setAssetClientIds: PropTypes.func.isRequired,
    setAssetType: PropTypes.func.isRequired,
    setAssetCategory: PropTypes.func.isRequired,
    setAssetMake: PropTypes.func.isRequired,
    setAssetYear: PropTypes.func.isRequired,
    setAssetValue: PropTypes.func.isRequired,
    setAssetDescription: PropTypes.func.isRequired,
    setAssetError: PropTypes.func.isRequired,
  };

  title() {
    const { working } = this.props;
    const formStateTitle = super.title();
    if (formStateTitle) {
      return `${formStateTitle} asset`;
    }
    return working.id ? 'Edit your asset' : 'Add an asset';
  }

  value = () => undefined;

  logSaveEvent() {
    logEvent(EVENTS.SAVE_FINANCIALS, { section: ASSETS_SLUG });
  }

  logSaveAndAddEvent() {
    logEvent(EVENTS.SAVE_AND_ADD_FINANCIALS, { section: ASSETS_SLUG });
  }

  logRemoveEvent() {
    logEvent(EVENTS.REMOVE_FINANCIALS, { section: ASSETS_SLUG });
  }

  renderForm() {
    const {
      isLocked,
      working,
      clientIdOwnershipOptions,
      setAssetClientIds,
      setAssetType,
      setAssetCategory,
      setAssetMake,
      setAssetYear,
      setAssetValue,
      setAssetDescription,
      setAssetError,
    } = this.props;

    return (
      <AssetForm
        key={`assetPopupForm-${this.state.refreshKey}`}
        isLocked={isLocked}
        working={working}
        clientIdOwnershipOptions={clientIdOwnershipOptions}
        setAssetClientIds={setAssetClientIds}
        setAssetType={setAssetType}
        setAssetCategory={setAssetCategory}
        setAssetMake={setAssetMake}
        setAssetYear={setAssetYear}
        setAssetValue={setAssetValue}
        setAssetDescription={setAssetDescription}
        setAssetError={setAssetError}
        save={this.save}
        remove={this.remove}
        saveAndAddAnother={this.saveAndAddAnother}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const paramId = ownProps.match.params.id;
  const id = parseInt(paramId, 10) || paramId || 'new';
  return {
    id,
    item: assetSelectors.entity(state)(id),
    working: assetSelectors.working(state)(id),
    clientIdOwnershipOptions: clientSelectors.clientIdOwnershipOptions(state),
    requestIsProcessing: UISelectors.requestProcessing(state)(id),
    errors: UISelectors.requestErrors(state),
    isLocked: applicationSelectors.getIsLocked(state),
    formPopUpStatus: UISelectors.formPopUpStatus(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const paramId = ownProps.match.params.id;
  const id = parseInt(paramId, 10) || paramId || 'new';
  return bindActionCreators(
    {
      load: assetActions.loadAsset,
      create: assetActions.createAsset,
      update: assetActions.updateAsset,
      delete: assetActions.deleteAsset,
      clearWorking: assetActions.clearWorkingAsset,
      clearErrors: UIActions.clearAsyncRequestErrors,
      resetFormPopupStatus: UIActions.resetFormPopupStatus,
      setAssetType: assetActions.setAssetType(id),
      setAssetCategory: assetActions.setAssetCategory(id),
      setAssetClientIds: assetActions.setAssetClientIds(id),
      setAssetMake: assetActions.setAssetMake(id),
      setAssetYear: assetActions.setAssetYear(id),
      setAssetValue: assetActions.setAssetValue(id),
      setAssetDescription: assetActions.setAssetDescription(id),
      setAssetError: assetActions.setAssetError(id),
    },
    dispatch,
  );
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(AssetPopup),
);
