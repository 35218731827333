export const CLEAR_WORKING_ADDRESS = 'clearWorkingAddress';
export const LOAD_ADDRESS = 'loadAddress';
export const LOAD_NEW_CURRENT_ADDRESS = 'loadNewCurrentAddress';
export const LOAD_NEW_MAIL_ADDRESS = 'loadNewMailAddress';
export const SET_ADDRESS = 'setAddress';
export const SET_NEW_ADDRESS = 'setNewAddress';
export const SET_NEW_ADDRESSES = 'setNewAddresses';

export const SET_IS_AT_PRIMARY_ADDRESS = 'setIsAtPrimaryAddress';
export const SET_IS_PRIMARY_AT_PARTNER_ADDRESS = 'setIsPrimaryAtPartnerAddress';
export const SET_INTL_ADDRESS = 'setIntlAddress';

export const CREATE_ADDRESS = 'createAddress';
export const UPDATE_ADDRESS = 'updateAddress';
export const DELETE_ADDRESS = 'deleteAddress';
export const REMOVE_ADDRESS = 'removeAddress';

export const CONFIRM_ADDRESSES = 'confirmAddresses';
export const CONFIRM_CURRENT_HOME = 'confirmCurrentHome';
