import { MYCRM_TO_MILLI_FREQ_ID } from 'shared/lib/frequencyHelper';
import { UNCATEGORISED_EXPENSE_NAME } from 'shared/constants/myCRMTypes/expenses';
import { historyBuilderForMilli } from './history';

export function expenseSanitizerForMilli(data) {
  if (data.id === undefined) {
    return false;
  }
  if (data.type_id === undefined || data.type_id <= 0) {
    return false;
  }
  if (data.frequency_id === undefined) {
    return false;
  }
  return true;
}

export function expenseBuilderForMilli(expense) {
  return {
    id: expense.id,
    value: expense.value || 0,
    clientIds: expense.client_ids ? expense.client_ids.sort() : undefined,
    categoryId: expense.category_id || null,
    typeId: expense.type_id,
    frequency: MYCRM_TO_MILLI_FREQ_ID[expense.frequency_id],
    description: expense.description,
    updatedAt: expense.update_at,
    uncategorised: expense.description === UNCATEGORISED_EXPENSE_NAME,
    ...historyBuilderForMilli(expense),
  };
}
