import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import immutability from 'redux-immutable-state-invariant';

import storageSave from './storageSave';
import trackAnalytics from './trackAnalytics';
import trackErrors from './trackErrors';
import ieEventPolyFill from './ieEventPolyFill';
import rollout from './rollout';

export const sagaMiddleware = createSagaMiddleware();

export function getMiddlewares(extraMiddlewares) {
  const middlewares = [
    storageSave,
    sagaMiddleware,
    ieEventPolyFill,
    ...extraMiddlewares,
  ];

  if (process.env.NODE_ENV === 'development') {
    middlewares.push(
      createLogger({ collapsed: true, diff: true }),
      immutability(),
    );
  }

  if (window.LM_CONFIG.TAG_MANAGER_ID) {
    middlewares.push(trackAnalytics());
  }

  if (window.LM_CONFIG.SENTRY_REACT_DSN) {
    middlewares.push(trackErrors());
  }

  if (window.LM_CONFIG.ROLLOUT_API_KEY) {
    middlewares.push(rollout);
  }

  return middlewares;
}
