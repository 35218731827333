import { DIRECT_PROPERTIES_API_PATH } from 'shared/constants/apiPaths';

import {
  axiosWithAuth as directAxiosWithAuth,
  dataTransform as directDataTransform,
  returnJSON as directReturnJSON,
} from './mycrmBaseApi';
import { propertyBuilderForMyCRM } from 'shared/lib/builders/mycrm/property';
import { propertyBuilderForMilli } from 'shared/lib/builders/milli/property';
import { fundingBuilderForMilli } from 'shared/lib/builders/milli/funding';
import { fundingBuilderForMyCRM } from 'shared/lib/builders/mycrm/funding';
import { incomeBuilderForMyCRM } from 'shared/lib/builders/mycrm/income';
import { incomeBuilderForMilli } from 'shared/lib/builders/milli/income';

// Begin port of serverless propertiesApi
//
const putPropertyDirect = (propertyId, data) => {
  return directAxiosWithAuth
    .put(`${DIRECT_PROPERTIES_API_PATH}/${propertyId}`, data)
    .then(directReturnJSON);
};

const delPropertyDirect = (propertyId) => {
  return directAxiosWithAuth
    .delete(`${DIRECT_PROPERTIES_API_PATH}/${propertyId}`)
    .then(directReturnJSON);
};

export const postFundingsForPropertyDirect = (propertyId, data) => {
  return directAxiosWithAuth
    .post(`${DIRECT_PROPERTIES_API_PATH}/${propertyId}/fundings`, data)
    .then(directReturnJSON);
};

export const getFundingsFromPropertyDirect = (propertyId) => {
  return directAxiosWithAuth
    .get(`${DIRECT_PROPERTIES_API_PATH}/${propertyId}/fundings`)
    .then(directReturnJSON);
};

export const postIncomeForPropertyDirect = (propertyId, data) => {
  return directAxiosWithAuth
    .post(`${DIRECT_PROPERTIES_API_PATH}/${propertyId}/incomes`, data)
    .then(directReturnJSON);
};
//
// End of port of serverless propertiesApi

// port of serverless handler: properties/properties.put
export function putProperty(property) {
  return putPropertyDirect(property.id, propertyBuilderForMyCRM(property))
    .then(propertyBuilderForMilli)
    .then(directDataTransform((data) => data))
    .then(directReturnJSON);
}

// port of serverless handler: properties/properties.del
export function delProperty(propertyId) {
  return delPropertyDirect(propertyId).then(directReturnJSON);
}

// port of serverless handler: properties/properties.getFundings
export function getFundingsFromProperty(propertyId) {
  return getFundingsFromPropertyDirect(propertyId)
    .then(directDataTransform((data) => data))
    .then(directDataTransform((data) => data.map(fundingBuilderForMilli)))
    .then(directReturnJSON);
}

// port of serverless handler: properties/properties.postFundings
export function postFundingsForProperty(fundings) {
  return postFundingsForPropertyDirect(
    fundings[0].propertyId,
    fundings.map((f) => fundingBuilderForMyCRM(f)),
  )
    .then(directDataTransform((data) => data))
    .then(directDataTransform((data) => data.map(fundingBuilderForMilli)))
    .then(directReturnJSON);
}

// port of serverless handler: properties/properties.postIncomes
export function postIncomeForProperty(income) {
  const { category } = income;
  return postIncomeForPropertyDirect(
    income.propertyId,
    incomeBuilderForMyCRM(income),
  )
    .then(directDataTransform((data) => data))
    .then(
      directDataTransform((data) => ({
        ...incomeBuilderForMilli(data),
        category,
      })),
    )
    .then(directReturnJSON);
}
